import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, Row} from 'react-bootstrap';
import CollectionTile from "./CollectionTile";
import {Button} from "react-bootstrap";
import {browserHistory} from 'react-router';
import {COLLECTION_PAGE} from "../../constants/Routes";


class CollectionTileView extends Component {


    render() {

        let collections = [];

        if (this.props.collectionPages) {
            if (window.location.pathname === "/") {
                for (let i = 0; i < 4; i++) {
                    let collectionsComp = <CollectionTile key={this.props.collectionPages[i].id}
                                                          collection={this.props.collectionPages[i]}/>;
                    collections.push(collectionsComp);
                }
            } else {
                this.props.collectionPages.map(collection => {
                    let collectionsComp = <CollectionTile key={collection.id} collection={collection}/>;
                    collections.push(collectionsComp);
                    return null;
                });
            }
        }

        return (

            <Grid className="collection-block">
                <Row>
                    {collections}
                </Row>
                {window.location.pathname === "/" ?
                    <p style={{marginTop: "40px"}} className={"collection-block-button"}>
                        <Button onClick={() => browserHistory.push(COLLECTION_PAGE)}>View all</Button>
                    </p>
                    : null}
            </Grid>

        );
    }
}

export default CollectionTileView = connect(state => {
    return {
        user: state.data.campableUser,
    }
})(CollectionTileView)