import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {browserHistory} from 'react-router';

export default class CallToActionBecomeAHost extends Component {

    render() {
        let buttonText = this.props.content.button || null;
        let buttonLink = this.props.content.link || "/become-a-host";

        return (

                <div className="cta-become-a-host">
                   {buttonText ? <a onClick={() => browserHistory.push(buttonLink)}><Button>{buttonText}</Button></a> : null }
                </div>

        );
    }

}