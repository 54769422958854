/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 12/09/18.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './OnlineRepublicWidget.css';
import classnames from "classnames";
import motorhome_republic_logo from "../../../resources/images/motorhome_republic_logo.png";


export default class OnlineRepublicWidget extends Component {

    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
    };

    static defaultProps = {
        className: null,
        classes: [],
    };

    render() {

        const className = classnames("online_republic_widget_container", this.props.className, this.props.classes);

        return (
            <div className={className}>
                <div className={"left"}>

                    <h4>Book your vehicle with</h4>
                    <div>
                        <img src={motorhome_republic_logo}/>
                    </div>
                    <h5>The world’s No.1 motorhome site</h5>
                </div>
                <iframe id={"online_republic_widget"} className={"right"} src={"https://www.motorhomerepublic.com/affiliates.html?rel=search&aff=campable&tag=&height=400&width=350&target=_blank&pickupFilter=&dropoffFilter=&extra_header_file=&resCountry=1&textDate=0&encodeurl=0&version=2&style=default.css"}>

                </iframe>
            </div>
        );
    }
}
