import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Grid, Row} from 'react-bootstrap';
import Load from '../components/Load';
import '../App.css';
import {adminCreateAffiliateTransfer, adminFetchAffiliateBookings, adminFetchAffiliates, getLocation} from '../actions/index';
import AdminBookingsController from "../components/admin/AdminBookingsController"
import moment from "moment";


class AdminAffiliateContainer extends Component {

    constructor(props) {
        super(props);
        this.fetchData = this.fetchData.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.payAffiliate = this.payAffiliate.bind(this);
        this.selectAllBookings = this.selectAllBookings.bind(this);
        this.unselectAllBookings = this.unselectAllBookings.bind(this);
        this.unselectBooking = this.unselectBooking.bind(this);
        this.selectBooking = this.selectBooking.bind(this);
        this.fetchBookings = this.fetchBookings.bind(this);
        this.filterBookings = this.filterBookings.bind(this);

        this.state = {
            selectedBookings : [],
            filteredBookings : []
        };

    }

    componentDidMount() {
       this.fetchData(this.props);
    }

    componentWillReceiveProps(nextProps) {
       this.fetchData(nextProps);  
    }

    unselectBooking(booking) {
        //Remove booking from selected bookings
        let selected = this.state.selectedBookings || [];

        this.state.selectedBookings.map((selectedBooking, i) => {
            if (booking.id === selectedBooking.id) {
                if (i > -1) {
                    selected.splice(i, 1);
                }
            }
            return false;
        });

        this.setState({
            selectedBookings: selected
        });

    }

    selectBooking(booking) {
        let selected = this.state.selectedBookings || [];
        selected.indexOf(booking) === -1 ? selected.push(booking) : console.log("This item already exists");

        this.setState({
            selectedBookings: selected
        });

    }


    selectAllBookings() {
        let userId = this.props.params.userId;
        let user;

        (this.props.affiliates || []).map((affiliate) => {
            if (affiliate.id === userId) {
                user = affiliate;
            }
            return null;
        });

        if (this.props.affiliateBookings && user) {
            if (this.props.affiliateBookings[userId] !== undefined) {
                let affiliateBookings = this.props.affiliateBookings[userId];

                let selectedBookings = affiliateBookings.filter((booking) => {
                    return !booking.referrerTransfer;
                });
                this.setState({
                    selectedBookings : selectedBookings
                });
            }
        }

    }

    unselectAllBookings() {
        this.setState({
            selectedBookings : []
        });
    }
    
    async payAffiliate(referrer, bookings) {
        let bookingIds = bookings
            .filter(booking => booking.status === "accepted" && !booking.referrerTransfer)
            .map(booking => booking.uid);

        await this.props.dispatch(adminCreateAffiliateTransfer(referrer.id, bookingIds));
        this.unselectAllBookings();
    }

    fetchData(nextProps) {
            if (nextProps.campableUser) {

                if (!nextProps.affiliates && !nextProps.isLoading) {
                    this.props.dispatch(adminFetchAffiliates());
                }

                if (nextProps.affiliates && !nextProps.isLoading) {
                    if(!nextProps.affiliateBookings || !nextProps.affiliateBookings[this.props.params.userId]) {
                        let fromDate = moment().subtract(30, "days").format('YYYY-MM-DD');
                        let toDate = moment().format('YYYY-MM-DD');
                        this.props.dispatch(adminFetchAffiliateBookings(this.props.params.userId, fromDate, toDate, null, true));
                    }
                }

                if (nextProps.affiliateBookings && nextProps.affiliateBookings[this.props.params.userId]) {
                    this.filterBookings(false, nextProps);
                }
            }
    }

    fetchBookings(startDate, endDate) {
        this.setState( {
            selectedBookings : [],
            filteredBookings : []
        }, ()=> {
            this.props.dispatch(adminFetchAffiliateBookings(this.props.params.userId, startDate, endDate, null, true));
        });

    }

    filterBookings(showPaid, nextProps) {
        //console.log("Filter Show Paid", showPaid);

        if (nextProps === undefined) {
            nextProps = this.props;
        }

        let userId = nextProps.params.userId;

        if (showPaid) {
            let filtered = nextProps.affiliateBookings[userId] || []
            this.setState({
                filteredBookings : filtered
            });
        } else if (nextProps.affiliateBookings && nextProps.affiliateBookings[userId]) {
            if (nextProps.affiliateBookings[userId] !== undefined) {

                let affiliateBookings = nextProps.affiliateBookings[userId];
                let filtered = [];
                (affiliateBookings || []).map((booking)=>{
                    if (!booking.referrerTransfer) {
                        filtered.push(booking);
                    }
                    return null;
                });
                this.setState({
                    filteredBookings : filtered
                });
            }
        }
    }

    getLocation(id){
        this.props.dispatch(getLocation(id));
    }


     render() {
        
        let userId = this.props.params.userId;
        let user;

         (this.props.affiliates || []).map((affiliate) => {
             if (affiliate.id === userId) {
                 user = affiliate;
             }
             return null;
         });

        if (!user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let affiliateBookingsPanel;

        if (this.props.affiliateBookings && user) {
            if(this.props.affiliateBookings[userId] !== undefined) {

               let affiliateBookings = this.props.affiliateBookings[userId];
                
                affiliateBookingsPanel =  ( <Row>
                                                <Col xs={12}>
                                                    <AdminBookingsController affiliate={user}
                                                                             bookings={affiliateBookings}
                                                                             selectedBookings={this.state.selectedBookings}
                                                                             filteredBookings={this.state.filteredBookings}
                                                                             payAffiliate={this.payAffiliate}
                                                                             selectAllBookings={this.selectAllBookings}
                                                                             unselectAllBookings={this.unselectAllBookings}
                                                                             unselectBooking={this.unselectBooking}
                                                                             selectBooking={this.selectBooking}
                                                                             isLoading={this.props.isLoading}
                                                                             fetchBookings={this.fetchBookings}
                                                                             filterBookings={this.filterBookings}
                                                    />
                                                </Col>
                                             </Row>
                                            );
            }
        }
        return (
            <Grid className="main-container">
                <Row>
                    <h1>{user ? user.firstName + " " + user.lastName : "User: No Name"}</h1>
                </Row>
                {affiliateBookingsPanel}
            </Grid>

        );
    }
}

export default AdminAffiliateContainer = connect((state) => {

    return {
        affiliates: state.admindata.affiliates,
        campableUser: state.data.campableUser,
        affiliateBookings: state.admindata.affiliateBookings,
        isLoading:state.auth.isLoading
    }
})(AdminAffiliateContainer);