import React, {Component} from 'react';
import ValidatedTextInput from '../common/ValidatedTextInput';
import image from '../../resources/images/green-pin-campervan@2x.png';
import DropdownSelect from "../common/DropdownSelect";
import {Col, Row} from 'react-bootstrap';


export default class LocationHeader extends Component {

    render() {

        const showSelector = this.props.handleListedSelectChange !== null;

        let unlisted = null;
        if (showSelector && (!!this.props.statusValue ? this.props.statusValue === 'Off' : true)) {
            unlisted = (
                <div className="location-unlisted">This property is unlisted</div>
            );
        }

        console.log("this.props.statusValue", this.props.statusValue)

        return (
            <div className="location-header">
                <Row>
                    <Col xs={12}>
                        <Col xs={12} sm={9} className="locations-panel-details">
                            <img alt='header' src={image}/>
                            <ValidatedTextInput className="form-name" placeholder="e.g. Title" name="name"/>

                        </Col>
                        <Col xs={12} sm={3}>

                            {unlisted}
                            {showSelector ? <DropdownSelect label={'Status:'}
                                                            selectOptions={["On", "Off"]}
                                                            value={this.props.statusValue}
                                                            onChange={this.props.handleListedSelectChange}
                                                            className="dropDown"/>
                                : null}

                        </Col>
                    </Col>
                </Row>
            </div>
        );
    }
}
