import React, {Component} from 'react';
/* eslint-disable */
export default class LocationFeatures extends Component {

    render() {
        let content = [];
        if (this.props.features) {
            this.props.features.map((feature) => {
                if (this.props.tagsObj[feature]){
                    let tagObj = this.props.tagsObj[feature];
                    let featClass = feature.replace("site_feature:","");
                    let uiComp = <li className={featClass} key={feature}>{tagObj.label}</li>;
                    content.push(uiComp);
                }
            });

        }
        return (
            <ul>
                {content}
            </ul>
        );
    }
}