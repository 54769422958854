import React, {Component} from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {Button, Col, Grid, Row} from "react-bootstrap";
import {validate} from "../components/common/form-validation/UpdatePropertyValidation";
import {fetchProperty, fetchUserProperties, updateProperty} from "../actions/";
import {browserHistory} from "react-router";
import MenuContainer from "./MenuContainer";
import {BECOME_A_HOST} from "../constants/Routes";
import {showAlert, updatePropertyStatus} from "../actions/index";
import {isAdmin} from "../auth";
import {fireTagEvent} from "../tagmanager";
import HostLocationDetailView from "../components/hostLocation/HostLocationDetailView";
import ProgressInformation from "../components/hostLocation/ProgressInformation";

class HostLocationContainer extends Component {

    constructor(props) {
        super(props);
        this.saveLocationDetails = this.saveLocationDetails.bind(this);
        this.stripeButtonPressed = this.stripeButtonPressed.bind(this);
        this.removeSitePressed = this.removeSitePressed.bind(this);
        this.handleListedSelectChange = this.handleListedSelectChange.bind(this);
        this.fetchProperty = this.fetchProperty.bind(this);

        this.fetchProperty(props);

        if (props.properties && props.properties[props.params.locationId]) {
            this.state = {
                statusValue: props.properties[props.params.locationId].status,
            }
        } else {
            this.state = {
                statusValue: 'Listed'
            }
        }
    }


    fetchProperty(nextProps) {

        //Manage state and user to get correct properties
        if (nextProps.campableUser) {
            //load the property from the url parameter
            if (!nextProps.userProperties) {
                this.props.dispatch(fetchUserProperties());
            }

            if (nextProps.userProperties && !nextProps.property) {
                this.props.dispatch(fetchProperty(nextProps.params.locationId));
            }

        }
    }


    componentWillReceiveProps(nextProps) {
        this.fetchProperty(nextProps);
        if (nextProps.properties && nextProps.properties[nextProps.params.locationId]) {
            this.setState({
                statusValue: nextProps.properties[nextProps.params.locationId].status,
            });
        }
    }

    removeSitePressed(index) {
        let propertyValue = (this.props.property || {});

        if (propertyValue.propertyInformation.sites[index]) {

            propertyValue.propertyInformation.sites[index].status = "deleted";
            let sites = propertyValue.propertyInformation.sites;

            this.props.dispatch(updateProperty({
                id: propertyValue.id,
                name: propertyValue.name,
                latitude: propertyValue.address.latitude,
                longitude: propertyValue.address.longitude,
                address: propertyValue.address.address,
                tags: [],
                images: propertyValue.images,
                directions: propertyValue.directions,
                propertyInformation: {
                    sites: sites
                }
            }));
        }
    }

    stripeButtonPressed() {
        browserHistory.push(BECOME_A_HOST);
    }

    saveLocationDetails(values) {
        let sites = values.propertyInformation.sites.map((site) => {
            if (!site.active && site.status !== 'deleted') {
                site.status = "active";
            }
            return site
        });

        let data = {
            id: values.id,
            name: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
            address: values.address.address,
            tags: values.tags,
            images: values.images,
            description: values.description,
            propertyInformation: {
                sites: sites,
                noticePeriod: values.propertyInformation.noticePeriod === 'Guests can book on the day' ? 0 : 1
            }
        };

        fireTagEvent("update-a-property", this.props.campableUser);
        this.props.dispatch(updateProperty(data));
    }

    handleListedSelectChange(e) {
        const propertyId = this.props.params.locationId;
        const numOfImages = this.props.properties[this.props.params.locationId].images.length;

        this.props.dispatch(showAlert("info", "Updating location status..."));

        if (e === "On") {
            if (numOfImages !== 0) {
                this.setState({
                    statusValue: e
                });
                this.props.dispatch(updatePropertyStatus(propertyId, "active"));
            } else {
                this.props.dispatch(showAlert('warning', 'There was a problem listing your property', 'Please add at least one photo to your property to list.'));
            }

        } else if (e === "Off") {

            this.setState({
                statusValue: e
            });
            this.props.dispatch(updatePropertyStatus(propertyId, "inactive"));
        }
    }


    render() {

        let location = this.props.property;

        let isAdminn = false;

        if (this.props.campableUser && isAdmin(this.props.campableUser)) {
            isAdminn = true;
        }

        let saveChanges;

        let progressInfo = <ProgressInformation errors={this.props.errors}/>;
        if (this.props.valid && this.props.campableUser && this.props.campableUser.accountInformation.valid) {
            progressInfo = null
        }

        if (this.props.dirty) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={2}/>
                        <Col md={4}>
                            <h4>You have made some changes</h4>
                            <p>Click Update to make your changes public</p>
                        </Col>
                        <Col md={4}>
                            {progressInfo}
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit" disabled={!(this.props.valid && this.props.campableUser && this.props.campableUser.accountInformation.valid)}>Update</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        let statusValue = 'Off';

        if (!!location) {
            statusValue = location.status === "active" ? "On" : "Off";
        }

        return (
            <Grid className="main-container">
                <Row>
                    <h1>Your Properties</h1>
                    <Col md={3} className="menu"><MenuContainer currentRoute={this.props.route.path}/></Col>
                    <form onSubmit={this.props.handleSubmit(this.saveLocationDetails)}>
                        <HostLocationDetailView removeSitePressed={this.removeSitePressed}
                                                stripeButtonPressed={this.stripeButtonPressed} {...this.props}
                                                handleListedSelectChange={this.handleListedSelectChange}
                                                statusValue={statusValue}
                                                location={location}
                                                isAdmin={isAdminn}/>
                        {saveChanges}
                    </form>
                </Row>
            </Grid>

        );
    }
}


HostLocationContainer = reduxForm({
    form: 'locationDetails',
    validate,
})(HostLocationContainer);

export default HostLocationContainer = connect((state, props) => {
    let property = state.data.properties && state.data.properties[props.params.locationId] ? state.data.properties[props.params.locationId] : '';

    return {
        properties: state.data.properties,
        property: property,
        userProperties: state.data.userProperties,
        tags: state.data.tags,
        campableUser: state.data.campableUser,
        initialValues: parseForm(props, state.data.properties),
        enableReinitialize: true,
        errors: state.form && state.form.locationDetails && state.form.locationDetails.syncErrors ? state.form.locationDetails.syncErrors : {},
    }

})(HostLocationContainer);


function parseForm(props, properties) {
    let locationId = parseInt(props.params.locationId, 10);
    let propertyValue = (properties || {})[locationId] || {};
    let temp;
    if (typeof propertyValue.address === 'string') {
        temp = (propertyValue.address || {});

        propertyValue.address = {
            address: temp,
            latitude: propertyValue.latitude,
            longitude: propertyValue.longitude
        };
    }

    if (propertyValue.propertyInformation && propertyValue.propertyInformation.noticePeriod !== undefined && !isNaN(propertyValue.propertyInformation.noticePeriod)) {
        propertyValue.propertyInformation.noticePeriod = propertyValue.propertyInformation.noticePeriod === 1 ? 'I would like one (1) day\'s notice' : 'Guests can book on the day';
    }

    return propertyValue

}




