
/* eslint-disable */

export function fireTagEvent( event, user, params) {

    let id = (user || {}).id;
    let usertype = "guest";
    let referrer = undefined;

    if(user !== undefined && user !== null){
        usertype = "camper";

        if(user.accountInformation !== undefined){
            usertype = "host";
        }

        if(user.referrer !== null && user.referrer !== undefined){
            referrer = user.referrer;
        }
    }

    let dataLayer = window.dataLayer || [];
    let eventdata = {'event': event, 'userid': id, 'usertype': usertype, 'referrer': referrer};
    if(params !== undefined){
        eventdata = Object.assign(eventdata, params);
    }
    dataLayer.push(eventdata);
    //console.log("pushing event to gtm: " + event);

}

export function fireECommerceEvent(user, type, listsource, data) {

    let id = (user || {}).id;
    let usertype = "guest";
    let referrer = undefined;

    if(user !== undefined && user !== null){
        usertype = "camper";

        if(user.accountInformation !== undefined){
            usertype = "host";
        }

        if(user.referrer !== null && user.referrer !== undefined){
            referrer = user.referrer;
        }
    }

    if (type === "impressions") {
        sendImpressions(id, usertype, referrer, listsource, data);
    } else if(type === "detail"){
        sendDetail(id, usertype, referrer, data);
    }
}

function sendImpressions(id, usertype, referrer, listsource, data){
    let dataLayer = window.dataLayer || [];
    let impressions = [];

    for(let i in data){
        let impression = {};
        impression["list"] = listsource;
        impression["position"] = i+1;
        impression["id"] = data[i].id;
        impression["name"] = data[i].name;
        impression["brand"] = data[i].id;
        impressions.push(impression);
    }

    dataLayer.push({
        'event': 'impression',
        'userid': id,
        'usertype': usertype,
        'referrer': referrer,
        'ecommerce': {
            'impressions': impressions
        }
    });
}

function sendDetail(id, usertype, referrer, data){
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'detail',
        'userid': id,
        'usertype': usertype,
        'referrer': referrer,
        'ecommerce': {
            'detail': {
                //'actionField': {'list': 'Search Results'},      // Optional list property.
                'products': [{
                    'name': data.name,
                    'id': data.id,
                    'brand': data.id
                }]
            }
        }
    });
}

export function sendECommerceAdd(location, site, date, price){
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
            'add': {
                'products': [{
                    'id': location.id,
                    'name': location.name,
                    'brand': location.owner,
                    'variant': date,
                    'price': site.price,
                    'quantity': 1
                }]
            }
        }
    });
}

export function sendECommerceRemove(location, site, date, price){
    let dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
            'remove': {
                'products': [{
                    'id': location.id,
                    'name': location.name,
                    'brand': location.owner,
                    'variant': date,
                    'price': site.price,
                    'quantity': 1
                }]
            }
        }
    });
}

export function sendECommercePurchase(bookings, coupon){
    //coupon not used yet - jacob to decide if relevent

    let dataLayer = window.dataLayer || [];
    let products = [];
    let totalPaid = 0;
    for(let i in bookings){
        let booking = {};
        booking["status"] = bookings[i].status;
        booking["name"] = bookings[i].locationName;
        booking["brand"] = bookings[i].host;
        products.push(booking);

	totalPaid += bookings[i].paid;
    }

    let transactionId = bookings[0].transactionReference;
    dataLayer.push({
        'event': 'purchase',
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': transactionId,
		            'revenue': totalPaid
                },
                'products': products
            }
        }
    });
}
