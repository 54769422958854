import React, {Component} from 'react';
import Autocomplete from 'react-google-autocomplete';
//import {Button, Image} from 'react-bootstrap';
//import whiteMagnifyingGlass from '../../resources/images/white-magnifying-glass.svg';


export default class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.onPlaceSelected = this.onPlaceSelected.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onSearchButtonPressed = this.onSearchButtonPressed.bind(this);
    }

    onKeyPress(value) {

    }

    onPlaceSelected(suggestion) {
        let country = "nz";
        let postcode = "1010";
        let city = "Auckland";

        suggestion.address_components.map((component) => {
            component.types.map((type) => {
                if (type === "postal_code") {
                    postcode = component.long_name;
                } else if (type === "sub_locality") {
                    city = component.long_name;
                } else if (type === "country") {
                    country = component.short_name.toLowerCase();
                }

                return null;
            })
            return null;
        });

        let value = {
            latitude: suggestion.geometry.location.lat(),
            longitude: suggestion.geometry.location.lng(),
            address: suggestion.formatted_address,
            postcode: postcode,
            country: country,
            city: city
        }

        this.props.searchLocation(value);
    }

    onSearchButtonPressed() {
        this.autoCompleteTextBox.props.onPlaceSelected();

    }

    render() {

        return (
            <div className="search-bar">
                <div className="search-field">
                    <Autocomplete
                        ref={(ref) => {this.autoCompleteTextBox = ref}}
                        className={"form-control"}
                        onKeyPress={this.onKeyPress}
                        onPlaceSelected={this.onPlaceSelected}
                        types={['(regions)']}
                        componentRestrictions={{country: "nz"}}
                    />
                </div>
            </div>



        )

    }

}

