import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';

export default class CallToActionPageEnd extends Component {

    render() {

        let buttonText = this.props.content.button || null;
        let buttonLink = this.props.content.buttonLink || null;
        let buttonText2 = this.props.content.button2 || null;
        let buttonLink2 = this.props.content.buttonLink2 || null;


        return (
            <div className="call-to-action-page-end">
                <Row>
                    <Col md={4} className="call-to-action-banner-buttons">
                        {buttonText ? <a href={buttonLink}><Button>{buttonText}</Button></a> : null }
                    </Col>
                    <Col md={8} className="call-to-action-banner-buttons">
                        {buttonText2 ? <a href={buttonLink2}><Button>{buttonText2}</Button></a> : null }
                    </Col>
                </Row>
            </div>
        );
    }

}