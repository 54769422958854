import React, {Component} from 'react';
import {Field} from 'redux-form';
import ImageInput from './inputs/ImageInput';


export default class ValidatedFileInput extends Component {
	render() {
		let name = "image";
		if(this.props.name){
			name = this.props.name;
		}
		return (
			<div className="form-image-input">
	 			<label htmlFor="formControlsFile" className="control-label">{this.props.label}</label>
	 			<Field name={name} component={ImageInput} />
    		</div>
		);
	}  
}

