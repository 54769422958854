import React, {Component} from "react";
import {ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import Cleave from "cleave.js/dist/cleave-react";

export default class CreditCardInput extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }



    onChange(event) {
        let newTarget = event.target;
        newTarget.value = event.target.rawValue;

        event.target = newTarget;
        this.props.input.onChange(event);
    }


    render() {
        let validationState = null;
        let helpBlock;

        if (this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;


        return (
            <FormGroup readOnly validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <Cleave className="cleave"
                        options={{blocks: [4, 4, 4, 4], delimiter: '-', numericOnly: true}}
                        {...this.props.input}
                        {...inputProps}
                        onChange={this.onChange}/>
                {helpBlock}
            </FormGroup>)
    }

}

