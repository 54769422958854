import React, {Component} from "react";
import {Field} from "redux-form";
import CreditCardInput from "./inputs/CreditCardInput";

export default class ValidatedCreditCardInput extends Component {

	render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }

		return (
			<div className={className}>
        		<Field component={CreditCardInput} {...this.props}/>
      		</div>	
		);
	}  
}
