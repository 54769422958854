import React, {Component} from "react";
import Card from "../../components/Card";
import {Grid} from "react-bootstrap";
import ValidatedBankAccountInput from "../common/ValidatedBankAccountInput";
import Loading from "react-loading";
import {getStripeAccountUpdateLink} from "../../actions";

export default class AccountHostDetails extends Component {

    constructor(props) {
        super(props);
        this.selectOptions = ["Company", "Individual"];
        this.state = {};
    }

    componentDidMount() {
        this.getUpdateLink();
    }

    getUpdateLink = async () => {
        const link = await getStripeAccountUpdateLink();
        this.setState({
            updateLink: link
        })
    }

    render() {

        if (!this.props.user.accountInformation || !this.props.user.accountInformation.valid) {
            return null;
        }

        let bankName = this.props.user.accountInformation.bankAccount.bankName || '';

        let loading = null;
        if (this.props.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }

        return (
            <div className="account-payment">
                <Card title="Host Details">
                    <Grid fluid>
                        {this.props.user.accountInformation.valid ?
                            <div>
                                <label>Bank Name</label>
                                <h4 className="bankName">{bankName}</h4>
                            </div> : null }
                        <a onClick={this.props.editBankDetailsPressed}>Change Bank Account</a>
                        <br/><br/>
                        {this.state.updateLink
                            ? <a href={this.state.updateLink}>Update Account Details</a>
                            : <span>Update Account Details</span>}

                        {!this.props.editingBankDetails ? null :
                            <div className="animated fadeIn edit-credit-card-container">
                                <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>

                                <ValidatedBankAccountInput name="bankAccount"
                                                           label="Bank Account Number"
                                                           placeholder="e.g. 00-1234-5678912-00"
                                                           required />
                            </div>
                        }
                    </Grid>
                    {loading}
                </Card>
            </div>
        );
    }
}
