import * as firebase from "firebase";
import moment from "moment";
import React from "react";
import tokenizer from "./tokenizer";

/* eslint-disable */

const USERS_API = "/v1/users";
const IMAGES_API = "/v1/images";
const LOCATIONS_API = "/v1/locations";
const TAGS_API = "/v1/tags";
const BOOKINGS_API = "/v1/bookings";
const CAMPAIGNS_API = "/v1/campaigns";
const ADMIN_API = "/v1/admin";
const CONTENT_API = "/v1/content";

/** USER **/

export function createUserInDatabase() {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return post(USERS_API, token)
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function updateUserEmailAdmin(userId, newEmail) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(ADMIN_API + "/user/update/" + userId, token, {
        email: newEmail,
      });
    });
}

export function updateCampableUser(user, cardInfo) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let params = {};
      let id = firebase.auth().currentUser.uid;
      if (user && user.id) {
        id = user.id;
      }
      if (cardInfo) {
        params = cardInfo;
      } else {
        params = user;
      }
      return put(`${USERS_API}/${id}`, token, params);
    })
    .then((response) => {
      return response;
    });
}

export function changeUserPasswordAdmin(id, newPassword) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(`${ADMIN_API}/user/${id}/password/${newPassword}`, token);
    })
    .then((r) => {
      return r;
    });
}

export function getCampableUser() {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let id = firebase.auth().currentUser.uid;
      return get(`${USERS_API}/${id}`, token);
    })
    .then((response) => {
      return response;
    });
}

export async function getCampableUserRequirements() {
  const token = await firebase.auth().currentUser.getIdToken();
  let id = firebase.auth().currentUser.uid;
  return get(`${USERS_API}/${id}/requirements`, token);
}

export function getCampableUserBookingsAPI(fromDate) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        let id = firebase.auth().currentUser.uid;

        if (!fromDate || fromDate === undefined) {
          fromDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        }
        return get(BOOKINGS_API, token, { guest: id, from: fromDate })
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            //console.log('BOOKINGS ERROR', err)
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function getLocationReviewsByUserAPI(locationId) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return get(LOCATIONS_API + "/" + locationId + "/reviews", token, {
          reviewer: r.user.uid,
        })
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function getCampableUserReviewsAPI() {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        let id = firebase.auth().currentUser.uid;
        return get(USERS_API + "/" + id + "/reviews", token)
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function createReviewAPI(locationId, rating, comment) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(LOCATIONS_API + "/" + locationId + "/reviews", token, {
        rating: rating,
        comment: comment,
      });
    })
    .then((response) => {
      return response;
    });
}

/** STRIPE **/
export function getStripeURL() {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let id = firebase.auth().currentUser.uid;
      return get(`${USERS_API}/${id}/account/stripe/authorize-url`, token);
    })
    .then((response) => {
      //console.log("Stripe url response", response);
      return response;
    });
}

export function getStripeBankToken(a) {
  let headers = {
    Accept: "application/x-www-form-urlencoded",
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${process.env.REACT_APP_STRIPE_KEY}`,
  };

  let url = `https://api.stripe.com/v1/tokens?bank_account[country]=${a.country}&bank_account[currency]=${a.currency}&bank_account[account_holder_name]=${a.accountName}&bank_account[account_number]=${a.accountNumber}&bank_account[routing_number]=${a.routingNumber}`;

  let request = new Request(url, {
    method: "POST",
    headers: headers,
  });
  return new Promise((resolve, reject) => {
    fetch(request)
      .then((response) => {
        return response
          .json()
          .then((json) => {
            return response.ok ? resolve(json) : reject(json);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export function updateStripeCode(code) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let id = firebase.auth().currentUser.uid;
      let stripeCode;
      stripeCode = {
        code: code,
      };
      return put(USERS_API + "/" + id + "/account/stripe", token, stripeCode);
    })
    .then((response) => {
      //console.log("Stripe code response", response);
      return response;
    });
}

export async function createStripeAccount(account) {
  const token = await firebase.auth().currentUser.getIdToken();
  let userId = firebase.auth().currentUser.uid;
  return post(`${USERS_API}/${userId}/account/stripe`, token, account);
}

export async function updateStripeAccount(userId, account) {
  const token = await firebase.auth().currentUser.getIdToken();
  return put(`${USERS_API}/${userId}/account/stripe`, token, account);
}

export async function getStripeAccountVerificationLink(payload) {
  const token = await firebase.auth().currentUser.getIdToken();
  let userId = firebase.auth().currentUser.uid;
  return post(
    `${USERS_API}/${userId}/account-verification-link`,
    token,
    payload
  );
}

export async function getStripeAccountUpdateLink(payload) {
  const token = await firebase.auth().currentUser.getIdToken();
  let userId = firebase.auth().currentUser.uid;
  return post(`${USERS_API}/${userId}/account-update-link`, token, payload);
}

export function contactUs(payload) {
  return post("/v1/contact_us", null, payload);
}

/** TAGS **/
export function getTags() {
  return get(TAGS_API);
}

export function createTag(tag) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(TAGS_API, token, tag);
    })
    .then((response) => {
      return response;
    });
}

/** BOOKINGS **/
export function getHostBookings(locationId, ownerId, cursor) {
  let params = {
    host: ownerId,
    location: locationId,
    cursor: cursor,
  };
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(BOOKINGS_API, token, params);
    })
    .then((response) => {
      return response;
    });
}

/** Create/Cancel booking(s) **/
export function createBookingsAPI(id, bookings) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(LOCATIONS_API + "/" + id + "/bookings", token, {
        bookings: bookings,
      });
    })
    .then((response) => {
      return response;
    });
}

export function cancelBooking(uid) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(BOOKINGS_API + "/" + uid + "/status", token, {
        status: "cancelled",
      });
    })
    .then((response) => {
      return response;
    });
}

/** Get Site Availability **/
export function getLocationSiteAvailabilitiesAPI(id, from, to) {
  let today = moment();
  let fromDate = today;

  if (from || from !== undefined) {
    fromDate = from.clone();
  }

  if (fromDate.diff(today.clone().subtract(1, "days"), "days") > 0) {
    let dayInWeek = fromDate.day();
    let firstDayOfWeek = fromDate.clone().subtract(dayInWeek, "days");

    if (firstDayOfWeek.diff(today.clone().subtract(1, "days"), "days") <= 0) {
      fromDate = today;
    } else {
      fromDate = firstDayOfWeek;
    }
  }

  if (!to || to === undefined) {
    to = moment(fromDate).add(28, "days");
  }

  return get(LOCATIONS_API + "/" + id + "/availability", "", {
    from: fromDate.format("YYYY-MM-DD"),
    to: to.format("YYYY-MM-DD"),
  });
}

/** IMAGE **/
export function createImage(files) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      const formData = new FormData();
      formData.isformdata = true;
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      return post(IMAGES_API, token, formData);
    })
    .then((response) => {
      return response;
    });
}

/** LOCATIONS **/
export function getLocations(params) {
  return get(LOCATIONS_API, null, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log('locations fetch error', error);
      return error;
    });
}

export function adminGetLocations(params) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(LOCATIONS_API, token, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          //console.log('locations fetch error', error);
          return error;
        });
    })
    .catch((err) => {
      return err;
    });
}

export function addReferralCodeAPI(userId, referallCode) {
  firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(USERS_API + "/" + userId + "/referral", token, {
        referrer: referallCode,
      })
        .then(() => {
          //fire and forget
          return;
        })
        .catch((error) => {
          //console.log('locations fetch error', error);
          return error;
        });
    });
}

export async function getTransactions(cursor) {
  return new Promise(async (resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then(async (token) => {
        const id = firebase.auth().currentUser.uid;

        console.log("token", token);
        return get(`${USERS_API}/${id}/account/transactions`, token, {
          cursor: cursor,
        })
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            console.log("transaction error", err);
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function getAccountBalance(userId) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return get(`${USERS_API}/${userId}/account/balance`, token)
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function getLocation(id) {
  return get(LOCATIONS_API + "/" + id)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log('location fetch error', error);
      return error;
    });
}

export function createProperty(property) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(LOCATIONS_API, token, property)
        .then((r) => {
          return r;
        })
        .catch((err) => {
          return err;
        });
    })
    .catch((err) => {
      return err;
    });
}

export function updateProperty(property) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(LOCATIONS_API + "/" + property.id, token, property)
        .then((r) => {
          return r;
        })
        .catch((err) => {
          return err;
        });
    })
    .catch((err) => {
      return err;
    });
}

export function updatePropertyStatus(propertyId, status) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(LOCATIONS_API + "/" + propertyId + "/status", token, {
        status: status,
      });
    })
    .then((response) => {
      //console.log("update property response", response);
      return response;
    });
}

export function getUserProperties(userId) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let id = firebase.auth().currentUser.uid;
      if (userId) {
        id = userId;
      }
      return get(`${USERS_API}/${id}/properties`, token);
    })
    .then((response) => {
      return response.items;
    })
    .catch((error) => {
      //console.log('firebase error', error);
      return error;
    });
}

export function createCampaign(values) {
  const params = {
    type: values.campaignType.replace(" ", ""),
    code: values.code,
    userMessage: values.userMessage,
    validUntil: values.dates.to,
    creditAmount: values.creditAmount,
    validFrom: values.dates.from,
    numberOfNights: values.numberOfNights,
    percentageDiscount: values.percentageDiscount,
    location: values.locationId,
    site: values.siteId,
    tourCode: values.collection,
    userEmail: values.userEmail,
    maximumRedeemCount: values.maxUses,
    affiliateEmail: values.affiliateEmail,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(CAMPAIGNS_API, token, params).then((r) => {
        return r;
      });
    });
}

export function updateCampaign(id, values) {
  const params = {
    validUntil: values.validUntil,
    status: values.status,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(CAMPAIGNS_API + "/" + id, token, params).then((r) => {
        return r;
      });
    });
}

export function adminFetchCampaigns(type, code, limit, cursor) {
  const params = {
    type: type,
    code: code,
    cursor: cursor,
    limit: limit,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(CAMPAIGNS_API + "/search", token, params);
    });
}

export function adminFetchAffiliates() {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(USERS_API + "/affiliates", token);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function adminFetchAffiliateUsers(id, cursor) {
  const params = {
    id: id,
    cursor: cursor,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(USERS_API + "/" + id + "/affiliate", token, params);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log('firebase error', error);
      return error;
    });
}

export async function adminCreateAffiliateTransfer(referrer, bookings) {
  let params = {
    referrer: referrer,
    bookings: bookings,
  };

  let token = await firebase.auth().currentUser.getIdToken();
  return post(ADMIN_API + "/transfer", token, params);
}

export function adminFetchAffiliateBookings(
  referrer,
  fromDate,
  toDate,
  cursor
) {
  let params = {
    referrer: referrer,
    from: fromDate,
    to: toDate,
  };

  if (cursor) {
    params = {
      referrer: referrer,
      fromDate: fromDate,
      toDate: toDate,
      cursor: cursor,
    };
  }

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(BOOKINGS_API, token, params);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function adminFetchUsers(search, cursor) {
  const params = {
    search: search,
    cursor: cursor,
  };

  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(USERS_API, token, params);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      //console.log('firebase error', error);
      return error;
    });
}

export function adminFetchUser(id) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(`${USERS_API}/${id}`, token);
    })
    .then((response) => {
      return response;
    });
}

export function adminFetchUserBookings(userId, cursor) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return get(BOOKINGS_API, token, { guest: userId, cursor: cursor })
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            //console.log('BOOKINGS ERROR', err)
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function adminFetchPropertyBookings(locationId, userId, cursor) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return resolve(
          get(BOOKINGS_API, token, {
            location: locationId,
            host: userId,
            cursor: cursor,
          })
        );
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function adminFetchTransactions(userId, cursor) {
  return new Promise((resolve, reject) => {
    return firebase
      .auth()
      .currentUser.getIdToken()
      .then((token) => {
        return get(`${USERS_API}/${userId}/account/transactions`, token, {
          cursor: cursor,
        })
          .then((r) => {
            return resolve(r);
          })
          .catch((err) => {
            return reject(err);
          });
      })
      .catch((err) => {
        return reject(err);
      });
  }).catch((err) => {
    return err;
  });
}

export function adminAddAffiliateToUserAPI(userId, affiliateEmail) {
  return tokenizer((token) => {
    return put(`${USERS_API}/${userId}/affiliate/add`, token, {
      email: affiliateEmail,
    });
  });
}

export function adminRemoveAffiliatefromUserAPI(userId) {
  return tokenizer((token) => {
    return del(`${USERS_API}/${userId}/affiliate/del`, token);
  });
}

export function adminBookingRefund(uid, reason) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(`${ADMIN_API}/refund`, token, { uid: uid, reason: reason });
    });
}

export function getProperty(id) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return get(`${LOCATIONS_API}/${id}`, token);
    });
}

/** CONTENT **/

export function getPage(slug) {
  return get(`${CONTENT_API}/pages/${slug}`);
}

export function getWidget(slug) {
  return get(`${CONTENT_API}/widgets/${slug}`);
}

export function getCollections() {
  return get(`${CONTENT_API}/collections`);
}

export function getCollectionPage(slug) {
  return get(CONTENT_API + "/collectionPage/" + slug);
}

export function getCollectionPages() {
  return get(CONTENT_API + "/collectionPages");
}

export function changeUserAffiliateStateAPI(userId) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return put(`${USERS_API}/${userId}/affiliate`, token);
    });
}

export function addRefferral(code) {
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      let uid = firebase.auth().currentUser.uid;

      return post(`${USERS_API}/${uid}/referral`, token, { referrer: code });
    });
}

export function uploadExistingImagesAPI(images) {
  const params = {
    images: images,
  };
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(IMAGES_API + "/existing", token, params);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export function uploadMultipleLocationsAPI(locations) {
  const params = {
    locations: locations,
  };
  return firebase
    .auth()
    .currentUser.getIdToken()
    .then((token) => {
      return post(LOCATIONS_API + "/many", token, params);
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

/** HTTP VERB WRAPPERS **/

function get(url, token, params) {
  const qs = [];
  if (params) {
    for (let param in params) {
      if (params[param] != null) {
        const p = encodeURIComponent(param);
        let v;
        if (
          Object.prototype.toString.call(params[param]) === "[object Array]"
        ) {
          v = encodeURIComponent(params[param].join(","));
        } else {
          v = encodeURIComponent(params[param]);
        }

        qs.push(p + "=" + v);
      }
    }
  }

  let query = "";
  if (qs.length > 0) {
    query = "?" + qs.join("&");
  }

  return fetchJSON("get", url + query, token);
}

export function post(url, token, data) {
  return fetchJSON("post", url, token, data);
}

function put(url, token, data) {
  return fetchJSON("put", url, token, data);
}

function del(url, token, data) {
  return fetchJSON("delete", url, token, data);
}

// function del(url, token, data) {
//     return fetchJSON('delete', url, token, data);
// }

function fetchJSON(method, url, token, data) {
  return new Promise((resolve, reject) => {
    if (url.match(/^\//)) {
      url = process.env.REACT_APP_CAMPABLE_API_URL + url;
    }

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (token) {
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      };
    }

    //console.log(method.toUpperCase(), url);

    let body = null;
    let requestParams = {
      method: method,
      headers: headers,
    };

    // noinspection EqualityComparisonWithCoercionJS
    if (method !== "get" && data != null) {
      if (data.isformdata) {
        body = data;
        delete headers["Content-Type"]; //'multipart/form-data'
      } else {
        body = JSON.stringify(data);
        //console.log('post data', body)
      }
      requestParams = {
        ...requestParams,
        body: body,
      };
    }

    //setTimeout(() => {

    let request = new Request(url, requestParams);

    fetch(request)
      .then((response) => {
        ////console.log('raw response:',response);
        return response
          .json()
          .then((json) => {
            //console.log('json response:',json);
            return response.ok ? resolve(json) : reject(json);
          })
          .catch((e) => {
            //console.log('error: ', e);
          });
      })
      .catch((e) => {
        //console.log('error here', e);
      });

    //}, 4000);
  });
}
