import React, {Component} from 'react';
import {Row} from 'react-bootstrap';
import ListingsItem from './ListingsItem';
import take from 'lodash/take';

export default class LocationCollection extends Component {


    render() {

        let locations=[];

        let fourLocations = take(this.props.locations, 8);

        fourLocations.map(location=> {
            let locationComp = <ListingsItem key={location.id} location={location}/> ;
            locations.push(locationComp);
            return null;
        });

        return (
                <Row>
                    {locations}
                </Row>

        );
    }
}