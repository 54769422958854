import React, {Component} from "react";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {MenuItem, Nav, Navbar, NavDropdown, NavItem} from "react-bootstrap";
import logo from "../resources/images/campable-logo.svg";
import whiteLogo from "../resources/images/campable-logo-white.svg";
import "../App.css";
import * as Routes from "../constants/Routes";
import {setLanguage} from "../actions/index";

class NavBar extends Component {

    onLoginPress = () => {
        this.props.showLoginOverlay();
    };

    onSignUpPress = () => {
        this.props.showLoginOverlay(true);
    };

    onLanguageSelect = (lang) => {
        this.props.dispatch(setLanguage(lang));
    };

    render() {

        let userHasProperty = false;

        if (this.props.user && this.props.user.accountInformation && this.props.user.accountInformation.valid) {
            userHasProperty = true;
        }

        const currentPage = this.props.pathName;

        let myHostingEventKey = Routes.HOSTING_LOCATIONS;
        if (currentPage && (this.props.pathName === Routes.HOSTING_LOCATIONS
            || currentPage === Routes.HOSTING_LOCATIONS_ID
            || currentPage === Routes.HOSTING_BOOKINGS
            || currentPage === Routes.HOSTING_CREATE_A_PROPERTY
            || currentPage === Routes.HOSTING_TRANSACTIONS)) {
            myHostingEventKey = currentPage;
        }

        let languageMenuItems = this.props.supportedLanguages.map(l => <MenuItem key={l.code} onClick={() => this.onLanguageSelect(l)}>{l.name}</MenuItem>);

        return (
            <Navbar collapseOnSelect>
                <Navbar.Header>
                    <Navbar.Brand>
                        <a onClick={() => browserHistory.push('/')} href="/">
                            {this.props.isHomepage
                                ? <img src={whiteLogo} className="logo" alt="campable logo"/>
                                : <img src={logo} className="logo" alt="campable logo"/>
                            }
                        </a>
                        <a className="navbar-link-to-map" onClick={() => browserHistory.push('/locations')} href="/locations">Where can I stay?</a>
                    </Navbar.Brand>
                    <Navbar.Toggle/>
                </Navbar.Header>
                <Navbar.Collapse>
                    <Nav pullRight activeKey={currentPage}>
                        {this.props.currentPage === Routes.FAQ_WIFI ? (
                                <NavDropdown title={this.props.selectedLanguage.code} id="language-dropdown">
                                    {languageMenuItems}
                                </NavDropdown>
                            ) : null
                        }
                        {this.props.isAdmin ? <NavItem eventKey={Routes.ADMIN} onClick={() => browserHistory.push(Routes.ADMIN)}>Admin</NavItem> : null}
                        {userHasProperty ?
                            <NavItem eventKey={myHostingEventKey} onClick={() => browserHistory.push(Routes.HOSTING_LOCATIONS)}>My Hosting</NavItem> :
                            <NavItem className="become-a-host-nav-link" eventKey="/why-host" onClick={() => browserHistory.push('/why-host')}>Become a Host</NavItem>
                        }
                        <NavItem onClick={() => window.open("http://partners.campable.com")}>Partner With Us</NavItem>
                        {this.props.loggedIn ? <NavItem eventKey={Routes.BOOKINGS} onClick={() => browserHistory.push(Routes.BOOKINGS)}>My Trips</NavItem> : null}

                        <NavDropdown eventKey={1} title="Help" id="basic-nav-dropdown">
                            <MenuItem eventKey="/how-does-campable-work" onClick={() => browserHistory.push('/how-campable-works')}>How does Campable Work?</MenuItem>
                            <MenuItem eventKey="/after-booking" onClick={() => browserHistory.push('/after-booking')}>After Booking</MenuItem>
                            <MenuItem eventKey="/why-host" onClick={() => browserHistory.push('/why-host')}>Why Host with Campable?</MenuItem>
                            <MenuItem eventKey="/faq-guest" onClick={() => browserHistory.push('/faq-guest')}>FAQ - Guests</MenuItem>
                            <MenuItem eventKey="/faq-host" onClick={() => browserHistory.push('/faq-host')}>FAQ - Hosts</MenuItem>
                            <MenuItem eventKey="/rules-of-etiquette" onClick={() => browserHistory.push('/rules-of-etiquette')}>Rules of Etiquette</MenuItem>
                            <MenuItem eventKey="/health-and-safety" onClick={() => browserHistory.push('/health-and-safety')}>Health and Safety</MenuItem>
                            <MenuItem eventKey="/terms-and-conditions" onClick={() => browserHistory.push('/terms-and-conditions')}>Terms and Conditions</MenuItem>
                            <MenuItem eventKey="/contact-us" onClick={() => browserHistory.push('/contact-us')}>Contact Us</MenuItem>
                        </NavDropdown>
                        {!this.props.loggedIn ?
                            <NavItem eventKey={Routes.SIGN_UP} onClick={this.onSignUpPress}>Join</NavItem>
                            :
                            <NavDropdown eventKey={2} title="Account" id="basic-nav-dropdown">
                                <MenuItem eventKey={Routes.ACCOUNT} onClick={() => browserHistory.push(Routes.ACCOUNT)}>Account</MenuItem>
                                <MenuItem eventKey={Routes.LOGIN} onClick={this.props.onLogOutPress}>Log Out</MenuItem>
                            </NavDropdown>

                        }

                        {userHasProperty ? <NavItem className="second-mobile-nav" eventKey={Routes.HOSTING_LOCATIONS} onClick={() => browserHistory.push(Routes.HOSTING_LOCATIONS)}>My
                            Properties</NavItem> : null}
                        {userHasProperty ?
                            <NavItem className="second-mobile-nav" eventKey={Routes.HOSTING_BOOKINGS} onClick={() => browserHistory.push(Routes.HOSTING_BOOKINGS)}>Bookings</NavItem> : null}
                        {userHasProperty ?
                            <NavItem className="second-mobile-nav" eventKey={Routes.HOSTING_CREATE_A_PROPERTY} onClick={() => browserHistory.push(Routes.HOSTING_CREATE_A_PROPERTY)}>Create
                                Property</NavItem> : null}

                        {!this.props.loggedIn ?
                            <NavItem eventKey={Routes.LOGIN} onClick={this.onLoginPress}>Log In</NavItem>
                            : null

                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default NavBar = connect((state, props) => {
    return {
        currentPage: state.routing.locationBeforeTransitions.pathname,
        supportedLanguages: state.languages.supported,
        selectedLanguage: state.languages.selected
    }
})(NavBar);
