import React, {Component} from "react";
import image from "../../resources/images/booking-land.svg";
import LocationBookingCalendar from "./LocationBookingCalendar";
import {Col, Row} from "react-bootstrap";
import {SingleDatePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "airbnb-browser-shims";

export default class LocationCampsite extends Component {


    constructor(props) {
        super(props);

        this.onDateChange = this.onDateChange.bind(this);
        this.onFocusChanged = this.onFocusChanged.bind(this);

        this.state = {
            focused: false,
        }
    }

    onDateChange(event) {
        this.props.onDateSelectedApply(event);
    }

    onFocusChanged(focused) {
        this.setState({
            ...focused,
        });
    }

    render() {

        let site = this.props.site;

        let name = "Campable Site";
        if (site.name) {
            name = site.name;
        }

        let features = [];
        if (site.tags && site.tags.length > 0 && this.props.tagsObj !== null) {
            let tagsAdded= 0;
            features = site.tags.map((feature) => {
                if (this.props.tagsObj[feature]) {
                    let tagObj = this.props.tagsObj[feature];
                    tagsAdded++;
                    return <span key={feature}>{tagObj.label}{tagsAdded === site.tags.length? '' : ', '}</span>;
                }
                return undefined;
            });
        }
        
        return (
            <div className="booking-information-container">
                <div className="booking-info-white-area">
                    <Row>
                        <Col xs={8} sm={10}>
                            <img src={image} width="75" height="75" alt="booking campsite icon"/>
                            <div className="booking-info-textbox">
                                <h4>{name}</h4>
                                {this.props.tagsObj && this.props.tagsObj.label !== null ? <p className="booking-info-list-of-features">{features}</p> : null }
                            </div>
                        </Col>
                        <Col xs={4} sm={2}>
                            <SingleDatePicker
                                focused={this.state.focused}
                                onDateChange={this.onDateChange}
                                onFocusChange={this.onFocusChanged}
                                displayFormat="DD MMM YYYY"
                                date={this.props.selectedDate}
                                numberOfMonths={1}
                                hideKeyboardShortcutsPanel
                            />
                        </Col>
                    </Row>

                    <LocationBookingCalendar
                        siteAvailability={this.props.siteAvailability}
                        onBookingTilePress={this.props.onBookingTilePress}
                        selectedBookings={this.props.selectedBookings}
                        selectedDay={this.props.selectedDate}
                        campableUserBookings={this.props.campableUserBookings}
                        location={this.props.location}
                        site={site}/>
                </div>
            </div>
        );
    }
}