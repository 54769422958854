import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import {isAdmin} from '../auth';
import {Col, Grid, Row} from 'react-bootstrap';
import Load from '../components/Load';
import {adminFetchAffiliates} from '../actions/index';
import UserSummaryPanel from '../components/UserSummaryPanel';
import {ADMIN_AFFILIATES_ID} from "../constants/Routes";
import {browserHistory} from 'react-router';

class AdminAffiliatesContainer extends Component {
    
    constructor(props) {
        super(props);
        this.userPressed = this.userPressed.bind(this);
    }

  componentDidMount() {

        if (this.props.user) {
            this.props.dispatch(adminFetchAffiliates());
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.user) {
            if (!nextProps.affiliates) {
                this.props.dispatch(adminFetchAffiliates());
            }
        }
    }

    componentWillUnmount() {
        
    }

    userPressed(id) {
        browserHistory.push(ADMIN_AFFILIATES_ID + id + "/details");

    }

	render() {
		
				if ((!this.props.user) || (!this.props.affiliates)) {
		            return (
		                <div className="loaderContainer">
		                    <Load/>
		                </div>
		            )
        		}

        		if (isAdmin(this.props.user)) {

					let affiliates = this.props.affiliates;

                    let panel = (affiliates || []).map((affiliate) => {
                        return <UserSummaryPanel key={affiliate.id} user={affiliate}
												 userPressed={this.userPressed}/>
                    });

		            return (
		                <Grid className="main-container">
		                    <Row>
		                        <Col md={12}>
		                          <h1>Affiliates</h1>
		                        </Col>
		                    </Row>
							<Row>
								<Col md={12}>
									{panel}
								</Col>
							</Row>
		                </Grid>
		            );
        		}

		        return (
		            <div className="loaderContainer">
		                <h1> Sorry you are not an admin. </h1>
		            </div>
		        )
    		}

}


export default AdminAffiliatesContainer = connect((state) => {
    return {
        user: state.data.campableUser,
        affiliates: state.admindata.affiliates
	}
})(AdminAffiliatesContainer);