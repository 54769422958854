import {sendECommerceAdd, sendECommerceRemove} from "../tagmanager";
import * as Actions from "../actions/index";

let initialState = {
    notifications: null,
    stripeURL: null,
    properties: null,
    tags: null,
    tagsObj: null,
    campableUser: null,
    hostBookings: null,
    userProperties: null,
    locations: null,
    locationsIndex: null,
    siteAvailabilities: {},
    selectedBookings: {},
    campableUserBookings: {},
    campableUserReviews: [],
    transactions: null,
    requirements: null,
}


const data = (state = initialState, action) => {

    switch (action.type) {
        case 'REQUIREMENTS_RECEIVED':
            return {
                ...state,
                requirements: action.requirements
            }
        case 'NOTIFICATION_RECEIVED':
            let notifs = [];
            if (state.notifications) {
                notifs = state.notifications.concat([action.notification])
            } else {
                notifs.push(action.notification);
            }
            return {
                ...state,
                notifications: notifs
            }
        case 'REMOVE_NOTIFICATION_RECEIVED':
            let notification = action.notification;
            let notificationsArray = [];

            state.notifications.map((notif) => {

                if (notif.id !== notification.id) {
                    notificationsArray.push(notif);
                }

                return null;

            });

            return {
                ...state,
                notifications: notificationsArray
            }

        case 'LOG_OUT':

            return {
                ...initialState,
                notifications: state.notifications,
                stripeURL: state.stripeURL,
                properties: state.properties,
                tags: state.tags,
                tagsObj: state.tagsObj,
                locations: state.locations,
                locationsIndex: state.locationsIndex,
                siteAvailabilities: state.siteAvailabilities,
            };

        case 'CAMPABLE_USER_RECEIVED':

            if(!state.campableUser || state.campableUser.id === action.campableUser.id) {
                return {
                    ...state,
                    campableUser: action.campableUser
                }
            }
            else{
                //admin updated a different user, let admin reducer take care of this.
                return state;
            }

        case 'USER_PROPERTIES_RECEIVED':

            return {
                ...state,
                userProperties: action.userProperties
            }
        case 'LOCATIONS_CLEAR':
            return {
                ...state,
                locations: null
            }
        case 'LOCATIONS_RECEIVED': {

            const locations = state.locations || [];
            for(var idx in action.locations.items){
                var found = false;
                for(var innerIdx in locations){
                    if(action.locations.items[idx].id === locations[innerIdx].id){
                        found = true;
                        break;
                    }
                }
                if(!found){
                    locations.push(action.locations.items[idx]);
                }
            }
            return {
                ...state,
                locations: locations.slice(),
                deepCopyFix: new Date()
            }
        }
        case 'LOCATION_RECEIVED':
            return {
                ...state,
                locationsIndex: {
                    ...state.locationsIndex,
                    [action.location.id]: action.location
                }
            }


        case 'CHANGE_PROPERTY':
            return {
                ...state,
                properties: {
                    ...state.properties,
                    [action.property.id]: action.property
                }
            }

        case 'PROPERTY_RECEIVED':
            return {
                ...state,
                properties: {
                    ...state.properties,
                    [action.property.id]: action.property
                }
            };

        case 'TRANSACTIONS_RESET':
            return {
                ...state,
                transactions: null,
            };

        case 'TRANSACTIONS_RECEIVED':
            let items = action.transactions.items;
            if (state.transactions && state.transactions.items) {
                items = state.transactions.items.concat(action.transactions.items);
            }

            return {
                ...state,
                transactions: {
                    ...action.transactions,
                    items: items,
                }
            }

        case 'TAGS_RECEIVED':
            let tagsObj = {};
            // eslint-disable-next-line
            action.tags.map((tag) => {
                tagsObj[tag.id] = tag;
            });
            return {
                ...state,
                tags: action.tags,
                tagsObj: tagsObj,
            }

        case 'HOST_BOOKINGS_RESET':
            return {
                ...state,
                hostBookings: null,
            }

        case 'HOST_BOOKINGS_RECEIVED':

            let hostBookings = {...state.hostBookings};

            // eslint-disable-next-line
            action.hostBookings.map((booking) => {
                hostBookings[booking.uid] = booking;
            });

            return {
                ...state,
                hostBookings: hostBookings,

            }
        case 'SITE_AVAILABILITY_RECEIVED':
            return {
                ...state,
                siteAvailabilities: action.siteAvailabilities,
            };

        case 'SITE_AVAILABILITY_CLEAR':
            return {
                ...state,
                siteAvailabilities: {},
            };


        case 'USER_BOOKINGS_RECEIVED':

            let currentUserBookings = {...state.campableUserBookings};
            let bookings = action.hostBookings;

            if (bookings) {
                // eslint-disable-next-line
                bookings.map((booking) => {
                    let currentLocationBookingsOnDate = {};
                    if (currentUserBookings[booking.date]) {
                        if (currentUserBookings[booking.date][booking.location]) {
                            currentLocationBookingsOnDate = currentUserBookings[booking.date][booking.location];
                        }
                    }
                    currentLocationBookingsOnDate[booking.id] = booking;
                    currentUserBookings[booking.date] = {
                        ...currentUserBookings[booking.date],
                        [booking.location]: currentLocationBookingsOnDate,
                    };
                });
            }

            return {
                ...state,
                campableUserBookings: currentUserBookings,
            };

        case 'SELECTED_BOOKINGS_ONPRESS':
            let selectedBookings = {...state.selectedBookings};
            let formattedDate = action.day.clone().format('YYYY-MM-DD');
            if (selectedBookings[formattedDate]) {
                sendECommerceRemove(action.location, action.site, formattedDate, action.price);
                delete selectedBookings[formattedDate];
            } else {
                sendECommerceAdd(action.location, action.site, formattedDate, action.price);
                selectedBookings[formattedDate] = {
                    site: action.site.id,
                    date: formattedDate,
                    price: action.price,
                }
            }

            return {
                ...state,
                selectedBookings: selectedBookings,
            };
        case 'SHOW_BOOKING_CONFIRM_MODAL':
            return {
                ...state,
                showBookingConfirmationModal: action.showConfirmationModal
            }
        case 'SELECTED_BOOKINGS_CLEAR':
            return {
                ...state,
                selectedBookings: {},
            }

        case 'USER_REVIEWS_RECEIVED':
            let campableUserReviews = state.campableUserReviews;

            // eslint-disable-next-line
            if (action.reviews) {
                // eslint-disable-next-line
                action.reviews.map((review) => {
                    campableUserReviews.push(review);
                });
            }
            return {
                ...state,
                campableUserReviews: campableUserReviews,
            };

        case 'USER_REVIEWS_CLEAR':
            return {
                ...state,
                campableUserReviews: null,
            }

        case Actions.LOCATION_FILTER_RECEIVED:
            return {
                ...state,
                locationFilter: action.locationFilter
            };
        case 'COLLECTION_PAGES_RECEIVED': {
            return {
                ...state,
                collectionPages: action.collectionPages
            }
        }
        case 'BULK_IMAGES_RECEIVED':{

            var images = [];
            if(state.uploadedImages){
                state.uploadedImages.map((image) => {
                    images.push(image);
                });
            }

            action.images.map((image) => {
                images.push(image);
            });

            return {
                ...state,
                uploadedImages: images
            }
        }
        case 'BULK_LOCATIONS_RECEIVED':{
            return {
                ...state,
                uploadedLocations: action.locations
            }
        }

        default:
            return state
    }
}

export default data
