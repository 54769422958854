import React, {Component} from 'react';
import {Col} from 'react-bootstrap';

export default class ListItem extends Component {

    render() {

        let iconUrl;
        if (this.props.content.icon && this.props.content.icon.url.length > 0) {
            iconUrl = this.props.content.icon.url;
        }
        let title = this.props.content.title || null;
        let text = this.props.content.text || null;
        let columns = 3;

        if (this.props.columns){
            columns = 12 / this.props.columns;
        }

        return (
            <Col md={columns} className="list-block">
                { iconUrl ? <img src={iconUrl} alt={title}/> : null }
                { title ? <h4>{title}</h4> : null }
                { text ? <p>{text}</p> : null }
            </Col>
        );
    }

}