import React, {Component} from 'react';
import AccountPayment from './AccountPayment';
import {reduxForm} from 'redux-form';
import {validate} from '../common/form-validation/CreditCardValidation';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import {createCreditCardToken} from "../../api/ApiStripe";
import {showAlert, updateCampableUserCreditCard} from "../../actions/index";

class AccountPaymentController extends Component {


    constructor(props) {
        super(props);
        this.saveCreditCard = this.saveCreditCard.bind(this);
        this.onEditCreditCardClick = this.onEditCreditCardClick.bind(this);

        this.state = {
            showEditCreditCard: false,
            loading: false,
        }
    }

    saveCreditCard(values) {

        this.setState({
            loading: true
        });

        this.props.dispatch(showAlert('info', 'Updating your credit card information...', ''));
        createCreditCardToken(values).then((r) => {
            let stripeToken = r.id;
            this.props.dispatch(updateCampableUserCreditCard({
                paymentInformation: {
                    token: stripeToken,
                }
            }));

            this.props.reset();

            this.setState({
                loading: false,
                showEditCreditCard: false,
            });
        }).catch((err) => {
            this.setState({
                loading: false
            });
            this.props.dispatch(showAlert('danger', 'There was an error while updating your credit card.', err.error.message))
        });
    }

    onEditCreditCardClick() {
        this.setState({
            showEditCreditCard: !this.state.showEditCreditCard,
        }, () => {
            if (!this.state.showEditCreditCard) {
                this.props.reset();
            }
        });
    }

    render() {
        let saveChanges;
        if (this.props.valid && this.props.dirty) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={3}/>
                        <Col md={7}>
                            <h4>You have made some changes</h4>
                            <p>Click Update your credit card</p>
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit">Update</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        return (
            <div>
                <Row>

                    <Col md={3} xs={12}/>
                    <Col md={9} xs={12}>
                        <form onSubmit={this.props.handleSubmit(this.saveCreditCard)}>
                            <AccountPayment user={this.props.user}
                                            onEditCreditCardClick={this.onEditCreditCardClick}
                                            showEditCreditCard={this.state.showEditCreditCard}
                                            loading={this.state.loading}
                            />
                            {this.state.showEditCreditCard ? saveChanges : null}
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}

AccountPaymentController = reduxForm({
    form: 'paymentDetails',
    validate
})(AccountPaymentController);

export default AccountPaymentController = connect(() => {
    return {initialValues: {}}
})(AccountPaymentController);

