/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 8/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
/* eslint-disable */
export default function detectBrowser(userAgentString) {
    if (!userAgentString) return null;
    let browsers = [
        [ 'edge', /Edge\/([0-9\._]+)/ ],
        [ 'yandexbrowser', /YaBrowser\/([0-9\._]+)/ ],
        [ 'chrome', /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/ ],
        [ 'crios', /CriOS\/([0-9\.]+)(:?\s|$)/ ],
        [ 'firefox', /Firefox\/([0-9\.]+)(?:\s|$)/ ],
        [ 'opera', /Opera\/([0-9\.]+)(?:\s|$)/ ],
        [ 'opera', /OPR\/([0-9\.]+)(:?\s|$)$/ ],
        [ 'ie', /Trident\/7\.0.*rv\:([0-9\.]+)\).*Gecko$/ ],
        [ 'ie', /MSIE\s([0-9\.]+);.*Trident\/[4-7].0/ ],
        [ 'ie', /MSIE\s(7\.0)/ ],
        [ 'bb10', /BB10;\sTouch.*Version\/([0-9\.]+)/ ],
        [ 'android', /Android\s([0-9\.]+)/ ],
        [ 'ios', /Version\/([0-9\._]+).*Mobile.*Safari.*/ ],
        [ 'safari', /Version\/([0-9\._]+).*Safari/ ]
    ];

    return browsers.map(function (rule) {
        if (rule[1].test(userAgentString)) {
            let match = rule[1].exec(userAgentString);
            let version = match && match[1].split(/[._]/).slice(0,3);

            if (version && version.length < 3) {
                Array.prototype.push.apply(version, (version.length === 1) ? [0, 0] : [0]);
            }

            return {
                name: rule[0],
                version: version.join('.')
            };
        }
    }).filter(Boolean).shift();
};