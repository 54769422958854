import React, {Component} from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";

import img_star_unrated from "../../resources/images/yellow-star-unrated.svg";
import img_star_rating from "../../resources/images/yellow-star-rating.svg";

import {Button} from "react-bootstrap";
import ValidatedTextBox from "../common/ValidatedTextBox";
import {createReview} from "../../actions/index";

class BookingReviewController extends Component {

    constructor(props) {
        super(props);
        this.createReview = this.createReview.bind(this);
        this.onStarClick = this.onStarClick.bind(this);

        this.state = {
            rating: 0
        }

    }


    createReview(x) {
        this.props.dispatch(createReview(this.props.groupedBooking.locationId, this.state.rating, x.comment));
    }

    onStarClick(rating) {
        this.setState({rating: rating});
    }

    render() {

        let createReview;
        if (this.state.rating >= 1) {
            createReview = <Button type="submit">Create Review</Button>;
        }


        return (
            <div className="reviews-container">
                <div className="star-container">
                    <label>Rating: </label>
                    <img src={this.state.rating >= 1 ? img_star_rating : img_star_unrated} alt="rating-star" className="star"
                         onClick={() => {
                             this.onStarClick(1)
                         }}/>
                    <img src={this.state.rating >= 2 ? img_star_rating : img_star_unrated} alt="rating-star" className="star"
                         onClick={() => {
                             this.onStarClick(2)
                         }}/>
                    <img src={this.state.rating >= 3 ? img_star_rating : img_star_unrated} alt="rating-star" className="star"
                         onClick={() => {
                             this.onStarClick(3)
                         }}/>
                    <img src={this.state.rating >= 4 ? img_star_rating : img_star_unrated} alt="rating-star" className="star"
                         onClick={() => {
                             this.onStarClick(4)
                         }}/>
                    <img src={this.state.rating === 5 ? img_star_rating : img_star_unrated} alt="rating-star" className="star"
                         onClick={() => {
                             this.onStarClick(5)
                         }}/>
                </div>

                <form onSubmit={this.props.handleSubmit(this.createReview)}>
                    <ValidatedTextBox label="Comment: " name="comment"/>
                    {createReview}
                </form>
            </div>
        )
    }
}

export default BookingReviewController = connect((state) => {
    return {
        initialValues: {},
    }
})(BookingReviewController = reduxForm({
    form: 'createReview',
    validate: () => {}
})(BookingReviewController));
