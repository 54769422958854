/**
 * Created by brodynelson on 8/09/17.
 */

import React, {Component} from "react";
import {WHITE_GREY} from "../../constants/Colors";
import {toProperCase} from "../../constants/Constants";
import moment from "moment";
import {Checkbox, Col, Row} from "react-bootstrap";

export default class AdminBookingsRowItem extends Component {

    constructor(props) {
        super(props);
        this.formatDate = this.formatDate.bind(this);
        this.state = { selected: this.props.selected };
        this.handleChange = this.handleChange.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selected: nextProps.selected });
    }


    handleChange(event) {
        this.setState({ selected: event.target.checked });
        if(event.target.checked) {
            this.props.selectBooking(this.props.booking);
        } else {
            this.props.unselectBooking(this.props.booking);
        }

    }


    render() {

        let booking = this.props.booking;
        let affiliate = this.props.affiliate;

        let transferAmount = this.props.calculateTransfer(booking);
        let feePercentage = 0;

        if (affiliate) {
            feePercentage = affiliate.referralTerms.feePercentage || 0
        }


        let dateOfArrival = moment(booking.date);
        let dayOfArrival = dateOfArrival.clone().format('DD');
        let monthOfArrival = toProperCase(dateOfArrival.clone().format('MMM'));
        let formattedArrival = dayOfArrival + ' ' + monthOfArrival;

        let hasBookingCancelled = false; //TO DO; booking.status === CANCELLED

        let bookingPriceDetails = <div className="location-tile-price"><span className="price-dollar-symbol">$</span>{booking.price / 100}</div>;
        let amountPaid = (booking.paid / 100) || "No data";
        let amountRefunded = (booking.refunded / 100) || 0;
        // let todayDate = moment();

        let checkBox = booking.referrerTransfer ? null : <Checkbox className="admin-checkbox"
                                                                   checked={this.state.selected}
                                                                   onChange={this.handleChange} />
        return (


            <Row className="location-panel" style={{backgroundColor: hasBookingCancelled ? WHITE_GREY : null}}>
                <Col xs={12}>
                    <Row>
                        <Col>
                            <h3>{booking.locationName}</h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Booking Date</h5>
                                <p>{formattedArrival}</p>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Booking Total</h5>
                                <p>${amountPaid}</p>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Refunded</h5>
                                <p>${amountRefunded}</p>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Affiliate Fee</h5>
                                <p>{feePercentage}%</p>
                            </div>
                        </Col>
                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Total Owed</h5>
                                <p>${transferAmount}</p>
                            </div>
                        </Col>

                        <Col md={2} sm={4} xs={6}>
                            <div className="booking-date-box">
                                <h5>Transfer Status</h5>
                                <p>{booking.referrerTransfer ? <strong>Paid</strong> : <strong>Not Paid</strong>}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} sm={6} xs={12}>
                            <ul>
                                { booking.hostFirstName ? <li>Hosted by: {booking.hostFirstName} {booking.hostLastName}</li> : null}
                                { booking.hostEmail ? <li>Email: {booking.hostEmail}</li> : null }
                                { booking.hostPhone ? <li>Phone: {booking.hostPhone}</li> : null }
                            </ul>
                        </Col>
                        <Col md={4} sm={6} xs={12}>
                            <ul>
                                { booking.guestFirstName ? <li>Guest: {booking.guestFirstName} {booking.guestLastName}</li> : null}
                                { booking.guestEmail ? <li>Email: {booking.guestEmail}</li> : null }
                                { booking.guestPhone ? <li>Phone: {booking.guestPhone}</li> : null }
                            </ul>
                        </Col>
                        <Col md={4}>
                            {checkBox}
                        </Col>
                    </Row>
                </Col>
                {bookingPriceDetails}
            </Row>

                );
    }

    formatDate(dateStr) {
        var monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const date = new Date(dateStr);

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }
}
