import React, {Component} from 'react';
import AccountHostDetails from './AccountHostDetails';
import {reduxForm} from 'redux-form';
import {validate} from '../common/form-validation/StripeValidation';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import {campableUserReceived, showAlert} from "../../actions/index";
import {createStripeAccount, getStripeBankToken, updateStripeAccount} from "../../api/api";

class AccountHostDetailsController extends Component {


    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.editBankDetailsPressed = this.editBankDetailsPressed.bind(this);

        this.state = {
            editingBankDetails: false,
            loading: false,
        };
    }

    onSubmit(values) {

        this.setState({
            loading: true
        });

        const bankAccount = values.bankAccount.replace(new RegExp('-', 'g'), '');

        let routingNumber = bankAccount.substring(0, 6);
        let accountNumber = bankAccount.substring(6);

        if (accountNumber.length === 9) {
            let firstOnes = accountNumber.slice(0, 7);
            let last2 = accountNumber.slice(7);
            accountNumber = firstOnes + '0' + last2;
        }

        let bankDetails = {
            currency: "nzd",
            accountNumber: accountNumber,
            routingNumber: routingNumber,
            country: "NZ"
        };

        this.props.dispatch(showAlert('info', 'Updating Host details...', 'Your bank details are being verified.'));

        getStripeBankToken(bankDetails).then((token) => {

            const account = {
                token: token.id
            };

            updateStripeAccount(this.props.user.id, account).then((user) => {
                this.setState({
                    loading: false,
                });
                this.props.dispatch(showAlert('success', 'Success!', 'Your account has been updated!'));
                this.props.dispatch(campableUserReceived(user));
                this.props.reset();
                this.setState({
                    editingBankDetails: false,
                    loading: false,
                });
            }).catch((err) => {
                if (err.message && err.message === 'invalid user or account') {
                    createStripeAccount(account).then((user) => {
                        this.props.dispatch(showAlert('success', 'Success!', 'Your account has been created!'));
                        this.props.dispatch(campableUserReceived(user));
                        this.setState({
                            editingBankDetails: false,
                            loading: false,
                        });
                        this.props.reset();
                    }).catch((err) => {
                        this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message));
                    });
                } else {
                    this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message));
                }

                this.setState({
                    loading: false,
                });
            })
        }).catch((err) => {
            this.setState({
                loading: false,
            });
            this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message + JSON.stringify(err)));
        });
    }

    editBankDetailsPressed() {
        let toggleState = !this.state.editingBankDetails;
        this.setState({
            editingBankDetails: toggleState
        });
    }

    render() {
        let saveChanges;
        if (this.props.valid && this.props.dirty) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={3}/>
                        <Col md={7}>
                            <h4>You have made some changes</h4>
                            <p>Click to Update your account details</p>
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit">Update</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        return (
            <div>
                <Row>
                    <Col md={3} xs={12}/>
                    <Col md={9} xs={12}>
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <AccountHostDetails user={this.props.user}
                                                loading={this.state.loading}
                                                editingBankDetails={this.state.editingBankDetails}
                                                companyType={this.props.companyType}
                                                editBankDetailsPressed={this.editBankDetailsPressed}/>
                            {saveChanges}
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}

AccountHostDetailsController = reduxForm({
    form: 'accountHostDetails',
    validate
})(AccountHostDetailsController);

export default AccountHostDetailsController = connect((state, props) => {
    return { }
})(AccountHostDetailsController);

