import React, {Component} from 'react';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import {browserHistory} from 'react-router';
import {BECOME_A_HOST} from "../../constants/Routes";

export default class HostBanner extends Component {

    render() {
        let title = this.props.content.title;
        let pointOne = this.props.content.pointOne;
        let pointTwo = this.props.content.pointTwo;
        let pointThree = this.props.content.pointThree;

        let backgroundImage;

        if (this.props.content.backgroundImage && this.props.content.backgroundImage.url.length > 0) {
            backgroundImage = this.props.content.backgroundImage.url;
        }


        return (
            <div  className="host-banner" style={{backgroundImage: `url(${backgroundImage})`}}>
                <Grid>
                    <Row>
                        <Col className="cta-contents" xs={12} md={7}>
                            { title ? <h1>{title}</h1> : null }
                            <Row>
                                { pointOne ? <Col md={3}><h4>{pointOne}</h4></Col> : null }
                                { pointTwo ? <Col md={3}><h4>{pointTwo}</h4></Col> : null }
                                { pointThree ? <Col md={4}><h4>{pointThree}</h4></Col> : null }
                            </Row>
                            <Button onClick={() => browserHistory.push(BECOME_A_HOST)}>Become a Host</Button>
                        </Col>
                    </Row>

                </Grid>
            </div>
        );
    }

}