import React, {Component} from "react";

export default class ProgressInformation extends Component {

    render() {
        // console.log(this);
        //
        //
        // let errors;
        // if (this.props.errors) {
        //     errors = values(this.props.errors).map((error, i) => {
        //         console.log(error);
        //         return (<li key={i}>{error}</li>);
        //     })
        // }

        return (
            <div className="progress-information">
                <div className="red-well">
                    <h4>Almost done</h4>
                    <h5>Complete all fields correctly and update ...</h5>
                </div>
            </div>
        );
    }
}
