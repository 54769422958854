import React, {Component} from "react";
import {Button, Col, Grid, Modal, Row} from "react-bootstrap";
import "../../App.css";
import logo from "../../resources/images/campable-logo-white.svg";
import {hasLocalStorageUser} from "../../auth";
import {browserHistory} from "react-router";
import {BECOME_A_HOST, HOSTING_LOCATIONS} from "../../constants/Routes";
import {connect} from "react-redux";
import {loading, loginPressed, onError, passwordResetPressed, pwReset, signUpPressed, socialLogin} from "../../actions/index";
import AuthError from "./../AuthError";
import Load from "../Load";
import SignUp from "../SignUp";
import Login from "../Login";
import {fireTagEvent} from "../../tagmanager";
import ResetPassword from "../ResetPassword";
import {hideLoginOverlay} from "../../actions/overlay";

class LoginOverlay extends Component {

    constructor(props) {
        super(props);

        this.navigateLogin = this.navigateLogin.bind(this);

        this.handleEmailInput = this.handleEmailInput.bind(this);
        this.handlePasswordInput = this.handlePasswordInput.bind(this);
        this.handlePasswordVerify = this.handlePasswordVerify.bind(this);


        this.handleSocialLogin = this.handleSocialLogin.bind(this);

        this.handleLoginPress = this.handleLoginPress.bind(this);
        this.handleSelectLoginPress = this.handleSelectLoginPress.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.handleSelectResetPasswordPress = this.handleSelectResetPasswordPress.bind(this);
        this.handleSignUpPress = this.handleSignUpPress.bind(this);
        this.handleSelectSignUpPress = this.handleSelectSignUpPress.bind(this);

        this.onBackPress = this.onBackPress.bind(this);
        this.renderLandingHome = this.renderLandingHome.bind(this);

        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.dismiss = this.dismiss.bind(this);


        this.state = {
            emailValue: '',
            passwordValue: '',
            passwordVerify: '',
            passwordMismatch: false,
            passwordValidation: null,
            signUp: false,
            logIn: false,
            reset: false,
        };

    }

    navigateLogin() {
        if (hasLocalStorageUser()) {
            if (this.props.campableUser && this.props.campableUser.accountInformation && this.props.campableUser.accountInformation.valid) {
                browserHistory.push(HOSTING_LOCATIONS);
                this.props.dispatch(loading(false))
            } else {
                browserHistory.push('/');
                this.props.dispatch(loading(false))
            }
        }
    }

    handleEmailInput(event) {
        this.setState({emailValue: event});
    }

    handlePasswordInput(event) {
        this.setState({passwordValue: event});
    }

    handlePasswordVerify(value) {
        this.setState({passwordVerify: value})
    }

    handleSocialLogin(socialProvider) {
        fireTagEvent("signup", undefined, {"provider": socialProvider});
        let referralCode = undefined;
        try {
            referralCode = localStorage.getItem('affiliateReferralCode');
        } catch (err) {
        }
        this.props.dispatch(socialLogin(socialProvider, referralCode))
    }

    //Login Stuff
    handleSelectLoginPress() {
        fireTagEvent("signup", undefined, {"provider": "email/pwd"});
        this.setState({logIn: true, signUp: false, reset: false,})
    }

    handleLoginPress() {
        fireTagEvent("login", undefined, {"provider": "email/pwd"});
        this.props.dispatch(loginPressed(this.state.emailValue, this.state.passwordValue))
    }

    // Password Stuff
    handleSelectResetPasswordPress() {
        fireTagEvent("reset_password", undefined, {"provider": "email/pwd/reset"});
        this.setState({logIn: true, signUp: false, reset: true,})
    }

    handleResetPassword() {
        this.props.dispatch(onError());
        this.props.dispatch(loading(true));

        if (this.state.emailValue) {
            fireTagEvent("reset_password", undefined, {"provider": "email/pwd/reset"});
            this.props.dispatch(loading(false));
            this.props.dispatch(passwordResetPressed(this.state.emailValue))
        } else {
            this.props.dispatch(loading(false));
            this.props.dispatch(onError({code: 'auth/invalid-email'}));
        }
    }

    //Sign up stuff
    handleSelectSignUpPress() {
        fireTagEvent("signup", undefined, {"provider": "email/pwd"});
        this.setState({logIn: false, signUp: true, reset: false,})
    }

    handleSignUpPress() {
        if (this.state.passwordValue === this.state.passwordVerify) {
            let referralCode = null;
            try {
                referralCode = localStorage.getItem('affiliateReferralCode');
            } catch (err) {
                console.log(err)
            }

            this.props.dispatch(signUpPressed(this.state.emailValue, this.state.passwordValue, referralCode));
            this.setState({pwValidation: "success", passwordMismatch: false})
        } else {
            this.props.dispatch(loading(false));
            this.props.dispatch(onError());
            this.setState({passwordMismatch: true, pwValidation: "error"});
        }
    }

    onBackPress() {
        if (this.state.logIn && !this.state.signUp) {
            this.props.dispatch(pwReset(false))
        }
        this.props.dispatch(onError());
        this.setState({logIn: false, signUp: false})
    }

    renderLandingHome() {
        return (
            <div>
                <Row>
                    <Col className="login-button-box">
                        <Button onClick={this.handleSelectLoginPress}>Login with Email</Button>
                    </Col>
                    <Col className="social-box">
                        <Button className="btn-facebook" onClick={() => this.handleSocialLogin('facebook')}>Login with Facebook</Button>
                    </Col>
                    <Col className="social-box">
                        <Button className="btn-inverse" onClick={() => this.handleSocialLogin('google')}>Login with Google</Button>
                        <div className="login-options-dividing-line">
                            <span>Or</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="signup-box">
                        <a onClick={this.handleSelectSignUpPress}>Sign Up</a>
                    </Col>
                </Row>
            </div>
        );
    }

    componentWillReceiveProps(nexProps) {
        if (nexProps.loginOverlayShown !== this.props.loginOverlayShown) {
            this.setState({
                signUp: nexProps.isSignUp,
            });
        }
    }

    renderChoice() {

        if (!this.state.signUp && this.state.logIn) {
            if (this.state.reset) {
                return (
                    <ResetPassword
                        onBackPress={this.handleSelectLoginPress}
                        handleEmailInput={this.handleEmailInput}
                        handleResetPassword={this.handleResetPassword}
                        emailValue={this.state.emailValue}
                        passwordReset={this.props.auth.passwordReset}
                    />
                );
            }

            return (
                <Login
                    onBackPress={this.onBackPress}
                    handleLoginPress={this.handleLoginPress}
                    handleEmailInput={this.handleEmailInput}
                    handlePasswordInput={this.handlePasswordInput}
                    handleSelectResetPasswordPress={this.handleSelectResetPasswordPress}
                />
            );

        } else if (this.state.signUp && !this.state.logIn) {
            return (
                <SignUp
                    onBackPress={this.onBackPress}
                    handleSignUpPress={this.handleSignUpPress}
                    handleEmailInput={this.handleEmailInput}
                    handlePasswordInput={this.handlePasswordInput}
                    handlePasswordVerify={this.handlePasswordVerify}
                    passwordMismatch={this.state.passwordMismatch}
                    passwordValidation={this.state.passwordValidation}
                />
            );
        } else {
            return this.renderLandingHome();
        }
    }

    toggle() {
        this.setState({shown: !this.state.shown});
    }

    show() {
        this.setState({shown: true});
    }

    hide() {
        this.dismiss();
    }

    dismiss() {
        if ((this.props.currentRoute.indexOf('/hosting') === -1) && (this.props.currentRoute.indexOf(BECOME_A_HOST) === -1)) {
            this.props.dispatch(hideLoginOverlay())
        }
    }

    hideLoginOverlay = () => {
        this.props.dispatch(hideLoginOverlay());
    };

    render() {

        let showBackButton = <a onClick={this.hideLoginOverlay} className="close-btn">Close</a>;
        if ((this.props.currentRoute.indexOf('/hosting') > -1) || (this.props.currentRoute.indexOf(BECOME_A_HOST) > -1)) {
            showBackButton = null;
        }


        return (
            <Modal
                className="login-overlay"
                show={this.props.loginOverlayShown}
                onHide={this.dismiss}>
                <Grid className="landing" fluid>
                    <Row className="logo">
                        <Col xs={12}>
                            <img ref='#' src={logo} className="img" alt="campable logo" onClick={() => {
                                this.hideLoginOverlay();
                                browserHistory.push('');
                            }}/>
                        </Col>
                    </Row>
                    <Row>
                        <div className="login-box">
                            <div className="close-btn">
                                {showBackButton}
                            </div>
                            <h4>Welcome to Campable!</h4>

                            {this.renderChoice()}
                            {this.props.auth.error ? <AuthError/> : null}
                            {this.props.auth.isLoading ? <Load/> : null}
                        </div>
                    </Row>
                </Grid>
            </Modal>
        );
    }
}

export default LoginOverlay = connect((state) => {
    return {
        campableUser: state.data.campableUser,
        auth: state.auth,
        loginOverlayShown: state.overlay.loginOverlayShown,
        isSignUp: state.overlay.isSignUp,
    };
})(LoginOverlay);
