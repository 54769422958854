import React, {Component} from 'react';
import {Col, Image} from 'react-bootstrap';
import star_rating from "../../resources/images/yellow-star-rating.svg";
import {LOCATIONS_ID} from "../../constants/Routes"
import PriceFormatter from '../../components/common/PriceFormatter';
import {Link} from 'react-router';

export default class ListingsItem extends Component {

    render() {

        let url = LOCATIONS_ID + this.props.location.id || null;
        let thumbnailUrl;
        if (this.props.location.image && this.props.location.image.length > 0) {
            thumbnailUrl = this.props.location.image;
        }
        let propertyName = this.props.location.name || null;
        let regionName = this.props.location.region || null;
        let price = PriceFormatter(this.props.location.price) || null;
        const rating = this.props.location.rating;

        return (
            <Col className="listing-item" key={window.location.id} xs={12} sm={4} md={3}>
                <Link to={url}>
                    { thumbnailUrl ?
                        <div className="listing-thumbnail" style={{backgroundImage: `url(${thumbnailUrl})`}}></div>
                        : null }
                    <div className="listing-item-text">
                        { propertyName ?
                            <div className="listing-title">{propertyName}</div> : null }
                        { regionName ?
                            <div className="listing-region-name">{regionName}</div> : null }
                        { price ?
                            <div className="listing-price">
                                <span className="price-dollar-symbol">$</span>
                                {price}
                                <span className="price-per-motorhome"> per motorhome</span>
                            </div> : null}

                        {rating ?
                        <div className="listing-rating">
                            {<Image src={star_rating}/>}
                            {(rating || rating) >= 1.5 ? <Image src={star_rating}/> : null}
                            {(rating || rating) >= 2.5 ? <Image src={star_rating}/> : null}
                            {(rating || rating) >= 3.5 ? <Image src={star_rating}/> : null}
                            {(rating || rating) >= 4.5 ? <Image src={star_rating}/> : null}
                        </div> : null }
                    </div>
                </Link>
            </Col>
        );
    }

}