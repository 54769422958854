import React, {Component} from "react";
import {connect} from "react-redux";
import styles from "./AlertBanner.module.scss";
import actionIcon from '../../resources/images/campable-action-icon.svg';
import {getCampableUserRequirements} from "../../actions";

class AlertBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if we've just logged in with a hosting account or just set up a hosting account
        if(!prevProps.accountInformation.valid && this.props.accountInformation.valid) {
            this.props.dispatch(getCampableUserRequirements());
        }
    }

    render() {

        if (!this.props.requirements.link) {
            return <div/>;
        }

        return (
            <div className={styles.root}>
                <div className={styles.content}>
                    <img src={actionIcon} className={styles.action_icon} />
                    <div className={styles.text}>
                        In order to continue receiving payments from us, we need some additional details from
                        you. <a href={this.props.requirements.link}>Update your account details.</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default AlertBanner = connect((state, props) => {
    const user = state.data.campableUser || {};
    return {
        accountInformation: user.accountInformation || {},
        requirements: state.data.requirements || {},
    }
})(AlertBanner);
