import React, {Component} from 'react';
import {Alert, Button, Col, Image, Row} from 'react-bootstrap';
import '../../App.css';
import glassesMan from '../../resources/images/girl-holding-device-dollars.svg';
import Card from '../Card';
import greenTick from '../../resources/images/green-tick.svg';


export default class HowDoIGetPaid extends Component {

    render() {

        return (
            <Card>
                <Row className="how-do-i-get-paid">
                    <Col md={3} className="get-paid-image-column">
                        <Image src={glassesMan} alt="glassesman" className="img-responsive center-block"/>
                    </Col>
                    <Col md={9} className="get-paid-description-text">
                        <h4>How do Guests Pay for their stay?</h4>
                        <p>All payments are organised through the Campable app, and are
                            processed by our payment provider Stripe. To make a booking with you every Guest has to pay in advance.</p>
                        <h4>How does the money get to me?</h4>
                        <p>Guests can only pay through our payment provider Stripe. In order for you to become a Host you must
                            have a Stripe account.</p>
                        <br/>
                        <h5>Click on the button below to create your free account.</h5>
                        <p><Image src={greenTick} alt="greentick" className="green-tick"/> Quick and FREE to setup</p>
                        <p><Image src={greenTick} alt="greentick" className="green-tick"/> 100% Safe and Secure</p>
                        <p><Image src={greenTick} alt="greentick" className="green-tick"/> Campable will never charge you for being a host.</p>
                        <br/>
                        <Button onClick={this.props.stripeButtonPressed}>Setup Account</Button>
                        <span className="form-alert-help">
                            <Alert bsStyle="danger">This is a required field</Alert>
                        </span>
                    </Col>
                </Row>

            </Card>
        );
    }

}

