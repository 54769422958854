const auth = (state = { user: null, isLoading: false, error: null, passwordReset: false }, action) => {
  switch (action.type) {

    case 'LOG_IN':
      return {
        ...state,
        user: action.user
      }

    case 'LOG_OUT':
      return {
        ...state,
        user: null
      }

    case 'IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }

    case 'PASSWORD_RESET':
    return {
      ...state,
      passwordReset: action.passwordReset
    }

    case 'ERROR':
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default auth
