/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 3/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


export const SHOW_LOGIN_OVERLAY = "SHOW_LOGIN_OVERLAY";
export const HIDE_LOGIN_OVERLAY = "HIDE_LOGIN_OVERLAY";

export function showLoginOverlay(isSignUp = false) {
    return {
        type: SHOW_LOGIN_OVERLAY,
        isSignUp: isSignUp,
    }
}

export function hideLoginOverlay() {
    return {
        type: HIDE_LOGIN_OVERLAY,
    }
}