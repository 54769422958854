import React, {Component} from 'react';
import {Grid, Row} from 'react-bootstrap';
import ListItem from './ListItem';

export default class ListBlock extends Component {

    render() {

        let title = this.props.content.title || null;
        let description = this.props.content.description || null;
        let buttonText = this.props.content.button || null;
        let buttonLink = this.props.content.buttonLink || null;

        let listItems;

        if (this.props.content.items && this.props.content.items.length > 0) {
            let items = this.props.content.items;
            let columns = items.length;
            listItems = items.map(listItem => {
                return <ListItem key={listItem.id} content={listItem} columns={columns} />
            });
        }

        return (
            <Grid className="list-block">
                <Row>
                    { title ? <h2>{title}</h2> : null }
                    { description ? <p className="description">{description}</p> : null }
                    <Row>
                        {listItems}
                    </Row>


                </Row>
                <Row>
                    {buttonText ? <p className="list-block-button"><a href={buttonLink}>{buttonText}</a></p> : null }
                </Row>

            </Grid>
        );
    }

}