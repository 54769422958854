import React, {Component} from "react";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import "../App.css";
import * as Routes from "../constants/Routes";
import {isLimitedAdmin} from "../auth";

class AdminNavBar extends Component {

    render() {

        let currentPage = this.props.state.routing.locationBeforeTransitions.pathname;

        const lastSlash = this.props.state.routing.locationBeforeTransitions.pathname.indexOf('/', 8);
        if(lastSlash !== -1){
            currentPage = this.props.state.routing.locationBeforeTransitions.pathname.substring(0, lastSlash);
        }

        const limitedAdmin = isLimitedAdmin(this.props.state.data.campableUser);

        return (
            <Navbar collapseOnSelect className="secondary-nav-bar">
                <Navbar.Header>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Navbar.Text><strong>Admin</strong></Navbar.Text>
                    <Nav activeKey={currentPage} onSelect={this.handleSelect}>
                        {!limitedAdmin ? <NavItem eventKey={Routes.ADMIN_USERS} onClick={() => browserHistory.push(Routes.ADMIN_USERS)}>Users</NavItem> : null}
                        <NavItem eventKey={Routes.ADMIN_LOCATIONS} onClick={() => browserHistory.push(Routes.ADMIN_LOCATIONS)}>Locations</NavItem>
                        {!limitedAdmin ? <NavItem eventKey={Routes.ADMIN_TAGS} onClick={() => browserHistory.push(Routes.ADMIN_TAGS)}>Tags</NavItem> : null}
                        {!limitedAdmin ? <NavItem eventKey={Routes.ADMIN_CAMPAIGNS} onClick={() => browserHistory.push(Routes.ADMIN_CAMPAIGNS)}>Campaigns</NavItem> : null}
                        {!limitedAdmin ? <NavItem eventKey={Routes.ADMIN_AFFILIATES} onClick={() => browserHistory.push(Routes.ADMIN_AFFILIATES)}>Affiliates</NavItem> : null}
                        {!limitedAdmin ? <NavItem eventKey={Routes.ADMIN_DATA_IMPORT} onClick={() => browserHistory.push(Routes.ADMIN_DATA_IMPORT)}>Data Import</NavItem> : null}
                        <NavItem eventKey={Routes.ADMIN_CREATE_COMMERCIAL} onClick={() => browserHistory.push(Routes.ADMIN_CREATE_COMMERCIAL)}>Create Non-Campable</NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default AdminNavBar = connect((state) => {
    return {
        state
    }
})(AdminNavBar);
