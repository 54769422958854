import React, {Component} from 'react';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import IOSBadge from "../common/badges/IOSBadge";
import AndroidBadge from "../common/badges/AndroidBadge";

export default class AppDownloadPanel extends Component {

    render() {
        return (
            <div className="call-to-action-banner" >
                <Grid>
                    <Row className="frosted-box">
                        <Col md={6}>
                            <h2>Campable is FREE to Download and Join</h2>
                            <ul>
                                <li>Stay with locals on their own land</li>
                                <li>Access vineyards and resorts</li>
                                <li>Plan and book with ease</li>
                            </ul>
                        </Col>
                        <Col md={6} className="call-to-action-banner-buttons">
                            {this.props.campableUser ? null :<Button onClick={() => this.props.showLoginOverlay(true)}>Join Now!</Button>}
                                <AndroidBadge/>
                                <IOSBadge/>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}



