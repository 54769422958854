import React, {Component} from 'react';

export default class TextImageBlock extends Component {

    render() {
        const title = this.props.content.title;
        const mainText = this.props.content.mainText;
        const subTitle = this.props.content.subTitle;
        const subText = this.props.content.subText;
        const subHeading = this.props.content.subHeading;

        const displayRight = this.props.content.imageDisplaysToRight;
        return (
            <div className="text-image-block">
                <img alt='i' style={{float: displayRight ? 'right' : 'left', marginLeft: displayRight ? '20px' : '0'}} src={"https:" + this.props.content.image.url}/>
                { title ? <h2>{title}</h2> : null }
                { subTitle ? <h3>{subTitle}</h3> : null } 
                { mainText ? <p>{mainText}</p> : null }
                { subHeading ? <p><strong>{subHeading}</strong></p> : null }
                { subText ? <p>{subText}</p> : null }
            </div>
        );
    }
}
