import React, {Component} from "react";
import {toProperCase} from "../../constants/toProperCase";
import cross from "../../resources/images/red-x.svg";
import AddNewRuleDialog from "./AddNewRuleDialog";
import moment from "moment";

export default class LocationCampsiteAvailabilityRules extends Component {


    constructor(props) {
        super(props);

        this.onAddNewRulePress = this.onAddNewRulePress.bind(this);
        this.onConfirmNewRulePress = this.onConfirmNewRulePress.bind(this);
        this.onDeleteRulePress = this.onDeleteRulePress.bind(this);

        this.onAvailablePress = this.onAvailablePress.bind(this);
        this.onUnavailablePress = this.onUnavailablePress.bind(this);

        this.state = {
            available: true,
        }
    }


    onAddNewRulePress() {
        this.addNewRuleDialog.show();
    }

    onConfirmNewRulePress(newRule) {
        this.props.fields.push(newRule);
        this.addNewRuleDialog.dismiss();
    }

    onDeleteRulePress(ruleIndex) {
        this.props.fields.remove(ruleIndex);
    }

    onAvailablePress() {
        this.setState({
            available: true,
        });
    }

    onUnavailablePress() {
        this.setState({
            available: false,
        });
    }

    render() {

        let dayMonthRulesViews = [];
        let dateRangeRuleViews = [];

        let dayMonthRules = [];
        let dateRangeRules = [];

        let rules = this.props.fields.getAll();
        // eslint-disable-next-line
        if (rules) {
            // eslint-disable-next-line
            rules.map((rule, i) => {

                let isUnavailable = (rule.spaces === 0);

                if (!!rule.dates) {

                    //console.log(rule);
                    let fromMoment = rule.dates && rule.dates.from ? moment(rule.dates.from) : moment();
                    let toMoment = rule.dates && rule.dates.to ? moment(rule.dates.to) : moment();

                    let from = fromMoment.clone().format('DD-MM-YYYY');
                    let to = toMoment.clone().format('DD-MM-YYYY');

                    dateRangeRules.push(rule);

                    dateRangeRuleViews.push(
                        <tr key={i} className="rule-item">
                            <td>
                                <p name={`${rule}.dates.from`}>{from}</p>
                            </td>
                            <td>
                                <p name={`${rule}.dates.to`}>{to}</p>
                            </td>
                            <td>
                                <p name={`${rule}.price`}>{isUnavailable ? 'Unavailable' : '$' + rule.price / 100}</p>
                            </td>
                            <td>
                                <p name={`${rule}.spaces`}>{isUnavailable ? 'Unavailable' : rule.spaces}</p>
                            </td>
                            <td>
                                <a onClick={() => {
                                    this.onDeleteRulePress(i);
                                }}><img src={cross} alt="delete-rule"/></a>
                            </td>
                        </tr>);
                } else {
                    let days = rule.days.map((day) => {
                        return toProperCase(day);
                    }).join(', ');

                    let months = rule.months.map((month) => {
                        return toProperCase(month);
                    }).join(', ');

                    dayMonthRules.push(rule);

                    dayMonthRulesViews.push(
                        <tr key={i} className="rule-item">
                            <td>
                                <p name={`${rule}.days`}>{days}</p>
                            </td>
                            <td>
                                <p name={`${rule}.months`}>{months}</p>
                            </td>
                            <td>
                                <p name={`${rule}.price`}>{isUnavailable ? 'Unavailable' : '$' + rule.price / 100}</p>
                            </td>
                            <td>
                                <p name={`${rule}.spaces`}>{isUnavailable ? 'Unavailable' : rule.spaces}</p>
                            </td>
                            <td>
                                <a onClick={() => {
                                    this.onDeleteRulePress(i);
                                }}><img src={cross} alt="delete-rule"/></a>
                            </td>
                        </tr>
                    );
                }
            });
        }

        return (
            <div className="campsite-availability-rules">
                <h4>Manage Availability</h4>
                <table>
                    <thead>
                    <tr>
                        <th width="24%"><h5>Days of Week</h5></th>
                        <th width="24%"><h5>Months of Year</h5></th>
                        <th width="24%"><h5>Price</h5></th>
                        <th width="24%"><h5>Spaces</h5></th>
                        <th width="4%"/>
                    </tr>
                    </thead>
                    <tbody>
                    {dayMonthRulesViews}
                    </tbody>
                    <thead>
                    <tr>
                        <th width="24%"><h5>Date From</h5></th>
                        <th width="24%"><h5>Date To</h5></th>
                        <th width="24%"><h5>Price</h5></th>
                        <th width="24%"><h5>Spaces</h5></th>
                        <th width="4%"/>
                    </tr>
                    </thead>
                    <tbody>
                    {dateRangeRuleViews}
                    </tbody>
                </table>

                <a onClick={this.onAddNewRulePress}>Add new rule for available/unavailable dates</a>

                <AddNewRuleDialog
                    setRef={(ref) => {
                        this.addNewRuleDialog = ref;
                    }}
                    dateRangeRules={dateRangeRules}
                    dayMonthRules={dayMonthRules}
                    location={this.props.location}
                    available={this.state.available}
                    onAvailablePress={this.onAvailablePress}
                    onUnavailablePress={this.onUnavailablePress}
                    onConfirmNewRulePress={this.onConfirmNewRulePress}
                />
            </div>
        );
    }
}

