import React, {Component} from "react";
import {ControlLabel, FormGroup, HelpBlock} from "react-bootstrap";
import Cleave from "cleave.js/dist/cleave-react";

export default class BankAccountInput extends Component {

    render() {
        let validationState = null;
        let helpBlock;

        if (this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;


        return (
            <FormGroup readOnly validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <Cleave className="cleave"
                        options={{blocks: [2, 4, 7, 3], delimiter: '-'}}
                        maxLength={19}
                        {...this.props.input}
                        {...inputProps}/>
                {helpBlock}
            </FormGroup>)
    }

}
