import React, {Component} from "react";
import {Button, Col, Grid, Row} from "react-bootstrap";
import {browserHistory} from "react-router";
import IOSBadge from "../common/badges/IOSBadge";
import AndroidBadge from "../common/badges/AndroidBadge";
import OnlineRepublicWidget from "../common/onlineRepublicWidget/OnlineRepublicWidget";

export default class CallToActionBanner extends Component {

    render() {
        let title = this.props.content.title || null;
        let buttonText = this.props.content.button || null;
        let buttonLink = this.props.content.buttonLink || null;
        let benefits = this.props.content.benefits;

        let benefitList;
        let backgroundImage;

        if (this.props.content.benefits && this.props.content.benefits.length > 0) {
            benefitList = benefits.map(benefit => {
                return <li key={benefit}>{benefit}</li>
            });
        }

        if (this.props.content.backgroundImage && this.props.content.backgroundImage.url.length > 0) {
            backgroundImage = this.props.content.backgroundImage.url;
        }

        return (
            <div className="call-to-action-banner" style={{backgroundImage: `url(${backgroundImage})`}}>
                <Grid>
                    <Col className={"download_block"}>
                        <Row className="frosted-box">
                            <Col className="cta-contents" >
                                {title ? <h2>{title}</h2> : <h2>Campable is FREE to Download and Join</h2>}
                                {benefits ? <ul>
                                    {benefitList}
                                </ul> : <ul>
                                    <li>Stay with locals on their own land</li>
                                    <li>Access vineyards and resorts</li>
                                    <li>Plan and book with ease</li>
                                </ul> }
                            </Col>
                            <Col className="call-to-action-banner-buttons">
                                {this.props.isLoggedIn ? null : buttonText ?
                                    <a onClick={() => {
                                        buttonLink.indexOf('login') > -1 ? this.props.showLoginOverlay(true) : browserHistory.push(buttonLink)
                                    }}><Button>{buttonText}</Button>
                                    </a> : null }
                                <AndroidBadge/>
                                <IOSBadge/>
                            </Col>
                        </Row>
                    </Col>
                </Grid>
            </div>
        );
    }

}