import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row, Table, Pagination} from 'react-bootstrap';
import Load from '../../components/Load';
import {isAdmin} from '../../auth';
import '../../App.css';
import {adminCreateCampaign, adminFetchCampaigns, adminUpdateCampaign, getLocation, getLocations} from '../../actions/index';
import TextInput from '../../components/common/inputs/TextInput';
import CampaignDialog from '../../components/admin/CampaignDialog';
import DropdownSelect from "../../components/common/DropdownSelect";
import {ADMIN_CAMPAIGN} from "../../constants/Routes";

class AdminCampaignsContainer extends Component {

    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onSearchButtonPressed = this.onSearchButtonPressed.bind(this);
        this.onCreateButtonPressed = this.onCreateButtonPressed.bind(this);
        this.cancelCampaignDialog = this.cancelCampaignDialog.bind(this);
        this.searchLocations = this.searchLocations.bind(this);
        this._getLocation = this._getLocation.bind(this);
        this.doCreateCampaign = this.doCreateCampaign.bind(this);
        this.deleteCampaign = this.deleteCampaign.bind(this);
        this.onSearchTypeChange = this.onSearchTypeChange.bind(this);

        this.state = {searchType: "Code", searchTypeValue: "Code", fetchingCampaigns: false, searchLimit: 1000, countPerPage: 25, activePage: 1}
    }

    componentWillMount() {
        if (this.props.user) {
            this.props.dispatch(adminFetchCampaigns());
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user && !this.state.fetchingCampaigns) {
            this.props.dispatch(adminFetchCampaigns(null, null, this.state.searchLimit));
            this.setState({fetchingCampaigns: true});
        }
        if (!this.props.campaigns && nextProps.campaigns) {
            this.setState({fetchingCampaigns: false});
        }
    }

    onSearchButtonPressed() {
        if ((this.state.searchType === "Code" && this.state.searchValue === undefined) || (this.state.searchType === "Type" && (this.state.searchTypeValue === undefined))) {
            return;
        }

        if (this.state.searchType === "Code") {
            this.props.dispatch(adminFetchCampaigns(null, this.state.searchValue, this.state.searchLimit, this.props.campaignCursor));
        }
        else if (this.state.searchType === "Type") {
            this.props.dispatch(adminFetchCampaigns(this.state.searchTypeValue.replace(" ", ""), null, this.state.searchLimit, this.props.campaignCursor));
        }

        this.setState({fetchingCampaigns: true});
    }

    onResetClick = () => {
        this.props.dispatch(adminFetchCampaigns());
        this.setState({activePage: 1});
    }

    onCreateButtonPressed() {
        this.setState({
            showCreateCampaignDialog: true
        })
    }

    handleSelectChange(e) {
        this.setState({
            searchType: e
        })
    }

    onSearchTypeChange(e) {
        this.setState({
            searchTypeValue: e
        })
    }

    onTextChange(e) {
        this.setState({
            searchValue: e.target.value
        })
    }

    cancelCampaignDialog() {
        this.setState({showCreateCampaignDialog: false});
    }

    searchLocations(query) {
        let params = {
            freeFormQuery: query,
            type: 'property'
        };

        this.props.dispatch(getLocations(params));
    }

    _getLocation(id) {
        this.setState({locationId: id});
        this.props.dispatch(getLocation(id));
    }

    doCreateCampaign(values) {
        this.props.dispatch(adminCreateCampaign(values));

        this.setState({
            showCreateCampaignDialog: false
        })
    }

    deleteCampaign(key) {
        this.props.dispatch(adminUpdateCampaign(key, {status: "deleted"}));
    }

    pageSelect = (page) => {
        this.setState({activePage: page, offset: (page - 1) * this.state.countPerPage});


    }

    itemCount = () => {
        if (this.props.campaignArray === undefined) {
            return 0;
        }
        return this.props.campaignArray === null ? 0 : Math.ceil(this.props.campaignArray.length / this.state.countPerPage);
    }

    pagination = () => {
        return (<Pagination prev next first last ellipsis boundaryLinks items={this.itemCount()} maxButtons={5}
                            activePage={this.state.activePage}
                            onSelect={this.pageSelect}/>)
    }

    render() {

        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let campaignArray = this.props.campaignArray;
        const campaigns = [];
        if (campaignArray !== undefined) {
            const startIndex = (this.state.activePage - 1) * this.state.countPerPage;
            for (let idx = startIndex; idx < Math.min(campaignArray.length, this.state.activePage * this.state.countPerPage); ++idx) {
                const campaign = campaignArray[idx];
                const format = campaign.deleted ? "table-deleted" : undefined;
                campaigns.push(<tr key={campaign.id}>
                    <td className={format}>{campaign.code}</td>
                    <td className={format}>{campaign.userEmail}</td>
                    <td className={format}>{campaign.type}</td>
                    <td className={format}>{campaign.validFrom}</td>
                    <td className={format}>{campaign.validUntil}</td>
                    <td className={format}>{campaign.deleted && "true"}</td>
                    <td className={format}>{campaign.userMessage}</td>
                    <td className={format}>{campaign.creditAmount}</td>
                    <td className={format}>{campaign.numberOfNights}</td>
                    <td className={format}>{campaign.percentageDiscount}</td>
                    <td className={format}>{campaign.site}</td>
                    <td className={format}>{campaign.tourCode}</td>
                    <td className={format}>{campaign.maximumRedeemCount}</td>
                    <td className={format}>{campaign.redeemCount}</td>
                    <td className={format}>{campaign.deleted ? undefined : <a className="negativeButton" onClick={() => this.deleteCampaign(campaign.id)}>Delete</a>}</td>
                </tr>);
            }
        }

        var collections = [];
        if (this.props.tags && this.props.tags.length > 0) {
            collections = this.props.tags.filter((tag) => {
                return tag.id.includes("collection");
            }).map((collection) => {
                return collection.id;
            });
        }

        const sites = this.props.locationsIndex[this.state.locationId] ? this.props.locationsIndex[this.state.locationId].propertyInformation.sites : undefined;
        const campaignDialog = this.state.showCreateCampaignDialog ?
            <CampaignDialog onCancel={this.cancelCampaignDialog}
                            setRef={(ref) => {
                                this.campaignDialog = ref;
                            }}
                            onOk={this.doCreateCampaign}
                            locations={this.props.locations}
                            sites={sites}
                            searchLocations={this.searchLocations}
                            collections={collections}
                            getLocation={this._getLocation}/>
            : null;

        if (isAdmin(this.props.user)) {
            return (
                <Grid className="main-container">
                    <Row>
                        {campaignDialog}
                        <Col md={12}>
                            <Row>
                                <Col xs={3} sm={2}>
                                    <Button onClick={this.onCreateButtonPressed}>Create</Button>
                                </Col>
                            </Row>
                            Search Campaign
                            <Row className="row-eq-height">
                                <Col xs={6} sm={6}>
                                    <div className="search-bar">
                                        <div className="search-field">
                                            {this.state.searchType === "Code" ? <TextInput onChange={this.onTextChange.bind(this)}/> :
                                                <DropdownSelect value={this.state.searchTypeValue} selectOptions={["Credit", "Discount", "Site Discount", "Tour Discount"]}
                                                                onChange={this.onSearchTypeChange}/>}
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={2} sm={2} className="row-centre-vert">
                                    <DropdownSelect value={this.state.searchType} selectOptions={["Code", "Type"]}
                                                    onChange={this.handleSelectChange}/>
                                </Col>
                                <Col xs={2} sm={2} className="row-centre-vert">
                                    <Button type="submit" onClick={this.onSearchButtonPressed}>Search</Button>
                                </Col>
                                <Col xs={2} sm={2} className="row-centre-vert">
                                    <Button type="submit" onClick={this.onResetClick}>Reset</Button>
                                </Col>
                            </Row>
                            <Table responsive striped bordered hover>
                                <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>User</th>
                                    <th>Type</th>
                                    <th>Valid From</th>
                                    <th>Valid Until</th>
                                    <th>Deleted</th>
                                    <th>User Message</th>
                                    <th>Credit Amount</th>
                                    <th>No Nights</th>
                                    <th>% Discount</th>
                                    <th>Site</th>
                                    <th>Tour Code</th>
                                    <th>Max Redeem Count</th>
                                    <th>Redeem Count</th>
                                    <th>Delete</th>
                                </tr>
                                </thead>
                                <tbody>
                                {campaigns}
                                </tbody>

                            </Table>
                            {this.itemCount() > 1 ? this.pagination() : undefined}
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <div className="loaderContainer">
                <h1> Sorry you are not an admin. </h1>
            </div>
        )
    }
}

export default AdminCampaignsContainer = connect((state) => {
    return {
        isLoading: state.auth.isLoading,
        user: state.data.campableUser,
        locations: state.data.locations,
        locationsIndex: state.data.locationsIndex || {},
        campaigns: (state.admindata.campaigns),
        campaignArray: (state.admindata.campaignArray),
        campaignCursor: state.admindata.campaignCursor,
        tags: state.data.tags
    }
})(AdminCampaignsContainer);