import React, {Component} from "react";
import {Button} from "react-bootstrap";
import './widget.css';
import SearchIcon from '../../resources/images/search-icon@2x.png';
import WidgetLogo from '../../resources/images/widget-logo@2x.png';
import Slider from 'react-slick';
import {getLocationFromSearch} from "../../actions/widget";
import {connect} from "react-redux";


class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            location: null
        };

        this.code = "";

        this.learnMoreClick = this.learnMoreClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.searchClick = this.searchClick.bind(this);
    }

    componentWillMount() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            ratio: window.innerWidth + "x" + window.innerHeight
        });
    }

    getImageSlides() {
        switch (this.state.width | this.state.height) {
            case 300 | 600:
                return this.props.widget.content[0].slides300x600;
            case 300 | 250:
                return this.props.widget.content[0].slides300x250;
            case 728 | 90:
                return this.props.widget.content[0].slides728x90;
            case 160 | 600:
                return this.props.widget.content[0].slides160x600;
            default:
                return this.props.widget.content[0].slides300x600;
        }
    }

    getStyles() {
        switch (this.state.width | this.state.height) {
            case 300 | 600:
                return {
                    widgetContainer: {
                        height: this.state.height,
                        maxWidth: this.state.width
                    },
                    widgetContainerStrapLine: {
                        padding: "46px 20px 24px"
                    },
                    widgetFooter: {
                        bottom: 0,
                        left: 0
                    },
                    widgetFooterCol: {
                        display: "inline-block"
                    },
                    searchBar: {
                        padding: "0 20px"
                    },
                    searchBarIcon: {
                    },
                };
            case 300 | 250:
                return {
                    widgetContainer: {
                        height: this.state.height,
                        maxWidth: this.state.width
                    },
                    widgetContainerStrapLine: {
                        padding: "18px 20px 22px",
                        width: "100%"
                    },
                    widgetFooter: {
                        height: "50px",
                        padding: "5px",
                        bottom: 0,
                        left: 0
                    },
                    widgetFooterCol: {
                        display: "inline-block"
                    },
                    searchBar: {
                        padding: "0 20px",
                        width: "100%"
                    },
                    searchBarIcon: {
                    },
                };
            case 728 | 90:
                return {
                    widgetContainer: {
                        height: this.state.height,
                        width: this.state.width,
                        display: "inline-flex"
                    },
                    widgetContainerStrapLine: {
                        padding: "10px 0 0 11px",
                        width: "300px"
                    },
                    widgetFooter: {
                        height: "90px",
                        right: 0,
                        width: "170px",
                        padding: "10px 10px 0",
                        margin: "0"
                    },
                    widgetFooterCol: {
                        display: "block",
                        width: "130px",
                        padding: "0 0 5px",
                        margin: "0",
                        textAlign: "center"
                    },
                    searchBar: {
                        padding: "0 20px",
                        width: "290px",
                        top: "23px",
                        right: "160px",
                        position: "absolute"
                    },
                    searchBarIcon: {
                    },
                };
            case 160 | 600:
                return {
                    widgetContainer: {
                        height: this.state.height,
                        maxWidth: this.state.width
                    },
                    widgetContainerStrapLine: {
                        padding: "26px 8px 24px"
                    },
                    widgetFooter: {
                        display: "block",
                        width: "100%",
                        height: "88px",
                        padding: "8px 0 5px",
                        bottom: 0,
                        left: 0
                    },
                    widgetFooterCol: {
                        width: "80%",
                        margin: "0 auto 5px"
                    },
                    searchBar: {
                        padding: "0 8px"
                    },
                    searchBarIcon: {
                        right: "19px"
                    }
                };
            default:
                return {
                    widgetContainer: {
                        height: this.state.height,
                        maxWidth: this.state.width
                    },
                    widgetContainerStrapLine: {
                        padding: "36px 20px 24px"
                    },
                    widgetFooter: {
                        bottom: 0,
                        left: 0
                    },
                    widgetFooterCol: {
                        display: "inline-block"
                    },
                    searchBar: {
                        padding: "0 20px"
                    },
                    searchBarIcon: {
                        right: "27px"
                    },
                };
        }
    }

    learnMoreClick() {
        let url = "";

        if (this.props.affiliate !== null && this.props.affiliate !== undefined) {
            this.code = "?affiliate=" + this.props.affiliate;
        }

        if (this.props.widget.content[0].buttonUrl) {
            url = this.props.widget.content[0].buttonUrl + this.code;
        } else {
            url = "https://campable.com/" + this.code;
        }
        window.open(url);
    }

    onChange(event) {
        this.setState({value: event.target.value});
        this.props.dispatch(getLocationFromSearch(this.state.value));
    }

    searchClick() {
        if (this.state.location !== null) {
            if (this.props.affiliate !== null && this.props.affiliate !== undefined) {
                this.code = "&affiliate=" + this.props.affiliate;
            }

            window.open(
                "https://campable.com/locations?address=" + this.state.location.address +
                "&c=" + this.state.location.latLng + this.code
                , "_blank");
            this.setState({location: null});
            this.code = "";
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== null) {
            this.setState({location: nextProps.location});
        }
    }

    imageClick(image) {
        if (image.url !== undefined) {
            let url = image.url;

            if (this.props.affiliate !== null && this.props.affiliate !== undefined) {
                let code = "?affiliate=" + this.props.affiliate;
                url = image.url + code;
            }

            window.open(url);
        }
    }

    render() {
        let title = this.props.widget.content[0].title || "";
        let tagLine = this.props.widget.content[0].tagLine || "";
        let slideImages = this.getImageSlides();
        let styles = this.getStyles();

        let footerColumnStyle = "half-column";
        let placeHolder = "Where would you like to go?";
        let h1Class = "";
        let h3Class = "";


        if (this.state.ratio === "160x600") {
            h1Class = "h1-160x600";
            h3Class = "h3-160x600";
            footerColumnStyle = "";
            placeHolder = "Where to?";
        } else if (this.state.ratio === "300x250") {
            h1Class = "widget-header";
            h3Class = "widget-sub-heading";
        } else if (this.state.ratio === "728x90") {
            h1Class = "h1-728x90";
            h3Class = "h3-728x90";
        }


        let settings = {
            dots: false,
            arrows: false,
            autoplay: true,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            variableWidth: true,
            draggable: false,
        };

        let slides = slideImages.map((image, i) => {
            return (
                <div key={i + i}>
                    <img onClick={() => this.imageClick(image)} style={{width: this.state.width, height: this.state.height}} src={image.slideContent.url}
                         alt="widget slide"/>
                </div>
            )
        });

        //console.log(this.props.widget.content);

        return (
            <div style={styles.widgetContainer} className="widget-container">
                <div style={styles.widgetContainerStrapLine} className="widget-strap-line">
                    <h1 className={h1Class}>{title}</h1>
                    <h3 className={h3Class}>{tagLine}</h3>
                </div>
                <div style={styles.searchBar} className="widget-search-bar">
                    <input type={"search"} placeholder={placeHolder} /*onClick={this.learnMoreClick}*/ onChange={this.onChange} onKeyDown={(e)=> {
                        if(e.keyCode === 13) {
                            this.searchClick();
                        }
                    }}/>
                    <button onClick={this.searchClick} style={styles.searchBarIcon} className="search-icon">
                        <img src={SearchIcon} alt={""}/>
                    </button>
                </div>
                <div style={styles.widgetFooter} className="widget-footer">
                    <div style={styles.widgetFooterCol} className={footerColumnStyle}>
                        <img src={WidgetLogo} alt={""}/>
                    </div>
                    <div style={styles.widgetFooterCol} className={footerColumnStyle}>
                        <Button onClick={this.learnMoreClick} style={{margin: "0 auto", width: "100%"}}>Learn
                            more</Button>
                    </div>
                </div>

                {slideImages ?
                    <div className="widget-slideshow">
                        <Slider {...settings}>
                            {slides}
                        </Slider>
                        {/*<div className="widget-slide slide-one">*/}
                        {/*<img src={WidgetSlideShowImage} className="widget-slideshow-image"/>*/}
                        {/*<div className="widget-location-details-box">*/}
                        {/*<h5>Wanaka, South Is.</h5>*/}
                        {/*<h2>Turanga Creek Vineyard and dome</h2>*/}
                        {/*<div className="widget-price"><sup>$</sup>40 <span>per motorhome</span></div>*/}
                        {/*<img src={FiveStars} className="widget-five-stars"/>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </div> : null}

            </div>
        );
    }
}


export default Widget = connect((state) => {
    return {
        location: state.widgetData.location
    }
})(Widget);
