import React, {Component} from "react";
import {Col, Grid, Row, Button} from "react-bootstrap";
import LocationHeader from "../hostLocation/LocationHeader";
import LocationDescription from "../hostLocation/LocationDescription";
import {Form, Field, reduxForm} from "redux-form";
import LocationImages from "../hostLocation/LocationImages";
import LocationAdminPanel from "../hostLocation/LocationAdminPanel";
import ValidatedTextInput from "../common/ValidatedTextInput";
import ProgressInformation from "../hostLocation/ProgressInformation";
import {createProperty, showAlert} from "../../actions";
import {validate} from "../common/form-validation/UpdatePropertyValidation";
import {connect} from "react-redux";
import ValidatedAddressInput from "../common/ValidatedAddressInput";
import ValidatedTextBox from "../common/ValidatedTextBox";
import DropdownSelect from "../common/DropdownSelect";
import AddAmenities from "../onboarding/property/AddAmenities";
import {ADMIN_LOCATIONS_ID} from "../../constants/Routes";
import TextInput from "../common/inputs/TextInput";

class AdminCreateCommercialProperty extends Component {

    constructor(props){
        super(props);

        this.state = {
            locationType: 'commercial_campsite'
        };

        this.saveLocationDetails = this.saveLocationDetails.bind(this);
        this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this);
    }

    saveLocationDetails(values) {


        let data = {
            type: this.state.locationType,
            name: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
            address: this.state.locationType === "commercial_campsite" ? values.address.address :  values.addressFreedom,
            tags: values.Features,
            images: values.images,
            description: values.description,
            website: values.website
        };

        this.props.dispatch(createProperty(data, ADMIN_LOCATIONS_ID));
    }


    handleTypeSelectChange(e){
        this.setState({
            locationType: e
        })
    }

    render() {

        let siteFeatures = [];
        if (this.props.tags) {
            this.props.tags.map((tag) => {
                if (tag.group === 'site_feature') {
                    siteFeatures.push(tag);
                }
                return null;
            });
        }

        let saveChanges;
        if (this.props.dirty) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={2}/>
                        <Col md={4}>
                            <h4>You have made some changes</h4>
                            <p>Click Save to make your changes public</p>
                        </Col>
                        <Col md={4}/>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit" >Save</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        return (
            <Form onSubmit={this.props.handleSubmit(this.saveLocationDetails)}>
                <Col md={9} className="location-detail-view">
                     <LocationHeader handleListedSelectChange={null} statusValue={this.props.statusValue}/>

                    <div className="inline-card">

                        <DropdownSelect label={'Property Type'} selectOptions={["commercial_campsite", "freedom_campsite"]}
                                        value={this.state.locationType}
                                        onChange={this.handleTypeSelectChange}
                                        className="dropDown"/>

                        <ValidatedTextInput label="Property Name" placeholder="e.g. Title" name="name" />

                        {this.state.locationType === "commercial_campsite" ? <ValidatedAddressInput label="Address" name="address" /> :  <Field label="Address" component={TextInput} name={"addressFreedom"}/>}

                        <ValidatedTextBox label="Description"  name="description" />

                        <ValidatedTextInput label="Latitude" className="form-name" name="latitude" />
                        <ValidatedTextInput label="Longitude" className="form-name" name="longitude" />

                        <ValidatedTextInput label="Web Site" className="form-name" name="website" />

                        <AddAmenities notOnBoarding tags={siteFeatures}/>
                    </div>

                    <Field name="images" component={LocationImages} imageArray={this.state.images}/>



                </Col>
                {saveChanges}
            </Form>
        );

    }
}

AdminCreateCommercialProperty = reduxForm({
    form: 'addCommercialLocation',
    validate,
})(AdminCreateCommercialProperty);

export default AdminCreateCommercialProperty = connect((state, props) => {

    return {
        tags: state.data.tags,
        campableUser: state.data.campableUser,
        initialValues: {images: []},
        enableReinitialize: true,
    }

})(AdminCreateCommercialProperty);
