export const validate = (values) => {

    const errors = {};

    if (!values.address) {
        errors.address = 'Property address is Required';
    } else if (!values.address.latitude) {
        errors.address = 'Please select an address from the options provided in the dropdown';
    }

    if (!values.description) {
        errors.description = 'Property description is Required';
    }

    if (values.propertyType === "Select One") {
        errors.propertyType = 'Required';
    }

    if (values.images && values.images.length === 0) {
        errors.images = 'There must be at least one image';
    }

    if (!values.name) {
        errors.name = 'Property name is Required';
    }

    if (!values.propertyInformation || !values.propertyInformation.sites || !values.propertyInformation.sites.length) {
        errors.propertyInformation = {_error: "At least one site must be listed"};
    } else {
        const siteErrorsArray = [];
        values.propertyInformation.sites.forEach((site, siteIndex) => {
            const siteErrors = {};
            // Check for campsite field errors
            if (!site || !site.name) {
                siteErrors.name = "Site name is Required";
                siteErrorsArray[siteIndex] = siteErrors;
            }
            if (!site || isNaN(site.spaces) || site.spaces.length <= 0) {
                siteErrors.spaces = "Site spaces is Required";
                siteErrorsArray[siteIndex] = siteErrors;
            } else if (!isNaN(site.spaces) && site.spaces < 0) {
                siteErrors.spaces = "Must be a number greater than or equal to zero (0).";
                siteErrorsArray[siteIndex] = siteErrors;
            }

            if (!site || !site.price) {
                siteErrors.price = "Site price is Required";
                siteErrorsArray[siteIndex] = siteErrors;
            } else {
                let price = site.price / 100;
                if (price < 10) {
                    siteErrors.price = 'You cannot charge less than $10 a night';
                    siteErrorsArray[siteIndex] = siteErrors;
                } else if (price > 1000) {
                    siteErrors.price = 'You cannot charge more than $999 a night';
                    siteErrorsArray[siteIndex] = siteErrors;
                }
            }
        });

        if (siteErrorsArray.length) {
            errors.propertyInformation = {sites: siteErrorsArray};
        }
    }

    return errors
}



