import React, {Component} from "react";
import {connect} from "react-redux";
import "../../App.css";
import "../../containers/inline-styles/side-menu.css";

import {browserHistory} from "react-router";
import * as Routes from "../../constants/Routes";

export default class AdminLocationMenu extends Component {

    constructor(props) {
        super(props);
     //   this.handleCreateProperty = this.handleCreateProperty.bind(this);
    }

   /* handleCreateProperty() {
        if (this.props.campableUser && this.props.campableUser.accountInformation && this.props.campableUser.accountInformation.valid) {
            browserHistory.push(Routes.HOSTING_CREATE_A_PROPERTY);
        } else {
            browserHistory.push(Routes.BECOME_A_HOST);
        }
    }*/

    render() {
        return (
            <div className="side-menu">
                <ul>
                    <li className={this.props.currentRoute.indexOf('details') > -1 ? 'active' : null} onClick={() => browserHistory.push(Routes.ADMIN_LOCATIONS_ID + this.props.locationId + '/details')}>Property Details</li>
                    <li className={this.props.currentRoute.indexOf('bookings') > -1 ? 'active' : null} onClick={() => browserHistory.push(Routes.ADMIN_LOCATIONS_ID + this.props.locationId + '/bookings')}>Bookings</li>
                    <li className={this.props.currentRoute.indexOf('host') > -1 ? 'active' : null} onClick={() => browserHistory.push(Routes.ADMIN_USERS_ID + this.props.hostId + "/details")}>Host</li>
                </ul>
            </div>
        );
    }
}


