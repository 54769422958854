import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Pager} from 'react-bootstrap';
import Load from '../components/Load';
import {browserHistory, Link} from "react-router";
import {HOSTING_LOCATIONS} from "../constants/Routes";
import {getCampableUserRequirements} from "../actions";

class RequirementsContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    goToLocations = () => {
        browserHistory.push(HOSTING_LOCATIONS);
    };

    onSuccess = () => {
        this.props.dispatch(getCampableUserRequirements());
    }

    render() {
        if(!this.props.user) {
            return <div/>;
        }

        const accountInformation = this.props.user.accountInformation || {};

        if (accountInformation.valid && !this.props.requirements) {
            return <div className="loaderContainer"><Load/></div>;
        }

        let components;

        if(this.props.requirements.link) {
            components = (
                <div style={{textAlign: 'center'}}>
                    <h4>{"Our payment provider needs to collect a few details to enable payments to your bank account."}</h4>
                    <br/>
                    <a href={this.props.requirements.link}>
                        <Button>Verify account</Button>
                    </a>
                </div>);
        } else {
            components = (
                <div style={{textAlign: 'center'}}>
                    <h4>{this.props.onboarding ? "You're ready to go!" : "Nothing needed here. You're all set!"}</h4>
                    <br/>
                    <Link to={"/hosting/locations"}>
                        <Button>My Properties</Button>
                    </Link>
                </div>);
        }

        return (
            <div>
                {components}
                {this.props.onBackPress && <Pager>
                    <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
                </Pager>}
            </div>
        );
    }
}

export default RequirementsContainer = connect((state) => {
    return {
        user: state.data.campableUser,
        requirements: state.data.requirements,
    }
})(RequirementsContainer);
