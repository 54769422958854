import React, {Component} from 'react';
import Load from '../Load';
import {Col, Row} from 'react-bootstrap';
import LocationFeatures from './LocationFeatures';

export default class LocationDetails extends Component {


    render() {
        let features = [];
        if (this.props.location && this.props.location.propertyInformation) {
            let sites = this.props.location.propertyInformation.sites;
            if (sites) {
                sites.map(site => {
                    site.tags.map(tag => {
                        if (features.length === 0) {
                            features.push(tag);
                        } else {
                            if (features.indexOf(tag) === -1) {
                                features.push(tag);
                            }
                        }
                        return null;
                    });
                    return null;
                });
            }
            return (
                <div>
                    <h3>Details</h3>

                    <Row className="location-details">
                        <Col md={2}>
                            <label>Description</label>
                        </Col>
                        <Col md={10}>
                            <p>{this.props.location.description}</p>
                            <hr/>
                        </Col>
                    </Row>
                    {this.props.tagsObj ?
                        <Row className="location-features">
                            <Col md={2}>
                                <label>
                                    Features
                                </label>
                            </Col>
                            <Col md={10}>
                                <LocationFeatures features={features} tagsObj={this.props.tagsObj}/>
                                <hr/>
                            </Col>
                        </Row> : null}
                </div>

            );
        }

        return (
            <div className="loaderContainer">
                <Load/>
            </div>
        );
    }
}