import React, {Component} from "react";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import "../App.css";
import "./inline-styles/side-menu.css";

import {browserHistory} from "react-router";
import * as Routes from "../constants/Routes";

class MenuContainer extends Component {

    constructor(props) {
        super(props);
        this.handleCreateProperty = this.handleCreateProperty.bind(this);
    }

    handleCreateProperty() {
        if (this.props.campableUser && this.props.campableUser.accountInformation && this.props.campableUser.accountInformation.valid) {
            browserHistory.push(Routes.HOSTING_CREATE_A_PROPERTY);
        } else {
            browserHistory.push(Routes.BECOME_A_HOST);
        }
    }

    render() {
        return (
            <div className="side-menu">
                <ul>
                    <li className={this.props.currentRoute ==='/hosting/locations' ? 'active' : null} onClick={() => browserHistory.push(Routes.HOSTING_LOCATIONS)}>My Properties</li>
                    <li className={this.props.currentRoute ==='/hosting/bookings' ? 'active' : null} onClick={() => browserHistory.push(Routes.HOSTING_BOOKINGS)}>Guest Bookings</li>
                    <li className={this.props.currentRoute ==='/hosting/transactions' ? 'active' : null} onClick={() => browserHistory.push(Routes.HOSTING_TRANSACTIONS)}>Transactions</li>
                </ul>
                <div className="mint-bottom-box">
                    <Button onClick={this.handleCreateProperty}>Create Property</Button>
                </div>
            </div>
        );
    }
}


export default MenuContainer = connect(state => {
    return {
        campableUser: state.data.campableUser
    }
})(MenuContainer)

