import * as firebase from "firebase";

export function hasLocalStorageUser() {
    return !!firebase.auth().currentUser;
}

export function isAdmin(user) {
    return user && user.roles && user.roles.some(role => {
        switch(role) {
            case 'system_admin':
            case 'administrator':
                return true;
        }
    });
}

//temp for outsourcers to add freedom campsites
export function isLimitedAdmin(user) {
    return user.roles && user.roles.includes('addpropertiesonly_administrator');
}
