let initialState = {
    userBookings: [],
    userBookingsCursor: null,
    userProperties: null,
    users: {},
    affiliates: null,
    affiliateBookings: null
}

/* eslint-disable */
const data = (state = initialState, action) => {

    switch (action.type) {
        case 'ADMIN_USER_RECEIVED': {
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.user.id]: action.user,
                },
            }
        }

        case 'ADMIN_USERS_RECEIVED': {
            const users = {};
            for (let idx in action.result.items) {
                users[action.result.items[idx].id] = action.result.items[idx]
            }
            return {
                ...state,
                users: users,
            }
        }
        case 'ADMIN_CAMPAIGNS_RECEIVED': {

            const count = action.result.items.length;
            const campaigns = {};
            for (let idx in action.result.items) {
                campaigns[action.result.items[idx].id] = action.result.items[idx]
            }

            return {
                ...state,
                campaigns: campaigns,
                campaignArray: action.result.items,
                campaignCursor: count === action.searchLimit ? action.result.cursor : undefined
            }
        }
        case 'ADMIN_CAMPAIGN_RECIEVED': {

            const campaigns = {};
            for (let idx in state.campaigns) {
                campaigns[state.campaigns[idx].id] = state.campaigns[idx]
            }
            campaigns[action.result.id] = action.result;

            const newArray = [];
            let added = false;
            for(let i=0; i < state.campaignArray.length; ++i){
                if(state.campaignArray[i].id === action.result.id){
                    newArray.push(action.result);
                    added = true;
                }
                else{
                    newArray.push(state.campaignArray[i]);
                }
            }

            if(added === false){
                newArray.push(action.result);
            }

            return {
                ...state,
                campaigns: campaigns,
                campaignArray: newArray,
            }
        }
        case 'ADMIN_AFFILIATE_USERS_RECEIVED': {
            const affiliateUsers = {};
            for (let idx in action.result.items) {
                affiliateUsers[action.result.items[idx].id] = action.result.items[idx]
            }
            return {
                ...state,
                affiliateUsers: affiliateUsers,
            }
        }

        case 'ADMIN_AFFILIATE_BOOKINGS_RECEIVED': {

            const affiliateBookings = {...state.affiliateBookings} || {};

            if (action.clearResults) {
                affiliateBookings[action.referrer] = action.result.items;
            } else {
                let existingBookings = affiliateBookings[action.referrer];
                affiliateBookings[action.referrer] = existingBookings.concat(action.result.items);
            }

            return {
                ...state,
                affiliateBookings: affiliateBookings
            }
        }

        case 'ADMIN_AFFILIATE_TRANSFER_RECEIVED': {

            const transfer = action.result;

            if (transfer && transfer.updatedBookings) {

                let updatedBookings = transfer.updatedBookings || [];
                let affiliate = transfer.referrerTransfer.referrer;

                const affiliateBookings = {...state.affiliateBookings} || {};
                let currentBookings = affiliateBookings[affiliate];

                let mergedBookings = [];
                currentBookings.map((currentBooking) => {
                    let needsAdding = true;
                    updatedBookings.map((updatedBooking) => {
                        if (updatedBooking.id === currentBooking.id) {
                            mergedBookings.push(updatedBooking)
                            needsAdding = false;
                        }
                        return null;
                    });
                    if (needsAdding) {
                        mergedBookings.push(currentBooking);
                    }
                    return null;
                });

                affiliateBookings[affiliate] = mergedBookings;

                return {
                    ...state,
                    affiliateBookings: affiliateBookings
                }
            }


            return {
                ...state
            }
        }
        case 'ADMIN_AFFILIATES_RECEIVED': {
            const affiliates = action.result.items;

            return {
                ...state,
                affiliates: affiliates,
            }
        }

        case 'CAMPABLE_USER_RECEIVED': {
            if (state.campableUser && state.campableUser.id !== action.campableUser.id) {
                const users = {};

                for (let prop in state.users) {
                    if (state.users.hasOwnProperty(prop)) {
                        users[prop] = state.users[prop];
                    }
                }

                users[action.campableUser.id] = action.campableUser;
                return {
                    ...state,
                    users: users,
                }
            }
            else {
                //updated same user, let data reducer take care of this.
                return {...state, campableUser: action.campableUser}
            }
        }
        case 'ADMIN_USER_TRANSACTIONS_RECEIVED': {

            const transactions = {};
            for (let prop in state.userTransactions) {
                if (state.userTransactions.hasOwnProperty(prop)) {
                    transactions[prop] = state.userTransactions[prop];
                }
            }

            if (transactions[action.userId] !== undefined) {
                for (let i = 0; i < action.result.items.length; ++i) {
                    let c = transactions[action.userId].filter(
                        function (e) {
                            return e.id === action.result.items[i].id
                        });
                    if (c.length === 0) {
                        transactions[action.userId].push(action.result.items[i]);
                    }
                }
            } else {
                transactions[action.userId] = action.result.items;
            }

            let cursor = action.result.cursor;
            if (cursor === state.cursor) {
                cursor = null;
            }

            return {
                ...state,
                userTransactions: transactions,
                userTransactionsCursor: cursor
            }
        }
        case 'ADMIN_USER_BOOKINGS_RECEIVED': {
            const userBookings = {};
            for (let prop in state.userBookings) {
                if (state.userBookings.hasOwnProperty(prop)) {
                    userBookings[prop] = state.userBookings[prop];
                }
            }


            let currentUserBookings = state.userBookings[action.userId] || {};
            let bookings = action.result.items;

            if (bookings) {
                // eslint-disable-next-line
                bookings.map((booking) => {
                    let currentLocationBookingsOnDate = {};
                    if (currentUserBookings[booking.date]) {
                        if (currentUserBookings[booking.date][booking.location]) {
                            currentLocationBookingsOnDate = currentUserBookings[booking.date][booking.location];
                        }
                    }
                    currentLocationBookingsOnDate[booking.id] = booking;
                    currentUserBookings[booking.date] = {
                        ...currentUserBookings[booking.date],
                        [booking.location]: currentLocationBookingsOnDate,
                    };
                    return null;
                });
            }


            userBookings[action.userId] = currentUserBookings;

            let cursor = action.result.cursor;
            if (cursor === state.cursor) {
                cursor = null;
            }

            return {
                ...state,
                userBookings: userBookings,
                userBookingsCursor: cursor
            }
        }
        case'ADMIN_USER_BOOKING_RECIEVED': {
            const userBookings = {};
            for (let prop in state.userBookings) {
                if (state.userBookings.hasOwnProperty(prop)) {
                    userBookings[prop] = state.userBookings[prop];
                }
            }

            let currentUserBookings = state.userBookings[action.booking.guest];

            let currentLocationBookingsOnDate = {};
            if (currentUserBookings[action.booking.date]) {
                if (currentUserBookings[action.booking.date][action.booking.location]) {
                    currentLocationBookingsOnDate = currentUserBookings[action.booking.date][action.booking.location];
                }
            }
            currentLocationBookingsOnDate[action.booking.id] = action.booking;
            currentUserBookings[action.booking.date] = {
                ...currentUserBookings[action.booking.date],
                [action.booking.location]: currentLocationBookingsOnDate,
            };

            userBookings[action.booking.guest] = currentUserBookings;

            return {
                ...state,
                userBookings: userBookings,
                shallowComparisonFix: new Date()
            }
        }
        case 'ADMIN_USER_PROPERTIES_RECEIVED': {
            const userProperties = {};
            for (var prop in state.userProperties) {
                if (state.userProperties.hasOwnProperty(prop)) {
                    userProperties[prop] = state.userProperties[prop];
                }
            }

            userProperties[action.userId] = action.result;

            return {
                ...state,
                userProperties: userProperties,
            }
        }
        case 'ADMIN_BOOKINGS_RECEIVED':

            const bookings = {};
            for (let prop in state.propertyBookings) {
                if (state.propertyBookings.hasOwnProperty(prop)) {
                    bookings[prop] = state.propertyBookings[prop];
                }
            }

            if (bookings[action.propertyId] !== undefined) {
                for (let i = 0; i < action.result.items.length; ++i) {
                    let c = bookings[action.propertyId].filter(
                        function (e) {
                            return e.id === action.result.items[i].id
                        });
                    if (c.length === 0) {
                        bookings[action.propertyId].push(action.result.items[i]);
                    }
                }
            }
            else {
                bookings[action.propertyId] = action.result.items;
            }

            let cursor = action.result.cursor;
            if (cursor === state.cursor) {
                cursor = null;
            }
            return {
                ...state,
                propertyBookings: bookings,
                cursor: action.result.cursor
            };
        default:
            return state;
    }
};

export default data;