/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 15/11/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import * as firebase from 'firebase';

export default function tokenizer(wrapperFunc) {
    return firebase.auth().currentUser.getIdToken().then((token) => {
        return wrapperFunc(token);
    }).catch((err) => {
        return err
    })
}