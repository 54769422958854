import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import '../App.css';
import EmailInput from './common/inputs/EmailInput';
import PasswordInput from './common/inputs/PasswordInput';

export default class Login extends Component {

    render() {
        return (
            <div className="landing-login">
                <EmailInput label="Email Address"
                            placeholder="john@smith.com"
                            onChange={this.props.handleEmailInput}
                            onEnter={() => {
                                this.loginPasswordInput.focus()
                            }}/>

                <PasswordInput ref={(ref) => {
                    this.loginPasswordInput = ref;
                }}
                               label="Password"
                               placeholder="Password"
                               onChange={this.props.handlePasswordInput}
                               onEnter={this.props.handleLoginPress}/>
                <Button type="submit" className="handle-submit" onClick={this.props.handleLoginPress}>Login</Button>
                <Row className="reset-password-container">
                    <Col xs={12} sm={6} className="button-container">
                        <a className="back-button" onClick={this.props.onBackPress}>Back</a>
                    </Col>
                    <Col xs={12} sm={6} className="button-container">
                        <a className="reset-password" onClick={this.props.handleSelectResetPasswordPress}>Reset Password</a>
                    </Col>
                </Row>
            </div>
        );
    }
}
