import React, {Component} from "react";
import ContentRow from "./ContentRow";
import {Col, Grid, MenuItem, Row} from "react-bootstrap";
import {browserHistory} from "react-router";

export default class Page extends Component {

    render() {

        let rows = [];
        let title = this.props.page.rows[0].title;
        this.props.page.rows.map((row, i) => {
            let rowComponent = <ContentRow key={i} row={row} showLoginOverlay={this.props.showLoginOverlay} isLoggedIn={this.props.isLoggedIn}/>
            rows.push(rowComponent);
            return null;
        });

        if (this.props.splat === "why-host") {
            return (
                <Grid className="page">
                    <Row>
                        <Col xs={12}>
                            {rows}
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <Grid className="page">
                <Row>
                    <Col md={3} className="page-menu hidden-sm hidden-xs">
                        <ul>
                            <h4>Help</h4>
                            <MenuItem eventKey="/how-does-campable-work" onClick={() => browserHistory.push('/how-campable-works')}>How does Campable Work?</MenuItem>
                            <MenuItem eventKey="/after-booking" onClick={() => browserHistory.push('/after-booking')}>After Booking</MenuItem>
                            {/*<MenuItem eventKey="/why-host" onClick={() => browserHistory.push('/why-host')}>Why Host with Campable?</MenuItem>*/}
                            <MenuItem eventKey="/faq-guest" onClick={() => browserHistory.push('/faq-guest')}>FAQ - Guests</MenuItem>
                            <MenuItem eventKey="/faq-host" onClick={() => browserHistory.push('/faq-host')}>FAQ - Hosts</MenuItem>
                            <MenuItem eventKey="/rules-of-etiquette" onClick={() => browserHistory.push('/rules-of-etiquette')}>Rules of Etiquette</MenuItem>
                            <MenuItem eventKey="/health-and-safety" onClick={() => browserHistory.push('/health-and-safety')}>Health and Safety</MenuItem>
                            <MenuItem eventKey="/terms-and-conditions" onClick={() => browserHistory.push('/terms-and-conditions')}>Terms and Conditions</MenuItem>
                        </ul>
                    </Col>
                    <Col xs={12} md={9} className="card">
                        <h1 className="page-title">{title}</h1>
                        {rows}
                    </Col>
                </Row>
            </Grid>
        );
    }
}