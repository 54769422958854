import React, {Component} from 'react';
import {Col, Row} from 'react-bootstrap';
import SearchBar from '../../common/SearchBar';
import bannerImage from '../../../resources/images/main-hero-image-v2.jpg';
import '../../../App.css';


export default class Banner extends Component {

    render() {

        // let bannerImage = this.props.bannerDetails.bannerImage.url;

        let tagline = this.props.bannerDetails.tagline || null;

        return (
            <Row className="home-banner" style={{backgroundImage: `url(${bannerImage})`}}>
                <Col xs={12} md={12} lg={12}>
                    <div className="home-banner-content">
                        <h1><strong>{this.props.bannerDetails.title} </strong><br/>
                            {tagline}
                        </h1>
                        <SearchBar searchLocation={this.props.searchLocation}/>
                    </div>
                </Col>
            </Row>
        );
    }
}