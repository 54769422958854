import React, {Component} from "react";
import {connect} from "react-redux";
import {reduxForm} from "redux-form";
import {Button, Col, Grid, Pager, Row} from "react-bootstrap";
import {validate} from "../../components/common/form-validation/UpdatePropertyValidation";
import {
    adminFetchPropertyBookings,
    cancelHostBooking,
    fetchProperty,
    showAlert,
    updateProperty,
    updatePropertyStatus
} from "../../actions/index";
import {browserHistory} from "react-router";
import AdminLocationMenu from "../../components/admin/AdminLocationMenu";
import {BECOME_A_HOST} from "../../constants/Routes";
import {isAdmin} from "../../auth";
import {fireTagEvent} from "../../tagmanager";
import HostLocationDetailView from "../../components/hostLocation/HostLocationDetailView";
import HostBookingsPanel from "../../components/hostBookings/HostBookingsPanel";
import ProgressInformation from "../../components/hostLocation/ProgressInformation";

class AdminLocationContainer extends Component {

    constructor(props) {
        super(props);
        this.saveLocationDetails = this.saveLocationDetails.bind(this);
        this.stripeButtonPressed = this.stripeButtonPressed.bind(this);
        this.removeSitePressed = this.removeSitePressed.bind(this);
        this.handleListedSelectChange = this.handleListedSelectChange.bind(this);
        this.fetchBookings = this.fetchBookings.bind(this);
        this.onPreviousPageClick = this.onPreviousPageClick.bind(this);
        this.onNextPageClick = this.onNextPageClick.bind(this);
        this.cancelPressed = this.cancelPressed.bind(this);

        if (props.properties && props.properties[props.params.locationId]) {
            this.state = {
                statusValue: props.properties[props.params.locationId].status,
                pagerPosition: 0,
                loadingBookings: false
            }
        } else {
            this.state = {
                statusValue: 'Listed',
                pagerPosition: 0,
                loadingBookings: false
            }
        }
    }

    componentWillMount() {
        this.fetchBookings(this.props, null);
    }


    fetchBookings(nextProps, bookingCursor) {
        if (!!nextProps.campableUser && !!nextProps.property) {
            if ((!nextProps.propertyBookings || bookingCursor ) && !this.state.loadingBookings) {
                this.setState({loadingBookings: true})
                this.props.dispatch(adminFetchPropertyBookings(nextProps.params.locationId, nextProps.property.owner, bookingCursor));
            }
        }
    }

    cancelPressed(booking) {
        this.props.dispatch(cancelHostBooking(booking.uid));
    }


    componentWillReceiveProps(nextProps, nextState, a,b,c) {
        if (nextProps.properties && nextProps.properties[nextProps.params.locationId]) {
            this.setState({
                statusValue: nextProps.properties[nextProps.params.locationId].status,
            });
        }

        if (!!nextProps.campableUser && !nextProps.property) {
            this.props.dispatch(fetchProperty(nextProps.params.locationId));
        }

        if (!!nextProps.propertyBookings && this.state.loadingBookings) {
            this.setState({loadingBookings: false})
        } else {
            this.fetchBookings(nextProps, null);
        }

    }

    onNextPageClick() {
        if (this.props.propertyBookings && this.props.propertyBookings.length <= ((this.state.pagerPosition + 1) * 50)) {
            this.fetchBookings(this.props, this.props.bookingsCursor);
        }

        this.setState({
            pagerPosition: this.state.pagerPosition + 1,
        });
    }

    onPreviousPageClick() {
        this.setState({
            pagerPosition: this.state.pagerPosition - 1,
        });
    }

    removeSitePressed(index) {
        let propertyValue = (this.props.property || {});

        if (propertyValue.propertyInformation.sites[index]) {

            propertyValue.propertyInformation.sites[index].status = "deleted";
            let sites = propertyValue.propertyInformation.sites;

            this.props.dispatch(updateProperty({
                id: propertyValue.id,
                name: propertyValue.name,
                latitude: propertyValue.address.latitude,
                longitude: propertyValue.address.longitude,
                address: propertyValue.address.address,
                tags: [],
                images: propertyValue.images,
                directions: propertyValue.directions,
                propertyInformation: {
                    sites: sites
                }
            }));
        }
    }

    stripeButtonPressed() {
        browserHistory.push(BECOME_A_HOST);
    }

    saveLocationDetails(values) {
        let sites = null;

        if (values.propertyInformation) {
            sites = values.propertyInformation.sites.map((site) => {
                if (!site.active && site.status !== 'deleted') {
                    site.status = "active";
                }
                return site
            });
        }

        let data = {
            id: values.id,
            name: values.name,
            latitude: values.latitude,
            longitude: values.longitude,
            address: values.type !== "freedom_campsite" ? values.address.address : values.addressFreedom,
            tags: values.tags,
            images: values.images,
            description: values.description,
            website: values.website,

        };

        if (values.propertyInformation) {
            data.propertyInformation = {
                sites: sites,
                noticePeriod: values.propertyInformation.noticePeriod === 'Guests can book on the day' ? 0 : 1
            }
        }

        fireTagEvent("update-a-property", this.props.campableUser);
        this.props.dispatch(updateProperty(data));
    }

    handleListedSelectChange(e) {
        const propertyId = this.props.params.locationId;
        const numOfImages = this.props.properties[this.props.params.locationId].images.length;

        this.props.dispatch(showAlert("info", "Updating location status..."));

        if (e === "On") {
            if (numOfImages !== 0) {
                this.setState({
                    statusValue: e
                });
                this.props.dispatch(updatePropertyStatus(propertyId, "active"));
            } else {
                this.props.dispatch(showAlert('warning', 'There was a problem listing your property', 'Please add at least one photo to your property to list.'));
            }

        } else if (e === "Off") {

            this.setState({
                statusValue: e
            });
            this.props.dispatch(updatePropertyStatus(propertyId, "inactive"));
        }
    }

    render() {

        let location = this.props.property;

        let isAdminn = false;

        if (this.props.campableUser && isAdmin(this.props.campableUser)) {
            isAdminn = true;
        }

        let saveChanges;

        const isHost = this.props.campableUser && this.props.campableUser.accountInformation.valid;

        const progressInfo = (this.props.valid && (isHost || isAdmin(this.props.campableUser)))
            ? null
            : <ProgressInformation errors={this.props.errors}/>;

        let showPropertyItems = [];

        if (this.props.propertyBookings) {
            let propertyItems = this.props.propertyBookings;
            for (let i = 0; i < 50; i++) {
                let property = propertyItems[(this.state.pagerPosition * 50) + i];
                if (property) {

                    showPropertyItems.push(property);
                }
            }
        }

        if (this.props.dirty) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={2}/>
                        <Col md={4}>
                            <h4>You have made some changes</h4>
                            <p>Click Update to make your changes public</p>
                        </Col>
                        <Col md={4}>
                            {progressInfo}
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit">Update</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        let statusValue = 'Off';

        if (!!location) {
            statusValue = location.status === "active" ? "On" : "Off";
        }

        const details = (
            <form onSubmit={this.props.handleSubmit(this.saveLocationDetails)}>
                <HostLocationDetailView removeSitePressed={this.removeSitePressed}
                                        stripeButtonPressed={this.stripeButtonPressed} {...this.props}
                                        handleListedSelectChange={this.handleListedSelectChange}
                                        statusValue={statusValue}
                                        location={location}
                                        isAdmin={isAdminn}/>
                {saveChanges}
            </form>
        );
        let showPager = false;
        let component = details;
        if (this.props.params.splat.indexOf('bookings') > -1) {
            component = (
                <div className="host-bookings-panel">
                    <HostBookingsPanel hostBookings={showPropertyItems} cancelPressed={this.cancelPressed}/>
                </div>
            );
            showPager = true;
        }

        const pager = (
            <Pager className="pager">
                <Pager.Item previous
                            onClick={this.onPreviousPageClick}
                            disabled={this.state.pagerPosition === 0}>&larr; Previous Page</Pager.Item>
                <Pager.Item next
                            onClick={this.onNextPageClick}
                            disabled={showPropertyItems.length !== 50}>Next Page &rarr;</Pager.Item>
            </Pager>
        );

        // console.log(this);

        return (
            <Grid className="main-container">
                <Row>
                    <h1>{location ? location.name : ""}</h1>
                    <Col md={2} className="menu">
                        <AdminLocationMenu locationId={this.props.params.locationId}
                                           hostId={this.props.property.owner}
                                           currentRoute={this.props.route.path}/>
                    </Col>
                    <Col md={10}>
                        {component}
                    </Col>
                </Row>
                <Row>
                    <Col md={5}/>
                    <Col md={5}>
                        {showPager ? pager : ""}
                    </Col>
                    <Col md={2}/>
                </Row>

            </Grid>

        );
    }
}


AdminLocationContainer = reduxForm({
    form: 'locationDetails',
    validate,
})(AdminLocationContainer);

export default AdminLocationContainer = connect((state, props) => {
    const property = state.data.properties && state.data.properties[props.params.locationId] ? state.data.properties[props.params.locationId] : '';
    const propertyBookings = state.admindata.propertyBookings && state.admindata.propertyBookings[props.params.locationId] ? state.admindata.propertyBookings[props.params.locationId] : '';

    return {
        properties: state.data.properties,
        property: property,
        propertyBookings: propertyBookings,
        bookingsCursor: state.admindata.cursor,
        tags: state.data.tags,
        campableUser: state.data.campableUser,
        initialValues: parseForm(props, state.data.properties),
        enableReinitialize: true,
        errors: state.form && state.form.locationDetails && state.form.locationDetails.syncErrors ? state.form.locationDetails.syncErrors : {},
    }

})(AdminLocationContainer);


function parseForm(props, properties) {
    let locationId = parseInt(props.params.locationId, 10);
    let propertyValue = (properties || {})[locationId] || {};
    let temp;
    if (typeof propertyValue.address === 'string') {
        temp = (propertyValue.address || {});

        propertyValue.address = {
            address: temp,
            latitude: propertyValue.latitude,
            longitude: propertyValue.longitude
        };
    }

    if (propertyValue.propertyInformation && propertyValue.propertyInformation.noticePeriod !== undefined && !isNaN(propertyValue.propertyInformation.noticePeriod)) {
        propertyValue.propertyInformation.noticePeriod = propertyValue.propertyInformation.noticePeriod === 1 ? 'I would like one (1) day\'s notice' : 'Guests can book on the day';
    }

    return propertyValue

}
