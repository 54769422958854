import React, {Component} from "react";
import {LINK_ANDROID_APP_STORE} from "../../../constants/Constants";
import android_download_badge from "../../../resources/images/badge_android_download.png";

export default class AndroidBadge extends Component {

    render() {
        return (
            <a href={LINK_ANDROID_APP_STORE} target="_blank">
                <img alt='Get it on Google Play' src={android_download_badge} className="app-download-badge"/>
            </a>
        );
    }
};