import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {validate} from "../common/form-validation/CreditCardValidation";
import {connect} from "react-redux";
import {Button, Pager} from "react-bootstrap";
import {createCreditCardToken} from "../../api/ApiStripe";
import {showAlert, updateCampableUserCreditCard} from "../../actions/index";
import ValidatedTextInput from "../common/ValidatedTextInput";
import ValidatedCreditCardInput from "../common/ValidatedCreditCardInput";
import Card from "../Card";
import Loading from "react-loading";

class AccountPaymentDetails extends Component {


    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false,
        }
    }


    onSubmit(values) {
        if (!this.props.pristine || this.props.dirty) {
            this.setState({
                loading: true
            });
            this.props.dispatch(showAlert('info', 'Updating your credit card information...', ''));
            createCreditCardToken(values).then((r) => {
                let stripeToken = r.id;
                this.props.dispatch(updateCampableUserCreditCard({
                    paymentInformation: {
                        token: stripeToken,
                    }
                }));
                this.setState({
                    loading: false
                });
                this.props.onNextStepPress();
                this.props.reset();
            }).catch((err) => {
                this.setState({
                    loading: false
                });
                this.props.dispatch(showAlert('danger', 'There was an error while updating your credit card.', err.error.message))
            });
        } else {
            this.props.onNextStepPress()
        }
    }


    render() {

        let loading = null;
        if (this.state.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }


        return (
            <Card title="Account payment details">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {this.props.user.paymentInformation.valid && this.props.pristine ?
                        <div className="onboarding-current-info">
                            <h4>You currently have a card added!</h4>
                            <Button onClick={this.props.onNextStepPress}>Next</Button>
                        </div> : null }

                    <ValidatedCreditCardInput name="cardNumber" label="Card Number" placeholder="e.g. 1234-5678-9101-1121" maxLength={19}/>
                    <ValidatedTextInput name="cardExpMonth" label="Card Expiry Month" placeholder="e.g. 12" maxLength={2}/>
                    <ValidatedTextInput name="cardExpYear" label="Card Expiry Year" placeholder="e.g. 24" maxLength={2}/>
                    <ValidatedTextInput name="cardCVV" label="Card CVV" placeholder="e.g. 123" maxLength={3}/>

                    <Pager>
                        <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
                        <Pager.Item next type="submit" disabled={!this.props.valid} onClick={this.props.handleSubmit(this.onSubmit)}>Next</Pager.Item>
                    </Pager>
                </form>
                {loading}
            </Card>
        );
    }
}

AccountPaymentDetails = reduxForm({
    form: 'onboardingPaymentDetailsForm',
    validate
})(AccountPaymentDetails);

export default AccountPaymentDetails = connect((state) => {
    return {
        initialValues: {},
        user: state.data.campableUser
    }
})(AccountPaymentDetails);

