import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Button, Pager} from "react-bootstrap";
import {campableUserReceived, showAlert} from "../../actions/index";
import Card from "../Card";
import {validate} from "../common/form-validation/StripeValidation";
import ValidatedBankAccountInput from "../common/ValidatedBankAccountInput";
import {createStripeAccount, getStripeBankToken, updateStripeAccount} from "../../api/api";
import Loading from "react-loading";

class HostDetailsController extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false,
        }
    }

    onSubmit(values) {

        this.setState({
            loading: true
        });

        const bankAccount = values.bankAccount.replace(new RegExp('-', 'g'), '');

        let routingNumber = bankAccount.substring(0, 6);
        let accountNumber = bankAccount.substring(6);

        if (accountNumber.length === 9) {
            let firstOnes = accountNumber.slice(0, 7);
            let last2 = accountNumber.slice(7);
            accountNumber = firstOnes + '0' + last2;
        }

        let bankDetails = {
            currency: "nzd",
            accountNumber: accountNumber,
            routingNumber: routingNumber,
            country: "NZ"
        };

        this.props.dispatch(showAlert('info', 'Updating Host details...', 'Your bank details are being verified.'));

        getStripeBankToken(bankDetails).then((token) => {

            const account = {
                token: token.id
            };

            updateStripeAccount(this.props.user.id, account).then((user) => {
                this.setState({
                    loading: false,
                });
                this.props.dispatch(showAlert('success', 'Success!', 'Your account has been updated!'));
                this.props.dispatch(campableUserReceived(user));
                this.props.onNextStepPress();
            }).catch((err) => {
                if (err.message && err.message === 'invalid user or account') {
                    createStripeAccount(account).then((user) => {
                        this.props.dispatch(showAlert('success', 'Success!', 'Your account has been created!'));
                        this.props.dispatch(campableUserReceived(user));
                        this.props.onNextStepPress();
                    }).catch((err) => {
                        console.log(111, err)
                        this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message));
                    });
                } else {
                    console.log(222, err);
                    this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message));
                }

                this.setState({
                    loading: false,
                });
            });
        }).catch((err) => {
            console.log(333, err)
            this.setState({
                loading: false,
            });
            this.props.dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + err.message));
        });
    }

    render() {

        let loading = null;
        if (this.state.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }

        return (
            <Card title="Account payment details">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    {this.props.user.accountInformation.valid && this.props.pristine ?
                        <div className="onboarding-current-info">
                            <h4>Your account is already setup!</h4>
                            <Button onClick={this.props.onNextStepPress}>Next</Button>
                        </div> : null }

                    <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>

                    <ValidatedBankAccountInput name="bankAccount" label="Bank Account Number" placeholder="e.g. 00-1234-5678912-00" required/>

                    <Pager>
                        <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
                        <Pager.Item next type="submit" disabled={!this.props.valid} onClick={this.props.handleSubmit(this.onSubmit)}>Next</Pager.Item>
                    </Pager>
                </form>
                {loading}
            </Card>
        );
    }
}

HostDetailsController = reduxForm({
    form: 'hostDetailsForm',
    validate
})(HostDetailsController);

export default HostDetailsController = connect((state) => {
    return {
        initialValues: {},
        user: state.data.campableUser,
    }
})(HostDetailsController);
