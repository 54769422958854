/**
 * Created by chris on 1/05/17.
 */

import React, {Component} from "react";
import Card from "../Card";
import {Button, Col, Row} from "react-bootstrap";
import {browserHistory} from "react-router";

export default class FinalOnboarding extends Component {

    constructor(props) {
        super(props);

        this.onLetsGoPress = this.onLetsGoPress.bind(this);
    }

    shouldComponentUpdate() {
        return false;
    }

    onLetsGoPress() {
        browserHistory.push('/')
    }


    render() {
        return (
            <Card title="You're all set!">
                <Row className="onboardingHostCampContainer">
                    <Col>
                        <Button onClick={this.props.onBackPress}>Back</Button>
                        <Button onClick={this.onLetsGoPress}>Lets Go!</Button>
                    </Col>
                </Row>
            </Card>
        );
    }
}
