import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Grid, Nav, NavItem, Row} from 'react-bootstrap';
import {getLocation} from '../actions'
import Load from '../components/Load';
import LocationGallery from '../components/location/LocationGallery';
import LocationDetails from '../components/location/LocationDetails';
import LocationMapDetails from '../components/location/LocationMapDetails';
import LocationBookingInformation from '../components/location/LocationBookingInformation';
import AppDownloadPanel from '../components/location/AppDownloadPanel';
import {clearSelectedBookings, clearSiteAvailability, createUserBookings, getLocationSiteAvailabilities, selectedBookingOnPress} from "../actions/index";
import values from 'lodash/values';
import {isAdmin} from "../auth";
import {browserHistory} from "react-router";
import {HOSTING_LOCATIONS_ID} from "../constants/Routes";
import {fireECommerceEvent} from "../tagmanager";
import {showLoginOverlay} from "../actions/overlay";

class LocationContainer extends Component {

    constructor(props) {
        super(props);

        this.getLocationSiteAvailabilities = this.getLocationSiteAvailabilities.bind(this);
        this.clearSiteAvailability = this.clearSiteAvailability.bind(this);
        this.clearSelectedBookings = this.clearSelectedBookings.bind(this);
        this.onBookingTilePress = this.onBookingTilePress.bind(this);
        this.onFinaliseBookingsButtonPress = this.onFinaliseBookingsButtonPress.bind(this);
        this.editPropertyPressed = this.editPropertyPressed.bind(this);
        this.showLoginOverlay = this.showLoginOverlay.bind(this);

        this.state = {}
    }

    componentWillMount() {

        let code = this.props.location.query.affiliate;
        if(code !== undefined) {
            localStorage.setItem('affiliateReferralCode', code);
        }
    }

    componentDidUpdate(prevProps, prevState) {

        let locId = parseInt(this.props.params.locationId, 10);
        if(prevProps.locationsIndex !== null) {
            let location = prevProps.locationsIndex[locId];

            if (location !== undefined && this.state.eCommerceSent === undefined) {
                fireECommerceEvent(this.props.campableUser, "detail", undefined, location);
                this.setState({"eCommerceSent": true})
            }
        }
    }

    componentDidMount() {
        let locId = parseInt(this.props.params.locationId, 10);
        this.props.dispatch(getLocation(locId));
    }

    componentWillUnmount() {
        this.clearSiteAvailability()
    }

    getLocationSiteAvailabilities(from, to) {
        this.props.dispatch(getLocationSiteAvailabilities(this.props.params.locationId, from, to));
    }

    clearSiteAvailability() {
        this.props.dispatch(clearSiteAvailability());
    }

    clearSelectedBookings() {
        this.props.dispatch(clearSelectedBookings());
    }

    onBookingTilePress(site, day, price) {
        let locId = parseInt(this.props.params.locationId, 10);
        this.props.dispatch(selectedBookingOnPress(this.props.locationsIndex[locId], site, day, price))
    }


    onFinaliseBookingsButtonPress() {
        this.props.dispatch(createUserBookings(this.props.params.locationId, values(this.props.selectedBookings)))
    }

    editPropertyPressed(property) {
        browserHistory.push(HOSTING_LOCATIONS_ID + property.id);
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp))
    }


    render() {


        let location;
        let locId = parseInt(this.props.params.locationId, 10);
        if (this.props.locationsIndex && this.props.locationsIndex[locId]) {
            location = this.props.locationsIndex[locId];
        }

        if (!location) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let adminButton;

        if (this.props.campableUser && isAdmin(this.props.campableUser)) {
            adminButton = <Button onClick={() => {
                this.editPropertyPressed(location)
            }}>Edit Property</Button>
        }

        const images = location.images.map(image => ({
            original: image.url,
        }));

        return (
            <Grid className="location-container">

                <Row>
                    <Col>
                        <LocationGallery images={images}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Nav bsStyle="pills" activeKey={1}>
                            <NavItem eventKey={1} href="#ldp-details">Details</NavItem>
                            <NavItem eventKey={2} href="#ldp-location">Location</NavItem>
                            <NavItem eventKey={2} href="#ldp-book-now">Book Now</NavItem>
                            <NavItem eventKey={3} href="#ldp-download">Download App</NavItem>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>{location.name}</h1>
                        {adminButton}
                    </Col>
                </Row>

                <Row>
                    <Col id="ldp-details">
                        <LocationDetails location={location} tagsObj={this.props.tagsObj}/>
                    </Col>
                </Row>
                <Row>
                    <Col id="ldp-location">
                        <LocationMapDetails location={location}/>
                    </Col>
                </Row>
                <Row>
                    <Col id="ldp-book-now">
                        <LocationBookingInformation
                            location={location}
                            clearSiteAvailability={this.clearSiteAvailability}
                            clearSelectedBookings={this.clearSelectedBookings}
                            onFinaliseBookingsButtonPress={this.onFinaliseBookingsButtonPress}
                            getLocationSiteAvailabilities={this.getLocationSiteAvailabilities}
                            onBookingTilePress={this.onBookingTilePress}
                            siteAvailabilities={this.props.siteAvailabilities}
                            selectedBookings={this.props.selectedBookings}
                            campableUser={this.props.campableUser}
                            campableUserBookings={this.props.campableUserBookings}
                            showLoginOverlay={this.showLoginOverlay}
                            tagsObj={this.props.tagsObj}/>
                    </Col>
                </Row>
                <hr/>
                <Row id="ldp-download">
                    <Col>
                        <AppDownloadPanel location={location} campableUser={this.props.campableUser} showLoginOverlay={this.showLoginOverlay}/>
                    </Col>
                </Row>
            </Grid>
        );
    }
}


export default LocationContainer = connect((state) => {
    return {
        locationsIndex: state.data.locationsIndex,
        tagsObj: state.data.tagsObj,
        siteAvailabilities: state.data.siteAvailabilities,
        selectedBookings: state.data.selectedBookings,
        campableUser: state.data.campableUser,
        campableUserBookings: state.data.campableUserBookings,
    }
})(LocationContainer);