import React, {Component} from "react";
import moment from "moment";
import {browserHistory} from "react-router";
import {LOCATIONS_ID} from "../../constants/Routes";
import values from "lodash/values";
import BookingsRowItem from "./BookingsRowItem";
import Load from "../Load";

export default class BookingsController extends Component {

    constructor(props) {
        super(props);

        this.renderRow = this.renderRow.bind(this);
        this.groupBookings = this.groupBookings.bind(this);
        this.formatGroupedBookings = this.formatGroupedBookings.bind(this);

        this.onBookingTitlePress = this.onBookingTitlePress.bind(this);
        this.onBookingTileCancelPress = this.onBookingTileCancelPress.bind(this);

        this.state = {
            today: moment(),
        }

    }

    onBookingTitlePress(id) {
        browserHistory.push(LOCATIONS_ID + id);
    }

    onBookingTileCancelPress(bookingUIDS) {
        this.props.cancelBooking(bookingUIDS)
    }

    renderRow(groupedBooking, i) {
        return <BookingsRowItem key={i}
                                groupedBooking={groupedBooking}
                                administratorView={this.props.administratorView}
                                campableUserReviews={this.props.campableUserReviews}
                                onBookingTitlePress={this.onBookingTitlePress}
                                onBookingTileCancelPress={this.onBookingTileCancelPress}
                                refundBooking={this.props.refundBooking}
                                location={this.props.locations !== null ? this.props.locations[groupedBooking.locationId] : undefined}
                                campablerUser={this.props.campableUser}
                                getLocation={this.props.getLocation}/>;
    }


    groupBookings() {
        let bookingsAccepted = {};
        let bookingsCancelled = {};
        let qz = 0;

        let sortedBookingList = [];

        // eslint-disable-next-line
        for (let bookingDate in this.props.campableUserBookings) {
            sortedBookingList.push([bookingDate, this.props.campableUserBookings[bookingDate]])
        }


        //This sorts all hostBookings in order of the date to give an ascending list.
        sortedBookingList.sort((a, b) => {
            let day1 = moment(a[0]);
            let day2 = moment(b[0]);
            return day1 - day2;
        });


        //This maps through all hostBookings on day. Meaning if there is a cancelled booking and the user books again it sorts those 2 into 2 different lists.
        // eslint-disable-next-line
        sortedBookingList.map((bookingObj) => {
            // eslint-disable-next-line
            values(bookingObj[1]).map((bookingDateArrayObject) => {
                // eslint-disable-next-line
                values(bookingDateArrayObject).map((booking) => {
                    if (booking.status !== 'cancelled') {
                        bookingsAccepted[qz] = booking;
                    } else {
                        bookingsCancelled[qz] = booking;
                    }
                    qz++;
                });
            });
        });

        return {...this.formatGroupedBookings(bookingsAccepted), ...this.formatGroupedBookings(bookingsCancelled)};
    }

    //This function is able to sort hostBookings into groups with date ranges
    formatGroupedBookings(bookingObj) {

        let groupedBookings = {};

        let bookingObjArray = values(bookingObj);
        let bookingObjArrayLength = bookingObjArray.length;

        let today = moment();

        for (let i = 0; i < bookingObjArrayLength; i++) {
            let currentBooking = bookingObjArray[i];
            let previousDay = moment(currentBooking.date);
            let groupBookingIndex = Object.keys(bookingObj)[i];

            let bookingUIDS = [];
            let currentDate = moment(currentBooking.date);

            //if (currentDate.isAfter(today) || currentDate.diff(today, 'days') > 0) {
                bookingUIDS.push(currentBooking.uid);
            //}

            let directions = [];
            if(currentBooking.siteDirections !== null) {
                directions.push({siteName: currentBooking.siteName, siteDirections: currentBooking.siteDirections});
            }

            groupedBookings[groupBookingIndex] = {
                dateOfArrival: currentBooking.date,
                dateOfDeparture: currentDate.clone().add(1, 'days').format('YYYY-MM-DD'),
                locationId: currentBooking.location,
                locationName: currentBooking.locationName,
                totalPaid: currentBooking.paid,
                bookingUIDS: bookingUIDS,
                hostFirstName: currentBooking.hostFirstName,
                hostLastName: currentBooking.hostLastName,
                hostPhone: currentBooking.hostPhone,
                hostEmail: currentBooking.hostEmail,
                status: currentBooking.status,
                totalRefunded: currentBooking.refunded,
                adminIndividualBookings: [{uid: currentBooking.uid, date: currentBooking.date, refunded: currentBooking.refunded, refundDate: currentBooking.refundDate, refundReason: currentBooking.refundReason}],
                siteDirections: directions
            };

            for (let ii = i + 1; ii < bookingObjArrayLength; ii++) {
                let nextBooking = bookingObjArray[ii];
                let nextDay = moment(nextBooking.date);
                if (nextDay.clone().diff(previousDay, 'days') === 1 && nextBooking.location === currentBooking.location) {
                    previousDay = nextDay;
                    i++;

                    let bookingUIDS = groupedBookings[groupBookingIndex].bookingUIDS;
                    //if (nextDay.isAfter(today) || nextDay.diff(today, 'days') > 0) {
                        bookingUIDS.push(nextBooking.uid);
                    //}

                    const totalRefunded = groupedBookings[groupBookingIndex].totalRefunded + nextBooking.refunded;
                    groupedBookings[groupBookingIndex].adminIndividualBookings.push({uid: nextBooking.uid, date: nextBooking.date, refunded: nextBooking.refunded, refundDate: nextBooking.refundDate, refundReason: nextBooking.refundReason});

                    let found = false;
                    if(nextBooking.siteDirections !== null) {
                        for (let h = 0; h < directions.length; ++h) {
                            if(directions[h].siteName === nextBooking.siteName){
                                found = true;
                                break;
                            }
                        }

                        if(!found) {
                            directions.push({
                                siteName: nextBooking.siteName,
                                siteDirections: nextBooking.siteDirections
                            });
                        }
                    }

                    groupedBookings[groupBookingIndex] = {
                        ...groupedBookings[groupBookingIndex],
                        dateOfDeparture: nextDay.clone().add(1, 'days').format('YYYY-MM-DD'),
                        totalPaid: groupedBookings[groupBookingIndex].totalPaid + nextBooking.paid,
                        bookingUIDS: bookingUIDS,
                        totalRefunded: totalRefunded,
                        siteDirections: directions
                    };
                } else {
                    break;
                }
            }
        }

        return groupedBookings;
    }


    render() {

        const userBookings = values(this.groupBookings()).map(this.renderRow);

        return (
            <div className="bookings-controller-container">

                {!!this.props.campableUser ?
                    <div>
                    {/*<h1>Bookings</h1>*/}
                        {userBookings.length === 0
                            ?
                            <div>
                                <p>You have no bookings.</p>
                            </div>
                            : userBookings}
                </div> : <Load/>}
            </div>
        )
    }
}
