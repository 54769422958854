import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import "../../App.css";
import placeholder from "../../resources/images/placeholder-image.png";
import DropdownSelect from "../common/DropdownSelect";
import {showAlert, updatePropertyStatus} from "../../actions/index";
import {connect} from "react-redux";
import {isAdmin} from "../../auth";

class LocationPanel extends Component {

    constructor(props) {
        super(props);

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.state = {
            statusValue: "Off"
        }
    }

    componentDidMount() {
        if (this.props.location && this.props.location.status === "active") {
            this.setState({
                statusValue: "On"
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location && nextProps.location.status === "active") {
            this.setState({
                statusValue: "On"
            });
        }
    }

    handleSelectChange(e) {
        if (e === "On") {
            this.props.dispatch(updatePropertyStatus(this.props.location.id, "active"));
        } else if (e === "Off") {
            this.props.dispatch(updatePropertyStatus(this.props.location.id, "inactive"));
        }
    }


    render() {

        let image = <img ref='#' src={placeholder} alt="property"/>

        if (this.props.location && this.props.location.images && this.props.location.images.length > 0) {
            image = <img ref='#' src={this.props.location.images[0].url} alt="property"/>
        } else if ((this.props.location || {}).image !== undefined) {
            image = <img ref='#' src={this.props.location.image} alt="property"/>
        }

        let address = (this.props.location || {}).address;

        if (typeof address === "object") {
            address = ((this.props.location || {}).address || {}).address
        }

        return (
            <div className='location-panel'>
                <Row>
                    <Col xs={12}>
                        <Col xs={12} sm={2} className="location-header-image" onClick={() => {
                            this.props.locationPressed(this.props.location.id)
                        }}>
                            {image}
                        </Col>


                        <Col xs={12} sm={6} className="locations-panel-details" onClick={() => {
                            this.props.locationPressed(this.props.location.id)
                        }}>
                            <h3>{this.props.location.name}</h3>
                            <h4>{address}</h4>
                            <p className="view-listing-link"><a className='location-panel-text'>View Listing</a></p>

                        </Col>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default LocationPanel = connect(() => {
    return {}
})(LocationPanel);
