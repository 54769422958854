import React, {Component} from "react";
import {Checkbox, Col, Row} from "react-bootstrap";
import "../App.css";
import placeholder from "../resources/images/placeholder-image.png";
import {changeUserAffiliateState} from "../actions/index";
import {connect} from "react-redux";

class UserSummaryPanel extends Component {

    constructor(props) {
        super(props);
    }



    onUserPressed = () => {
        this.props.userPressed(this.props.user.id);
    };


    onChange = (e) => {
        console.log(e.target);
        this.props.dispatch(changeUserAffiliateState(this.props.user.id));
    };

    render() {

        let image = <img href='#' src={placeholder} alt="property"/>;

        if ((this.props.user || {}).image !== undefined) {
            image = <img href='#' src={this.props.user.image} alt="property"/>
        }
        return (
            <div className='location-panel'>
                <Row>
                    <Col xs={12}>
                        <Col xs={12} sm={2} className="location-header-image" onClick={this.onUserPressed}>
                            {image}
                        </Col>


                        <Col xs={12} sm={6} className="locations-panel-details" onClick={this.onUserPressed}>
                            <h3>{this.props.user.firstName || "No Name" + " " + this.props.user.secondName || ""}</h3>
                            <h4>{this.props.user.email}</h4>
                            <p className="view-listing-link"><a className='location-panel-text'>View User</a></p>

                        </Col>
                        <Col xs={0} sm={2}/>
                        <Col xs={12} sm={2}>
                            <Checkbox type="checkbox"
                                      checked={this.props.user.isReferrer}
                                      onChange={this.onChange}>
                                Affiliate
                            </Checkbox>
                        </Col>
                    </Col>
                </Row>
            </div>

        );
    }
}

export default UserSummaryPanel = connect(() => {
    return {}
})(UserSummaryPanel);
