import React, {Component} from 'react';
import '../../App.css';
import HostBookingsPanel from './HostBookingsPanel'
import YouHaveNoBookings from './HostYouHaveNoBookings'


export default class HostBookingsController extends Component {

    render() {
        if (this.props.hostBookings && Object.keys(this.props.hostBookings).length > 0) {
            return (
                <div className="host-bookings-panel">
                    <HostBookingsPanel hostBookings={this.props.hostBookings} cancelPressed={this.props.cancelPressed}/>
                </div>
            );
        } else {
           return (
               <div className="host-bookings-panel">
                   <YouHaveNoBookings />
               </div>
           );
        }
    }
}
