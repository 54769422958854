import React, {Component} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import '../../App.css';
import youngGirlWithFringe from '../../resources/images/young-girl-with-fringe.svg';
import greenTick from '../../resources/images/green-tick.svg';


export default class HostYouHaveNoBookings extends Component {

    render() {

        return (

            <Row>
                <Col md={3} className="get-paid-image-column"><Image src={youngGirlWithFringe} alt="young girl with fringe" className="img-responsive center-block"/></Col>
                <Col md={9} className="">
                    <h4>It looks like you haven't had any bookings yet!</h4>
                    <p>Don't worry, your next booking should be coming soon. Remember that motorhome travel can be quite seasonal. </p>
                    <h4>How can I get more bookings?</h4>
                    <p> The best thing you can do is improve your listing. Take great (not photoshopped!) photos of your property - some times of the day will have nicer light, and
                        a different angle can make all the difference.</p>
                    <br/>
                    <h4>I feel like I should have had a booking by now</h4>
                    <p><Image src={greenTick} alt="greentick" className="green-tick"/> Check your Property listing and make sure you have completed all
                        the required fields. </p>
                    <p><Image src={greenTick} alt="greentick" className="green-tick"/> Check you Property listing and make sure the status is "Listed"</p>
                    <p><Image src={greenTick} alt="greentick" className="green-tick"/> If you need any help or more information, contact us at support@campable.com</p>
                </Col>
            </Row>


        );
    }

}