import React, {Component} from 'react';
import {Image} from 'react-bootstrap';
import PriceFormatter from '../PriceFormatter';

export default class Marker extends Component {



    constructor(props) {
        super(props);
        this.state = {
            label: false
        };

        this.onMarkerEnter = this.onMarkerEnter.bind(this);
        this.onMarkerLeave = this.onMarkerLeave.bind(this);

    }

    onMarkerEnter() {
        this.setState({
            label: true
        });

        if (this.props.onMarkerEnter) {
            this.props.onMarkerEnter(this.props.location.id);
        }
    }

    onMarkerLeave() {
        this.setState({
            label: false
        });

        if (this.props.onMarkerLeave) {
            this.props.onMarkerLeave();
        }
    }

    render() {

        const styles = {
            width: '20px',
            height: '20px',
            position: 'absolute',
            top: -10,
            left: -10,
        };

        return (
            <div className="map-marker">
                {this.state.label && this.props.location && this.props.location.price ?
                    <div className="map-marker-label" onClick={() => {this.props.onMarkerPress(this.props.location.id)}}>
                        <div className="location-tile-image map-marker-label-image">
                            <Image src={this.props.location.image}/>
                            <div className="location-tile-price"><span className="price-dollar-symbol">$</span>{PriceFormatter(this.props.location.price)}</div>
                        </div>
                        <div className="map-marker-label-text">
                            {this.props.location.name}
                        </div>

                    </div> : null }

                <div className='location-marker' onMouseEnter={this.onMarkerEnter} onMouseLeave={this.onMarkerLeave} onClick={() => {this.props.onMarkerPress(this.props.location.id)}}>
                    <div style={styles}>
                        <svg id="Group_1607" data="Group 1607" xmlns="http://www.w3.org/2000/svg" viewBox="1021 1810 15 17">
                            <g id="Ellipse_314" data="Ellipse 314" className="cls-2" transform="translate(1021 1810)">
                                <circle className="cls-3" cx="7.5" cy="7.5" r="7.5"/>
                                <circle className="cls-4" cx="7.5" cy="7.5" r="6.5"/>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        );
    }
}
