import React, {Component} from 'react';
import Autocomplete from 'react-google-autocomplete';
import {ControlLabel, FormGroup, HelpBlock} from 'react-bootstrap';

export default class AddressInput extends Component {

    constructor(props) {
        super(props);
        this.onPlaceSelected = this.onPlaceSelected.bind(this);
    }

    onPlaceSelected(suggestion) {

        let country = "nz";
        let postcode = "1010";
        let city = "Auckland";

        if (suggestion.address_components) {
            suggestion.address_components.map((component) => {
                component.types.map((type) => {
                    if (type === "postal_code") {
                        postcode = component.long_name;
                    } else if (type === "sub_locality") {
                        city = component.long_name;
                    } else if (type === "country") {
                        country = component.short_name.toLowerCase();
                    }

                    return null;
                });
                return null;
            });

            let value = {
                "latitude" : suggestion.geometry.location.lat(),
                "longitude" : suggestion.geometry.location.lng(),
                "address" : suggestion.formatted_address,
                "postcode" : postcode,
                "country" : country,
                "city" : city
            };

            this.props.input.onChange(value);
        }
    }

    render() {


        let validationState = null;
        let helpBlock;

        if (this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let input = this.props.input;
        input.value = input.value.address;
        return (
            <FormGroup readOnly className="location-address" validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <Autocomplete
                    className="form-control"
                    {...input}
                    onPlaceSelected={(place) => {
                        this.onPlaceSelected(place);
                    }}
                    types={['address']}
                    componentRestrictions={{country: "nz"}}
                />
                {helpBlock}
            </FormGroup>

        )

    }

}

