/**
 * Created by chris on 1/05/17.
 */

import React, {Component} from "react";
import Card from "../Card";
import {Button, Col, Row} from "react-bootstrap";

export default class InitialOnboarding extends Component {

    shouldComponentUpdate() {
        return false;
    }


    render() {
        return (
            <div style={{textAlign: 'center'}}>
                <h4>What would you like to do first?</h4>
                <Row className="onboardingHostCampContainer">
                    <Col xs={12} sm={6}>
                        <Button className="button"
                                onClick={this.props.onHostPress}>I want to Host</Button>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Button className="button"
                                onClick={this.props.onGuestPress}>I want to Camp</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}


