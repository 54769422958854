import React, {Component} from 'react';
import {Checkbox, ControlLabel, FormGroup, HelpBlock} from 'react-bootstrap';

export default class CampableCheckBoxGroup extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }


    onChange(input) {

        let checked = input.target.checked;
        let option = input.target.value;
        let optionsArray = this.props.input.value || [];

        if (checked) {
            let newArray = optionsArray.slice();
            newArray.push(option);
            optionsArray = newArray;
        } else {
            let tempArray = [];
            optionsArray.map(optionItem => {
                if (optionItem !== option) {
                    tempArray.push(optionItem);
                }
                return null;
            });

            optionsArray = tempArray;

        }

        this.props.input.onChange(optionsArray);
    }

    render() {
        const checkOptions = this.props.checkOptions;


        let validationState = null;
        let helpBlock;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }


        let generatedOptions;
        if (checkOptions) {
            generatedOptions = checkOptions.map((option) => {
                let isChecked = this.props.input.value.indexOf(option.id) >= 0;

                return (
                    <Checkbox type="checkbox"
                              key={option.id}
                              checked={isChecked}
                              onChange={this.onChange}
                              value={option.id}>
                        {option.label}
                    </Checkbox>
                );
            });
        }

        return (
            <div>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <div className="checkbox-group">
                    <FormGroup validationState={validationState}>
                        {generatedOptions}
                        {helpBlock}
                    </FormGroup>
                </div>
            </div>
        );
    }
}

