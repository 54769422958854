/**
 * Created by Chris on 27/03/17.
 */

import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import moment from 'moment';
import values from 'lodash/values';
import mapValues from 'lodash/mapValues';

export default class LocationBookingCalendarMonthTile extends Component {

    constructor(props) {
        super(props);

        this.renderDayAvailableTile = this.renderDayAvailableTile.bind(this);
        this.renderDayGoneTile = this.renderDayGoneTile.bind(this);
        this.renderDayBookedTile = this.renderDayBookedTile.bind(this);
        this.renderDayUnavailableDayTile = this.renderDayUnavailableDayTile.bind(this);
        this.renderDayAvailableTile = this.renderDayAvailableTile.bind(this);
        this.renderNoDayTile = this.renderNoDayTile.bind(this);
        this.checkSiteAvail = this.checkSiteAvail.bind(this);
        this.filterItem = this.filterItem.bind(this);

    }

    renderDayAvailableTile(day, siteAvail) {
        return (
            <div key={day} className="day-container available">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>${siteAvail.price / 100}/night</h5>
                    <Button className="button" onClick={() => {
                        this.props.onBookingTilePress(this.props.site, day, siteAvail.price)
                    }}>Select</Button>
                </div>
            </div>);
    }

    renderDayGoneTile(day) {
        return (
            <div key={day} className="day-container gone">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>.</h5>
                    <Button disabled className="button">Unavailable</Button>
                </div>
            </div>);
    }

    renderDayBookedTile(day, siteAvail) {
        return (
            <div key={day} className="day-container booked">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>${siteAvail.price / 100}/night</h5>
                    <Button className="button" disabled>Booked</Button>
                </div>
            </div>);
    }

    renderBookingDayTile(day, siteAvail) {
        return (
            <div key={day} className="day-container booking">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>${siteAvail.price / 100}/night</h5>
                    <Button className="button" onClick={() => {
                        this.props.onBookingTilePress(this.props.site, day)
                    }}>Selected</Button>
                </div>
            </div>);
    }

    renderDayUnavailableDayTile(day) {
        return (
            <div key={day} className="day-container unavailable">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>.</h5>
                    <Button disabled className="button">Unavailable</Button>
                </div>
            </div>);
    }

    renderDayUnavailableDayBookedTile(day, siteAvail) {
        return (
            <div key={day} className="day-container unavailable-booked">
                <div className="box">
                    <p>{day.format('DD MMM')}</p>
                    <h5>${siteAvail.price / 100}/night</h5>
                    <Button disabled className="button">Day Booked</Button>
                </div>
            </div>);
    }

    renderNoDayTile(day) {
        return (<div key={day} className="no-day-container"/>);
    }

    checkSiteAvail(formattedDay, day, selectedBookings) {
        let siteAvail = this.props.siteAvailability[formattedDay];
        if (siteAvail) {
            if (siteAvail.available) {
                let selectedBooking = selectedBookings[formattedDay];
                if (selectedBooking) {
                    if (selectedBooking.site === this.props.site.id) {
                        return this.renderBookingDayTile(day, siteAvail);
                    } else {
                        return this.renderDayUnavailableDayTile(day, siteAvail);
                    }
                } else {
                    return this.renderDayAvailableTile(day, siteAvail);
                }
            } else {
                return this.renderDayUnavailableDayTile(day, siteAvail);
            }
        }
    }

    filterItem(day) {
        let selectedBookings = this.props.selectedBookings;
        let formattedDay = day.clone().format('YYYY-MM-DD');

        let siteAvail = this.props.siteAvailability[formattedDay];

        if (siteAvail) {
            let bookedSites = this.props.campableUserBookings[formattedDay];

            if (bookedSites) {//Checks if any booking exist on the same day
                let bookedSiteBooking = mapValues(bookedSites, (bookingsObj) => {
                    return values(bookingsObj).filter((bookingObj) => {
                        return bookingObj.status !== 'cancelled';
                    });
                });

                if (bookedSiteBooking) {
                    let bookingObjArray = values(bookedSiteBooking).filter((bookingObjArray) => {
                        return bookingObjArray.length !== 0;
                    });
                    if (bookingObjArray && bookingObjArray.length !== 0) {
                        if (values(bookingObjArray)[0].length !== 0) {
                            if (bookedSiteBooking[this.props.location.id] && bookedSiteBooking[this.props.location.id].length >= 1) {
                                if (bookedSiteBooking[this.props.location.id] && bookedSiteBooking[this.props.location.id][0] && bookedSiteBooking[this.props.location.id][0].site === this.props.site.id) {
                                    return this.renderDayBookedTile(day, siteAvail)
                                } else {
                                    return this.renderDayUnavailableDayBookedTile(day, siteAvail);
                                }
                            } else {
                                return this.renderDayUnavailableDayBookedTile(day, siteAvail);
                            }
                        }
                    }
                }
            }

            return this.checkSiteAvail(formattedDay, day, selectedBookings);
        } else {
            return this.renderDayUnavailableDayTile(day);
        }
    }

    render() {

        let selectedDay = this.props.selectedDay.clone();
        let selectedDayOfWeek = selectedDay.day();

        let dayTiles = [];
        let daysBefore = selectedDay.clone().subtract(selectedDayOfWeek, 'days');
        let today = moment();

        for (let i = 0; i < selectedDayOfWeek; i++) {
            let day = daysBefore.clone();
            if (day.isBefore(today.clone().subtract(1, 'days'))) {
                dayTiles.push(this.renderDayGoneTile(day));
            } else {
                dayTiles.push(this.filterItem(day));
            }
            daysBefore.add(1, 'days')
        }


        for (let i = 0; i < (28 - selectedDayOfWeek); i++) {
            let day = this.props.selectedDay.clone().add(i, 'days');
            dayTiles.push(this.filterItem(day));
        }

        return (
            <div className="day-tiles">
                {dayTiles}
            </div>
        );
    }
}