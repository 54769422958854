import React, {Component} from 'react';

export default class ImageBlock extends Component {

    render() {

        if (!this.props.block.imageField) {
            return (
                null
            );
        }

        let imageName = this.props.block.imageField.title || "";
        let url = this.props.block.imageField.url || null;
        let imageClass = this.props.block.class || null;

        return (
            <div className="image-block">
                <img src={url} alt={imageName} className={imageClass}/>
            </div>
        );
    }

}