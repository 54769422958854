import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import {getCollectionPage} from '../actions';
import Load from '../components/Load';
import CollectionPage from '../components/content/CollectionPage';
import {showLoginOverlay} from "../actions/overlay";

class CollectionContainer extends Component {

    constructor(props) {
        super(props);

        this.showLoginOverlay = this.showLoginOverlay.bind(this);

        this.state = {
            noPageFound: false,
        }
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp))
    }

    componentWillMount() {
        let splat = this.props.params.splat;
        if (splat === undefined) {
            splat = "all-collections";
        }

        this.props.dispatch(getCollectionPage(splat));
        let code = this.props.location.query.affiliate;
        if (code !== undefined) {
            localStorage.setItem('affiliateReferralCode', code);
        }
    }


    componentWillReceiveProps(props) {

        let splat = props.params.splat;
        if (splat === undefined) {
            splat = "all-collections";
        }

        if (props.params.splat !== this.props.params.splat) {
            this.setState({
                noPageFound: false,
            });
            props.dispatch(getCollectionPage(splat));
        } else {
            if (!props.collectionPages[splat]) {
                this.setState({
                    noPageFound: true,
                });
            }
        }

    }

    render() {

        let splat = this.props.params.splat;
        if (splat === undefined) {
            splat = "all-collections";
        }

        if (this.props.collectionPages) {
            if (this.props.collectionPages[splat]) {
                return (
                    <CollectionPage page={this.props.collectionPages[splat]} splat={splat} showLoginOverlay={this.showLoginOverlay} isLoggedIn={!!this.props.user}/>
                );
            } else if (this.state.noPageFound) {
                return (
                    <div>
                        <h1>
                            Page not found
                        </h1>
                        <h2>
                            Oops. Looks like you have gone off track.
                        </h2>
                    </div>
                )
            }
        }

        return (
            <div className="loaderContainer">
                <Load/>
            </div>
        );
    }
}


export default CollectionContainer = connect(state => {
    return {
        collectionPages: state.content.collectionPages,
        user: state.data.campableUser,
    }
})(CollectionContainer)
