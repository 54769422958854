import React, { Component } from "react";
import { FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default class ValidatedPhoneNumberInput extends Component {

    render() {
        const { meta, label, required, className, input } = this.props;

        let validationState = null;
        let helpBlock;

        if (meta && meta.touched && meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{meta.error}</HelpBlock>;
        }

        return (
            <FormGroup readOnly validationState={validationState} className={className}>
                <ControlLabel>{label}{required ? <span className="required-star">*</span> : null}</ControlLabel>
                <PhoneInput
                    country={'nz'} // You can change the default country if needed
                    value={input.value || ''}
                    onChange={input.onChange}
                />
                {helpBlock}
            </FormGroup>
        );
    }
}
