import React, {Component} from 'react';
import {FieldArray} from 'redux-form'
import CampableSites from './LocationCampsites';


export default class LocationCampsiteController extends Component {

    render() {
        return (
            <FieldArray name="propertyInformation.sites"
                        component={CampableSites}
                        removeSitePressed={this.props.removeSitePressed}
                        tags={this.props.tags}
                        location={this.props.location}
                        admin={this.props.admin}/>
        );
    }
}


