import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {Button} from 'react-bootstrap';
import '../../App.css';
import {updatePropertyStatus} from '../../actions/';
import LocationPanel from './LocationPanel';
import YouHaveNoProperties from './YouHaveNoProperties';
import {BECOME_A_HOST, HOSTING_CREATE_A_PROPERTY} from "../../constants/Routes";
import {fireTagEvent} from "../../tagmanager";


export default class LocationsController extends Component {
    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCreateProperty = this.handleCreateProperty.bind(this);
    }

    handleSelectChange(propertyId, e) {
        if (e === "On") {
            this.props.dispatch(updatePropertyStatus(propertyId, "active"));
        } else if (e === "Off") {
            this.props.dispatch(updatePropertyStatus(propertyId, "inactive"));
        }
    }

    handleCreateProperty() {
        fireTagEvent("create-a-property", this.props.campableUser);
        if (this.props.campableUser && this.props.campableUser.accountInformation && this.props.campableUser.accountInformation.valid) {
            browserHistory.push(HOSTING_CREATE_A_PROPERTY);
        } else {
            browserHistory.push(BECOME_A_HOST);
        }
    }

    render() {

        const locations = this.props.locations;
        let panel = null;

        if (locations && locations.length > 0) {
            panel = (locations || []).map((location) => {
                return <LocationPanel key={location.id} location={location}
                                      campableUser={this.props.campableUser}
                                      locationPressed={this.props.locationPressed}
                                      handleSelectChange={this.handleSelectChange}/>
            })

        } else {
            panel =
                <div className="you-have-no-properties">
                    <YouHaveNoProperties />
                    <div className="mint-bottom-box">
                        <Button onClick={this.handleCreateProperty}>Create Property</Button>
                    </div>
                </div>
            }


        return (
            <div className='location-panels'>
                {panel}
            </div>
        );
    }
}