import React, {Component} from 'react';
import {Alert, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

export default class EmailInput extends Component {


    constructor(props) {
        super(props);

        this.inputChange = this.inputChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    inputChange(e) {
        this.props.onChange(e.target.value);
    }

    onKeyPress(event) {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }

        if (event.key === 'Enter') {
            this.onEnter();
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    focus() {
        this.inputRef.focus()
    }

    onEnter() {
        if (this.props.onEnter) {
            this.props.onEnter();
        }
    }

    render() {

        const alertInstance = (
            <Alert bsStyle="danger">This is a required field</Alert>
        );

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;
        delete inputProps.onEnter;


        return (
            <FormGroup>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl type="email"
                             {...this.props.input}
                             {...inputProps}
                             inputRef={(ref) => {
                                 this.inputRef = ref;
                             }}
                             onKeyPress={this.onKeyPress}
                             onChange={this.inputChange}/>
                {this.props.required ?
                    <span>
		            	{ this.state.fieldValidated ? null :
                            <span className="form-alert-help">{alertInstance}</span> }
	            	</span>
                    : null}
            </FormGroup>
        );
    }
}
