const supportedLanguages = [
    {code:'en',name:'English',default:true},
    {code:'fr',name:'Français'},
    {code:'es',name:'Español'},
    {code:'de',name:'Deutsch'},
    {code:'zh',name:'简体中文'}
];

export default (state = {
    supported: supportedLanguages,
    selected: supportedLanguages.find(l => l.default)
}, action) => {

    switch (action.type) {

        case 'SET_LANGUAGE':
            return {
                ...state,
                selected: action.language
            }
        default:
            return state
    }
}
