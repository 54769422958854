import React, {Component} from 'react';

export default class TitleBlock extends Component {

    render() {
        let title = this.props.content.title;
        let tagline = this.props.content.tagline;

        return (
            <div className="title-block">
                { title ? <h1>{title}</h1> : null }
                { tagline ? <p>{tagline}</p> : null }
            </div>
        );
    }

}