import React, {Component} from 'react';
import moment from 'moment';
import {DateRangePicker} from 'react-dates';
import {ControlLabel, FormGroup, HelpBlock} from 'react-bootstrap';
import 'react-dates/lib/css/_datepicker.css';
import 'airbnb-browser-shims';

export default class CustomDateRangePicker extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocusChanged = this.onFocusChanged.bind(this);

        this.state = {
            focusedInput: null,
            from: props.from,
            to: props.to,
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            from: props.from,
            to: props.to,
        });
    }

    onChange(event) {
        let from = moment().format('YYYY-MM-DD');

        if (event.startDate) {
            from = event.startDate.clone().format('YYYY-MM-DD');
        }

        let to = moment().format('YYYY-MM-DD');
        if (event.endDate) {
            to = event.endDate.clone().format('YYYY-MM-DD');
        }


        if (from !== this.state.from) {
            to = null;
        }


        this.props.input.onChange({
            from: from,
            to: to,
        });
    }

    onFocusChanged(focusedInput) {
        this.setState({
            focusedInput: focusedInput,
        });
    }

    render() {

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;
        delete inputProps.from;
        delete inputProps.to;

        let fromMoment = moment(this.state.from);
        let toMoment = moment(this.state.to);

        if (this.props.to) {
            inputProps.endDate = toMoment;
        }

        let validationState = null;
        let helpBlock;

        if (this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }


        return (
            <FormGroup validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <DateRangePicker
                    focusedInput={this.state.focusedInput}
                    onDatesChange={this.onChange}
                    onFocusChange={this.onFocusChanged}
                    displayFormat="DD/MM/YYYY"
                    minimumNights={0}
                    startDate={fromMoment}
                    hideKeyboardShortcutsPanel
                    {...inputProps}
                />
                {helpBlock}
            </FormGroup>)
    }
}

