import './content.css';
import {browserHistory} from "react-router";
import React, {Component} from "react";
import ContentRow from "./ContentRow";
import {Col, Row} from "react-bootstrap";
import CallToActionBanner from './CallToActionBanner';

export default class CollectionPage extends Component {
    constructor(props) {
        super(props);
        this.onButtonPress = this.onButtonPress.bind(this);
    }

    onButtonPress(route) {
        browserHistory.push(route);
    }

    render() {

        let rows = [];
        let title = this.props.page.title || null;
        this.props.page.rows.map((row, i) => {
            let rowComponent = <ContentRow key={i} row={row} showLoginOverlay={this.props.showLoginOverlay}
                                           isLoggedIn={this.props.isLoggedIn}/>
            rows.push(rowComponent);
            return null;
        });


        let allCollections = (
            <div className="container all-collections">
                <h2>{title}</h2>
                <div className="breadcrumbs">
                    <a onClick={() => {
                        this.onButtonPress('/')
                    }}>Home</a> > {title}
                </div>

                    {rows}

            </div>
        );

        let collectionPage = (
            <div>
                <div className="collections-banner"
                     style={{backgroundImage: `url(${this.props.page.banner.url})`}}></div>

                <div className="collections-page">
                    <Row className="collections-container">
                        <h2>{title}</h2>
                        <div className="breadcrumbs">
                            <a onClick={() => {
                                this.onButtonPress('/')
                            }}>Home</a> > <a onClick={() => {
                            this.onButtonPress('/collections')
                        }}>Our Adventures</a> > {title}
                        </div>
                        {rows}
                    </Row>
                </div>
            </div>
        );

        return (
            <div>
                {
                    this.props.page.slug === "all-collections" ?
                        <div>{allCollections}</div>
                        :
                        <div>{collectionPage}</div>
                }

                <Row id="ldp-download">
                    <Col>
                        <CallToActionBanner content={window.location}/>
                    </Col>
                </Row>
            </div>

        );
    }
}