import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {connect} from "react-redux";
import {getStripeAccountUpdateLink} from "../actions";
import Load from "../components/Load";

class AccountUpdateFailureContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!prevProps.user && this.props.user) {
            this.getUpdateLink();
        }
    }

    getUpdateLink = async () => {
        const link = await getStripeAccountUpdateLink();
        this.setState({
            updateLink: link
        })
    }

    render() {
        return this.state.updateLink ? (
            <div>
                <div style={{textAlign: 'center'}}>
                    <h4>{"Oops! It looks like something went wrong."}</h4>
                    <br/>
                    <a href={this.state.updateLink}>
                        <Button>Try again</Button>
                    </a>
                </div>
            </div>
        ) : <Load/>;
    }
}

export default AccountUpdateFailureContainer = connect((state) => {
    return {
        user: state.data.campableUser,
    }
})(AccountUpdateFailureContainer);
