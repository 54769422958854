/**
 * Created by Chris on 27/03/17.
 */

import React, {Component} from 'react';

export default class LocationBookingCalendarHeader extends Component {

    shouldComponentUpdate() {
        return false;
    }

    render() {
        return (
            <div className="location-booking-calendar-header-container">
                <h3>S</h3>
                <h3>M</h3>
                <h3>T</h3>
                <h3>W</h3>
                <h3>T</h3>
                <h3>F</h3>
                <h3>S</h3>
            </div>
        );
    }
}