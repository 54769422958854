import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isAdmin} from '../../auth';
import '../../App.css';
import {
    createProperty, getLocations, showAlert, uploadExistingImages,
    uploadMultipleLocations
} from '../../actions/index'
import {browserHistory} from 'react-router';
import {ADMIN_LOCATIONS_ID} from "../../constants/Routes";
import LocationPanel from "../locations/LocationPanel";
import {Col, Grid, Row} from "react-bootstrap";
import Load from "../Load";
import {createImage} from "../../api/api";



class AdminUploadProperties extends Component {


    constructor(props) {
        super(props);

        this.locationPressed = this.locationPressed.bind(this);
        this.loadFile = this.loadFile.bind(this);
        this.receivedText = this.receivedText.bind(this);
        this.performUploadImages = this.performUploadImages.bind(this);
        this.receivedImages = this.receivedImages.bind(this);
        this.performUploadCommercialLocations = this.performUploadCommercialLocations.bind(this);
        this.performUploadFreedomLocations = this.performUploadFreedomLocations.bind(this);
        this.loadImages = this.loadImages.bind(this);
        this.getTags = this.getTags.bind(this);
        this.processImage = this.processImage.bind(this);
        this.state = {uploadedJson: undefined, uploadedImageJson: undefined, freedomSitesDone: []};
    }

    componentDidUpdate(){
        if(!this.state.imagesUploaded &&this.props.uploadedImages.length > 0 && (this.props.uploadedImages.length+2 === this.state.uploadedImageJson.length)){
            this.setState({imagesUploaded: true});
        }

        if(!this.state.locationsUploaded && this.props.uploadedLocations.length > 0 && (this.props.uploadedLocations.length === this.state.uploadedJson.length)){
            this.setState({locationsUploaded: true});
        }
    }

    locationPressed(id) {
        //this.props.dispatch(fetchProperty(id));
        browserHistory.push(ADMIN_LOCATIONS_ID + id + '/details');
    }

    loadFile() {
        var input, file, fr;

        if (typeof window.FileReader !== 'function') {
            alert("The file API isn't supported on this browser yet.");
            return;
        }

        input = document.getElementById('fileinput');
        if (!input) {
            alert("Um, couldn't find the fileinput element.");
        }
        else if (!input.files) {
            alert("This browser doesn't seem to support the `files` property of file inputs.");
        }
        else if (!input.files[0]) {
            alert("Please select a file before clicking 'Load'");
        }
        else {
            file = input.files[0];
            fr = new FileReader();
            fr.onload = this.receivedText;
            fr.readAsText(file);
        }

    }

    receivedText(e) {
        var lines = e.target.result;
        var newArr = JSON.parse(lines);
        this.setState({uploadedJson: newArr});

        if(newArr[0].weblink || newArr[0].photoone){
            this.setState({doingFreedom: true});
        }
    }

    loadImages() {
        var input, file, fr;

        if (typeof window.FileReader !== 'function') {
            alert("The file API isn't supported on this browser yet.");
            return;
        }

        input = document.getElementById('imageinput');
        if (!input) {
            alert("Um, couldn't find the imageinput element.");
        }
        else if (!input.files) {
            alert("This browser doesn't seem to support the `files` property of file inputs.");
        }
        else if (!input.files[0]) {
            alert("Please select a file before clicking 'Load'");
        }
        else {
            file = input.files[0];
            fr = new FileReader();
            fr.onload = this.receivedImages;
            fr.readAsText(file);
        }

    }

    receivedImages(e) {
        var lines = e.target.result;
        var newArr = JSON.parse(lines);
        this.setState({uploadedImageJson: newArr});
    }

    performUploadImages(){
        this.setState({uploadingImages: true});

        const imageNames = [];

        for(var v in this.state.uploadedImageJson){
            imageNames.push(this.state.uploadedImageJson[v].filename);

        }

        this.props.dispatch(uploadExistingImages(imageNames.slice(0,100)));
        this.props.dispatch(uploadExistingImages(imageNames.slice(100,200)));
        this.props.dispatch(uploadExistingImages(imageNames.slice(200,300)));
        this.props.dispatch(uploadExistingImages(imageNames.slice(300,400)));
        this.props.dispatch(uploadExistingImages(imageNames.slice(400,500)));
        this.props.dispatch(uploadExistingImages(imageNames.slice(500, imageNames.length)));
    }

    performUploadCommercialLocations(){
        this.setState({uploadingLocations: true});

        const newLocations = [];
        this.state.uploadedJson.map((jsonLocation) => {

            if(jsonLocation.deleted_at !== null && jsonLocation.deleted_at !== undefined){
                return;
            }

            const tags = ["site_feature:water", "site_feature:shower", "site_feature:toilet", "site_feature:power"];
            let data = {
                type: "commercial_campsite",
                name: jsonLocation.name,
                address: jsonLocation.streetaddress,
                latitude: jsonLocation.latitude,
                longitude: jsonLocation.longitude,
                description: jsonLocation.description + " Phone: " + jsonLocation.phone,
                website: jsonLocation.url,
                foreignKey: jsonLocation.id,
                tags: tags
            };

            const imageFileNames = [];
            this.state.uploadedImageJson.map((image) => {
                if(image.campsiteid === jsonLocation.id){
                    imageFileNames.push(image.filename);
                }
            });

            const imageUrls = [];
            imageFileNames.map((fileName) => {
                this.props.uploadedImages.map((image) => {
                    if (image.reference === fileName) {
                        delete image.audit;
                        imageUrls.push(image);
                    }
                });
            });


            if(imageUrls.length > 0) {
                data.images = imageUrls;
            }


            data.status = 'active';
            newLocations.push(data);
        });

        this.props.dispatch(uploadMultipleLocations(newLocations));
        return newLocations;
    }

    performUploadFreedomLocations(){
        this.setState({uploadingFreedomLocations: true});

        const newLocations = [];
        this.state.uploadedJson.map((jsonLocation) => {

            if(jsonLocation.deleted_at !== null && jsonLocation.deleted_at !== undefined){
                return;
            }

            const tags = this.getTags(jsonLocation);

            let data = {
                type: "freedom_campsite",
                name: jsonLocation.name,
                address: jsonLocation.address || jsonLocation.name,
                latitude: jsonLocation.lat,
                longitude: jsonLocation.long,
                description: jsonLocation.description,
                website: jsonLocation.weblink,
                tags: tags,
                status: 'active',
                images: []
            };

          /*  if(jsonLocation.photoone) {
                this.processImage(jsonLocation.photoone, (image) => {
                    data.images.push(image);
                    if (jsonLocation.phototwo) {
                        this.processImage(jsonLocation.phototwo, (image) => {
                            data.images.push(image);
                            createProperty(data);
                            this.state.freedomSitesDone.push(data);
                            this.setState({freedomSitesDone: this.state.freedomSitesDone});
                        });
                    }
                    else {
                        createProperty(data);
                        this.state.freedomSitesDone.push(data);
                        this.setState({freedomSitesDone: this.state.freedomSitesDone.length});
                    }
                });
            }
            else{
                //createProperty(data);
                this.state.freedomSitesDone.push(data);
                this.setState({freedomSitesDone: this.state.freedomSitesDone.length});
            }*/


            newLocations.push(data);

        });

        this.props.dispatch(uploadMultipleLocations(newLocations));
        return newLocations;
    }

    processImage(image, doneCallback){
        var x = new XMLHttpRequest();
        x.open('GET', image);
        x.responseType = 'blob';
        x.onload = function() {
            var blob = x.response;

            const imageFile = new File(blob, "image.png")

            createImage([imageFile]).then((image) => {
                this.props.dispatch(showAlert("success", "Image 1 uploaded"));
                doneCallback(image);
            });


        };
        x.onerror = function() {
            alert('A network error occurred!');
        };
        x.send();
    }

    getTags(json){
        const tags = [];
        if(json.fire){
            tags.push("site_feature:fire");
        }
        if(json.laundry){
            tags.push("site_feature:laundry");
        }
        if(json.limited_cell){
            tags.push("site_feature:limited_cell");
        }
        if(json.non_self_contained){
            tags.push("site_feature:non_self_contained");
        }
        if(json.shower){
            tags.push("site_feature:shower");
        }
        if(json.toilet){
            tags.push("site_feature:toilet");
        }
        if(json.water){
            tags.push("site_feature:water");
        }
        if(json.wifi){
            tags.push("site_feature:wifi");
        }
        if(json.bins){
            tags.push("site_feature:bins");
        }
        if(json.power){
            tags.push("site_feature:power");
        }
        if(json.dogs){
            tags.push("site_feature:pets");
        }

        return tags;
    }

    render(){

        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let user = this.props.user;

        let panel = (this.props.uploadedLocations || []).map((location) => {
            return <LocationPanel key={location.id} location={location}
                                  campableUser={this.props.user}
                                  locationPressed={this.locationPressed}
                                  handleSelectChange={this.handleSelectChange}/>
        });

        if (isAdmin(user)) {
            return (
                    <Grid className="main-container">
                        <Row>
                            {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                            <Col xs={4} className="row-centre-vert">
                                <input type='file' id='imageinput'/>
                            </Col>
                            <Col xs={4} className="row-centre-vert">
                                <input type='button' id='btnLoadImages' value='Load Image JSON'
                                       onClick={this.loadImages}/>
                            </Col>
                            <Col xs={4} className="row-centre-vert">
                                <input type='button' id='btnLoadImages' value='Skip Image JSON'
                                       onClick={() => {this.setState({uploadedImageJson: true})}}/>
                            </Col>
                        </Row>
                        {this.state.uploadedImageJson !== undefined ? <Row>
                            {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                            <Col xs={4} className="row-centre-vert">
                                <input type='file' id='fileinput'/>
                            </Col>
                            <Col xs={4} className="row-centre-vert">
                                <input type='button' id='btnLoad' value='Load Properties JSON' onClick={this.loadFile}/>
                            </Col>
                        </Row> : null}

                        {this.state.uploadedJson ?
                            <Row>
                                {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                                <Col xs={4} className="row-centre-vert">
                                    <p>{"Ready to Upload " + this.state.uploadedImageJson.length + " images"}</p>
                                </Col>
                                <Col xs={4} className="row-centre-vert">
                                    <input type='button' id='btnLoad' value='Do Image Upload'
                                           onClick={this.performUploadImages}/>
                                </Col>
                                <Col xs={4} className="row-centre-vert">
                                    <input type='button' id='btnLoad' value='Skip Images'
                                           onClick={() => {this.setState({imagesUploaded: true});}}/>
                                </Col>
                            </Row>
                            : null}
                        {this.state.uploadingImages ?
                            <Row>
                                <Col xs={4} className="row-centre-vert">
                                    <p>{"Uploaded " + this.props.uploadedImages.length + " images of " + this.state.uploadedImageJson.length}</p>
                                </Col>

                            </Row> : null
                        }
                        {this.state.imagesUploaded === true ?
                            <Row>
                                <Col xs={4} className="row-centre-vert">
                                    <p>{"Ready to upload " + this.state.uploadedJson.length + " locations"}</p>
                                </Col>
                                <input type='button' id='btnLoad' value='Upload Commercial Locations'
                                       onClick={this.performUploadCommercialLocations}/>
                                <input type='button' id='btnLoad' value='Upload Freedom Locations'
                                       onClick={this.performUploadFreedomLocations}/>
                            </Row> : null
                        }

                        {this.state.uploadingLocations ?
                            <Row>
                                <Col xs={4} className="row-centre-vert">
                                    <p>{"Uploaded " + this.props.uploadedLocations.length + " images of " + this.state.uploadedJson.length}</p>
                                </Col>

                            </Row> : null
                        }
                        {this.state.locationsUploaded === true ?
                            <Row>
                                <p>DONE!</p>
                            </Row> : null
                        }
                        {this.state.freedomSitesDone > 0 ?
                            <Row>
                                <p>{this.state.freedomSitesDone + " freedom sites done"}</p>
                            </Row> : null
                        }
                    </Grid>
            );
        }

        return (
            <div className="loaderContainer">
                <h1> Sorry you are not an admin. </h1>
            </div>
        )
    }
}

export default AdminUploadProperties = connect((state) => {
    return {
        user: state.data.campableUser,
        uploadedLocations: state.data.uploadedLocations || [],
        uploadedImages: state.data.uploadedImages || [],
    }
})(AdminUploadProperties);
