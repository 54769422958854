/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 23/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


export const MAP = `__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

// export const SKELETON = `__SECRET_SKELETON_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const MARKER = `__SECRET_MARKER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const RECTANGLE = `__SECRET_RECTANGLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const POLYLINE = `__SECRET_POLYLINE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const POLYGON = `__SECRET_POLYGON_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const CIRCLE = `__SECRET_CIRCLE_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const KML_LAYER = `__SECRET_KML_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const DIRECTIONS_RENDERER = `__SECRET_DIRECTIONS_RENDERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const HEATMAP_LAYER = `__SECRET_HEATMAP_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const FUSION_TABLES_LAYER = `__SECRET_FUSION_TABLES_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const ANCHOR = `__SECRET_ANCHOR_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const INFO_WINDOW = `__SECRET_INFO_WINDOW_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const OVERLAY_VIEW = `__SECRET_OVERLAY_VIEW_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const DRAWING_MANAGER = `__SECRET_DRAWING_MANAGER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const SEARCH_BOX = `__SECRET_SEARCH_BOX_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const MARKER_CLUSTERER = `__SECRET_MARKER_CLUSTERER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const INFO_BOX = `__SECRET_INFO_BOX_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;

export const TRAFFIC_LAYER = `__SECRET_TRAFFIC_LAYER_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`;