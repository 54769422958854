import React, {Component} from "react";
import {connect} from "react-redux";
import "../App.css";
import {reduxForm} from "redux-form";
import {fireTagEvent} from "../tagmanager";
import AccountDetailsController from "../components/onboarding/AccountDetailsController";
import Load from "../components/Load";
import InitialOnboarding from "../components/onboarding/InitialOnboarding";
import AccountImageController from "../components/onboarding/AccountImageController";
import FinalOnboarding from "../components/onboarding/FinalOnboarding";
import AccountPaymentDetails from "../components/onboarding/AccountPaymentDetails";
import HostOnboardingContainer from "./HostOnboardingContainer";

class OnboardingContainer extends Component {

    constructor(props) {
        super(props);


        this.onHostPress = this.onHostPress.bind(this);
        this.onGuestPress = this.onGuestPress.bind(this);
        this.onBackPress = this.onBackPress.bind(this);
        this.onNextStepPress = this.onNextStepPress.bind(this);

        this.state = {
            step: 1,
            isHost: false,
            isGuest: false,
            loading: false,
        }
    }

    onHostPress() {
        fireTagEvent("onboarding-usecase", this.props.user, {"usecase": "host"});
        this.setState({
            isHost: true,
            isGuest: false,
            step: 2,
        });
    }

    onGuestPress() {
        fireTagEvent("onboarding-usecase", this.props.user, {"usecase": "guest"});
        this.setState({
            isHost: false,
            isGuest: true,
            step: 2,
        });
    }

    onBackPress() {
        let step = this.state.step;
        let state = {};
        if (step === 2) {
            state = {
                isHost: false,
                isGuest: false,
            }
        }

        step--;

        this.setState({
            step: step,
            ...state,
        });
    }

    onNextStepPress() {
        let step = this.state.step;
        step++;

        this.setState({
            step: step,
        });
    }

    render() {
        let user = this.props.user;

        if (!user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        if(this.state.isHost) {
            return <HostOnboardingContainer/>;
        }

        switch (this.state.step) {
            case 1:
                return <InitialOnboarding onHostPress={this.onHostPress}
                                          onGuestPress={this.onGuestPress}/>;
            case 2:
                return <AccountDetailsController onNextStepPress={this.onNextStepPress}
                                                 onBackPress={this.onBackPress}/>;
            case 3:
                return <AccountImageController onNextStepPress={this.onNextStepPress}
                                               onBackPress={this.onBackPress}/>;
            case 4:
                return <AccountPaymentDetails onNextStepPress={this.onNextStepPress}
                                                onBackPress={this.onBackPress}/>;
            case 5:
                return <FinalOnboarding onBackPress={this.onBackPress}/>;
            default:
                return null;
        }
    }
}

OnboardingContainer = reduxForm({
    form: 'initialOnboardingForm',
})(OnboardingContainer);

export default OnboardingContainer = connect(state => {
    return {
        user: state.data.campableUser,
        initialValues: state.data.campableUser
    }
})(OnboardingContainer);


 


