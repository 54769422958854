import React, {Component} from "react";
import {connect} from "react-redux";
import {browserHistory} from "react-router";
import {Nav, Navbar, NavItem} from "react-bootstrap";
import "../App.css";
import * as Routes from "../constants/Routes";

class NavBarSecondary extends Component {

    render() {
        const currentPage = this.props.state.routing.locationBeforeTransitions.pathname;
        return (
            <Navbar collapseOnSelect className="secondary-nav-bar">
                <Navbar.Header>
                    <Navbar.Toggle />
                </Navbar.Header>
                <Navbar.Collapse>
                    <Navbar.Text><strong>My Hosting</strong></Navbar.Text>
                    <Nav activeKey={currentPage} onSelect={this.handleSelect}>
                        <NavItem eventKey={Routes.HOSTING_LOCATIONS} onClick={() => browserHistory.push(Routes.HOSTING_LOCATIONS)}>My Properties</NavItem>
                        <NavItem eventKey={Routes.HOSTING_BOOKINGS} onClick={() => browserHistory.push(Routes.HOSTING_BOOKINGS)}>Guest Bookings</NavItem>
                        <NavItem eventKey={Routes.HOSTING_TRANSACTIONS} onClick={() => browserHistory.push(Routes.HOSTING_TRANSACTIONS)}>Transactions</NavItem>
                        <NavItem eventKey={Routes.HOSTING_CREATE_A_PROPERTY} onClick={() => browserHistory.push(Routes.HOSTING_CREATE_A_PROPERTY)}>Create Property</NavItem>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default NavBarSecondary = connect((state) => {
    return {
        state
    }
})(NavBarSecondary);
