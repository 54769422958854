import React, {Component} from 'react';
import Card from '../../Card';
import ValidatedSelectInput from '../../common/ValidatedSelectInput';

export default class DescriptionDropDown extends Component {

	render() {

		const descriptionOptions1 = ["a private location for only a few guests",
			"a social location with lots of visitors", "a local New Zealand business"];
		const descriptionOptions2 = [
			"it has amazing views and scenery",
			"we're friendly and social Hosts",
			"it's a great central location",
			"you'll get a real feel for kiwi life", "it's a romantic spot", "it's perfect for family adventures"];
		const descriptionOptions3 = ["a major New Zealand destination", "great places to eat and shop",
			"the water's edge", "beautiful open spaces", "native forest", "fun family activities"];
		const descriptionOptions4 = ["a self-contained motorhome or self-contained campervan.",
			"a motorhome or campervan, including those without toilet facilities.",
			"any self-contained vehicle, including motorhome, campervan, RV, caravan or converted bus.",
			"any motorhome, campervan, RV, caravan or converted bus, including those without toilet facilities."];

		return (
			
			<Card title="Tell us what is great about your place" onboardingCard={true} helpText={<p>Let us know what people love about your place! Campers will decide where to stay based on the details you provide.<br/><br/>Tip: If none of these options is quite right for you, please edit the information in the next page. You can also add more options for Guests if you would like to - for example, some people may want to just travel via car and pitch a tent, and some motorhome travellers may want to set up their awnings (which requires significantly more space). Let them know in your description if they can stay at your place!</p>} >
		        <ValidatedSelectInput label="My place is..."            selectOptions={descriptionOptions1} name="descriptionOne"/>
		        <ValidatedSelectInput label="You'll love staying here because..."  selectOptions={descriptionOptions2} name="descriptionTwo" />
		        <ValidatedSelectInput label="This site is close to..."         selectOptions={descriptionOptions3} name="descriptionThree" />
		        <ValidatedSelectInput label="You're welcome to stay here if you're travelling in..."    selectOptions={descriptionOptions4} name="descriptionFour" />
		</Card>

		);
	} 
}