import React, {Component} from 'react';
import {Col, Grid, OverlayTrigger, Popover, Row} from 'react-bootstrap';

export default class Card extends Component {

    render() {

        const helpText = this.props.helpText;
        const helpButton = this.props.helpButton;
        const popOverTitle = this.props.popOverTitle;
        const popOverBody = this.props.popOverBody;

        const popoverClickRootClose = (
            <Popover id="popover-trigger-click-root-close" title={popOverTitle}>
                {popOverBody}
            </Popover>
        );


        if (!this.props.onboardingCard) {
            return (
                <div className={this.props.className}>
                    <div className={'card'}>
                        {this.props.title ?
                            <h4>{this.props.title}<br/><br/></h4>
                            : null }
                        {this.props.children}
                    </div>
                </div>
            );
        }


        return (
            <div className={this.props.className}>
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            <Col md={3} className="onboarding-card">
                                {helpText ?
                                    <div className="card">
                                        <div className="help-text"><h4>Tips:</h4>
                                            <p>{helpText}</p>
                                            <OverlayTrigger trigger="click" rootClose placement="right" overlay={popoverClickRootClose}>
                                                <a>{helpButton}</a>
                                            </OverlayTrigger>
                                        </div>
                                    </div> : null }
                            </Col>
                            <Col md={9} id="hostCard">
                                <div className="card">
                                    {this.props.title ?
                                        <h4>{this.props.title}<br/><br/></h4>
                                        : null }
                                    {this.props.children}
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}