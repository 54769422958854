import React, {Component} from 'react';
import {Button, FormGroup} from 'react-bootstrap';
import EmailInput from './common/inputs/EmailInput';
import PasswordInput from './common/inputs/PasswordInput';
import {browserHistory} from "react-router";
import {PRIVACY_POLICY} from "../constants/Routes";
import {hideLoginOverlay} from "../actions/overlay";
import {connect} from "react-redux";

class SignUp extends Component {

    state = {
        privacyPolicyAccepted: false,
    };

    onTogglePrivacyAcceptance = (e) => {
        this.setState({
            privacyPolicyAccepted: !this.state.privacyPolicyAccepted
        })
    };

    onLinkPress = (route) => {
        this.props.dispatch(hideLoginOverlay());
        browserHistory.push(route)
    };

    render() {
        return (
            <div className="landing-sign-up">
                <EmailInput label="Email Address" placeholder="john@smith.com" onChange={this.props.handleEmailInput}
                            onEnter={() => {
                                this.passwordInput.focus()
                            }}/>
                <FormGroup validationState={this.props.passwordValidation}>
                    <PasswordInput ref={(ref) => {
                        this.passwordInput = ref
                    }}
                                   label="Password"
                                   placeholder="Password"
                                   onChange={this.props.handlePasswordInput}
                                   onEnter={() => {
                                       this.passwordVerifyInput.focus()
                                   }}/>
                    <PasswordInput ref={(ref) => {
                        this.passwordVerifyInput = ref
                    }}
                                   label="Password Again"
                                   placeholder="Password Again"
                                   onChange={this.props.handlePasswordVerify}
                                   onEnter={() => {
                                       this.state.privacyPolicyAccepted && this.props.handleSignUpPress()
                                   }}/>
                </FormGroup>
                {this.props.passwordMismatch ? "Passwords do not match" : null}

                <div className={"privacy-container"}>
                    <label className={"privacy-checkbox"}>I consent to the Campable <a
                        onClick={() => this.onLinkPress(PRIVACY_POLICY)}>privacy policy</a></label>
                    <input type={"checkbox"} value={this.state.privacyPolicyAccepted}
                           onChange={this.onTogglePrivacyAcceptance}/>
                </div>
                <div className="bottom-container">
                    <Button type="submit" className="handle-submit" onClick={this.props.handleSignUpPress}
                            disabled={!this.state.privacyPolicyAccepted}>Sign
                        Up</Button>
                    <a className="back-button" onClick={this.props.onBackPress}>Back</a>
                </div>
            </div>
        );
    }
}

export default SignUp = connect()(SignUp)
