import React, {Component} from "react";
import AccountImageCard from "./AccountImageCard";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Button, Col, Grid, Row} from "react-bootstrap";
import {adminFetchUser, adminUserReceived, sendVerificationEmail, updateCampableUser, updateCampableUserWithEmail} from "../../actions/index";
import {PhoneNumberUtil} from 'google-libphonenumber';
import {isAdmin} from "../../auth";
import Card from "../Card";
import ValidatedTextInput from "../common/ValidatedTextInput";
import PasswordInput from "../common/inputs/PasswordInput";
import ValidatedPhoneNumberInput from "../common/ValidatedPhoneNumberInput";
import {updateUserEmailAdmin} from "../../actions";
import ValidatedTelephoneNumberInput from "../common/ValidatedTelephoneNumberInput";

class AccountProfileController extends Component {


    constructor(props) {
        super(props);
        this.saveCampableUser = this.saveCampableUser.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onEmailVerificationClick = this.onEmailVerificationClick.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);

        this.state = {
            loading: false,
            emailChanged: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.dirty) {
            this.setState({emailChanged: false})
        }

        if (!!nextProps.currentUser && nextProps.user.referrer && !nextProps.users[nextProps.user.referrer] && process.env.REACT_APP_ENV === 'production') {
            this.props.dispatch(adminFetchUser(nextProps.user.referrer));
        }
    }

    saveCampableUser(values) {
        console.log("values", values)
        console.log("phone change",this.props.initialValues)
        console.log("phone change",values.phone)
        
        let user = {};
        user.id = this.props.user.id;

        if (this.props.initialValues.firstName !== values.firstName || this.props.initialValues.lastName !== values.lastName) {
            user.names = {
                firstName: values.firstName,
                lastName: values.lastName
            };
        }

        if (values.phone && this.props.initialValues.phone !== values.phone) {
            user.contact = {
                phone: values.phone,
            };
        }

        if (values.telephone && this.props.initialValues.telephone !== values.telephone) {
            user.contact = {
               

                    telephone: values.telephone,
            };
        }

        if (values.image) {
            user.image = {
                url: values.image
            }
        }

        if (values.referrer) {
            user.referrerEmail = values.referrer;
        }

        if (this.props.initialValues.email !== values.email) {
            console.log("user if", user)


            if (values.email) {
                user.email = values.email;
            }

            if (isAdmin(this.props.campableUser)) {
                this.props.dispatch(updateUserEmailAdmin(this.props.user.id, values.email));
            } else {
                this.props.dispatch(updateCampableUserWithEmail(user, this.state.password), this.onUpdateSuccess);
            }

        } else {
            console.log("user else", user)
            this.props.dispatch(updateCampableUser(user, this.onUpdateSuccess));
        }

    }

    onUpdateSuccess = (user) => {
        if (isAdmin(this.props.campableUser)) {
            this.props.dispatch(adminUserReceived(user));
        }
    };

    onEmailChange(e) {
        const changed = e.currentTarget.value !== this.props.initialValues.email;
        this.setState({emailChanged: changed});
    }

    onPasswordChange(pwd) {
        this.setState({password: pwd});
    }

    onEmailVerificationClick() {
        this.props.dispatch(sendVerificationEmail());
    }

    onEditReferrerClick = () => {
        this.props.onEditReferrerClick(this.props.user)
    };

    onRemoveReferrerClick = () => {
        this.props.onRemoveReferrerClick(this.props.user)
    };

    onEditPasswordClick = () => {
        this.props.onEditPasswordClick(this.props.user)
    };

    render() {
        let saveChanges = null;
        if (this.props.dirty && this.props.valid) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={3}/>
                        <Col md={7}>
                            <h4>You have made some changes</h4>
                            <p>Click Update to make your changes public</p>
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit">Update</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        const isAdminn = isAdmin(this.props.campableUser);

        const referrerId = this.props.user.referrer;

        let referrerText = <a onClick={this.onEditReferrerClick}>Add Affiliate</a>;

        if (!!referrerId) {
            (this.props.affiliates || []).map((affiliate) => {
                if (affiliate.id === referrerId) {
                    referrerText = <h5>{affiliate.email} <a onClick={this.onRemoveReferrerClick}>Remove Affiliate</a></h5>;
                }
            });
        }

        return (
            <div>
                <Row>
                    <Col md={3} className="profilePhoto">
                        <div>
                            <AccountImageCard/>
                        </div>
                    </Col>
                    <Col md={9}>
                        <form onSubmit={this.props.handleSubmit(this.saveCampableUser)}>
                            <div className="account-profile">
                                <Card title="My Details">
                                    <ValidatedTextInput onChange={this.onEmailChange}
                                                        label="Email"
                                                        placeholder="e.g. john@doe.com"
                                                        name="email"
                                                        required={true}/>
                                    {this.state.emailChanged && !isAdminn ? <PasswordInput hideAlert label="Password" required={true} onChange={this.onPasswordChange}/> : ''}
                                    <ValidatedTextInput label="First Name" placeholder="e.g. John" name="firstName" required={this.props.user.accountInformation.valid}/>
                                    <ValidatedTextInput label="Last Name" placeholder="e.g. Smith" name="lastName" required={this.props.user.accountInformation.valid}/>
                                    <ValidatedPhoneNumberInput label="Mobile Phone (for SMS notifications)" placeholder="e.g. +64271234567" name="phone" required={this.props.user.accountInformation.valid}/>
                                    {/* <ValidatedTelephoneNumberInput label="phone Number" placeholder="e.g. +64271234567" name="phone" required={this.props.user.accountInformation.valid} /> */}

                                    <ValidatedTelephoneNumberInput label="Telephone Number" placeholder="e.g. +64271234567" name="telephone" />

                                    {isAdminn ? <div>
                                        <label>Affiliate</label>
                                        <p className="form-disabled-field">{referrerText}</p>
                                    </div> : null}
                                    {isAdminn ?
                                        <div>
                                            <label>Password</label>
                                            <p className="form-disabled-field"><a onClick={this.onEditPasswordClick}>Set User Password</a></p>
                                        </div> : null}
                                    {this.props.user.emailVerified || isAdminn ? null : <a onClick={this.onEmailVerificationClick}>Resend Verification Email</a>}
                                </Card>
                            </div>
                            {saveChanges}
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}

AccountProfileController = reduxForm({
    form: 'accountDetails',
    validate: (values, props) => {

        let errors = {};

        if (props.user.accountInformation.valid) {

            if (!values.firstName || values.firstName === "") {
                errors.firstName = 'Required';
            }

            if (!values.lastName || values.lastName === "") {
                errors.lastName = 'Required';
            }

            if (!values.phone) {
                errors.phone = 'Required';
            } 
            // else {
            //     try {
            //         const phoneUtil = PhoneNumberUtil.getInstance();
            //         const valid = phoneUtil.isValidNumber(phoneUtil.parse(values.phone));
            //         if (!valid) {
            //             errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789'
            //         }
            //     }
            //     catch (e) {
            //         errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789';
            //     }
            // }
        } 
        else {

            //TODO When user i inputting their first name or last name make sure the other is also valid. So you have to have both to continue.

            // if (!!values.phone) {
            //     try {
            //         const phoneUtil = PhoneNumberUtil.getInstance();
            //         const valid = phoneUtil.isValidNumber(phoneUtil.parse(values.phone));
            //         if (!valid) {
            //             errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789'
            //         }
            //     }
            //     catch (e) {
            //         errors.phone = 'Invalid Phone Number, please enter with country code eg. +64 21 123456789';
            //     }
            // }
        }


        return errors;


    }
})(AccountProfileController);

export default AccountProfileController = connect((state, props) => {


    return {
        campableUser: state.data.campableUser,
        users: state.admindata.users,
    }
})(AccountProfileController);

