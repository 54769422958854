import React, {Component} from "react";
import {Field} from "redux-form";
import PhoneNumberInput from "./inputs/PhoneNumberInput";
import TelephoneNumberInput from "./inputs/TelephoneNumberInput";

export default class ValidatedTelephoneNumberInput extends Component {

	render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }

		return (
			<div className={className}>
        		<Field component={TelephoneNumberInput} {...this.props}/>
      		</div>	
		);
	}  
}
