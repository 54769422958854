import React, {Component} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import "../../App.css";
import moment from 'moment';
import Dialog from "../common/widgets/Dialog";
import {WHITE_GREY} from "../../constants/Colors";

export default class HostBookingRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showProfileModal: false
        };
        this.closeProfile = this.closeProfile.bind(this);
        this.openProfile = this.openProfile.bind(this);
        this.cancelPressed = this.cancelPressed.bind(this);
    }

    closeProfile() {
        this.setState({showProfileModal: false});
    }

    openProfile() {
        this.setState({showProfileModal: true});
    }

    cancelPressed() {
        this.props.cancelPressed(this.props.booking)
    }

    render() {
        let name = this.props.booking.guestFirstName + ' ' + this.props.booking.guestLastName;
        let phone = this.props.booking.guestPhone;
        let email = this.props.booking.guestEmail;
        let bookingDate = this.props.booking.date;
        const emailTo = "mailto:" + email;
        const phoneTel = "tel:" + phone;

        let statusComponent = !!this.props.cancelPressed ? <a onClick={() => {
            this.cancelBookingDialog.show()
        }}>Cancel</a> : "";

        let todayDate = moment();
        let hasBookingPassed = false;
        let dayDiffBooking = moment(bookingDate).diff(todayDate, 'days');
        if (dayDiffBooking < -1) {
            hasBookingPassed = true;
        }

        if (!!this.props.booking && this.props.booking.status === 'cancelled') {
            statusComponent = <span>Booking Cancelled</span>;
        } else if (hasBookingPassed) {
            statusComponent = <p>Booking Completed</p>;
        }

        let bookingRowClass = "host-booking-row";
        // console.log(this.props.isLastItem)
        if (this.props.isLastItem) {
            bookingRowClass = "host-booking-row-last";
        }

        return (
            <Table responsive className={bookingRowClass} style={{backgroundColor: moment(bookingDate) < moment() ? WHITE_GREY : null}}>
                <thead>

                <tr>
                    <th width="120">Check In</th>
                    <th width="150">Guest</th>
                    <th width="75">Rego</th>
                    <th>Property</th>
                    <th width="180">Site</th>
                    <th width="75">Price</th>
                </tr>

                </thead>
                <tbody>

                <tr>
                    <td>{moment(bookingDate).format('Do MMM YYYY')}</td>
                    <td><a onClick={this.openProfile}>{name}</a></td>
                    <td>{this.props.booking.guestVehicle.registration ? this.props.booking.guestVehicle.registration : "Pending"}</td>
                    <td>{this.props.booking.locationName}</td>
                    <td>{this.props.booking.siteName}</td>
                    <td><span className="price-dollar-symbol">$</span>{this.props.booking.price / 100}</td>

                </tr>
                <tr>
                    <td colSpan="6" className="booking-cancel-row">{statusComponent}</td>
                </tr>
                </tbody>

                {/*Guest Profile Modal*/}
                <Modal show={this.state.showProfileModal} onHide={this.closeProfile}>
                    <Modal.Header closeButton>
                        <Modal.Title>Guest Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>{name}</h4>
                        {email ? <p>Email: <a href={emailTo}>{email}</a></p> : null}
                        {phone ? <p>Phone: <a href={phoneTel}>{phone}</a></p> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.closeProfile}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Dialog ref={(ref) => {
                    this.cancelBookingDialog = ref
                }}
                        title="Cancel Booking"
                        body="Are you sure you want to cancel this booking?"
                        positiveText="Confirm"
                        negativeText="Back"
                        onPositivePress={this.cancelPressed}/>


            </Table>


        );
    }
}
