import React, {Component} from 'react';
import {Field} from 'redux-form';
import CampableCheckBoxGroup from './CampableCheckBoxGroup';

export default class ValidatedCheckBoxGroup extends Component {
    render() {
        return (
            <Field component={CampableCheckBoxGroup} {...this.props} />
        );
    }
}
