import React, {Component} from 'react';
import ValidatedTextInput from '../common/ValidatedTextInput';
import ValidatedTextBox from '../common/ValidatedTextBox';
import ValidatedAddressInput from '../common/ValidatedAddressInput';
import ValidatedDropdownSelect from "../common/ValidatedDropdownSelect";
import TextInput from "../common/inputs/TextInput";
import {Field} from "redux-form";

export default class LocationDescription extends Component {

	render() {
		return (
			<div className="inline-card">			
				<ValidatedTextInput label="Property Name" placeholder="e.g. Title" name="name" />

                {this.props.isFreedomCampsite ? <Field label="Address" component={TextInput} name={"addressFreedom"}/> : <ValidatedAddressInput label="Address" name="address" /> }


				<ValidatedTextBox label="Description"  name="description" />

				{this.props.hideNoticePeriod ? null : <ValidatedDropdownSelect label="Notice Period" name="propertyInformation.noticePeriod" selectOptions={['Guests can book on the day', 'I would like one (1) day\'s notice']}/>}
			</div>
		);
	}
}