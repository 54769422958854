import React, {Component} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import '../../App.css';
import indianManHouse from '../../resources/images/indan-man-house.svg';


export default class YouHaveNoProperties extends Component {

    render() {

        return (

            <Row>
                <Col md={12}>
                    <Col md={3} sm={3} className="get-paid-image-column"><Image src={indianManHouse}
                                                                         alt="You have no properties"
                                                                         className="img-responsive center-block"/></Col>
                    <Col md={9} className="">
                        <h4>It looks like you haven't listed a property yet!</h4>
                        <p>To become a Host, you need to list a property where motorhome travellers can park for the
                            night. Listing a property only takes 5 minutes. You will be asked to answer questions about
                            your property and to provide photos. To get started, push Create Property.</p>
                        <br/>
                    </Col>

                </Col>

            </Row>


        );
    }

}