import React, {Component} from 'react';
import {Link} from 'react-router';
import {Col} from 'react-bootstrap';
import {COLLECTIONS} from "../../constants/Routes";

export default class CollectionTile extends Component {

    render() {
        let url = COLLECTIONS + "/" + this.props.collection.slug;
        return (
            <Col className="collection-thumbnail" key={window.location.id} xs={6} sm={4} md={3}>
                <Link to={url}>
                    <div style={window.location.pathname === "/" ? {} : {marginBottom: "15px"}} className="thumbnail-frame">
                        <img src={this.props.collection.thumbnail.url} alt="thumbnail"/>
                    </div>
                </Link>
            </Col>
        )
    }
}
