import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row, Table} from 'react-bootstrap';
import Load from '../components/Load';
import {isAdmin} from '../auth';
import '../App.css';
import {reduxForm} from 'redux-form';
import ValidatedTextInput from '../components/common/ValidatedTextInput';
import Card from '../components/Card';
import {createTag} from "../actions/index";

class AdminTagsContainer extends Component {

    constructor(props) {
        super(props);
        this.addTag = this.addTag.bind(this);
    }

    addTag(tag) {

        this.props.dispatch(createTag(tag));
    }
    render() {


        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let user = this.props.user;


        if (isAdmin(user)) {
            let tags = this.props.tags.map((tag)=> {
                return  <tr key={tag.id}>
                            <td>{tag.label}</td>
                            <td>{tag.group}</td>
                            <td>{tag.name}</td>
                            <td>{tag.id}</td>
                            <td>{tag.status}</td>
                        </tr>
            });


            return (
                <Grid className="main-container">
                    <Row>
                        {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                        <Col md={12}>
                            <div>
                                <h2>Current Tags</h2>
                                <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                    <th>Label</th>
                                    <th>Group</th>
                                    <th>Name</th>
                                    <th>Id</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {tags}
                                </tbody>

                                </Table>

                                <Card title="Add Tag" onboardingCard={false} helpText="Add a new tag to the system.">
                                <form onSubmit={this.props.handleSubmit(this.addTag)}>
                                    <ValidatedTextInput label="Tag Label" placeholder="e.g. Toilet"
                                                        name="label" />
                                    <ValidatedTextInput label="Group" placeholder="e.g. site_feature"
                                                        name="group" />
                                    <ValidatedTextInput label="Name" placeholder="e.g. toilet"
                                                        name="name" />
                                    <Button type="submit">Add Tag</Button>
                                </form>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <div className="loaderContainer">
                <h1> Sorry you are not an admin. </h1>
            </div>
        )



    }
}

AdminTagsContainer = reduxForm({
    form: 'addTag'
})(AdminTagsContainer)

export default AdminTagsContainer = connect((state) => {
    return {
        user: state.data.campableUser,
        tags: state.data.tags
    }
})(AdminTagsContainer);