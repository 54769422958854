import React, {Component} from "react";
import {LINK_IOS_APP_STORE} from "../../../constants/Constants";
import ios_download_badge from "../../../resources/images/badge_ios_download.png";

export default class IOSBadge extends Component {

    render() {
        return (
            <a href={LINK_IOS_APP_STORE} target="_blank">
                <img alt='Get it on Apple App Store' src={ios_download_badge} className="app-download-badge"/>
            </a>
        );
    }
};