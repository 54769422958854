import React, {Component} from 'react';
import '../../App.css';
import pin from '../../resources/images/site-pin-no-shad.png';
import Map from "../common/map/Map";
import Marker from "../common/map/Marker";

export default class HostLocationMap extends Component {

    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);

    }

    onDragEnd(event) {
        let lat = event.latLng.lat();
        let lng = event.latLng.lng();

        if (this.props.latitude.input.onChange && this.props.longitude.input.onChange) {
            this.props.latitude.input.onChange(lat);
            this.props.longitude.input.onChange(lng);
        }
    }


    render() {

        let lat = this.props.latitude.input.value;
        let lng = this.props.longitude.input.value;
        let center = {lat: lat, lng: lng};

        return (
            <div className="location-map">
                {/*<h4>Click and drag the pin to your desired location.</h4>*/}
                <Map
                    containerStyle={{height: '35vh'}}
                    mapStyle={{height: '35vh'}}
                    options={{
                        streetViewControl: false,
                        panControl: true,
                        mapTypeControl: true,
                        scrollwheel: false,
                        maxZoom: 18,
                        minZoom: 3,
                    }}
                    center={center}>
                    <Marker lat={lat}
                            lng={lng}
                            src={pin}
                            onDragEnd={this.onDragEnd}
                            scaledSize={{height: 55, width: 45}}
                            draggable={true}/>
                </Map>
            </div>
        );
    }
}

