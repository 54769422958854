/**
 * Created by brodynelson on 16/03/17.
 */
import React, {Component} from 'react';
import {Field} from 'redux-form';
import PriceInput from './inputs/PriceInput';

export default class ValidatedPriceInput extends Component {


    render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }

        return (
            <div className={className}>
                <Field component={PriceInput} {...this.props}/>
            </div>
        );
    }
}
