import React, {Component} from "react";
import {Button, ControlLabel, Modal} from "react-bootstrap";
import {formValueSelector, reduxForm} from "redux-form";
import moment from "moment";
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';
import ValidatedDropdownSelect from "../common/ValidatedDropdownSelect"
import ValidatedTextBox from "../common/ValidatedTextBox"
import ValidatedDateRangePicker from "../common/ValidatedDateRangePicker"
import ValidatedTextInput from '../common/ValidatedTextInput';
import 'react-dates/lib/css/_datepicker.css';


const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderLocationSuggestion = suggestion => (
    <div>
        {suggestion.name}
    </div>
);

class CampaignDialog extends Component {

    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
        this.onEnter = this.onEnter.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onLocationAutoSuggestChange = this.onLocationAutoSuggestChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);

        this.state = {reason: undefined, campaignType:"Credit", locationSearchValue: "", from: moment(), to: moment().add(1, 'month'), locationId: undefined}

    }


    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    onTypeChange(type, val){
        this.setState({campaignType: val});
    }

    focus() {
        this.inputRef.focus()
    }

    onEnter(values) {
        if(this.state.campaignType === "Site Discount" && this.props.sites) {

            values.locationId = this.state.locationId;

            this.props.sites.map((site) => {
                if (values.campaignSite === site.name) {
                    values.siteId = site.id;
                }
            });
        }

        values.code = values.code.toUpperCase();

        this.props.onOk(values);
    }

    onLocationAutoSuggestChange = (event, { newValue }) => {
        this.setState({
            locationSearchValue: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.props.searchLocations(value);
    };

    locationSelected = (evt, obj) => {
        this.props.getLocation(obj.suggestion.id);
        this.setState({locationId: obj.suggestion.id});
    }

    render(){

        const inputProps = {
            placeholder: 'Search for location',
            value: this.state.locationSearchValue,
            onChange: this.onLocationAutoSuggestChange,
            type: 'search',
        };

        var siteNames = [""];
        if(this.props.sites && this.props.sites.length > 0){
            this.props.sites.map((site) => {
                siteNames.push(site.name);
                return null;
            });
        }

        var collections = [""];
        if(this.props.collections && this.props.collections.length > 0){
            this.props.collections.map((collection) => {
                collections.push(collection);
                return null;
            });
        }

        return (<div className="static-modal">
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>


                    <form onSubmit={this.props.handleSubmit(this.onEnter)}>
                        <Modal.Body>
                            <ValidatedTextInput label="Code" placeholder="e.g. ALLBLACKS" name="code" required={true}/>
                            <ValidatedTextInput label="User Email" placeholder="e.g. user@mail.com" name="userEmail" required={false}/>
                            <ValidatedTextInput label="Affiliate Email" placeholder="e.g. affiliate@mail.com" name="affiliateEmail" required={false}/>
                            <ValidatedTextInput label="Max Uses" name="maxUses" required={false}/>
                            <ValidatedDropdownSelect label="Type" onChange={this.onTypeChange} name="campaignType" selectOptions={["Credit", "Discount", "Site Discount", "Tour Discount"]}/>
                            <ControlLabel>{"Valid Dates"}<span className="required-star">*</span></ControlLabel>
                            {this.props.dates ? <ValidatedDateRangePicker name="dates" from={this.props.dates.from} to={this.props.dates.to} /> : null }

                            {this.props.campaign ? <ValidatedDropdownSelect label="Deleted" name="deleted" selectOptions={['Not Deleted', 'Deleted']}/> : null}

                            <ValidatedTextBox name="userMessage" label="User Message" required={true}/>
                            {this.state.campaignType === "Credit" ? <ValidatedTextInput name="creditAmount" label="Credit Amount (in cents)" required={true}/> : null}
                            {this.state.campaignType !== "Credit" ? <ValidatedTextInput name="percentageDiscount" label="Percentage Discount" required={false}/> : null}
                            {this.state.campaignType !== "Credit" ? <ValidatedTextInput name="numberOfNights" label="Number of Nights" required={false}/> : null}

                            {this.state.campaignType === "Site Discount" ?  <div><ControlLabel>{"Search Location"}<span className="required-star">*</span></ControlLabel>
                            <Autosuggest disabled={this.state.campaignType !== "Site Discount"} suggestions={this.props.locations || []} onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={()=>{}} getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderLocationSuggestion} inputProps={inputProps} onSuggestionSelected={this.locationSelected}/></div> : null}

                            {this.state.campaignType === "Site Discount" ? <ValidatedDropdownSelect label="Select Site" onChange={this.siteSelected} required={true} name="campaignSite" selectOptions={siteNames}/> : null}

                            {this.state.campaignType === "Tour Discount" ? <ValidatedDropdownSelect label="Select Collection" required={true} name="collection" selectOptions={collections}/> : null}
                        </Modal.Body>
                        <Modal.Footer>
                            <a className="negativeButton" onClick={() => {
                                this.props.onCancel();
                            }}>Cancel</a>
                            <Button disabled={!this.props.valid || this.props.pristine} type="submit" className="positiveButton">Create</Button>
                        </Modal.Footer>
                    </form>



            </Modal.Dialog>
        </div>)
    }
}

CampaignDialog = reduxForm({
    form: 'campaignDialogForm',
    validate: (values, props, state) => {
        let errors = {};

        if(values.code === undefined){
            errors.code = "Code required";
        }

        if (values.dates === undefined || values.dates.from === null){
            errors.fromDate = "From date required";
        }

        if(values.campaignType === "Credit" && values.creditAmount === undefined){
            errors.creditAmount = "Credit amount required";
        }

        if(values.campaignType === "Credit"  || values.campaignType === "Site Discount"  || values.campaignType === "Tour Discount" ){
            if(values.percentageDiscount === null){
                errors.percentageDiscount = "% discount required for discount campaigns";
            }

            if(values.numberOfNights === null){
                errors.numberOfNights = "Number of nights required";
            }
        }

        if(values.campaignType === "Site Discount" && values.campaignSite === undefined){
            errors.campaignSite = "Site required";
        }

        if(values.campaignType === "Tour Discount" && values.collection === undefined){
            errors.collection = "Collection required";
        }

        return errors;
    }
    })(CampaignDialog);

export default CampaignDialog = connect((state, props) => {

    let dates = formValueSelector('campaignDialogForm')(state, 'dates');
    //let site = formValueSelector('campaignDialogForm')(state, 'site');
    return {
        initialValues: {
            dates: {
                from: props.campaign ? moment(props.campaign.validFrom) : moment().format('YYYY-MM-DD'),
                to: props.campaign ? moment(props.campaign.validUntil) : null,
            },
            campaignType: "Credit",
            //site: undefined,
        },
        dates: dates,
       // site: site
    }
})(CampaignDialog);
