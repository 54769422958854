/**
 * Created by Chris on 13/03/17.
 */
/* eslint-disable */

const STRIPE_API = 'https://api.stripe.com/v1/';
const STRIPE_API_FILES = 'https://files.stripe.com/v1/files';

export function createCreditCardToken(data) {
    return new Promise((resolve, reject) => {
        let cardDetails = {
            "card[number]": data.cardNumber,
            "card[exp_month]": data.cardExpMonth,
            "card[exp_year]": data.cardExpYear,
            "card[cvc]": data.cardCVV
        };

        let formBody = [];
        for (let property in cardDetails) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(cardDetails[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        formBody = formBody.join("&");

        return fetch(STRIPE_API + 'tokens', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + process.env.REACT_APP_STRIPE_KEY
            },
            body: formBody
        }).then(response => {
            return response.json()
                .then(json => {
                    return response.ok ? resolve(json) : reject(json)
                })
                .catch(e => {
                    reject(e);
                })
        }).catch(e => {
            reject(e);
        });

    });
}

// add stripe webhook to note when account requires identity verification

// listen for account.updated event & check for requirements changes
// this returns the account object, account.charges_enabled & account.payouts_enabled
// Person.requirements.currently_due being non empty indicates that further information is required

export function uploadIdImage(image, purpose) {

    const formData = new FormData();
    formData.append('purpose', purpose);
    formData.append('file', image.file, image.name);

    const REQUEST = new Request(STRIPE_API_FILES, {
        method: "POST",
        headers: {
            'Accept-Charset': 'UTF-8',
            'Authorization': 'Bearer ' + process.env.REACT_APP_STRIPE_KEY,
            'Accept': 'application/json',
            'User-Agent': 'Stripe/v1 JavaBindings/5.8.0',
        },
        body: formData
    });

    return new Promise((resolve, reject) => {
        fetch(REQUEST).then(response => {
            return response.json()
                .then(json => {
                    return response.ok ? resolve(json) : reject(json)
                }).catch((err) => {
                    return reject(err);
                })
        }).catch((err) => {
            // handle stripe error returned
            // document type errors need to prompt user to reupload

            return reject(err);
        });
    });
}
