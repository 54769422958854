import React, {Component} from 'react';
import {FieldArray} from 'redux-form';
import LocationCampsiteAvailabilityRules from './LocationCampsiteAvailabilityRules';

export default class LocationCampsiteAvailabilityRulesController extends Component {

    render() {
        return (
            <FieldArray name={this.props.name}
                        component={LocationCampsiteAvailabilityRules}
                        {...this.props}/>
        );
    }
}