import React, {Component} from 'react';

import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';
import {Field} from 'redux-form';

class SelectInput extends Component {

    render() {

        let validationState = null;
        let helpBlock;
        let generateOptions = this.props.selectOptions.map((option) => {
                return <option key={option.toString()} value={option}>{option}</option>;
            }
        );

        if (this.props.meta.touched && this.props.meta.error) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        return (
            <FormGroup validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl {...this.props.input} componentClass="select" placeholder="select">
                    <option value={null}>Select One</option>
                    {generateOptions}
                </FormControl>
                {helpBlock}
            </FormGroup>
        );
    }
}

export default class ValidatedSelectInput extends Component {

    render() {

        return (
            <div>
                <Field {...this.props} component={SelectInput}/>
            </div>
        );
    }
}