import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import Load from '../components/Load';
import {showLoginOverlay} from "../actions/overlay";
import {Col, Grid, MenuItem, Row} from "react-bootstrap";
import {browserHistory} from "react-router";
import BlockContent from '@sanity/block-content-to-react';

const sanityDataset = process.env.REACT_APP_SANITY_DATASET;

const sanityClient = require('@sanity/client')({
  projectId: 'qmsf8vfw',
  dataset: sanityDataset,
  useCdn: true
})

const serializers = {
    types: {
        code: props => (
            <pre data-language={props.node.language}>
            <code>{props.node.code}</code>
            </pre>
        )
    }
}

class FaqContainer extends Component {

    constructor(props) {
        super(props);

        this.showLoginOverlay = this.showLoginOverlay.bind(this);

        this.state = {
            noPageFound: false,
        }
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp))
    }

    componentDidUpdate(prevProps) {
        if(prevProps.language != this.props.language) {
            this.getContent();
        }
    }

    componentDidMount() {
        this.getContent();
    }

    getContent = () => {
        let lang = this.props.language.code;
        sanityClient.fetch(`* | [_type == "faqWifi"] | {"question": ${lang}.question,"answer":${lang}.answer[]{...,"asset":asset->}} | order(sort)`)
            .then(faq => this.setState({faq: faq.filter(f=>f.answer)})) //filter out the FAQs with no answer
            .catch(err => console.log("error",err));
    }

    render() {
        if (!this.state.faq) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            );
        }

        let title = "FAQs for Wifi"

        let links = this.state.faq.map((f,i) => <p key={i}><a>{f.question}</a></p>);

        let faqs = this.state.faq.map((f,i) => (
            <div key={i}><hr/><h3>{f.question}</h3><BlockContent blocks={f.answer} serializers={serializers} /></div>
        ));

        return (
            <Grid className="page">
                <Row>
                    <Col md={3} className="page-menu hidden-sm hidden-xs">
                        <ul>
                            <h4>Help</h4>
                            <MenuItem eventKey="/how-does-campable-work" onClick={() => browserHistory.push('/how-campable-works')}>How does Campable Work?</MenuItem>
                            <MenuItem eventKey="/after-booking" onClick={() => browserHistory.push('/after-booking')}>After Booking</MenuItem>
                            <MenuItem eventKey="/faq-guest" onClick={() => browserHistory.push('/faq-guest')}>FAQ - Guests</MenuItem>
                            <MenuItem eventKey="/faq-host" onClick={() => browserHistory.push('/faq-host')}>FAQ - Hosts</MenuItem>
                            <MenuItem eventKey="/rules-of-etiquette" onClick={() => browserHistory.push('/rules-of-etiquette')}>Rules of Etiquette</MenuItem>
                            <MenuItem eventKey="/health-and-safety" onClick={() => browserHistory.push('/health-and-safety')}>Health and Safety</MenuItem>
                            <MenuItem eventKey="/terms-and-conditions" onClick={() => browserHistory.push('/terms-and-conditions')}>Terms and Conditions</MenuItem>
                        </ul>
                    </Col>
                    <Col className="card" xs={12} md={9}>
                        <h1 className="page-title">{title}</h1>
                        <Col className="contentful-column" xs={12} md={12}>
                            {links}
                            {faqs}
                        </Col>
                    </Col>
                </Row>
            </Grid>
        );
    }
}


export default FaqContainer = connect(state => {
    return {
        language: state.languages.selected,
        user: state.data.campableUser,
    }
})(FaqContainer)
