/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 12/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {Component} from "react";
import Dialog from "../common/widgets/Dialog";
import {connect} from "react-redux";
import {formValueSelector, reduxForm} from "redux-form";
import {Button, ButtonGroup} from "react-bootstrap";
import ValidatedDropdownSelect from "../common/ValidatedDropdownSelect";
import ValidatedTextInput from "../common/ValidatedTextInput";
import ValidatedCheckBoxGroup from "../common/ValidatedCheckBoxGroup";
import ValidatedDateRangePicker from "../common/ValidatedDateRangePicker";
import moment from "moment";
import ValidatedPriceInput from "../common/ValidatedPriceInput";

class AddNewRuleDialog extends Component {

    constructor(props) {
        super(props);

        this.onConfirmNewRulePress = this.onConfirmNewRulePress.bind(this);
        this.onTypeSelectChange = this.onTypeSelectChange.bind(this);

        this.isDayBlocked = this.isDayBlocked.bind(this);

        this.state = {
            from: moment(),
            to: moment().add(1, 'month'),
        };

        this.weekDayCheckOptions = [
            {id: 'monday', label: 'Monday'},
            {id: 'tuesday', label: 'Tuesday'},
            {id: 'wednesday', label: 'Wednesday'},
            {id: 'thursday', label: 'Thursday'},
            {id: 'friday', label: 'Friday'},
            {id: 'saturday', label: 'Saturday'},
            {id: 'sunday', label: 'Sunday'}
        ];

        this.monthsCheckOptions = [
            {id: 'january', label: 'January'},
            {id: 'february', label: 'February'},
            {id: 'march', label: 'March'},
            {id: 'april', label: 'April'},
            {id: 'may', label: 'May'},
            {id: 'june', label: 'June'},
            {id: 'july', label: 'July'},
            {id: 'august', label: 'August'},
            {id: 'september', label: 'September'},
            {id: 'october', label: 'October'},
            {id: 'november', label: 'November'},
            {id: 'december', label: 'December'},
        ];
    }

    componentWillMount() {
        this.props.onAvailablePress();
    }

    onConfirmNewRulePress(values) {

        if (values.type === 'Custom') {
            values.dates = null;
        } else if (values.type === 'Date Range') {
            values.days = [];
            values.months = [];
        }

        values.description = 'abc123';
        if (this.props.available) {
            this.props.onConfirmNewRulePress(values);
        } else {
            values.price = this.props.location.propertyInformation.sites[0].price;
            values.spaces = Number(0);

            this.props.onConfirmNewRulePress(values);
        }
        this.props.reset();
    }


    onTypeSelectChange(type) {

    }

    isDayBlocked(day) {

        let returnValue = false;
        // eslint-disable-next-line
        this.props.dateRangeRules.map((rule) => {
            let ruleFromDate = moment(rule.dates.from);
            let ruleToDate= moment(rule.dates.to);
            if (day.isSameOrAfter(ruleFromDate) && day.isSameOrBefore(ruleToDate)) {
                returnValue = true;
            }
        });
        return returnValue;
    }

    render() {
        return (
            <Dialog
                ref={(ref) => {
                    this.props.setRef(ref);
                    this.addNewRuleDialog = ref;
                }}
                onPositivePress={this.onConfirmNewRulePress}
                dontDismissOnConfirmPress
                title={"Add new Rule"}>
                <div className="add-new-rule-dialog">
                    <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>
                    <div className="availability-control-block">
                        <ButtonGroup>
                            <Button className={!this.props.available ? 'btn-inverse' : ''} name="available" onClick={this.props.onAvailablePress}>Available</Button>
                            <Button className={this.props.available ? 'btn-inverse' : ''} name="unavailable" onClick={this.props.onUnavailablePress}>Unavailable</Button>
                        </ButtonGroup>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onConfirmNewRulePress)}>
                        <ValidatedDropdownSelect
                            name="type"
                            label="Rule Type"
                            className="dropDown"
                            selectOptions={['Select One', 'Custom', 'Date Range']}
                            required/>

                        {this.props.type === 'Date Range' ?
                            <ValidatedDateRangePicker name="dates" from={this.props.dates.from} to={this.props.dates.to} isDayBlocked={this.isDayBlocked}/>
                            : null}

                        {this.props.type === 'Custom' ?
                            <h4>{this.props.available ? 'Set ' : 'Block '}these days:</h4>
                            : null}

                        {this.props.type === 'Custom' ?
                            <ValidatedCheckBoxGroup name="days" checkOptions={this.weekDayCheckOptions}/>
                            : null}

                        {this.props.type === 'Custom' ?
                            <h4>In these months: </h4>
                            : null}

                        {this.props.type === 'Custom' ?
                            <ValidatedCheckBoxGroup name="months" checkOptions={this.monthsCheckOptions}/>
                            : null}

                        {this.props.type === 'Custom' && this.props.available ?
                            <h4>With this price and availability: </h4>
                            : null}

                        {this.props.available ? <ValidatedPriceInput label="Price" name="price" disabled={this.props.type === 'Select One'} required/> : null}
                        {this.props.available ? <ValidatedTextInput label="Spaces" name="spaces" disabled={this.props.type === 'Select One'} required/> : null}

                        <div className="modal-footer">
                            <a className="negativeButton" onClick={() => {
                                this.addNewRuleDialog.dismiss();
                            }}>Cancel</a>
                            <Button type="submit" className="positiveButton" disabled={!this.props.valid || this.props.pristine}>Create</Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        );
    }
}

AddNewRuleDialog = reduxForm({
    form: 'addNewRuleDialogForm',
    validate: (values, props, state) => {
        let errors = {};

        if (!values.type || values.type === 'Select One') {
            errors.type = "Required";
        } else if (values.type === 'Custom') {

            if (!values.days || values.days.length === 0) {
                errors.days = 'At least 1 day is required';
            }

            if (!values.months || values.months.length === 0) {
                errors.months = 'At least 1 month is required';
            }

        } else if (values.type === 'Date Range') {
            if (!values.dates && !values.dates.from) {
                errors.dates = 'Invalid from and/or to date(s).';
            } else if (!values.dates.to) {
                errors.dates = 'Invalid from and/or to date(s).';
            }
        }

        if (props.available) {
            if (!values.price) {
                errors.price = 'Required'
            } else if (isNaN(Number(values.price))) {
                errors.price = 'Must be a number';
            } else {
                if (values.price < 1000) {
                    errors.price = 'You cannot charge less than $10 a night';
                } else if (values.price > 99900) {
                    errors.price = 'You cannot charge more than $999 a night';
                }
            }

        } else {

            if (!values.description) {
                errors.description = "Please provide a valid description.";
            }

        }

        return errors;
    }
})(AddNewRuleDialog);

export default AddNewRuleDialog = connect((state, props) => {
    let type = formValueSelector('addNewRuleDialogForm')(state, 'type');
    let dates = formValueSelector('addNewRuleDialogForm')(state, 'dates');
    return {
        type: type,
        initialValues: {
            type: 'Date Range', dates: {
                from: moment().format('YYYY-MM-DD'),
                to: null,
            }
        },
        dates: dates,
    }
})(AddNewRuleDialog);
