import React, {Component} from 'react';
import {connect} from 'react-redux';

class AuthError extends Component {

    render() {
        let message = undefined;
        if (this.props.state.auth.error && this.props.state.auth.error.code) {
            switch (this.props.state.auth.error.code) {
                case 'auth/invalid-email':
                    message = 'Email address is not valid.';
                    break;

                case 'auth/user-disabled':
                    message = 'Email has been disabled.';
                    break;

                case 'auth/user-not-found':
                    message = 'No account in Campable corresponding to the given email.';
                    break;

                case 'auth/wrong-password':
                    message = 'Password is incorrect.';
                    break;

                case 'auth/email-already-in-use':
                    message = 'Account already exists.';
                    break;

                case 'auth/operation-not-allowed':
                    message = 'Account is not enabled.';
                    break;

                case 'auth/account-exists-with-different-credential':
                    message = 'Account already exists.';
                    break;

                case 'auth/weak-password':
                    message = 'Password(s) should be at least six characters.';
                    break;

                case 'user_exists':
                    message = 'Account already exists.';
                    break;

                default:
                    message = undefined
            }
        }

        return (message ? <div className="auth-error">
                {message}
            </div> : null

        )

    }
}


export default AuthError = connect((state) => {
    return {state};
})(AuthError);

