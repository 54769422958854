import React, {Component} from "react";
import {Col, OverlayTrigger, Popover} from "react-bootstrap";
import LocationHeader from "./LocationHeader";
import LocationDescription from "./LocationDescription";
import {Field, Fields} from "redux-form";
import LocationImages from "./LocationImages";
import LocationCampsiteController from "./LocationCampsiteController";
import HowDoIGetPaid from "./HowDoIGetPaid";
import HostLocationMap from "./HostLocationMap";
import LocationAdminPanel from "./LocationAdminPanel";
import Load from "../Load";
import {isAdmin} from "../../auth";
import ValidatedTextInput from "../common/ValidatedTextInput";
import AddAmenities from "../onboarding/property/AddAmenities";
import ValidatedCheckBoxGroup from "../common/ValidatedCheckBoxGroup";

export default class HostLocationDetailView extends Component {

    render() {

        const siteFeatures = [];
        if (this.props.tags) {
            this.props.tags.map((tag) => {
                if (tag.group === 'site_feature') {
                    siteFeatures.push(tag);
                }
                return null;
            });
        }

        const popoverClickRootClose = (
            <Popover id="popover-trigger-click-root-close"
                     title="What is the difference between a Property and a Site">
                A Property refers to the overall property defined by a single postal address. A Property can have
                multiple Sites, each with individual features and prices.
            </Popover>
        );

        if (this.props.location) {
            let howDoIGetPaid = null;
            if (this.props.campableUser && !isAdmin(this.props.campableUser) && !this.props.campableUser.accountInformation.valid) {
                howDoIGetPaid = (
                    <div>
                        <h2>How Do I get Paid?</h2>
                        <HowDoIGetPaid {...this.props}/>
                    </div>);
            }

            const imageArray = this.props.location.images;

            return (
                <Col md={9} className="location-detail-view">
                    <LocationHeader handleListedSelectChange={this.props.handleListedSelectChange}
                                    statusValue={this.props.statusValue}/>

                    <LocationDescription isFreedomCampsite={this.props.location.type === "freedom_campsite"} hideNoticePeriod={this.props.location.type !== "property"}/>

                    <div className="inline-card">
                        {this.props.location.type !== "property" ? <ValidatedTextInput label="Web Site" className="form-name" name="website"/> : null}
                    </div>

                    <Fields names={['latitude', 'longitude']} component={HostLocationMap}/>

                    <Field name="images" component={LocationImages} imageArray={imageArray}/>

                    {this.props.location.type !== "property" ? <ValidatedCheckBoxGroup label="Facilities" name={`tags`}
                                                                                       checkOptions={siteFeatures}/> : null}

                    {this.props.isAdmin && this.props.location.type === "property" ? <LocationAdminPanel tags={this.props.tags} location={this.props.location}/> : null}

                    {this.props.location.type === "property" ? <h2 className="campsites-h2">Sites</h2> : null}
                    {this.props.location.type === "property" ? <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popoverClickRootClose}>
                        <a className="link-property-vs-campsite">What is the difference between a Property and a Site?</a>
                    </OverlayTrigger> : null}

                    {this.props.location.type === "property" ? <LocationCampsiteController location={this.props.location}
                                                                                           tags={this.props.tags}
                                                                                           removeSitePressed={this.props.removeSitePressed}
                                                                                           admin={this.props.admin}/>
                        : null}
                    {howDoIGetPaid}
                </Col>
            );
        }

        return (
            <Load/>
        );
    }
}


