import React, {Component} from "react";
import {connect} from "react-redux";
import "../App.css";
import Load from "../components/Load";
import AccountDetailsController from "../components/onboarding/AccountDetailsController";
import AccountImageController from "../components/onboarding/AccountImageController";
import HostDetailsController from "../components/onboarding/HostDetailsController";
import CreatePropertyController from "../components/onboarding/CreatePropertyController";
import {HOSTING_CREATE_A_PROPERTY} from "../constants/Routes";
import {browserHistory} from "react-router";
import RequirementsContainer from "./RequirementsContainer";

class HostOnboardingContainer extends Component {

    constructor(props) {
        super(props);

        this.onNextStepPress = this.onNextStepPress.bind(this);
        this.onBackPress = this.onBackPress.bind(this);

        this.createProperty = this.createProperty.bind(this);

        this.state = {
            step: 1,
            loading: false,
        }
    }


    onNextStepPress() {
        let step = this.state.step;
        step++;

        this.setState({
            step: step,
        });
    }


    onBackPress() {
        this.setState({
            step: this.state.step - 1
        });
    }

    createProperty() {
        browserHistory.push(HOSTING_CREATE_A_PROPERTY)
    }


    render() {
        let user = this.props.user;

        if (!user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }


        switch (this.state.step) {
            case 1:
                return <AccountDetailsController isHost
                                                 isHostOnboarding
                                                 onNextStepPress={this.onNextStepPress}
                                                 onBackPress={this.onBackPress}/>;
            case 2:
                return <AccountImageController onNextStepPress={this.onNextStepPress}
                                               onBackPress={this.onBackPress}/>;
            case 3:
                return <HostDetailsController onNextStepPress={this.onNextStepPress}
                                              onBackPress={this.onBackPress}/>;
            case 4:
                return <RequirementsContainer onboarding
                                              onNextStepPress={this.onNextStepPress}
                                              onBackPress={this.onBackPress}/>;
            case 5:
                return <CreatePropertyController onNextStepPress={this.onNextStepPress}
                                                 onBackPress={this.onBackPress}/>;
            default:
                return null;
        }
    }
}

export default HostOnboardingContainer = connect((state) => {
    return {
        user: state.data.campableUser,
    }
})(HostOnboardingContainer);




 


