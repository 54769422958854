import React, {Component} from 'react';
import DragAndDropGallery from '../../components/common/DndGallery';
import {createImage} from '../../api/api';
import {connect} from 'react-redux';
import {showAlert} from '../../actions';
import {fireTagEvent} from "../../tagmanager";
import {FormGroup, HelpBlock} from "react-bootstrap";


class LocationImages extends Component {

    onRemoveImage(imageId) {
        const array = this.props.input.value;
        const removed = [];
        array.map(image => {
            if (image.id !== imageId) {
                removed.push(image);
            }
            return null;
        });
        this.props.input.onChange(removed);
    }

    onChangeImageOrder(newData) {
        this.props.input.onChange(newData);
    }

    onDrop(files) {
        fireTagEvent("create-a-property", this.props.campableUser, {'action': 'add-image'});

        const array = this.props.input.value;
        this.props.dispatch(showAlert("info", "Image uploading...", "Your image is uploading..."));

        createImage(files).then((images) => {
            this.props.dispatch(showAlert("success", "Image uploaded", "Select update to save your changes"));
            this.props.input.onChange(array.concat(images));
        });
    }

    render() {

        let validationState = null;
        let helpBlock;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }


        return (
            <FormGroup className="location-images inline-card" validationState={validationState}>
                <h4>Images</h4>
                {helpBlock}
                <DragAndDropGallery
                    onChangeImageOrder={this.onChangeImageOrder.bind(this)}
                    onDrop={this.onDrop.bind(this)}
                    onRemoveImage={this.onRemoveImage.bind(this)}
                    className="photos"
                    images={this.props.input.value}
                />
            </FormGroup>
        );
    }
}

export default LocationImages = connect(state => {
    return {
        campableUser: state.data.campableUser
    }
})(LocationImages)
