import React, {Component} from "react";
import LocationCampsite from "./LocationCampsite";
import moment from "moment";
import Dialog from "../common/widgets/Dialog";
import {Button, Col, Grid, Row} from "react-bootstrap";
import values from "lodash/values";
import PaymentDetailsDialog from "./dialogs/PaymentDetailsDialog";
import AccountDetailsDialog from "./dialogs/AccountDetailsDialog";


export default class LocationBookingInformation extends Component {

    constructor(props) {
        super(props);

        this.getLocationSiteAvailabilities = this.getLocationSiteAvailabilities.bind(this);
        this.clearSiteAvailability = this.clearSiteAvailability.bind(this);
        this.onDateSelectedApply = this.onDateSelectedApply.bind(this);
        this.onDatePickerShow = this.onDatePickerShow.bind(this);

        this.onBookNowPress = this.onBookNowPress.bind(this);

        this.state = {
            selectedDate: moment(),
        }
    }

    componentWillMount() {
        this.getLocationSiteAvailabilities(this.state.selectedDate);
    }

    getLocationSiteAvailabilities(from, to) {
        this.props.getLocationSiteAvailabilities(from, to);
    }

    clearSiteAvailability() {
        this.props.clearSiteAvailability();
    }

    clearSelectedBookings() {
        this.props.clearSelectedBookings();
    }

    componentWillUnmount() {
        this.clearSelectedBookings();
        this.clearSiteAvailability();
    }

    onDateSelectedApply(date) {
        this.setState({
            selectedDate: date
        }, () => {
            this.clearSiteAvailability();
            this.getLocationSiteAvailabilities(this.state.selectedDate);
        });

    }

    onDatePickerShow(jQuery, obj) {

    }

    onBookNowPress() {
        let campableUser = this.props.campableUser;
        if (campableUser) {
            if (!campableUser.phone && !campableUser.firstName && !campableUser.lastName) {
                this.accountDetailsDialog.show();
            } else if (!campableUser.paymentInformation.valid) {
                this.paymentDetailsDialog.show();
            } else {
                this.finaliseBookingsDialog.show();
            }
        } else {
            this.props.showLoginOverlay();
        }
    }

    render() {
        let campsites = [];
        if (this.props.location.propertyInformation.sites && this.props.location.propertyInformation.sites.length > 0) {
            let sites = this.props.location.propertyInformation.sites;

            campsites = sites.map((site) => {
                let siteAvailability = {};
                if (this.props.siteAvailabilities && this.props.siteAvailabilities[site.id]) {
                    siteAvailability = this.props.siteAvailabilities[site.id];
                }


                return <LocationCampsite key={site.id}
                                         site={site}
                                         selectedDate={this.state.selectedDate}
                                         selectedBookings={this.props.selectedBookings}
                                         onBookingTilePress={this.props.onBookingTilePress}
                                         onDateSelectedApply={this.onDateSelectedApply}
                                         siteAvailability={siteAvailability}
                                         location={this.props.location}
                                         onDatePickerShow={this.onDatePickerShow}
                                         campableUserBookings={this.props.campableUserBookings}
                                         tagsObj={this.props.tagsObj}/>
            });
        }


        let totalBookingStay = 0;
        let numberOfBookings = 0;


        let bookingsList = values(this.props.selectedBookings).map((booking) => {
            numberOfBookings++;
            totalBookingStay += booking.price;
            return booking;
        });


        let createBookings = null;

        if (numberOfBookings >= 1) {
            let sortedBookingList = bookingsList.sort((a, b) => {
                let day1 = moment(a.date);
                let day2 = moment(b.date);
                return day1 - day2;
            });


            let groupedBookings = [];
            let bookingsSortedListLength = sortedBookingList.length;

            for (let i = 0; i < bookingsSortedListLength; i++) {

                let previousDayBooking = sortedBookingList[i];
                let initialDay = moment(previousDayBooking.date);
                let previousDay = initialDay;

                let dateOfDeparture = previousDay.clone().add(1, 'days');
                let totalPrice = previousDayBooking.price;

                for (let ii = i + 1; ii < bookingsSortedListLength; ii++) {

                    let nextDayBooking = sortedBookingList[ii];
                    let nextDay = moment(nextDayBooking.date);

                    if (nextDay.clone().diff(previousDay, 'days') === 1) {
                        previousDay = nextDay;
                        i++;

                        totalPrice += nextDayBooking.price;
                        dateOfDeparture = nextDay.clone().add(1, 'days')
                    } else {
                        break;
                    }
                }

                groupedBookings.push({
                    dateOfArrival: initialDay,
                    dateOfDeparture: dateOfDeparture,
                    totalPrice: totalPrice,
                });
            }

            createBookings =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col xs={6} sm={10}>
                            {groupedBookings.map((groupBooking) => {
                                return (
                                    <div key={groupBooking.dateOfArrival} className="selected-booking-tile-container">
                                        <div className="arrival-departure-container">
                                            <p>{groupBooking.dateOfArrival.clone().format('DD MMM')} - {groupBooking.dateOfDeparture.clone().format('DD MMM')}</p>
                                        </div>
                                        <h4>${groupBooking.totalPrice / 100}</h4>
                                    </div>);
                            })}
                        </Col>
                        <Col xs={6} sm={2} className="update-details-button-group button-container">
                            <Button onClick={this.onBookNowPress} type="submit">Book Now!</Button>
                        </Col>

                    </Row>
                </Grid>;
        }

        return (
            <div className="location-booking-information">
                <h3>Book Now</h3>
                {campsites}
                {createBookings}
                <PaymentDetailsDialog setRef={(ref) => {
                    this.paymentDetailsDialog = ref;
                }} onBookNowPress={this.onBookNowPress}/>
                <AccountDetailsDialog setRef={(ref) => {
                    this.accountDetailsDialog = ref;
                }} onBookNowPress={this.onBookNowPress}/>
                <Dialog ref={(ref) => {
                    this.finaliseBookingsDialog = ref;
                }}
                        body={
                            <div>
                                <h3>Total Price: ${totalBookingStay / 100}</h3>
                                <h4>Nights: {numberOfBookings}</h4>
                            </div>}
                        positiveText={'Confirm'}
                        negativeText={'Cancel'}
                        onPositivePress={this.props.onFinaliseBookingsButtonPress}
                />
            </div>
        );
    }
}


