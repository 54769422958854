import React, {Component} from 'react';
import Block from './Block';


export default class Column extends Component {

    render() {
        let blocks = [];
        this.props.column.blocks.map(block => {
            let blockComp = <Block key={block.id} block={block} showLoginOverlay={this.props.showLoginOverlay} isLoggedIn={this.props.isLoggedIn}/>;
            blocks.push(blockComp);
            return null;
        });
        return (
            <div>
                {blocks}
            </div>
        );
    }
}