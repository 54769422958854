import React, {Component} from "react";
import PropTypes from 'prop-types';
import {Button, FormControl, Modal} from "react-bootstrap";

export default class TextEntryDialog extends Component {

    static propTypes = {
        textBoxType: PropTypes.string,
    };

    static defaultProps = {
        textBoxType: 'textarea',
        minLength: 10,
    };

    constructor(props) {
        super(props);

        this.inputChange = this.inputChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
        this.onEnter = this.onEnter.bind(this);

        this.state = {reason: undefined}
    }

    inputChange(e) {
        this.setState({reason: e.target.value});
    }

    onKeyPress(event) {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }

        if (event.key === 'Enter') {
            this.props.onOk(this.state.reason);
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    focus() {
        this.inputRef.focus()
    }

    onEnter() {
        this.props.onOk(this.state.reason);
    }

    render(){
        return (<div className="static-modal">
            <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormControl componentClass={this.props.textBoxType}
                                 placeholder={this.props.hint}
                                 inputRef={(ref) => {
                                     this.inputRef = ref;
                                 }}
                                 onKeyPress={this.onKeyPress}
                                 onChange={this.inputChange}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button disabled={this.state.reason === undefined || this.state.reason.length < this.props.minLength} type="submit" onClick={() => {this.props.onOk(this.state.reason)}}>Ok</Button>
                </Modal.Footer>

            </Modal.Dialog>
        </div>)
    }
}