import React, {Component} from 'react';
import {connect} from 'react-redux';
import Load from '../components/Load';
import Locations from '../components/location/Locations';
import {getLocations, setLocationFilter} from "../actions/index";
import {fireECommerceEvent} from "../tagmanager";
import ValidatedCheckBoxGroup from "../components/common/ValidatedCheckBoxGroup";
import {reduxForm} from "redux-form";
import Dialog from "../components/common/widgets/Dialog";
import {Button} from "react-bootstrap";

class LocationsContainer extends Component {

    constructor(props) {
        super(props);

        this.onMapChange = this.onMapChange.bind(this);
        this.setLocationFilter = this.setLocationFilter.bind(this);

        this.state = {
            centre: props.centre,
            zoom: props.zoom,
            hasMounted: false
        }

    }

    componentDidMount() {
        let params = {
            latitude: this.props.centre.lat,
            longitude: this.props.centre.lng,
            type: 'property'
        }

        this.props.dispatch(getLocations(params));

        this.setState({
            hasMounted: true
        });
    }

    onMapChange(centre, zoom) {
        //console.log(centre, zoom)
        // centre = {
        //     lat: centre.lat(),
        //     lng: centre.lng(),
        // };
        //
        let params = {
            latitude: centre.lat,
            longitude: centre.lng,
            type: 'property'
        };

        if (this.state.hasMounted) {
            this.setState({
                centre: centre,
                zoom: zoom,
            });
        }

        this.props.dispatch(getLocations(params));
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.locations === null && nextProps.locations !== null) {
            fireECommerceEvent(this.props.campableUser, "impressions", nextProps.route.path, nextProps.locations);
        }
    }

    setLocationFilter(newLocationFilter) {
        this.props.dispatch(setLocationFilter(newLocationFilter))

    }


    render() {

        const locations = this.props.locations;

        if (!locations) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            );
        }

        return (
            <div>
                <Locations onMapChange={this.onMapChange}
                           centre={this.state.centre}
                           zoom={this.state.zoom}
                           locationFilter={this.props.locationFilter}
                           showFilterDialogPress={() => {
                               this.filterDialog.show()
                           }}
                           setLocationFilter={this.setLocationFilter}
                           tagsObj={this.props.tagsObj}
                           locations={locations}/>
                <FilterDialog setRef={(ref) => {
                    this.filterDialog = ref
                }}/>
            </div>
        )
    }
}

export default LocationsContainer = connect((state, props) => {

    const query = props.location.query;
    const c = query.c && query.c.match(/^(-?\d+\.\d+),(-?\d+\.\d+)$/);

    return {
        tagsObj: state.data.tagsObj,
        locationFilter: state.data.locationFilter,
        locations: state.data.locations,
        centre: c ? {
            lat: Number(c[1]),
            lng: Number(c[2])
        } : {
            // default middle of nz
            lat: -40.9898276,
            lng: 173.4098914
        },
        zoom: c ? 10 : 7,
        campableUser: state.data.campableUser
    }
})(LocationsContainer);


class FilterDialog extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(values) {
        this.props.dispatch(setLocationFilter(values))
        this.dialog.dismiss();
    }

    render() {
        return (
            <Dialog
                ref={(ref) => {
                    this.props.setRef(ref);
                    this.dialog = ref;
                }}
                title={"Select Tags to search by:"}
                {...this.props}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <ValidatedCheckBoxGroup name="tags" checkOptions={this.props.tags}/>

                    <div className="modal-footer">
                        <a className="negativeButton" onClick={() => {
                            this.dialog.dismiss();
                        }}>Cancel</a>
                        <Button type="submit" className="positiveButton">OK</Button>
                    </div>
                </form>
            </Dialog>
        );
    }
}

FilterDialog = reduxForm({
    form: 'filterTagDialog'
})(FilterDialog);
FilterDialog = connect((state) => {
    return {
        tags: state.data.tags,
        initialValues: {tags: state.data.locationFilter}
    }
})(FilterDialog);


