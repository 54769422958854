import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Col, Grid, Row} from "react-bootstrap";
import {createProperty} from "../actions";
import LocationDetails from "../components/onboarding/property/LocationDetails";
import AddAmenities from "../components/onboarding/property/AddAmenities";
import DescriptionDropDown from "../components/onboarding/property/DescriptionDropDown";
import Title from "../components/onboarding/property/Title";
import Price from "../components/onboarding/property/Price";
import {validate} from "../components/common/form-validation/CreatePropertyValidation";
import {reduxForm} from "redux-form";
import {fireTagEvent} from "../tagmanager";
import ValidatedCheckBoxGroup from "../components/common/ValidatedCheckBoxGroup";
import Card from "../components/Card";

class PropertyOnboardingContainer extends Component {
    constructor(props) {
        super(props);
        this.saveProperyDetails = this.saveProperyDetails.bind(this);
    }


    saveProperyDetails(values) {

        //profileValidation
        let descriptionSummary = (values.descriptionOne ? "My place is " + values.descriptionOne + '.': '')
                + ( values.descriptionTwo  ? "You'll love staying here because " + values.descriptionTwo + '.' : '')
                + ( values.descriptionThree ?". This site is close to " + values.descriptionThree + '.' : '')
                + ( values.descriptionFour ? ". You're welcome to stay here if you're travelling in " + values.descriptionFour + "."  : '') ;


        let data = {
            type: "property",
            name: values.title,
            address: values.address.address,
            latitude: values.address.latitude,
            longitude: values.address.longitude,
            description: descriptionSummary,

            tags: [values.propertyType],
            site: {
                spaces: values.spaces,
                price: values.price,
                tags: values.siteTypes.concat(values.Features),
            },
            images: []
        };

        fireTagEvent("create-a-property", this.props.campableUser);
        this.props.dispatch(createProperty(data));

    }

    render() {
        let siteFeatures = [];
        let siteTypes = [];
        if (this.props.tags) {
            siteFeatures = this.props.tags.filter((tag) => (tag.group === "site_feature" && tag.status === "active"));
            siteTypes = this.props.tags.filter((tag) => (tag.group === "site_type" && tag.status === "active"));
        }


        let saveChanges;

        if (this.props.valid && !this.props.pristine) {
            saveChanges =
                <Grid fluid className="animated fadeInUp update-detail-panel">
                    <Row>
                        <Col md={3}/>
                        <Col md={7}>
                            <h4>You have made some changes</h4>
                            <p><strong>Great stuff, you made it!</strong> After you click save we will take you to your listing page to view and finalise some details.</p>
                        </Col>
                        <Col md={2} className="update-details-button-group">
                            <Button type="submit" disabled={this.props.submitting}>Save</Button>
                        </Col>
                    </Row>
                </Grid>
        }

        return (
            <div className="onboarding">
                <h1>Create Property</h1>
                <form onSubmit={this.props.handleSubmit(this.saveProperyDetails)}>
                    <LocationDetails />
                    <Card onboardingCard={true}>
                        <ValidatedCheckBoxGroup label="Location Type(s)"
                                                name={`siteTypes`}
                                                checkOptions={siteTypes}/>
                    </Card>
                    <AddAmenities tags={siteFeatures}/>
                    <DescriptionDropDown />
                    <Title />
                    <Price />
                    {saveChanges}
                </form>
            </div>
        );
    }
}

PropertyOnboardingContainer = reduxForm({
    form: 'createProperty',
    validate
})(PropertyOnboardingContainer);

export default PropertyOnboardingContainer = connect((state) => {
    return {
        initialValues: {
            siteTypes: ["site_type:self_contained"]
        },
        user: state.auth.user,
        tags: state.data.tags,
        campableUser: state.data.campableUser
    }
})(PropertyOnboardingContainer);


 


