import React, {Component} from 'react';
import Card from '../../Card';
import ValidatedSelectInput from '../../common/ValidatedSelectInput';
import ValidatedTextInput from '../../common/ValidatedTextInput';
import ValidatedAddressInput from '../../common/ValidatedAddressInput';

export default class LocationDetails extends Component {

    constructor(props) {
        super(props);

        this.selectOptions = ["Private residence", "Farm", "Rural home", "Vineyard", "Resort", "Hotel/Pub", "Surf Club",
            "Golf Club", "Rugby Club", "Cultural site", "Restaurant", "Lodge", "RSA", "Other business"];
    }


    render() {

        return (
            <Card title="Location Details"
                  onboardingCard={true}
                  helpText="We need your address so Guests can find you on the map and travel to your site once they’ve made a booking. Providing the number of spaces available lets us know how many motorhomes can book your site at one time."
                  helpButton="How many motorhomes will fit on my property?"
                  popOverTitle="How many motorhomes will fit on my property?"
                  popOverBody="We recommend a minuimum space of around 4m x 8m for each motorhome plus any additional room for access.">

                <h5 className="required-text">Fields marked with <span className="star">*</span> are required</h5>

                <ValidatedAddressInput label="Address" name="address" required/>
                <ValidatedSelectInput selectOptions={this.selectOptions} label="Select Property Type" name="propertyType" required/>
                <ValidatedTextInput label="Spaces" placeholder="e.g. 5" name="spaces" required type="number"/>
            </Card>

        );
    }
}