let initialState = {
    location: null
};

const widgetData = (state = initialState, action) => {
    switch (action.type) {
        case "LOCATION_SEARCH_RECEIVED":
            return {
                ...state,
                location: action.location
            };
        default:
            return state
    }
};

export default widgetData;