import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';

export default class DropdownSelect extends Component {

    constructor(props) {
        super(props);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(e) {

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }

        if (this.props.input && this.props.input.onChange) {
            this.props.input.onChange(e.target.value);
        }

    }

    render() {
        const label = this.props.label;
        const helpText = this.props.helpText;
        const value = this.props.value || this.props.input.value;

        const generatedOptions = this.props.selectOptions.map((option) =>
            <option key={option.toString()} value={option}>{option}</option>
        );

        let validationState = null;
        let helpBlock = null;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        return (
            <FormGroup controlId="formControlsSelect" validationState={validationState}>
                <ControlLabel>{label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <span className="help-text">{helpText}</span>
                <FormControl componentClass="select" value={value} placeholder="select" onChange={this.handleSelectChange}>
                    {generatedOptions}
                </FormControl>
                {helpBlock}
            </FormGroup>
        );
    }
}
