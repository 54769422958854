import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import ValidatedTextInput from '../common/ValidatedTextInput';
import ValidatedTextBox from '../common/ValidatedTextBox';
import ValidatedCheckBoxGroup from '../common/ValidatedCheckBoxGroup';
import ValidatedPriceInput from '../common/ValidatedPriceInput';
import campsiteIcon from '../../resources/images/campsite-area-icon.svg';
import campsiteIconAdd from '../../resources/images/campsite-area-add.svg';
import LocationCampsiteAvailabilityRulesController from './LocationCampsiteAvailabilityRulesController';

export default class CampableSites extends Component {

    render() {
        const fields = this.props.fields;

        const siteFeatures = this.props.tags.filter((tag) => (tag.group === "site_feature" && tag.status === "active"));
        const siteTypes = this.props.tags.filter((tag) => (tag.group === "site_type" && tag.status === "active"));

        const error = this.props.error;
        const touched = this.props.touched;


        return (
            <div>
                {fields.map((campsite, index) => {
                        let campsitePlaceholder = "Site Area " + (index + 1);
                        return (
                            <div key={`${campsite}.name`} className="campsite-container">
                                <div className="campsite-header">
                                    <img alt='campsite icon' src={campsiteIcon}/>
                                    <ValidatedTextInput className="form-name" label="Site Name" name={`${campsite}.name`}
                                                        placeholder={campsitePlaceholder}/>
                                </div>
                                <div className="location-campsite inline-card">
                                    <ValidatedTextInput className="valid1ated-text-input" label="Site Name"
                                                        name={`${campsite}.name`} placeholder={campsitePlaceholder}/>
                                    <ValidatedTextInput className="validated-text-input" label="Spaces"
                                                        name={`${campsite}.spaces`} placeholder="e.g 5 "/>
                                    <ValidatedPriceInput className="validated-text-input campsite-price" label="Price per Night"
                                                         name={`${campsite}.price`} placeholder="e.g $35"/>
                                    <div className="campsite-types">
                                        <ValidatedCheckBoxGroup label="Location Type(s)" name={`${campsite}.tags`}
                                                                checkOptions={siteTypes}/>
                                    </div>

                                    <div className="campsite-features">
                                        <ValidatedCheckBoxGroup label="Facilities" name={`${campsite}.tags`}
                                                                checkOptions={siteFeatures}/>
                                    </div>
                                    <h4>Add directions for Guests</h4>
                                    <p>This will be sent to guests after they have booked.</p>
                                    <ValidatedTextBox className="validated-text-input" label="Directions"
                                                      name={`${campsite}.directions`} placeholder="e.g Details"/>
                                    { /* <ValidatedFileInput className="validated-file-input" label="Image of Campsite (optional)" name={`${campsite}.image`}  /> */ }

                                    {(this.props.fields.length !== this.props.location.propertyInformation.sites.length) && (index > this.props.location.propertyInformation.sites.length-1)
                                        ? null
                                        : <LocationCampsiteAvailabilityRulesController name={`${campsite}.availabilityRules`} location={this.props.location}/>}


                                    { index > 0 ?
                                        <div className="remove-campsite">
                                            <a onClick={() => {
                                                if (window.confirm('Are you sure you would like to delete this site?')) {

                                                    if (this.props.location.propertyInformation.sites[index]) {
                                                        this.props.removeSitePressed(index);
                                                    } else {
                                                        fields.remove(index);
                                                    }
                                                }
                                            }}>Remove Site</a>
                                        </div> : null }
                                </div>
                            </div>
                        )
                    }
                )}

                <div className='create-new-campsite'>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: '0px',
                            zIndex:'100'
                        }}
                        onClick={() => fields.push({})}>
                        <img src={campsiteIconAdd} alt='remove' />
                    </button>
            
                    <div className="lower-block">
                        <h3>Add another Site to this Property</h3>
                        <p>If you have more than one notable area on your property where you want Guests to park, you can
                            list each area as a unique site. You might do this if separate areas within your property
                            have different facilities or attributes, or if they require different parking directions for
                            Guests.</p>
                        <Button onClick={() => fields.push({})}>Add Site</Button>{touched && error &&
                    <span>{error}</span>}
                    </div>
                </div>
            </div>
        );
    }
}