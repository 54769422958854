import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';
import {Field} from 'redux-form';

class TextBox extends Component {
    render() {

        let validationState = null;
        let helpBlock;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;


        return (
            <FormGroup validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl componentClass="textarea" rows="5"
                             {...this.props.input}
                             {...inputProps}/>
                {helpBlock}
            </FormGroup>)
    }
}


export default  class ValidatedTextBox extends Component {
    render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }

        return (
            <div className={className}>
                <Field component={TextBox} {...this.props}/>
            </div>
        );
    }
}
