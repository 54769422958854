/**
 * Created by Chris on 27/03/17.
 */

import React, {Component} from 'react';
import LocationBookingCalendarHeader from './LocationBookingCalendarHeader';
import Loading from 'react-loading';
import LocationBookingCalendarTile from './LocationBookingCalendarTile';


export default class LocationBookingCalendar extends Component {

    render() {
        if (!this.props.siteAvailability || !(Object.keys(this.props.siteAvailability).length > 0)) {
            return (
                <div style={{display: 'flex', justifyContent: 'center', height: 50}}>
                    <Loading
                        type='bubbles'
                        color='grey'
                    />
                </div>);
        }

        let formattedMonthYear = this.props.selectedDay.format('MMMM YYYY');


        return (
            <div className="location-booking-calendar-container">
                <div>
                    <LocationBookingCalendarHeader/>
                    <LocationBookingCalendarTile key={formattedMonthYear}
                                                 siteAvailability={this.props.siteAvailability}
                                                 selectedBookings={this.props.selectedBookings}
                                                 campableUserBookings={this.props.campableUserBookings}
                                                 formattedMonthYear={formattedMonthYear}
                                                 location={this.props.location}
                                                 selectedDay={this.props.selectedDay}
                                                 site={this.props.site}
                                                 onBookingTilePress={this.props.onBookingTilePress}/>
                </div>
            </div>
        );
    }
}
