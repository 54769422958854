/**
 * Created by brodynelson on 8/09/17.
 */

import React, {Component} from "react";
import moment from "moment";
import values from "lodash/values";
import AdminBookingsRowItem from "./AdminBookingsRowItem";
import AdminBookingsTransferPanel from "./AdminBookingsTransferPanel";
import AdminBookingsSettingsPanel from "./AdminBookingsSettingsPanel";

export default class AdminBookingsController extends Component {

    constructor(props) {
        super(props);

        this.renderRow = this.renderRow.bind(this);
        this.calculateTransfer = this.calculateTransfer.bind(this);


        this.state = {
            today: moment(),
        }

    }


    calculateTransfer(booking) {
        let affiliate = this.props.affiliate
        let feePercentage = 0;
        let transferAmount = 0;
        if (affiliate && affiliate.referralTerms) {
            feePercentage = affiliate.referralTerms.feePercentage || 0;
            transferAmount = booking.price / 100 * (feePercentage / 100);
        }

        return transferAmount
    }

    renderRow(booking, i) {
        let selected = false;

        this.props.selectedBookings.map((selectedBooking) => {
            if (selectedBooking.uid === booking.uid) {
                selected = true;
            }
        });

        return <AdminBookingsRowItem key={i}
                                     booking={booking}
                                     selected={selected}
                                     affiliate={this.props.affiliate}
                                     calculateTransfer={this.calculateTransfer}
                                     selectBooking={this.props.selectBooking}
                                     unselectBooking={this.props.unselectBooking}
                                />;
    }

    render() {

        return (
            <div>
                <AdminBookingsSettingsPanel
                    selectAllBookings={this.props.selectAllBookings}
                    unselectAllBookings={this.props.unselectAllBookings}
                    fetchBookings={this.props.fetchBookings}
                    filterBookings={this.props.filterBookings}
                />
                {values(this.props.filteredBookings || []).map(this.renderRow)}
                <AdminBookingsTransferPanel
                    selectedBookings={this.props.selectedBookings}
                    calculateTransfer={this.calculateTransfer}
                    isLoading={this.props.isLoading}
                    payAffiliate={()=> {
                        this.props.payAffiliate(this.props.affiliate, this.props.selectedBookings)
                        }
                    }/>
            </div>
        )
    }
}
