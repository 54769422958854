import React, {Component} from 'react'
import {Col, Row} from 'react-bootstrap'
import Dimensions from 'react-dimensions'
import DragNDropItem from './SortableDndItem'
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc';
import RemoveButton from './RemoveButton';


const SortableItem = SortableElement(({value, onRemoveImage}) => {
    return (
        <div className='image-holder'>
            <img alt='gallery' className='main-image' src={value.url}/>
            <RemoveButton className='red-x' onClick={() => {
                if (window.confirm('Are you sure you would like to delete this image?')) {
                    onRemoveImage(value.id)
                }
            }}/>
        </div>)
});


const SortableList = SortableContainer(({items, onRemoveImage}) => {
    return (
        <div>
            {items.map((item, index) =>
                <SortableItem key={`item-${index}`} index={index} value={item} onRemoveImage={onRemoveImage}/>
            )}
        </div>
    );
});

class DndComponentGallery extends Component {

    onSortEnd = ({oldIndex, newIndex}) => {
        const newData = arrayMove(this.props.images, oldIndex, newIndex);
        this.props.onChangeImageOrder(newData)
    }

    removeItem = (image) => {
        this.props.onRemoveImage(image)
    };

    shouldCancelStart = (e) => {
        // Cancel sorting if the event target is an `input`, `textarea`, `select` or `option`
        if (['button', 'textarea', 'select', 'option'].indexOf(e.target.parentNode.tagName.toLowerCase()) !== -1) {
            return true; // Return true to cancel sorting
        }
    };

    render() {

        return (
            <div>
                {this.props.images === undefined ? null :
                    <div className="sortable-image-gallery">
                        <SortableList shouldCancelStart={this.shouldCancelStart} axis='xy' items={this.props.images}
                                      onSortEnd={this.onSortEnd} onRemoveImage={this.props.onRemoveImage}/>
                    </div>
                }
                <Row>
                    <Col md={12}>
                        <DragNDropItem onDrop={this.props.onDrop} sortData='chooser'/>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Dimensions()(DndComponentGallery)
