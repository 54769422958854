import React, {Component} from 'react';
import {Field} from 'redux-form';
import AddressInput from './inputs/AddressInput';

export default class ValidatedAddressInput extends Component {

    render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }



        return (
            <div className={className}>
                <Field {...this.props} component={AddressInput} />
            </div>
        );
    }
}