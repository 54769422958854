import React, {Component} from 'react';
import {Button, Col, Row} from 'react-bootstrap'
import TextInput from './inputs/TextInput';


export default class CampableSearchBar extends Component {

    constructor(props) {
        super(props);
        this.onSearchButtonPressed = this.onSearchButtonPressed.bind(this);

        this.state = {text: ''}
    }

    onChange(e){
        this.setState({
            text: e.target.value
        })
    }

    onSearchButtonPressed() {
        this.props.onSearch(this.state.text);
    }

    render() {

        return (
            <Row className="row-eq-height">
                <Col xs={12} sm={9}>
                    <div className="search-bar">
                        <div className="search-field">
                            <TextInput onChange={this.onChange.bind(this)}/>
                        </div>
                    </div>
                </Col>
                <Col xs={2} sm={3} className="row-centre-vert">
                    <Button  type="submit" onClick={this.onSearchButtonPressed}>Search</Button>
                </Col>
            </Row>)
    }

}

