import React, {Component} from "react";
import {Alert, FormControl, FormGroup, HelpBlock} from "react-bootstrap";

export default class PasswordInput extends Component {

    constructor(props) {
        super(props);

        this.inputChange = this.inputChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
        this.onEnter = this.onEnter.bind(this);
    }

    inputChange(e) {
        this.props.onChange(e.target.value);
    }

    onKeyPress(event) {
        if (this.props.onKeyPress) {
            this.props.onKeyPress(event);
        }

        if (event.key === 'Enter') {
            this.onEnter();
        }
    }

    onKeyDown(event) {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    }

    focus() {
        this.inputRef.focus()
    }

    onEnter() {
        if (this.props.onEnter) {
            this.props.onEnter();
        }
    }


    render() {


        const alertInstance = (
            <Alert bsStyle="danger">This is a required field</Alert>
        );


        let validationState = null;
        let helpBlock = null;
        let required = null;


        if (this.props.meta && this.props.meta.touched && this.props.meta.error) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        if (this.props.required) {
            let fieldValidated = null;
            if (!this.props.fieldValidated) {
                fieldValidated = <span className="form-alert-help">{alertInstance}</span>;
            }
            required = (
                <span>
                    {fieldValidated}
                </span>);
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;
        delete inputProps.onEnter;
        delete inputProps.hideAlert;

        return (
            <FormGroup readOnly validationState={validationState}>
                <label>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</label>
                <FormControl type="password"
                             {...this.props.input}
                             {...inputProps}
                             inputRef={(ref) => {
                                 this.inputRef = ref;
                             }}
                             onKeyPress={this.onKeyPress}
                             onChange={this.inputChange}/>
                {this.props.required && !this.props.hideAlert ?
                    <span>
		            	{ this.props.fieldValidated ? null :
                            <span className="form-alert-help">{alertInstance}</span> }
	            	</span>
                    : null}
                {helpBlock}
            </FormGroup>
        );
    }
}