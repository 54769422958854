const data = (state = {
    pages: null,
    widgets: null
}, action) => {

    switch (action.type) {

        case 'PAGE_RECEIVED':
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [action.page.slug]: action.page
                }
            }

        case 'WIDGET_RECEIVED':
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [action.widget.slug]: action.widget
                }
            }
        case 'COLLECTION_PAGE_RECEIVED':

            const collectionPages = {};
            if(state.collectionPages) {
                Object.values(state.collectionPages).map((page) => {
                    collectionPages[page.slug] = page;
                });
            }

            collectionPages[action.collectionPage.slug] = action.collectionPage;

            return {
                ...state,
                collectionPages: collectionPages
            }
        default:
            return state
    }
}

export default data