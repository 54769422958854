import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import LocationsController from '../components/locations/LocationsController';
import {fetchProperty, fetchUserProperties} from '../actions';
import {browserHistory} from 'react-router';
import Load from '../components/Load';
import MenuContainer from './MenuContainer';
import {Col, Grid, Row} from 'react-bootstrap';
import {HOSTING_LOCATIONS_ID} from "../constants/Routes";
import values from "lodash/values";

class HostLocationsContainer extends Component {

    constructor(props) {
        super(props);
        this.locationPressed = this.locationPressed.bind(this);
    }

    componentDidMount() {
        if (this.props.campableUser) {
            this.props.dispatch(fetchUserProperties());
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.campableUser) {
            if (!this.props.userProperties) {
                this.props.dispatch(fetchUserProperties());
            }
        }
    }


    locationPressed(id) {
        this.props.dispatch(fetchProperty(id));
        browserHistory.push(HOSTING_LOCATIONS_ID + id);
    }

    render() {

        let renderScene = (
            <div className="loaderContainer">
                <Load/>
            </div>);
        if (this.props.userProperties) {
            renderScene = (
                <LocationsController
                    locations={this.props.locations}
                    campableUser={this.props.campableUser}
                    dispatch={this.props.dispatch}
                    locationPressed={this.locationPressed}/>
            );
        }

        return (
            <Grid className="main-container">
                <Row>
                    <h1>My Properties</h1>
                    <Col md={3} className="menu">
                        <MenuContainer currentRoute={this.props.route.path}/>
                    </Col>
                    <Col md={9}>
                        {renderScene}
                    </Col>
                </Row>
            </Grid>
        );
    }
}


export default HostLocationsContainer = connect((state) => {
    return {
        userProperties: state.data.userProperties,
        locations: values(state.data.properties || {}),
        campableUser: state.data.campableUser
    }
})(HostLocationsContainer)
