import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Pager} from "react-bootstrap";
import Card from "../Card";
import {fireTagEvent} from "../../tagmanager";
import {browserHistory} from "react-router";
import {BECOME_A_HOST, HOSTING_CREATE_A_PROPERTY} from "../../constants/Routes";

class CreatePropertyController extends Component {

    constructor(props) {
        super(props);
    }

    createProperty = () => {
        fireTagEvent("create-a-property", this.props.campableUser);
        if (this.props.campableUser && this.props.campableUser.accountInformation && this.props.campableUser.accountInformation.valid) {
            browserHistory.push(HOSTING_CREATE_A_PROPERTY);
        } else {
            browserHistory.push(BECOME_A_HOST);
        }
    }

    render() {
        return (
            <Card title="Create Property" className="account-details-container">
                <Button onClick={this.createProperty}>Create Property</Button>
                <Pager>
                    <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
                </Pager>
            </Card>
        );
    }
}

export default CreatePropertyController = connect((state) => {
    return {
        initialValues: {
            firstName: state.data.campableUser.firstName,
            lastName: state.data.campableUser.lastName,
            phone: state.data.campableUser.phone
        },
        campableUser: state.data.campableUser
    }
})(CreatePropertyController);

