import React, {Component} from "react";
import {Button, Checkbox, Row} from 'react-bootstrap';
import '../../App.css';
import {DateRangePicker} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "airbnb-browser-shims";
import moment from "moment";

export default class AdminBookingsSettingsPanel extends Component {

    constructor(props) {
        super(props);
        let startDate = moment().subtract(1, 'months');
        let endDate = moment();

        this.state = {
            selected: this.props.selected,
            focused: null,
            startDate:startDate,
            endDate:endDate,
            showPaid: false
    };
        this.handleChange = this.handleChange.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChanged = this.onFocusChanged.bind(this);
        this.getBookings = this.getBookings.bind(this);


    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selected: nextProps.selected });
    }

    onDatesChange({ startDate, endDate }) {
        this.setState({ startDate, endDate });

    }

    getBookings() {
        let start = moment(this.state.startDate).format('YYYY-MM-DD');
        let end = moment(this.state.endDate).format('YYYY-MM-DD');
        this.props.fetchBookings(start,end);
    }


    onFocusChanged(focused) {
        console.log("onfocus",focused);
        this.setState({
            focused:focused
        });
    }


    handleChange(event) {
        this.setState({ selected: event.target.checked });
    }


    render() {


        return (

            <Row className="location-panel">
                <h3>Search Affiliate Bookings</h3>

                <div style={{marginRight:"10px", display:"inline-block"}}>
                    <DateRangePicker
                        focusedInput={this.state.focused}
                        onFocusChange={this.onFocusChanged}
                        displayFormat="DD MMM YYYY"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        numberOfMonths={1}
                        onDatesChange={this.onDatesChange}
                        isOutsideRange={() => false}
                        isDayBlocked={()=>false}
                    />
                </div>

                <p className="admin-block-button"><Button onClick={this.getBookings}>Get Bookings</Button></p>
                <div className="admin-settings-panel">
                    <a onClick={this.props.selectAllBookings}>Select All </a>
                    <a onClick={this.props.unselectAllBookings}>Unselect All</a>

                    <Checkbox className="admin-checkbox"
                              checked={this.state.showPaid}
                              onChange={() => {
                                  this.setState({
                                      showPaid: !this.state.showPaid
                                  }, () => {this.props.filterBookings(this.state.showPaid)});
                              }} >Show Paid</Checkbox>

                </div>


            </Row> );

    }

}