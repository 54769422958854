import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';
import CollectionBlock from './CollectionBlock';
import ImageBlock from './ImageBlock';
import ImageBlockWithButton from './ImageBlockWithButton';
import ListBlock from './ListBlock';
import TitleBlock from './TitleBlock';
import HostBanner from './HostBanner';
import CallToActionBanner from './CallToActionBanner';
import CallToActionPageEnd from './CallToActionPageEnd';
import HostHomepageCallToActionBanner from './HostHomepageCallToActionBanner';
import Divider from './Divider';
import CallToActionBecomeAHost from './CallToActionBecomeAHost';
import TextImageBlock from './TextImageBlock'
import CollectionListingsBlock from './CollectionListingsBlock'
import CollectionTileView from './CollectionTileView';
import scrollToElement from "scroll-to-element"
import {withRouter} from "react-router";

class Block extends Component {

    onScrollToHash = () => {
        const hash = this.props.router.location.hash;
        if(hash) {
            scrollToElement(hash, { offset: -120 })
        }
    };

    componentDidMount(){
        this.onScrollToHash()
    }

    render() {
        switch (this.props.block._type) {
            case "textBlock":
                return <ReactMarkdown source={this.props.block.content}
                                      renderers={{
                                          heading: (props) => {
                                              const text = props.children[0];
                                              let id = null;
                                              if (typeof (text) === "string") {
                                                  id = text.toLowerCase().replace(" ", "-");
                                              }

                                              const Component = `h${props.level}`;

                                              return (
                                                  <Component id={id}>{text}</Component>
                                              );
                                          },
                                      }}
                />;
            case "collectionBlock":
                return <CollectionBlock collection={this.props.block}/>;
            case "listingsBlock":
                return <CollectionListingsBlock block={this.props.block}/>;
            case "imageBlock":
                return <ImageBlock block={this.props.block}/>;
            case "imageBlockWithButton":
                return <ImageBlockWithButton block={this.props.block}/>;
            case "listBlock":
                return <ListBlock content={this.props.block}/>;
            case "titleBlock":
                return <TitleBlock content={this.props.block}/>;
            case "hostBanner":
                return <HostBanner content={this.props.block}/>;
            case "callToActionBanner":
                return <CallToActionBanner content={this.props.block} showLoginOverlay={this.props.showLoginOverlay}
                                           isLoggedIn={this.props.isLoggedIn}/>;
            case "callToActionPageEndhostHomepageCallToActionBanner":
                return <CallToActionPageEnd content={this.props.block}/>;
            case "hostHomepageCallToActionBanner":
                return <HostHomepageCallToActionBanner content={this.props.block}/>;
            case "ctaBecomeAHost":
                return <CallToActionBecomeAHost content={this.props.block}/>;
            case "divider":
                return <Divider/>;
            case "textImageBlock":
                return <TextImageBlock content={this.props.block}/>;
            case "collectionGroupBlock":
                return <CollectionTileView collectionPages={this.props.block.collectionPages}/>;
            case "videoBlock":
                return <iframe width={600} height={300} src={"https:" + this.props.block.videoContent.url}/>;
            default:
                return null;
        }
    }
}

export default Block = withRouter(Block);