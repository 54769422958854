import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import Load from '../components/Load';
import {Col, Grid, Row} from 'react-bootstrap';
import MenuContainer from './MenuContainer';
import {getTransactions, resetTransactions} from "../actions/index";

import HostTransactionsController from '../components/hostTransactions/HostTransactionsController';
import {getAccountBalance} from "../actions";

class HostTransactionsContainer extends Component {

    constructor(props) {
        super(props);

        this.onNextPageClick = this.onNextPageClick.bind(this);

        this.state = {
            accountBalance: null,
        };

    }


    componentDidMount() {
        if (!!this.props.campableUser) {
            this.props.dispatch(getTransactions());
            this.props.dispatch(getAccountBalance(this.props.campableUser.id, this.onBalanceSuccess));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!!nextProps.campableUser && !this.props.campableUser) {
            nextProps.dispatch(getTransactions());
            nextProps.dispatch(getAccountBalance(nextProps.campableUser.id, this.onBalanceSuccess));
        }
    }

    onBalanceSuccess = (accountBalance) => {
        console.log("accountBalanceaccountBalance", accountBalance)

        this.setState({
            accountBalance: accountBalance,
        });
    };

    onNextPageClick(cursor) {
        this.props.dispatch(getTransactions(cursor));
    }

    componentWillUnmount() {
        this.props.dispatch(resetTransactions())
    }

    render() {
        let renderScene = (
            <div className="loaderContainer">
                <Load/>
            </div>
        );

        if (!!this.props.transactions) {
            renderScene = (
                <HostTransactionsController
                    accountBalance={this.state.accountBalance}
                    transactions={this.props.transactions}
                    onNextPageClick={this.onNextPageClick}
                />);
        }

        return (
            <Grid className="host-transactions-container">
                <Row>
                    <h1>Transactions</h1>
                    <Col md={3} className="menu"><MenuContainer currentRoute={this.props.route.path}/></Col>
                    <Col md={9}>
                        {renderScene}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default HostTransactionsContainer = connect((state) => {
    return {
        transactions: state.data.transactions,
        campableUser: state.data.campableUser
    }
})(HostTransactionsContainer);