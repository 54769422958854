import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {connect} from "react-redux";
import Load from "../components/Load";

class AccountVerificationFailureContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return this.props.requirements.link ? (
            <div>
                <div style={{textAlign: 'center'}}>
                    <h4>{"Oops! It looks like something went wrong."}</h4>
                    <br/>
                    <a href={this.props.requirements.link}>
                        <Button>Try again</Button>
                    </a>
                </div>
            </div>
        ) : <Load/>;
    }
}

export default AccountVerificationFailureContainer = connect((state) => {
    return {
        requirements: state.data.requirements || {},
    }
})(AccountVerificationFailureContainer);
