/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 11/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/




export function toProperCase(str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}


