/**USER ACTIONS **/
import * as firebase from "firebase";
import * as api from "../api/api";
import {addReferralCodeAPI, createUserInDatabase, getProperty, getUserProperties} from "../api/api";
import {browserHistory} from "react-router";
import {HOSTING_LOCATIONS_ID, ONBOARDING} from "../constants/Routes";
import {fireTagEvent, sendECommercePurchase} from "../tagmanager";
import {hideLoginOverlay} from "./overlay";

export const LOCATION_FILTER_RECEIVED = 'LOCATION_FILTER_RECEIVED';

export function setLocationFilter(locationFilter) {
    return {
        type: LOCATION_FILTER_RECEIVED,
        locationFilter: locationFilter,
    }
}

export function loginFirebaseUser(user) {
    return (dispatch) => {
        dispatch(login(user));
    }
}

export function getCampableUser() {
    return (dispatch) => {
        api.getCampableUser().then((user) => {
            dispatch(campableUserReceived(user));
        }).catch(error => {
            //console.log(error.code, error.message);
            dispatch(onError(error))
        });
    }
}

export function getCampableUserBookings(fromDate) {
    return (dispatch) => {
        api.getCampableUserBookingsAPI(fromDate).then((bookings) => {
            dispatch(campableUserBookingsReceived(bookings.items));
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(onError(error))
        });
    }
}

export function createUserBookings(locationId, bookings) {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(showAlert('warning', 'Creating booking!', ''));
        api.createBookingsAPI(locationId, bookings).then((r) => {
            dispatch(showAlert('success', 'You\'re all booked.', 'Your booking(s) were successfully created..'));
            sendECommercePurchase(r);
            dispatch(campableUserBookingsReceived(r));
            dispatch(clearSelectedBookings());
            dispatch(loading(false));
            dispatch(showBookingConfirmedModal(true));
            browserHistory.push('/bookings');
            //console.log('user hostBookings successfully created', r);
        }).catch((err) => {
            console.log(err)
            dispatch(showAlert('danger', 'We encountered a problem', err.message));
            dispatch(onError(err));
            dispatch(loading(false));
        });
    }
}

export function setLanguage(language) {
    return {
        type: 'SET_LANGUAGE',
        language: language
    }
}

export function showBookingConfirmedModal(show) {
    return {
        type: 'SHOW_BOOKING_CONFIRM_MODAL',
        showConfirmationModal: show,
    }
}

export function campableUserBookingsReceived(bookings) {
    return {
        type: 'USER_BOOKINGS_RECEIVED',
        hostBookings: bookings,
    }
}

export function updateCampableUser(user, onSuccess, isAdmin) {
    return (dispatch) => {
        dispatch(loading(true));
        api.updateCampableUser(user).then((campableUser) => {
            //console.log("action campable user updated:", userResponse);
            if (!isAdmin) {
                dispatch(campableUserReceived(campableUser));
            }
            dispatch(loading(false));
            dispatch(showAlert('success', 'Account details successfully updated', ''))
            !!onSuccess && onSuccess(campableUser);
        }).catch((err) => {
            dispatch(showAlert('danger', 'There was an error while updating your account details.', err.message));
            dispatch(onError(err));
            dispatch(loading(false));
        });
    }
}

export function updateCampableUserWithEmail(newUserDetails, password, onSuccess) {
    return (dispatch) => {
        let user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );
        user.reauthenticate(credential).then(() => {
            user.updateEmail(newUserDetails.email).then((e) => {
                dispatch(sendVerificationEmail())
                api.updateCampableUser(newUserDetails).then((campableUser) => {
                    //console.log("action campable user updated:", userResponse);
                    dispatch(campableUserReceived(campableUser));
                    dispatch(loading(false));
                    !!onSuccess && onSuccess(campableUser);
                }).catch((err) => {
                    dispatch(showAlert('danger', 'There was an error while updating your account details.', 'If this problem persists please contact Campable support.'));
                    dispatch(onError(err));
                    dispatch(loading(false));
                });
            }).catch((err) => {
                dispatch(showAlert("danger", "Failed updating account details", "Please try again.  If this problem persists please contact Campable support"));
                dispatch(onError(err));
                dispatch(loading(false));
            })
        }).catch((err) => {
            dispatch(showAlert("danger", "Failed updating account details", "Please check your password and try again."));
            dispatch(onError(err));
            dispatch(loading(false));
        })
    }
}

export function updateUserEmailAdmin(userId, newEmail, onSuccess) {
    return (dispatch) => {
        // dispatch(showAlert("warning", "Updating users email!"));
        api.updateUserEmailAdmin(userId, newEmail).then((r) => {
            dispatch(adminUserReceived(r));
            dispatch(showAlert("success", "Updated user email successfully."))
            !!onSuccess && onSuccess(r);
        }).catch((err) => {
            dispatch(showAlert("danger", "Failed to update users email!", JSON.stringify(err)))
            console.log("ERRRRR", err)
        });
    }
}

export function updateCampableUserCreditCard(creditCardInfo) {
    return (dispatch) => {
        let user = firebase.auth().currentUser;
        api.updateCampableUser(user, creditCardInfo).then((userResponse) => {
            dispatch(campableUserReceived(userResponse));
            dispatch(loading(false));
            dispatch(showAlert('success', 'Credit card successfully updated', ''))
        }).catch((err) => {
            dispatch(showAlert('danger', 'There was an error while updating your credit card.', 'If this problem persists please contact Campable support. ERROR:' + err.message));
            dispatch(loading(false));
        });
    }
}

export function loginPressed(email, password) {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(onError());
        firebase.auth().signInWithEmailAndPassword(email, password).then((user) => {
            dispatch(pwReset(false));
            dispatch(login(user));
            dispatch(getTags());
            api.getCampableUser().then((user) => {
                dispatch(hideLoginOverlay());
                dispatch(campableUserReceived(user));
            }).catch((error) => {
                dispatch(onError(error))
            });
        }).catch((error) => {
            dispatch(loading(false));
            dispatch(onError(error))
        })
    }
}

export function socialLogin(socialProvider, referrer) {
    let provider = '';
    if (socialProvider === 'google') {
        provider = new firebase.auth.GoogleAuthProvider();
    } else if (socialProvider === 'facebook') {
        provider = new firebase.auth.FacebookAuthProvider();
    }

    return (dispatch) => {
        dispatch(loading(true));
        dispatch(onError());
        firebase.auth().signInWithPopup(provider).then((user) => {
            createUserInDatabase().then((campableUser) => {
                if (referrer !== undefined) {
                    addReferralCodeAPI(campableUser.id, referrer);//fire and forget response doesn't matter
                }
                dispatch(loading(false));
                dispatch(pwReset(false));
                dispatch(login(user));
                dispatch(getTags());
                dispatch(campableUserReceived(campableUser));
                dispatch(hideLoginOverlay());
                browserHistory.push(ONBOARDING)
            }).catch((error) => {
                console.log(error);
                dispatch(onCampableResponseError(error, user));
            });
        }).catch((error) => {
            dispatch(loading(false));
            dispatch(onError(error));
        })
    }
}

export function onCampableResponseError(error, user) {
    return (dispatch) => {
        if (error.code === 'user_exists') {
            dispatch(loading(false));
            dispatch(pwReset(false));
            dispatch(login(user));
            dispatch(getTags());
            api.getCampableUser().then((user) => {
                dispatch(hideLoginOverlay());
                dispatch(campableUserReceived(user));
            }).catch(error => {
                console.log(error.code, error.message);
                dispatch(onError(error))
            });
        } else {
            dispatch(loading(false));
            dispatch(onError(error));
        }
    }
}

export function signUpPressed(email, password, referrer) {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(onError());
        firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
            createUserInDatabase().then((campableUser) => {
                if (referrer !== undefined) {
                    addReferralCodeAPI(campableUser.id, referrer);//fire and forget response doesn't matter
                }
                dispatch(loading(false));
                dispatch(pwReset(false));
                dispatch(login(user));
                dispatch(getTags());
                dispatch(campableUserReceived(campableUser));
                fireTagEvent("signup", campableUser, {"success": true});
                dispatch(hideLoginOverlay());
                browserHistory.push(ONBOARDING)
            }).then(() => {
                dispatch(sendVerificationEmail())
            }).catch((error) => {
                dispatch(onCampableResponseError(error));
            })
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(loading(false));
            dispatch(onError(error))
        })
    }
}

export function sendVerificationEmail() {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(showAlert("success", "Sending email verification.", ""));
        let user = firebase.auth().currentUser;
        if (user) {
            user.sendEmailVerification().then((r) => {
                dispatch(loading(false));
                dispatch(showAlert("success", "Email verification sent.", "Please check your email inbox and click on the verification link to activate your account."));
            }).catch((err) => {
                dispatch(loading(false));
                dispatch(showAlert("danger", "Sending email verification failed.", ""));
                //console.log('send verification email ERROR', err)
            });
        } else {

            dispatch(showAlert("danger", "Sending email verification failed.", "No current user to send email too."));
        }
    }
}

export function pwReset(bool) {
    return {
        type: 'PASSWORD_RESET',
        passwordReset: bool
    }
}

export function setAffliateCode(code) {
    return {
        type: 'SET_AFFILIATE',
        affiliateCode: code
    }
}

export function passwordResetPressed(email) {
    return (dispatch) => {
        firebase.auth().sendPasswordResetEmail(email).then((r) => {
            //console.log('password reset email sent', r);
            dispatch(pwReset(true));
        }).catch((error) => {
            //console.log(error.code, error.message)
        });
    }
}

export function logout() {
    return {
        type: 'LOG_OUT'
    }
}

export function logoutPressed() {
    return (dispatch) => {
        dispatch(loading(true));
        firebase.auth().signOut().then(() => {
            dispatch(loading(false));
            dispatch(logout());
            browserHistory.push('/')
        }).catch((error) => (console.log('ERROR', error)));
    }
}


/** LOCATIONS **/
export function locationPressed(id) {
    browserHistory.push(HOSTING_LOCATIONS_ID + id);
}

export function getLocations(params) {
    return (dispatch) => {
        api.getLocations(params).then(locations => {
            dispatch(locationsReceived(locations));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function adminGetLocations(params) {
    return (dispatch) => {
        api.adminGetLocations(params).then(locations => {
            dispatch(locationsReceived(locations));
        }).catch(error => {
            console.log('error:', error);
            dispatch(showAlert('danger', 'There was an error fetching locations.', 'Error: ' + error.message));
        });
    }
}

export function getTransactions(cursor) {
    return (dispatch) => {
        api.getTransactions(cursor).then((transactions) => {
            dispatch(transactionsReceived(transactions));
        }).catch(error => {
            console.log('error', error);
        });
    }
}

export function getAccountBalance(userId, onSuccess = () => null, onError = () => null) {
    return () => {
        api.getAccountBalance(userId).then((accountBalance) => {
            onSuccess(accountBalance);
        }).catch((err) => {
            onError(err);
        });
    }
}


export function getCampableUserReviews() {
    return (dispatch) => {
        api.getCampableUserReviewsAPI().then((reviews) => {
            dispatch(campableUserReviewsReceived(reviews.items));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function createReview(locId, rating, comment) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Creating review!', ''));
        api.createReviewAPI(locId, rating, comment).then((review) => {
            dispatch(showAlert('success', 'Review was successfully created!', ''));
            dispatch(campableUserReviewsReceived([review]));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function campableUserReviewsReceived(reviews) {
    return {
        type: 'USER_REVIEWS_RECEIVED',
        reviews: reviews,
    }
}

export function clearReviews() {
    return {
        type: 'USER_REVIEWS_CLEAR',
    }
}

export function getLocation(id) {
    return (dispatch) => {
        api.getLocation(id).then((location) => {
            dispatch(locationReceived(location));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}


export function fetchUserProperties() {
    return (dispatch) => {
        getUserProperties().then((properties) => {

            dispatch(propertiesReceived(properties));
            properties.map((prop) => {
                dispatch(propertyReceived(prop));
                return null;
            });

            dispatch(loading(false));
        }).catch(error => {
            console.log('error:', error);
            dispatch(loading(false));
        });
    }
}

export function createProperty(data, pushLink) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Creating your Property...', ''));
        api.createProperty(data).then(property => {
            dispatch(propertyReceived(property));
            dispatch(showAlert('success', 'Success!', 'Your property was saved successfully'));
            dispatch(fetchUserProperties());
            if(pushLink){
                browserHistory.push(pushLink + property.id + "/details");
            }
            else {
                browserHistory.push(HOSTING_LOCATIONS_ID + property.id);
            }
        }).catch((error) => {
            dispatch(showAlert('danger', 'Creating your property was unsuccessful', ''));
            console.log('error:', error);
        });
    }
}

export function updatePropertyStatus(propertyId, status) {
    return (dispatch) => {
        api.updatePropertyStatus(propertyId, status).then((property) => {
            dispatch(showAlert('success', 'Success!', 'Your property status was updated successfully'));
            dispatch(propertyReceived(property));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function updateProperty(property) {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch(showAlert("warning", "Saving property updates...", ""));
        api.updateProperty(property).then((property) => {
            dispatch(propertyReceived(property));
            dispatch(showAlert("success", "Property Updated", "Your property was updated successfully"));
        }).catch((error) => {
            dispatch(showAlert("danger", "Property could no be updated", "Your property could not be updated due to an error: " + error.message));
        });
    }
}

export function fetchProperty(id) {
    return (dispatch) => {
        getProperty(id).then((property) => {
            dispatch(propertyReceived(property));
        }).catch((error) => {
            console.log('error:', error);
        });
    }
}


/** BOOKINGS **/

export function cancelHostBooking(uid) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Cancelling booking!', ''));
        api.cancelBooking(uid).then((booking) => {
            dispatch(hostBookingsReceived([booking]));
            dispatch(showAlert('success', 'Booking successfully cancelled!', ''));
        }).catch((error) => {
            console.log('error:', error);
        });
    }
}

export function cancelCampableUserBooking(uid) {
    return (dispatch) => {
        api.cancelBooking(uid).then((bookings) => {
            dispatch(campableUserBookingsReceived([bookings]));
            dispatch(showAlert('success', 'Booking successfully cancelled!', ''));
        }).catch((error) => {
            console.log('error:', error);
        });
    }
}

export function fetchHostBookings(locationId, ownerId, cursor) {
    return (dispatch) => {
        api.getHostBookings(locationId, ownerId, cursor).then((bookings) => {
            dispatch(loading(false));
            dispatch(hostBookingsReceived(bookings.items));
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function getLocationSiteAvailabilities(locId, from, to) {
    return (dispatch) => {
        api.getLocationSiteAvailabilitiesAPI(locId, from, to).then((siteAvailabilities) => {
            dispatch(loading(false));
            dispatch(siteAvailabilityReceived(siteAvailabilities))
        }).catch((error) => {
            console.log('error:', error);
            dispatch(loading(false));
        });
    }
}

export function siteAvailabilityReceived(siteAvailabilities) {
    return {
        type: 'SITE_AVAILABILITY_RECEIVED',
        siteAvailabilities: siteAvailabilities,
    }
}

export function clearSiteAvailability() {
    return {
        type: 'SITE_AVAILABILITY_CLEAR',
    }
}

export function selectedBookingOnPress(location, site, day, price) {
    return {
        type: 'SELECTED_BOOKINGS_ONPRESS',
        location: location,
        site: site,
        day: day,
        price: price,
    }
}

export function clearSelectedBookings() {
    return {
        type: 'SELECTED_BOOKINGS_CLEAR',
    }
}


export function getTags() {
    return (dispatch) => {
        api.getTags().then((tags) => {
            dispatch(tagsReceived(tags));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function createTag(tag) {
    return (dispatch) => {
        dispatch(showAlert('info', 'Creating tag...', 'Your tag is being created.'));
        api.createTag(tag)
            .then((tag) => {
                dispatch(showAlert('success', 'Success!', 'Your tag has been created'));
                dispatch(getTags);
            }).catch((error) => {
            dispatch(showAlert('danger', 'There was an error while creating your tag.', 'Error: ' + error.message));
            dispatch(onError(error));
        });
    }
}

/** STRIPE **/


export function createStripeAccount(account, bankDetails) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Checking Bank details...', 'Your bank details are being verified.'));
        api.getStripeBankToken(bankDetails).then((token) => {
            account.token = token.id;
            account.timeZone = "Pacific/Auckland";
            return api.createStripeAccount(account)
        }).then((user) => {
            dispatch(showAlert('success', 'Success!', 'Your account has been created'));
            dispatch(campableUserReceived(user));
        }).catch((error) => {
            dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + error.message));
            dispatch(onError(error));
        });
    }
}


export function updateStripeAccount(account, bankDetails) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Updating Host details...', 'Your bank details are being verified.'));
        api.getStripeBankToken(bankDetails)
            .then((token) => {
                account.token = token.id;
                account.timeZone = "Pacific/Auckland";
                return api.updateStripeAccount(account)
            }).then((user) => {
            dispatch(showAlert('success', 'Success!', 'Your account has been updated'));
            dispatch(campableUserReceived(user));
        }).catch((error) => {
            dispatch(showAlert('danger', 'There was an error while creating your account.', 'Error: ' + error.message));
            dispatch(onError(error));
        });
    }
}


export function updateStripeCode(code) {
    return (dispatch) => {
        //console.log("update stripe code");
        //console.log(code);
        api.updateStripeCode(code)
            .then(user => {
                //console.log('user', user);
                dispatch(campableUserReceived(user));
                dispatch(loading(false));
            }).catch(error => {
            console.log('error:', error);
            dispatch(loading(false));
        });
    }
}


/** NOTIFCATIONS **/

export function displayNotification(notification) {
    return (dispatch) => {
        dispatch(notificationReceived(notification));
    }
}

export function removeNotification(notification) {
    return (dispatch) => {
        dispatch(removeNotificationReceived(notification));
    }
}

export function showAlert(type, headline, message) {
    return (dispatch) => {
        let alert =
            {
                id: (new Date()).getTime(),
                type: type,
                headline: headline,
                message: message
            };
        dispatch(notificationReceived(alert));
    }
}

/** CONTENT **/

export function getPage(slug) {
    return async (dispatch) => {
        try {
            const page = await api.getPage(slug);
            return dispatch(pageReceived(page));
        } catch (error) {
            console.log('error:', error);
        }
    }
}

export function getWidget(slug) {
    return (dispatch) => {
        api.getWidget(slug)
            .then(widget => {
                dispatch(widgetReceived(widget));
            }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function getCollectionPage(slug) {
    return (dispatch) => {
        api.getCollectionPage(slug)
            .then(page => {
                dispatch(collectionPageReceived(page));
            }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function getCollections() {
    return (dispatch) => {
        api.getCollections()
            .then(collections => {
                dispatch(collectionsReceived(collections));
            }).catch(error => {
            console.log('error:', error);
        });
    }
}

function collectionsReceived(collections) {
    return {
        type: 'COLLECTIONS_RECEIVED',
        collections: collections
    };
}

export function getCollectionPages() {
    return (dispatch) => {
        api.getCollectionPages().then((r) => {
            dispatch(collectionPagesReceived(r));
        }).catch((err) => {
            console.log('error:', err);
        });
    };
}

export function collectionPagesReceived(collectionPages) {
    return {
        type: 'COLLECTION_PAGES_RECEIVED',
        collectionPages: collectionPages.items,
    }
}

/** ADMIN **/

export function adminCreateCampaign(values) {
    return (dispatch) => {
        api.createCampaign(values).then((result) => {
            dispatch(adminCampaignReceived(result));
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(onError(error))
            dispatch(displayNotification(error));
        });
    }
}

export function adminUpdateCampaign(id, values) {
    return (dispatch) => {
        api.updateCampaign(id, values).then((result) => {
            dispatch(adminCampaignReceived(result));
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(onError(error))
            dispatch(displayNotification(error));
        });
    }
}


export function adminFetchPropertyBookings(propertyId, ownerId, cursor) {
    return (dispatch) => {
        api.getHostBookings(propertyId, ownerId, cursor).then((result) => {
            dispatch(adminPropertyBookingsReceived(propertyId, result));
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(onError(error))
        });
    }
}

export function adminFetchUserBookings(userId, cursor) {
    return (dispatch) => {
        api.adminFetchUserBookings(userId, cursor).then((result) => {
            dispatch(adminUserBookingsReceived(userId, result));
        }).catch((error) => {
            console.log(error.code, error.message);
            dispatch(onError(error))
        });
    }
}

export function adminFetchUserProperties(userId) {
    return (dispatch) => {
        api.getUserProperties(userId).then(result => {
            dispatch(adminUserlocationsReceived(userId, result));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function adminFetchCampaigns(type, code, searchLimit, cursor) {
    return (dispatch) => {
        dispatch(loading(true));
        api.adminFetchCampaigns(type, code, searchLimit, cursor).then((result) => {
            dispatch(loading(false));
            dispatch(adminCampaignsReceived(searchLimit, result));
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function adminFetchUsers(search, cursor) {
    return (dispatch) => {
        dispatch(loading(true));
        api.adminFetchUsers(search, cursor).then((result) => {
            dispatch(loading(false));
            dispatch(adminUsersReceived(result));
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function adminFetchAffiliateUsers(id, cursor) {
    return (dispatch) => {
        dispatch(loading(true));
        api.adminFetchAffiliateUsers(id, cursor).then((result) => {
            dispatch(adminAffiliateUsersReceived(result));
            dispatch(loading(false));
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function adminFetchAffiliateBookings(referrer, fromDate, toDate, cursor, clearResults) {
    return (dispatch) => {
        dispatch(loading(true));
        api.adminFetchAffiliateBookings(referrer, fromDate, toDate, cursor).then((result) => {
            dispatch(loading(false));
            dispatch(adminAffiliateBookingsReceived(result, referrer, clearResults));

            if ((result) && (result.cursor) && (result.total === 50)) {
                //more results to fetch
                dispatch(adminFetchAffiliateBookings(referrer, fromDate, toDate, result.cursor, false));
            }
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function adminCreateAffiliateTransfer(referrer, bookings) {
    return (dispatch) => {
        dispatch(loading(true));
        return api.adminCreateAffiliateTransfer(referrer, bookings).then((result) => {
            dispatch(loading(false));
            dispatch(showAlert("success", "Success", "Transfer complete"));
            dispatch(adminAffiliateTransferReceived(result, referrer));
        }).catch((error) => {
            dispatch(loading(false));
            dispatch(showAlert("danger", "Error completing transfer", error.message));
            throw error;
        });
    }
}

export function adminFetchUser(id) {
    return (dispatch) => {
        api.adminFetchUser(id)
            .then(user => {
                dispatch(adminUserReceived(user));
            }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function adminFetchAffiliates() {
    return (dispatch) => {
        dispatch(loading(true));
        api.adminFetchAffiliates().then((result) => {
            dispatch(loading(false));
            dispatch(adminAffiliatesReceived(result));
        }).catch((error) => {
            dispatch(loading(false));
        });
    }
}

export function adminAddAffiliateToUser(userId, affiliateEmail, onSuccess, onError) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Updating user affiliate...', 'User affiliate is being added.'));
        api.adminAddAffiliateToUserAPI(userId, affiliateEmail).then((user) => {
            dispatch(showAlert("success", "Success", "Affiliate added to user"));
            dispatch(adminUserReceived(user));
            !!onSuccess && onSuccess(user);
        }).catch((err) => {
            dispatch(showAlert("danger", "Error adding affiliate to user", err.message));
            console.log("err", err);
            !!onError && onError(err);
        });
    }
}

export function adminRemoveAffiliatefromUser(userId, onSuccess, onError) {
    return (dispatch) => {
        dispatch(showAlert('warning', 'Updating user affiliate...', 'User affiliate is being added.'));
        api.adminRemoveAffiliatefromUserAPI(userId).then((user) => {
            dispatch(showAlert("success", "Success", "Affiliate removed from user"));
            dispatch(adminUserReceived(user));
            !!onSuccess && onSuccess(user);
        }).catch((err) => {
            dispatch(showAlert("danger", "Error removing affiliate from user", err.message));
            console.log("err", err);
            !!onError && onError(err);
        });
    }
}


export function adminFetchTransactions(userId, cursor) {
    return (dispatch) => {
        api.adminFetchTransactions(userId, cursor)
            .then(results => {
                dispatch(adminTransactionsReceived(userId, results));
            }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function refundBooking(bookingUID, reason) {
    return (dispatch) => {
        api.adminBookingRefund(bookingUID, reason)
            .then(booking => {
                dispatch(showAlert("success", "Success", "Refund complete"));
                dispatch(adminBookingReceived(booking));
            }).catch(error => {
            dispatch(showAlert("danger", "Error refunding booking", error.message));
        });
    }
}

function adminCampaignReceived(result) {
    return {
        type: 'ADMIN_CAMPAIGN_RECIEVED',
        result: result
    }
}

function adminBookingReceived(booking) {
    return {
        type: 'ADMIN_USER_BOOKING_RECIEVED',
        booking: booking
    }
}

export function adminTransactionsReceived(userId, result) {
    return {
        type: 'ADMIN_USER_TRANSACTIONS_RECEIVED',
        result: result,
        userId: userId
    };
}

export function adminUserBookingsReceived(userId, result) {
    return {
        type: 'ADMIN_USER_BOOKINGS_RECEIVED',
        result: result,
        userId: userId
    };
}

export function adminCampaignsReceived(searchLimit, result) {
    return {
        type: 'ADMIN_CAMPAIGNS_RECEIVED',
        result: result,
        searchLimit: searchLimit
    };
}

export function adminUsersReceived(result) {
    return {
        type: 'ADMIN_USERS_RECEIVED',
        result: result
    };
}


export function adminAffiliateUsersReceived(result) {
    return {
        type: 'ADMIN_AFFILIATE_USERS_RECEIVED',
        result: result
    };
}

export function adminAffiliateBookingsReceived(result, referrer, clearResults) {
    return {
        type: 'ADMIN_AFFILIATE_BOOKINGS_RECEIVED',
        result: result,
        referrer: referrer,
        clearResults: clearResults
    };
}

export function adminAffiliateTransferReceived(result, referrer) {
    return {
        type: 'ADMIN_AFFILIATE_TRANSFER_RECEIVED',
        result: result,
        referrer: referrer
    };
}

export function adminAffiliatesReceived(result) {
    return {
        type: 'ADMIN_AFFILIATES_RECEIVED',
        result: result
    };
}

export function adminUserReceived(user) {
    return {
        type: 'ADMIN_USER_RECEIVED',
        user: user
    }
}

export function adminUserlocationsReceived(userId, result) {
    return {
        type: 'ADMIN_USER_PROPERTIES_RECEIVED',
        result: result,
        userId: userId
    }
}

/** RESPONSE METHODS **/

export function pageReceived(page) {
    return {
        type: 'PAGE_RECEIVED',
        page: page
    }
}

export function widgetReceived(widget) {
    return {
        type: 'WIDGET_RECEIVED',
        widget: widget
    }
}

export function collectionPageReceived(page) {
    return {
        type: 'COLLECTION_PAGE_RECEIVED',
        collectionPage: page
    }
}

export function notificationReceived(notification) {
    return {
        type: 'NOTIFICATION_RECEIVED',
        notification: notification
    }
}

export function removeNotificationReceived(notification) {
    return {
        type: 'REMOVE_NOTIFICATION_RECEIVED',
        notification: notification
    }
}

export function loading(bool) {
    return {
        type: 'IS_LOADING',
        isLoading: bool
    };
}

export function login(user) {
    return {
        type: 'LOG_IN',
        user
    };
}

export function onError(error) {
    return {
        type: 'ERROR',
        error
    }
}

export function campableUserReceived(campableUser) {
    return {
        type: 'CAMPABLE_USER_RECEIVED',
        campableUser: campableUser
    }
}

export function adminCampableUserReceived(campableUser) {
    return {
        type: 'ADMIN_CAMPABLE_USER_RECEIVED',
        campableUser: campableUser
    }
}

export function changeProperty(property) {
    return {
        type: 'CHANGE_PROPERTY',
        property
    };
}

export function propertyReceived(property) {
    return {
        type: 'PROPERTY_RECEIVED',
        property: property
    };
}

export function tagsReceived(tags) {
    return {
        type: 'TAGS_RECEIVED',
        tags: tags
    };
}

export function propertiesReceived(properties) {
    return {
        type: 'USER_PROPERTIES_RECEIVED',
        userProperties: properties
    };
}

export function clearLocations() {
    return {
        type: 'LOCATIONS_CLEAR'
    };
}

export function locationsReceived(locations) {
    return {
        type: 'LOCATIONS_RECEIVED',
        locations: locations
    };
}

export function transactionsReceived(transactions) {
    return {
        type: 'TRANSACTIONS_RECEIVED',
        transactions: transactions
    }
}

export function resetTransactions() {
    return {
        type: 'TRANSACTIONS_RESET',
    }
}


export function locationReceived(location) {
    return {
        type: 'LOCATION_RECEIVED',
        location: location
    };
}


export function hostBookingsReceived(bookings) {
    return {
        type: 'HOST_BOOKINGS_RECEIVED',
        hostBookings: bookings
    };
}

export function resetHostBookings() {
    return {
        type: 'HOST_BOOKINGS_RESET',
    };
}

export function adminPropertyBookingsReceived(propertyId, result) {
    return {
        type: 'ADMIN_BOOKINGS_RECEIVED',
        result: result,
        propertyId: propertyId
    };
}


export function changeUserAffiliateState(userId) {
    return (dispatch) => {
        api.changeUserAffiliateStateAPI(userId).then(locations => {
            dispatch(adminUserReceived(locations));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

function adminBulkImagesReceived(images){
    return {
        type: 'BULK_IMAGES_RECEIVED',
        images: images
    };
}

export function uploadExistingImages(images) {
    return (dispatch) => {
        api.uploadExistingImagesAPI(images).then(newImages => {
            dispatch(adminBulkImagesReceived(newImages));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

function adminBulkLocationsReceived(locations){
    return {
        type: 'BULK_LOCATIONS_RECEIVED',
        locations: locations
    };
}

export function uploadMultipleLocations(locations) {
    return (dispatch) => {
        api.uploadMultipleLocationsAPI(locations).then(newLocations => {
            dispatch(adminBulkLocationsReceived(newLocations));
        }).catch(error => {
            console.log('error:', error);
        });
    }
}

export function getCampableUserRequirements() {
    return async (dispatch) => {
        let requirements = await api.getCampableUserRequirements();
        if(requirements.currentlyDue.length > 0) {
            requirements.link = await api.getStripeAccountVerificationLink({
                successUrl: window.location.origin + "/account-verification-success",
                failureUrl: window.location.origin + "/account-verification-failure",
            });
        }
        dispatch({
            type: 'REQUIREMENTS_RECEIVED',
            requirements: requirements,
        });
        return requirements;
    }
}

export function getStripeAccountUpdateLink() {
    return api.getStripeAccountUpdateLink({
        successUrl: window.location.origin + "/account",
        failureUrl: window.location.origin + "/account-update-failure",
    });
}
