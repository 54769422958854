import React, {Component} from "react";
import {Button} from "react-bootstrap";
import EmailInput from "./common/inputs/EmailInput";

export default class ResetPassword extends Component {

    render() {
        return (
            <div className="landing-sign-up">
                <EmailInput label="Email Address"
                            value={this.props.emailValue}
                            placeholder="john@smith.com"
                            onChange={this.props.handleEmailInput}
                            onEnter={this.props.handleResetPassword}/>
                <br/>
                <div className="bottom-container">
                    {this.props.passwordReset ? <p>If you have an account with us, a reset password has been sent.</p> :
                        <Button className="handle-submit" onClick={this.props.handleResetPassword}>Reset Password</Button>}
                    <a className="back-button" onClick={this.props.onBackPress}>Back</a>
                </div>
            </div>
        );
    }
}
