import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../../App.css';
import {getWidget} from '../../actions';
import Load from '../../components/Load';
import Widget from '../../components/content/Widget';


class WidgetContainer extends Component {

    constructor(props) {
        super(props);
        this.slug = window.location.pathname.split("/widget/")[1];
        props.dispatch(getWidget(this.slug));
    }

    componentWillMount() {
        let code = this.props.location.query.affiliate;
        if (code !== undefined) {
            localStorage.setItem('affiliateReferralCode', code);
        }
    }

    render() {
        if (this.props.widgets && this.props.widgets[this.slug]) {
            return (
                <Widget
                    widget={this.props.widgets[this.slug]}
                    affiliate={this.props.location.query.affiliate}
                />
            );
        }

        return (
            <div className="loaderContainer">
                <Load/>
            </div>
        );
    }
}

export default WidgetContainer = connect(state => {
    return {
        widgets: state.content.widgets
    }
})(WidgetContainer)