import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, Row} from 'react-bootstrap';
import AccountProfileController from '../components/account/AccountProfileController'
import AccountPaymentController from '../components/account/AccountPaymentController'
import AccountHostDetailsController from '../components/account/AccountHostDetailsController'
import Load from '../components/Load';
import '../App.css';
import {updateCampableUser} from "../actions/index";

class AccountContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.saveCampableUser = this.saveCampableUser.bind(this);
    }

    saveCampableUser(values) {
        let user = {};
        if (values.firstName && values.lastName) {
            user.names = {
                firstName: values.firstName,
                lastName: values.lastName
            };
        }

        if (values.phone) {
            user.contact = {
                phone: values.phone,
            };
        }

        if (values.image) {
            user.image = {
                url: values.image
            }
        }

        this.props.dispatch(updateCampableUser(user));
    }

    render() {

        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let user = this.props.user;

        console.log("user account", user)

        return (
            <Grid className="main-container">
                <Row>
                    <div className="account-container">
                        <AccountProfileController
                            onSubmit={this.saveCampableUser}
                            initialValues={{
                                email: user.email,
                                firstName: user.firstName,
                                lastName: user.lastName,
                                phone: user.phone,
                                image: user.image,
                                telephone: user.telephone
                            }} user={this.props.user}/>
                        <AccountPaymentController initialValues={undefined} user={this.props.user}/>
                        <AccountHostDetailsController initialValues={undefined} user={this.props.user}/>
                    </div>
                </Row>
            </Grid>
        );
    }
}

export default AccountContainer = connect((state) => {
    return {
        user: state.data.campableUser,
    }
})(AccountContainer);