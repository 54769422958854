import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row, Well} from 'react-bootstrap';
import '../App.css';
import Card from '../components/Card';
import ValidatedTextInput from '../components/common/ValidatedTextInput';
import ValidatedTextBox from '../components/common/ValidatedTextBox';
import {reduxForm} from 'redux-form';
import {contactUs} from '../api/api';
import ValidatedPhoneNumberInput from "../components/common/ValidatedPhoneNumberInput";

class ContactContainer extends Component {

    constructor(props) {
        super(props);
        this.submitContactForm = this.submitContactForm.bind(this);

        this.state = {
            messageSubmitted: false
        }
    }


    submitContactForm(values) {
        const data = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            message: values.message
        };

        contactUs(data)
            .then(() => {
                this.setState({
                    messageSubmitted: true
                });
            })
            .catch((error) => {
                console.log("error", error);
            });

    }

    render() {
        return (
            <Grid className="contact-container">
                <h1>Contact Us</h1>
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col md={3}>
                                <h4>
                                    Send us an email
                                </h4>
                                <p>
                                    If you have a question or concerns, or simply wish to get in touch with us, please
                                    send us a message. We are always happy to help.
                                </p>
                                <hr/>
                                <h4>
                                    Frequently asked questions
                                </h4>
                                <p>
                                    You may also find your answer in the frequently asked questions pages, <a
                                    onClick={() => browserHistory.push('/faq-host')}>FAQs for Hosts</a> and <a
                                    onClick={() => browserHistory.push('/faq-guest')}>FAQs for Guests.</a>
                                </p>
                            </Col>
                            <Col md={9}>
                                <form onSubmit={this.props.handleSubmit(this.submitContactForm)}>
                                    <Card title="Contact Us">
                                        <ValidatedTextInput label="First Name" placeholder="e.g. John"
                                                            name="firstName"/>
                                        <ValidatedTextInput label="Last Name" placeholder="e.g. Smith" name="lastName"/>
                                        <ValidatedTextInput label="Email" placeholder="e.g. john@smith.com"
                                                            name="email"/>

                                        <ValidatedPhoneNumberInput label="Phone" placeholder="e.g. +64271234567" name="phone"/>

                                        <ValidatedTextBox label="Message" placeholder="e.g. Hi Campable,"
                                                          name="message"/>
                                        <Button type="submit" disabled={this.props.submitting}>Submit</Button>
                                        { this.state.messageSubmitted ?
                                            <Well bsSize="small" className="message-submitted">Message
                                                Submitted</Well> : null }
                                    </Card>
                                </form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid>

        );
    }
}

ContactContainer = reduxForm({
    form: 'contactForm',
    validate: (values) => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = 'Required'
        }
        if (!values.email) {
            errors.email = 'Required'
        }
        if (!values.message) {
            errors.message = 'Required'
        }
        return errors
    }
})(ContactContainer);

export default ContactContainer = connect(state => {
    return {
        initialValues: state.data.campableUser
    }
})(ContactContainer);


 


