import React, {Component} from 'react';
import Column from './Column';
import {Col, Row} from 'react-bootstrap';

export default class ContentRow extends Component {

    render() {

        let columns = [];
        let columnWidth = Math.floor(12 / this.props.row.columns.length);
        let className = "contentful-row";

        if (this.props.row.overwriteMargin !== undefined) {
            className = "contentful-row overwrite-margin";
        }

        this.props.row.columns.map((column, i) => {
            let columnComp = (
                <Col className="contentful-column" key={i} xs={12} md={columnWidth}>
                    <Column column={column} showLoginOverlay={this.props.showLoginOverlay} isLoggedIn={this.props.isLoggedIn}/>
                </Col>);
            columns.push(columnComp);
            return null;
        });

        return (
            <Row className={className}>
                {columns}
            </Row>
        );
    }
}