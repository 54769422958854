import React, {Component} from "react";
import {connect} from "react-redux";
import {removeNotification} from "../actions";
import {Col, Grid, Row} from "react-bootstrap";
import NavBar from "./NavBar";
import NavBarSecondary from "./NavBarSecondary";
import AdminNavBar from "./AdminNavBar";
import Footer from "./Footer";
import {AlertList} from "react-bs-notifier";
import {getTags, logoutPressed} from "../actions/index";
import {HOSTING_CREATE_A_PROPERTY, LOCATIONS} from "../constants/Routes";
import LoginOverlay from "./overlay/LoginOverlay";
import {showLoginOverlay} from "../actions/overlay";
import {isAdmin} from '../auth'
import 'airbnb-js-shims';
import 'airbnb-browser-shims';
import '../App.css';
import AlertBanner from "./alertBanner/AlertBanner";

class App extends Component {

    constructor(props) {
        super(props);

        this.onAlertDismissed = this.onAlertDismissed.bind(this);
        this.showLoginOverlay = this.showLoginOverlay.bind(this);
        this.onLogOutPress = this.onLogOutPress.bind(this);

        this.enableScroll = this.enableScroll.bind(this);
        this.disableScroll = this.disableScroll.bind(this);
        this.preventDefault = this.preventDefault.bind(this);
        this.preventDefaultForScrollKeys = this.preventDefaultForScrollKeys.bind(this);


        this.keys = {37: 1, 38: 1, 39: 1, 40: 1};
    }

    componentWillMount() {
        this.props.dispatch(getTags());
    }

    onAlertDismissed(alert) {
        this.props.dispatch(removeNotification(alert));
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp));
    }

    onLogOutPress() {
        this.props.dispatch(logoutPressed())
    }


    disableScroll() {
        if (window.addEventListener) // older FF
            window.addEventListener('DOMMouseScroll', this.preventDefault, false);
        window.onwheel = this.preventDefault; // modern standard
        window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
        window.ontouchmove = this.preventDefault; // mobile
        document.onkeydown = this.preventDefaultForScrollKeys;
    }

    enableScroll() {
        if (window.removeEventListener)
            window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.onmousewheel = document.onmousewheel = null;
        window.onwheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
    }

    preventDefault(e) {
        e = e || window.event;
        if (e.preventDefault)
            e.preventDefault();
        e.returnValue = false;
    }

    preventDefaultForScrollKeys(e) {
        if (this.keys[e.keyCode]) {
            this.preventDefault(e);
            return false;
        }
    }


    render() {

        let isLoggedIn = !!this.props.campableUser;
        let isHost = false;

        if (isLoggedIn) {
            isHost = this.props.campableUser.accountInformation.valid;
        }

        let alertList;

        if (this.props.notifications) {
            alertList = <AlertList
                timeout={4000}
                alerts={this.props.notifications}
                onDismiss={this.onAlertDismissed}
            />
        }

        let secondaryNav = null;
        if (this.props.location.pathname.indexOf('hosting') > -1 || this.props.location.pathname === HOSTING_CREATE_A_PROPERTY) {
            if (isHost) {
                secondaryNav = <NavBarSecondary/>;
            }
        } else if (this.props.location.pathname.indexOf('admin') > -1) {
            if(isLoggedIn && isAdmin(this.props.campableUser)){
                secondaryNav = <AdminNavBar/>;
            }
        }

        let isHomePage = false;
        let mainContainerClass = 'standard-page';
        let childrenContainerClass = 'page-content';
        let mainContainerId = '';

        // let isLoginOrSignUp = false;

        let footer = (
            <div className="footer-container">
                <Grid>
                    <hr/>
                    <Footer loggedIn={isLoggedIn} showLoginOverlay={this.showLoginOverlay}/>
                </Grid>
            </div>
        );


        if (this.props.location.pathname === "/") {
            isHomePage = true;
            mainContainerClass = '';
            mainContainerId = 'homepage';
            childrenContainerClass = null;
        }

        if (this.props.location.pathname === LOCATIONS) {
            mainContainerClass = '';
            mainContainerId = 'locations-page';
            childrenContainerClass = null;
            footer = null;
        }


        let children = (
            <Grid className={childrenContainerClass}>
                <Row>
                    <Col>
                        {this.props.children}
                    </Col>
                </Row>
            </Grid>
        );


        if (!childrenContainerClass) {
            children = (
                <Row>
                    <Col>
                        <div className={childrenContainerClass}>
                            {this.props.children}
                        </div>
                    </Col>
                </Row>
            );
        }

        return (
            <Grid fluid className={mainContainerClass} id={mainContainerId}>
                {alertList}
                <div>
                    <Row>
                        <div>
                            <AlertBanner/>
                            <NavBar user={this.props.campableUser}
                                    isHomepage={isHomePage}
                                    loggedIn={isLoggedIn}
                                    onLogOutPress={this.onLogOutPress}
                                    showLoginOverlay={this.showLoginOverlay}
                                    pathName={this.props.location.pathname}
                                    isAdmin={this.props.campableUser ? isAdmin(this.props.campableUser) : false}/>

                            {secondaryNav}
                        </div>
                    </Row>
                    {children}
                    {footer}
                </div>
                <LoginOverlay currentRoute={this.props.location.pathname}/>
            </Grid>
        );
    }
}

export default App = connect((state) => {
    return {
        campableUser: state.data.campableUser,
        notifications: state.data.notifications,
    }
})(App);
