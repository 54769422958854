/**
 * Created by Chris on 29/03/17.
 */


export const RED_ERROR = '#d9000c';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const FACEBOOK_BLUE = '#3b5998';

export const BLUE = '#0482e6';
export const GREEN = '#5fce17';
export const FOREST_GREEN = '#004d40';
export const YELLOW = '#fbd740';

export const DARK_GREY = '#414141';
export const MID_GREY = '#888888';
export const WHITE_GREY = '#f2f2f2';
export const BORDER_GREY = '#e1e1e1';
export const LIGHT_GREY = '#b7b7b7';
export const LIGHT_GREY_V2 = '#e6e6e6';

export const ORANGE = '#fbb040';
export const UNICORN_PURPLE = '#9c27b0';
export const BROWNIE = '#8d6e63';
export const RED_ROOSTER = '#e82248';
export const ROYAL_BLUE = '#1a2373';
export const SKY_BLUE = '#a7d8ff';
export const MINTY_GREEN = '#e6fde2';


export const HIGHLIGHT_GREEN_DARK = '#9de56e';
export const HIGHLIGHT_GREEN_LIGHT = '#b8e09d';
export const HIGHLIGHT_GREEN_LIGHT_WHITE_1 = '#fcfffa';
export const HIGHLIGHT_GREEN_LIGHT_WHITE_2 = '#e1fccf';
export const HIGHLIGHT_BLUE_DARK = '#2196f3';
export const HIGHLIGHT_BLUE_LIGHT = '#2299ff';
export const HIGHLIGHT_YELLOW_DARK = '#ffeb3b';
export const HIGHLIGHT_YELLOW_LIGHT = '#feea3a';
export const HIGHLIGHT_ORANGE_DARK = '#fac053';
export const HIGHLIGHT_ORANGE_LIGHT = '#f9bf53';

export const HIGHLIGHT_UNICORN_PURPLE_DARK = '#9c4c9d';
export const HIGHLIGHT_UNICORN_PURPLE_LIGHT = '#b833ce';
export const HIGHLIGHT_BROWNIE_DARK = '#ba9c92';
export const HIGHLIGHT_BROWNIE_LIGHT = '#bb9d93';
export const HIGHLIGHT_RED_ROOSTER_DARK = '#ee2649';
export const HIGHLIGHT_RED_ROOSTER_LIGHT = '#ff1744';