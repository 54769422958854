/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 9/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, {Component} from "react";
import Dialog from "../../common/widgets/Dialog";
import {reduxForm} from "redux-form";
import {validate} from "../../common/form-validation/CreditCardValidation";
import {connect} from "react-redux";
import {createCreditCardToken} from "../../../api/ApiStripe";
import {showAlert, updateCampableUserCreditCard} from "../../../actions/index";
import ValidatedTextInput from "../../common/ValidatedTextInput";
import ValidatedCreditCardInput from "../../common/ValidatedCreditCardInput";

class PaymentDetailsDialog extends Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.show = this.show.bind(this);
        this.dismiss = this.dismiss.bind(this);

        this.state = {
            loading: false,
        }

    }

    onSubmit(values) {
        if (!this.props.pristine || this.props.dirty) {
            this.setState({
                loading: true
            });
            this.props.dispatch(showAlert('info', 'Updating your credit card information...', ''));
            createCreditCardToken(values).then((r) => {
                let stripeToken = r.id;
                this.props.dispatch(updateCampableUserCreditCard({
                    paymentInformation: {
                        token: stripeToken,
                    }
                }));
                this.setState({
                    loading: false
                });
                this.paymentDetailsDialog.dismiss();
                this.props.onBookNowPress();
            }).catch((err) => {
                this.setState({
                    loading: false
                });
                this.props.dispatch(showAlert('danger', 'There was an error while updating your credit card.', err.error.message));
            });
        } else {
            this.props.onNextStepPress()
        }
    }

    show() {
        this.paymentDetailsDialog.show();
    }

    dismiss() {
        this.paymentDetailsDialog.hide();
    }

    render() {
        return (

            <Dialog
                ref={(ref) => {
                    this.props.setRef(ref);
                    this.paymentDetailsDialog = ref;
                }}
                loading={this.state.loading}
                title={'Payment Details'}
                body={'Before you are able to book, you need to configure your payment details.'}
                positiveText={'Add Credit Card'}
                negativeText={'Cancel'}
                dontDismissOnConfirmPress
                onPositivePress={this.props.handleSubmit(this.onSubmit)}>
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <br/>
                    <br/>
                    <ValidatedCreditCardInput name="cardNumber" label="Card Number" placeholder="e.g. 1234-5678-9101-1121" maxLength={19}/>
                    <ValidatedTextInput name="cardExpMonth" label="Card Expiry Month" placeholder="e.g. 12" maxLength={2}/>
                    <ValidatedTextInput name="cardExpYear" label="Card Expiry Year" placeholder="e.g. 24" maxLength={2}/>
                    <ValidatedTextInput name="cardCVV" label="Card CVV" placeholder="e.g. 123" maxLength={3}/>
                </form>
            </Dialog>

        );
    }
}

PaymentDetailsDialog = reduxForm({
    form: 'paymentDetailsDialogForm',
    validate
})(PaymentDetailsDialog);

export default PaymentDetailsDialog = connect((state) => {
    return {
        initialValues: {},
        user: state.data.campableUser
    }
})(PaymentDetailsDialog);


