import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCampableUserBookings} from '../actions';
import Load from '../components/Load';
import {Col, Grid, Row} from 'react-bootstrap';
import BookingsController from '../components/bookings/BookingsController';
import {cancelCampableUserBooking, getCampableUserReviews, getLocation, showAlert, showBookingConfirmedModal} from "../actions/index";
import Dialog from "../components/common/widgets/Dialog";

class BookingsContainer extends Component {

    constructor(props) {
        super(props);
        this.cancelBooking = this.cancelBooking.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.checkEmailDialog = this.checkEmailDialog.bind(this);
        this.hideBookingModal = this.hideBookingModal.bind(this);
    }

    componentDidMount() {
        if (this.props.campableUser) {
            this.props.dispatch(getCampableUserBookings());
            this.props.dispatch(getCampableUserReviews());
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.refCheckEmailDialog !== undefined && this.props.showBookingConfirmationModal) {
            this.refCheckEmailDialog.show();
        }
    }

    cancelBooking(bookingUIDS) {
        if (bookingUIDS) {
            this.props.dispatch(showAlert('info', 'Cancelling booking(s)...', ''));
            // eslint-disable-next-line
            bookingUIDS.map((bookingUID) => {
                this.props.dispatch(cancelCampableUserBooking(bookingUID))
            });
        }
    }

    getLocation(id){
        if (!!this.props.campableUser) {
            this.props.dispatch(getLocation(id));
        }
    }


    checkEmailDialog(){
        return (<Dialog ref={(ref) => {
                        this.refCheckEmailDialog = ref;
                    }}
                    body={
                       <div>
                           <h3>Your booking has been confirmed</h3>
                           <h4>Please check your email for detailed information on your stay.</h4>
                       </div>}
                    positiveText={'Ok'}
                    onPositivePress={this.hideBookingModal}
        />)
    }

    hideBookingModal(){
        this.props.dispatch(showBookingConfirmedModal(false));
    }

    render() {

        if (this.props.campableUserBookings && Object.keys(this.props.campableUserBookings).length > 0) {

            return (
                <div>
                    <Grid className="main-container">
                        <h1>My Trips</h1>
                        <Row>
                            <Col xs={12}>
                                <BookingsController campableUserBookings={this.props.campableUserBookings}
                                                    campableUserReviews={this.props.campableUserReviews}
                                                    cancelBooking={this.cancelBooking}
                                                    getLocation={this.getLocation}
                                                    campableUser={this.props.campableUser}
                                                    locations={this.props.locations}/>
                            </Col>
                        </Row>
                    </Grid>
                    {this.checkEmailDialog()}
                </div>

            );
        } else if (this.props.campableUserBookings && Object.keys(this.props.campableUserBookings).length === 0) {
            return (
                <div>
                    <p>You have no bookings.</p>
                </div>);
        }

        return (
            <div className="loaderContainer">
                <Load />
            </div>
        )


    }
}

export default BookingsContainer = connect((state) => {
    return {
        campableUserBookings: state.data.campableUserBookings,
        campableUserReviews: state.data.campableUserReviews,
        campableUser: state.data.campableUser,
        locations: state.data.locationsIndex,
        showBookingConfirmationModal: state.data.showBookingConfirmationModal
    }
})(BookingsContainer);