import React, {Component} from 'react';
import {Col, Grid, Row} from 'react-bootstrap';
import Map from "../common/map/Map";

export default class LocationMapDetails extends Component {


    render() {

        let url = `http://maps.google.com?q=
            ${this.props.location.latitude},
            ${this.props.location.longitude}`;

        return (
            <Grid>
                <Row>
                    <Col>
                        <h3>Location</h3>
                    </Col>
                </Row>
                <Row className="location-map-container">
                    <Row>

                        <Col xs={12}>
                            <Map containerStyle={{height: '30vh'}}
                                 mapStyle={{height: '30vh'}}
                                 hasCenterPin={true}
                                 defaultOptions={{
                                     scrollwheel: false,
                                     streetViewControl: false,
                                     mapTypeControl: false,
                                     fullscreenControl: false,
                                 }}
                                 center={{lat: this.props.location.latitude, lng: this.props.location.longitude}}/>
                        </Col>
                    </Row>
                    <hr/>
                </Row>
            </Grid>
        );
    }
}