import React, {Component} from 'react';
import Card from '../../Card';
import ValidatedTextInput from '../../common/ValidatedTextInput';

export default class Title extends Component {

    render() {

        return (

            <Card title="Create Listing Title" onboardingCard={true} helpText="Your name will be visible to Campers when they view your site.">
                <ValidatedTextInput label="Title" placeholder="e.g. Hawkes Lodge" name="title" required/>

                <h5>Examples of effective titles</h5>
                <ul>
                    <li className="tick-li">Riverside Meadow</li>
                    <li className="tick-li">In the Shire</li>
                    <li className="tick-li">Mountain Escape</li>
                </ul>
            </Card>

        );
    }
}