import React, {Component} from "react";
import Card from "../../Card";
import ValidatedPriceInput from "../../common/ValidatedPriceInput";

export default class Price extends Component {
	render() {

		return (
			
      	<Card 	title="Price Per Night" 
      			onboardingCard={true} 
      			helpText="Unsure about how to price your site?" 
      			helpButton="How should I price my site?"
      			popOverTitle="How should I price my site?"
      			popOverBody="We recommend starting at $20 per night. If you have more features (such as power or toilets) you can charge more." >
           <ValidatedPriceInput label="Price" placeholder="e.g. $20" name="price" type="number" maxLength={4} required/>
        </Card>

		);
	} 
}