import React, {Component} from "react";
import "../App.css";
import {Button, Col, Row} from "react-bootstrap";
import {browserHistory} from "react-router";
import AndroidBadge from "./common/badges/AndroidBadge";
import IOSBadge from "./common/badges/IOSBadge";
import tiaki from "../resources/images/tiaki.png";
import tsc from "../resources/images/sustainable-tourism.png";

export default class Footer extends Component {

    constructor(props) {
        super(props);

        Footer.onFooterItemPress = Footer.onFooterItemPress.bind(this);
    }

    static onFooterItemPress(route) {
        browserHistory.push(route);
    }

    showLoginOverlay = () => {
        this.props.showLoginOverlay(true);
    };


    render() {
        return (
            <Row className="footer">

                <Col md={3} sm={3} className="footer-column-one">
                    {this.props.loggedIn ? null : (<div><h4>FREE to join and download</h4>
                        <Button onClick={this.showLoginOverlay}>Join Now</Button></div>)
                    }
                    <ul>
                        <li>
                            <AndroidBadge/>
                        </li>
                        <li>
                            <IOSBadge/>
                        </li>
                        <li className="footer-contact-us-box">
                            <h3>Can't find your answer?</h3>
                            We're here to help. Get in touch and we'll get back to you as soon as we can.<br/>
                            <a onClick={() => {
                                Footer.onFooterItemPress('/contact-us')
                            }}>Contact Us</a></li>
                    </ul>
                </Col>

                <Col className="hidden-xs" md={2} sm={2}>
                    <h4>Campable</h4>
                    <ul>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/about')
                        }}>About</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/media')
                        }}>In the Media</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/rules-of-etiquette')
                        }}>Rules of Etiquette</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/health-and-safety')
                        }}>Health and Safety</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/terms-and-conditions')
                        }}>Terms and Conditions</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/privacy-policy')
                        }}>Privacy Policy</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/cancellation-policy')
                        }}>Cancellation Policy</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/privacy-policy#cookie-policy')
                        }}>Cookie Policy</a></li>
                    </ul>
                </Col>
                <Col className="hidden-xs" md={2} sm={2}>
                    <h4>Guest</h4>
                    <ul>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/how-campable-works')
                        }}>How does Campable Work?</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/faq-guest')
                        }}>FAQ Guest</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/after-booking')
                        }}>After Booking</a></li>

                        {/*<li><a>Get Campable Credits</a></li>*/}
                        {/*<li><a>Blog</a></li>*/}
                        {/*<li><a>Motorhome Equipment Recommendations</a></li>*/}
                        {/*<li><a>Motorhome Rental</a></li>*/}
                        {/*<li><a>Events</a></li>*/}
                    </ul>
                </Col>
                <Col className="hidden-xs" md={2} sm={2}>
                    <h4>Hosting</h4>
                    <ul>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/why-host')
                        }}>Why Host with Campable?</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/faq-host')
                        }}>FAQ Host</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/vineyards')
                        }}>Hosting for Vineyards</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/farms')
                        }}>Hosting for Farms</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/bed-and-breakfasts')
                        }}>Hosting for B&amp;Bs</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/resorts')
                        }}>Hosting for Resorts</a></li>
                        <li><a onClick={() => {
                            Footer.onFooterItemPress('/clubs')
                        }}>Hosting for Clubs</a></li>

                        {/*<li><a>Facilities/Self Contained</a></li>*/}
                        {/*<li><a>Hosting Multiple Motorhomes</a></li>*/}
                        {/*<li><a>Health & Safety</a></li>*/}
                        {/*<li><a>Legal / Insurance</a></li>*/}
                        {/*<li><a>Host Stories</a></li>*/}
                        {/*<li><a>Luxury / Other Guest Types</a></li>*/}
                        {/*<li><a>Experiences</a></li>*/}
                    </ul>
                </Col>
                <Col md={2} sm={2} className="support-logo-container">
                    <h4>Campable supports</h4>
                    <div className="support-logos">
                    <img src={tiaki} alt={""}/>
                    <img src={tsc} alt={""}/>
                    </div>
                </Col>
            </Row>
        );
    }
}