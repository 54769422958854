import React, {Component} from 'react';
import {getLocations} from '../../actions';
import {connect} from 'react-redux';
import LocationCollection from './LocationCollection';
import {Button, Grid} from 'react-bootstrap';
import {browserHistory} from 'react-router';
import {LOCATIONS} from "../../constants/Routes";
import {fireECommerceEvent} from "../../tagmanager";
import {clearLocations} from "../../actions/index";

class CollectionBlock extends Component {

    componentWillMount() {
        if (this.props.collection) {
            const tag = this.props.collection.collection.tag || null;
            const params = {
                "tags": tag
            };


            this.props.dispatch(getLocations(params));
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.locations === null && nextProps.locations !== null && this.props.collection !== undefined){
            let collectionTag = this.props.collection.collection.tag || null;
            let locations = [];
            nextProps.locations.map(location => {
                location.tags.map(tag => {
                    if (tag === collectionTag) {
                        locations.push(location)
                    }
                    return null;
                });
                return null;
            });
            fireECommerceEvent(this.props.campableUser, "impressions", "/", locations);
        }
    }

    render() {

        let locations = [];
        // let displayTitle = this.props.collection.displayTitle;

        if (this.props.collection && this.props.locations) {
            let collectionTag = this.props.collection.collection.tag || null;

            this.props.locations.map(location => {
                location.tags.map(tag => {
                    if (tag === collectionTag) {
                        locations.push(location)
                    }
                    return null;
                });
                return null;
            });
        }



        return (
            <Grid className="collection-block">
                <LocationCollection locations={locations}/>
                <p className="collection-block-button"><Button onClick={() => {
                    this.props.dispatch(clearLocations());
                    browserHistory.push(LOCATIONS)
                }}>Search More</Button></p>
            </Grid>
        );
    }

}

export default CollectionBlock = connect(state => {
    return {
        locations: state.data.locations,
        campableUser: state.data.campableUser
    }
})(CollectionBlock)
