/**
 * Created by brodynelson on 14/09/17.
 */
import React, {Component} from "react";
import {Button, Col, Row} from 'react-bootstrap';
import Load from '../Load';

export default class AdminBookingsTransferPanel extends Component {

    render() {

        let total = 0;
        let count = 0;
        this.props.selectedBookings.map((booking) => {
            let transferAmount = this.props.calculateTransfer(booking) || 0;
            total += transferAmount;
            count++;
            return null;
        });

        if (this.props.isLoading) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        return (
            <Row className="location-panel">
                <Col xs={12}>
                    <h3>Affiliate Transfer Summary</h3>

                    <div className="booking-date-box">
                        <h5>Total Transfer Amount</h5>
                        <p>${total}</p>
                    </div>

                    <div className="booking-date-box">
                        <h5>Selected bookings</h5>
                        <p>{count++}</p>
                    </div>

                    <p className="admin-block-button"><Button onClick={this.props.payAffiliate}>Pay Affiliate</Button></p>
                </Col>
            </Row>
        );
    }
}
