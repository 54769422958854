import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import {getPage} from '../actions';
import Load from '../components/Load';
import Page from '../components/content/Page';
import {showLoginOverlay} from "../actions/overlay";

class PageContainer extends Component {

    constructor(props) {
        super(props);

        this.showLoginOverlay = this.showLoginOverlay.bind(this);

        this.state = {}
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp))
    }

    componentWillMount() {
        this.getPage();
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.params.splat !== this.props.params.splat) {
            this.getPage(nextProps.params.splat);
        }
    }

    getPage = async (splat) => {
        this.setState({loading:true})
        await this.props.dispatch(getPage(splat || this.props.params.splat));
        this.setState({loading:false})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.params.splat !== this.props.params.splat) {
            getPage();
        }
    }

    render() {

        if(this.state.loading) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            );
        }
        
        let splat = this.props.params.splat;

        if (this.props.pages) {
            if (this.props.pages[splat]) {
                return (
                    <Page page={this.props.pages[splat]} splat={splat} showLoginOverlay={this.showLoginOverlay} isLoggedIn={!!this.props.user}/>
                );
            }
        }

        return (
            <div>
                <h1>
                    Page not found
                </h1>
                <h2>
                    Oops. Looks like you have gone off track.
                </h2>
            </div>
        )
    }
}

export default PageContainer = connect(state => {
    return {
        pages: state.content.pages,
        user: state.data.campableUser,
    }
})(PageContainer)
