import React from 'react';

export const LOCATION_RECEIVED = 'LOCATION_SEARCH_RECEIVED';

export function getLocationFromSearch(location) {
    return (dispatch) => {
        getLocationFromGoogle(location).then((l) => {
            let location = l.results[0];
            let latLng = location.geometry.location.lat + "," + location.geometry.location.lng;
            let address = location.formatted_address || "";
            let locationObject = {latLng: latLng, address: address};
            dispatch(locationReceived(locationObject));
        });
    }
}

function locationReceived(location) {
    return {
        type: LOCATION_RECEIVED,
        location: location
    }
}

function getLocationFromGoogle(location) {
    return new Promise((resolve, reject) => {
        let requestParams = {
            headers: {
                'Accept': 'application/json',
            },
            method: 'get'
        };

        let request = new Request("https://maps.googleapis.com/maps/api/geocode/json?address="
            + location + "&components=country:NZ&key=AIzaSyDhfTGluapfZQYfowMZ7VVYsCZTIDywufY", requestParams);

        fetch(request).then(response => {
            ////console.log('raw response:',response);
            return response.json()
                .then(json => {
                    //console.log('json response:',json);
                    return response.ok ? resolve(json) : reject(json)
                })
                .catch(e => {

                    //console.log('error: ', e);

                })
        }).catch(e => {
            //console.log('error here', e);
        });

        //}, 4000);

    });
}

