import React, {Component} from 'react';
import Loading from 'react-loading';

class Load extends Component {

  render() {
    return (
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Loading
          type='bubbles'
          color='grey'
        />
      </div>
    );
  }
}

export default Load;
