import React, {Component} from "react";
import {createReview} from "../../actions/index";
import {WHITE_GREY} from "../../constants/Colors";
import BookingReviewController from "./BookingReviewController";
import {toProperCase} from "../../constants/Constants";
import moment from "moment";


export default class BookingsRowItem extends Component {

    constructor(props) {
        super(props);
        this.createReview = this.createReview.bind(this);
        this.onStarClick = this.onStarClick.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.state = {
            rating: 0,
            hasReviewedLocationBefore: false,
            showReview: false,
            hasCalledLocation: false,
        }
    }


    componentWillReceiveProps(props) {
        if (!props.location && !this.state.hasCalledLocation) {
            props.getLocation(this.props.groupedBooking.locationId);

            this.setState({
                hasCalledLocation: true
            });

        }
    }

    createReview(x) {
        this.props.dispatch(createReview(this.props.groupedBooking.locationId, this.state.rating, x.comment));
    }

    onStarClick(rating) {
        this.setState({rating: rating});
    }

    render() {

        let groupedBooking = this.props.groupedBooking;


        let dateOfArrival = moment(groupedBooking.dateOfArrival);
        let dateOfDeparture = moment(groupedBooking.dateOfDeparture);
        let dayOfArrival = dateOfArrival.clone().format('DD');
        let monthOfArrival = toProperCase(dateOfArrival.clone().format('MMM'));
        let dayOfDeparture = dateOfDeparture.clone().format('DD');
        let monthOfDeparture = toProperCase(dateOfDeparture.clone().format('MMM'));
        // let formattedDate = dayOfArrival + ' ' + monthOfArrival + '-' + dayOfDeparture + ' ' + monthOfDeparture;
        let formattedArrival = dayOfArrival + ' ' + monthOfArrival;
        let formattedDeparture = dayOfDeparture + ' ' + monthOfDeparture;

        //let hasReviewedLocation = false;
        let reviewable = false;
        let hasBookingCancelled = groupedBooking.status !== 'accepted';


        let bookingPriceDetails = <div className="location-tile-price"><span className="price-dollar-symbol">$</span>{groupedBooking.totalPaid / 100}</div>;

        if (groupedBooking.totalRefunded > 0) {
            if (groupedBooking.totalPaid !== groupedBooking.totalRefunded) {
                bookingPriceDetails =
                    <div className="location-tile-price">Paid: <span
                        className="price-dollar-symbol">$</span>{(groupedBooking.totalPaid / 100) + " Refunded:"} <span
                        className="price-dollar-symbol">$</span>{groupedBooking.totalRefunded / 100}</div>;
            }
            else {
                bookingPriceDetails =
                    <div className="location-tile-price">Refunded: <span
                        className="price-dollar-symbol">$</span>{groupedBooking.totalRefunded / 100}</div>;
            }
        }


        let todayDate = moment();
        let dayDiffBooking = dateOfDeparture.diff(todayDate, 'days');
        if (dayDiffBooking < 0) {
            reviewable = true;
        }

        let cancellable = true;
        dayDiffBooking = dateOfArrival.diff(todayDate, 'days');
        if (dayDiffBooking < 0) {
            cancellable = false;
        }
        let adminRefunds = null;
        if (this.props.administratorView) {
            adminRefunds = groupedBooking.adminIndividualBookings.map((details) => {
                if (!details.refunded || details.refunded === 0) {
                    //individual booking not refunded so allow refund
                    return (<div key={details.uid}>
                        <a onClick={() => {
                            this.props.refundBooking(details.uid);
                        }}>{"Refund " + details.date}</a>
                    </div>);
                }
                else {
                    return <div
                        key={details.uid}>{"Booking for " + this.formatDate(details.date) + " refunded on " + this.formatDate(details.refundDate) + " (" + details.refundReason + ")"}</div>
                }
            });
        }

        //Render cancel booking button for hostBookings in the future.
        let cancelBooking = null;
        if (!this.props.administratorView && !hasBookingCancelled && cancellable) {
            cancelBooking = <a onClick={() => {
                this.props.onBookingTileCancelPress(groupedBooking.bookingUIDS)
            }}>Cancel Booking</a>;

        }

        let hasReviewedLocationBefore = false;
        let campableUserReviews = this.props.campableUserReviews;
        for (let i = 0; i < this.props.campableUserReviews.length; i++) {
            let review = campableUserReviews[i];
            if (review.location === groupedBooking.locationId) {
                hasReviewedLocationBefore = true;
                break;
            }
        }


        let reviewBookings = null;
        if (!this.props.administratorView && reviewable && !hasBookingCancelled && !hasReviewedLocationBefore) {
            reviewBookings = <div>
                <a onClick={() => {
                    this.setState({showReview: !this.state.showReview})
                }}>Review Booking</a>

                {this.state.showReview ? <BookingReviewController groupedBooking={groupedBooking}/> : null}
            </div>;
        }

        let directionsWidget = null;
        if (groupedBooking.siteDirections.length > 0) {
            directionsWidget = (<div className="directions-box">
                <h5 style={{fontWeight: 'bold'}}>Directions</h5><br/>
                {groupedBooking.siteDirections.map((dir) => {
                    return (<div key={dir.siteName}>
                        <h5>{dir.siteName}</h5>
                        <p>{dir.siteDirections}</p><br/>
                    </div>)
                })
                }
            </div>);
        }

        return (
            <div className="location-panel" style={{backgroundColor: hasBookingCancelled ? WHITE_GREY : null}}>
                <h3 onClick={() => {
                    this.props.onBookingTitlePress(groupedBooking.locationId)
                }}>{groupedBooking.locationName}</h3>

                <div className="booking-date-box">
                    <h5>Arrival</h5>
                    <p>{formattedArrival}</p>
                </div>

                <div className="booking-date-box">
                    <h5>Departure</h5>
                    <p>{formattedDeparture}</p>
                </div>

                {directionsWidget}

                <ul>
                    {groupedBooking.hostFirstName ? <li>Hosted by: {groupedBooking.hostFirstName} {groupedBooking.hostLastName}</li> : null}
                    <li>Address: {this.props.location ? this.props.location.address : ""}</li>
                    {groupedBooking.hostEmail ? <li>Email: {groupedBooking.hostEmail}</li> : null}
                    {groupedBooking.hostPhone ? <li>Phone: {groupedBooking.hostPhone}</li> : null}
                </ul>

                {cancelBooking}
                {adminRefunds}
                {bookingPriceDetails}
                {reviewBookings}
            </div>);
    }

    formatDate(dateStr) {
        const monthNames = [
            "January", "February", "March",
            "April", "May", "June", "July",
            "August", "September", "October",
            "November", "December"
        ];

        const date = new Date(dateStr);

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        return day + ' ' + monthNames[monthIndex] + ' ' + year;
    }
}
