import React, {Component} from 'react'
import redX from '../../resources/images/red-x.svg';

export default class RemoveButton extends Component {

    onClick = (event) => {
        event.preventDefault();
        this.props.onClick();
    }

    render() {

        const style = {
            backgroundColor: 'transparent', 
            border: '0px',
            zIndex:'100'
        };

        return (
            <div className="red-x">
                <button
                    style={style}
                    onClick={this.onClick}>
                    <img src={redX} alt='remove' width="24" height="24"/>
                </button>
            </div>
        );
    }
}

