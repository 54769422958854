import React from 'react';
import {render} from 'react-dom';
import {browserHistory, IndexRoute, Route, Router} from 'react-router'
import {syncHistoryWithStore} from 'react-router-redux'
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import App from './components/App';
import * as firebase from 'firebase';
import {getCampableUser, getTags, loading, loginFirebaseUser} from './actions';
import HomeContainer from './containers/HomeContainer';
import ContactContainer from './containers/ContactContainer';
import LocationContainer from './containers/LocationContainer';
import PageContainer from './containers/PageContainer';
import FaqContainer from './containers/FaqContainer';
import HostLocationsContainer from './containers/HostLocationsContainer';
import HostLocationContainer from './containers/HostLocationContainer';
import HostTransactionsContainer from './containers/HostTransactionsContainer';
import HostBookingsContainer from './containers/HostBookingsContainer';
import HostOnboardingContainer from './containers/HostOnboardingContainer';
import AccountContainer from './containers/AccountContainer';
import RequirementsContainer from './containers/RequirementsContainer';
import BookingsContainer from './containers/BookingsContainer';
import PropertyOnboardingContainer from './containers/PropertyOnboardingContainer';
import LocationsContainer from './containers/LocationsContainer';
import OnboardingContainer from './containers/OnboardingContainer';
import AdminTagsContainer from './containers/AdminTagsContainer';
import AdminLocationsContainer from './containers/AdminLocationsContainer';
import AdminUsersContainer from './containers/AdminUsersContainer';
import AdminUserContainer from './containers/admin/AdminUserContainer';
import AdminLocationContainer from './containers/admin/AdminLocationContainer';
import AdminCampaignsContainer from './containers/admin/AdminCampaignsContainer'
import AdminAffiliatesContainer from './containers/AdminAffiliatesContainer';
import AdminAffiliateContainer from './containers/AdminAffiliateContainer';
import CollectionContainer from './containers/CollectionContainer';
import WidgetContainer from './containers/widget/WidgetContainer';

import {hasLocalStorageUser} from './auth';
import {getCampableUserBookings, getCampableUserReviews} from "./actions/index";
import * as Routes from "./constants/Routes";
import {hideLoginOverlay, showLoginOverlay} from "./actions/overlay";
import detectBrowser from "./constants/DetectBrowser";
import './index.css';
import AdminUploadProperties from "./components/admin/AdminUploadProperties";
import AdminCreateCommercialProperty from "./components/admin/AdminCreateCommercialProperty";
import {addRefferral} from "./api/api";
import AccountVerificationSuccessContainer from "./containers/AccountVerificationSuccessContainer";
import AccountVerificationFailureContainer from "./containers/AccountVerificationFailureContainer";
import AccountUpdateFailureContainer from "./containers/AccountUpdateFailureContainer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    /* preloadedState, */ composeEnhancers(
        applyMiddleware(thunk)
    )
);

// eslint-disable-next-line
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
};
firebase.initializeApp(config);

let currentUser = null;
// eslint-disable-next-line
let firebaseListener = firebase.auth().onAuthStateChanged((user) => {
    currentUser = user;
    if (user) {

        window.branch.data(function(err, data) {
            if (!!data && !!data.data_parsed && !!data.data_parsed.affiliate_code) {
                addRefferral(data.data_parsed.affiliate_code).then(() => null).catch(() => null);
            }
        });

        store.dispatch(loading(true));
        store.dispatch(loginFirebaseUser(user));
        store.dispatch(getCampableUser());
        store.dispatch(getTags());
        store.dispatch(getCampableUserBookings());
        store.dispatch(getCampableUserReviews());
        store.dispatch(loading(false));
        store.dispatch(hideLoginOverlay());
    } else {
        //firebaseListener();
        //console.log("There is no current firebase user...");
    }
});

const history = syncHistoryWithStore(browserHistory, store);

document.addEventListener('DOMContentLoaded', () => {

    function requireAuth() {
        //WOOOOO HOOOO INTERNET EXPLODER HACKY MEH PACKEY
        // console.log(detectBrowser(navigator.userAgent).name);
        if (detectBrowser(navigator.userAgent).name === 'ie') {
            setTimeout(() => {
                if (!currentUser) {
                    store.dispatch(showLoginOverlay())
                }
            }, 850)
        } else {
            if (!hasLocalStorageUser()) {
                store.dispatch(showLoginOverlay());
            }
        }
    }

    render(
        <Provider store={store}>
            <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
                {/*<Route path={Routes.LOGIN} component={Landing}/>
                <Route path={Routes.SIGN_UP} component={Landing}/>*/}
                <Route path={Routes.WIDGET + ':slug'} component={WidgetContainer}/>

                <Route path="/" component={App}>
                    <IndexRoute component={HomeContainer}/>
                    <Route path={Routes.HOSTING_LOCATIONS} component={HostLocationsContainer} onEnter={requireAuth}/>
                    <Route path={Routes.HOSTING_LOCATIONS_ID + ':locationId'} component={HostLocationContainer} onEnter={requireAuth}/>
                    <Route path={Routes.HOSTING_BOOKINGS} component={HostBookingsContainer} onEnter={requireAuth}/>
                    <Route path={Routes.HOSTING_TRANSACTIONS} component={HostTransactionsContainer} onEnter={requireAuth}/>
                    <Route path={Routes.ACCOUNT} component={AccountContainer} onEnter={requireAuth}/>
                    <Route path={Routes.REQUIREMENTS} component={RequirementsContainer} onEnter={requireAuth}/>
                    <Route path={Routes.ACCOUNT_VERIFICATION_SUCCESS} component={AccountVerificationSuccessContainer} onEnter={requireAuth}/>
                    <Route path={Routes.ACCOUNT_VERIFICATION_FAILURE} component={AccountVerificationFailureContainer} onEnter={requireAuth}/>
                    <Route path={Routes.ACCOUNT_UPDATE_FAILURE} component={AccountUpdateFailureContainer} onEnter={requireAuth}/>
                    <Route path={Routes.BOOKINGS} component={BookingsContainer} onEnter={requireAuth}/>
                    <Route path={Routes.CONTACT_US} component={ContactContainer}/>
                    <Route path={Routes.BECOME_A_HOST} component={HostOnboardingContainer} onEnter={requireAuth}/>
                    <Route path={Routes.HOSTING_CREATE_A_PROPERTY} component={PropertyOnboardingContainer} onEnter={requireAuth}/>
                    <Route path={Routes.ONBOARDING} component={OnboardingContainer} onEnter={requireAuth}/>
                    <Route path={Routes.LOCATIONS} component={LocationsContainer}/>
                    <Route path={Routes.LOCATIONS_ID + ':locationId'} component={LocationContainer}/>
                    <Route path={Routes.ADMIN} /*component={AdminContainer} onEnter={requireAuth}*/>
                        <IndexRoute component={AdminLocationsContainer}/>
                        <Route path={Routes.ADMIN_TAGS} component={AdminTagsContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_LOCATIONS} component={AdminLocationsContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_AFFILIATES} component={AdminAffiliatesContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_DATA_IMPORT} component={AdminUploadProperties} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_CREATE_COMMERCIAL}  component={AdminCreateCommercialProperty} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_AFFILIATES_ID + ':userId/*'} component={AdminAffiliateContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_LOCATIONS_ID + ':locationId/*' } component={AdminLocationContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_USERS} component={AdminUsersContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_CAMPAIGNS} component={AdminCampaignsContainer} onEnter={requireAuth}/>
                        <Route path={Routes.ADMIN_USERS_ID + ':userId/*'} component={AdminUserContainer} onEnter={requireAuth}/>
                    </Route>

                    <Route path={Routes.COLLECTIONS} component={CollectionContainer}/>
                    <Route path={Routes.COLLECTION_PAGE + "*"} component={CollectionContainer}/>
                    <Route path={Routes.FAQ_WIFI} component={FaqContainer}/>
                    <Route path="*" component={PageContainer}/>
                </Route>
            </Router>
        </Provider>
        , document.getElementById('root'))
});
