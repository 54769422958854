export const validate = (values) => {

    const errors = {};

    if (!values.address) {
        errors.address = 'Required'
    } else if (!values.address.latitude) {
        errors.address = 'Please select an address from the options provided in the dropdown'
    }

    if (!values.title) {
        errors.title = 'Required'
    }

    if (values.propertyType === "Select One") {
        errors.propertyType = 'Required'
    }

    if (!values.spaces) {
        errors.spaces = 'Required'
    } else if (isNaN(Number(values.spaces))) {
        errors.spaces = 'Must be a number'
    } else if (values.spaces > 1500) {
        errors.spaces = 'You cannot have more than 1500 spaces'
    }


    if (!values.price) {
        errors.price = 'Required'
    } else if (isNaN(Number(values.price))) {
        errors.price = 'Must be a number';
    } else {
        if (values.price < 1000) {
            errors.price = 'You cannot charge less than $10 a night';
        } else if (values.price > 99900) {
            errors.price = 'You cannot charge more than $999 a night';
        }
    }

    return errors
}



