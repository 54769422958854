import React, {Component} from 'react';
import ValidatedFileInput from '../../components/common/ValidatedFileInput';

export default class AccountImageCard extends Component {


    render() {

        return (
            <div className="profilePhoto">
                <ValidatedFileInput name="image" />
            </div>
        );
    }
}