import React, {Component} from "react";
import {Button, Col, Grid, Row, Tab, Tabs} from "react-bootstrap";
import GoogleMap from "google-map-react";
import ListingsItem from "../content/ListingsItem";
import Marker from "../common/widgets/Marker";
import debounce from "lodash/debounce";
import {browserHistory} from "react-router";
import {LOCATIONS_ID} from "../../constants/Routes";

export default class Locations extends Component {

    //onMapChange = debounce(({center, zoom}) => {this.props.onChange(center, zoom)} || noop, 1000);


    constructor(props) {
        super(props);
        this.onMapChange = this.onMapChange.bind(this);
        this.onMarkerEnter = this.onMarkerEnter.bind(this);
        this.onMarkerLeave = this.onMarkerLeave.bind(this);
        this.onMarkerPress = this.onMarkerPress.bind(this);
        this.calculateDistance = this.calculateDistance.bind(this);
        this.removeLocationTag = this.removeLocationTag.bind(this);

        this.state = {
            hovered: null,
        };

        this.tiles = {};
    }

    onMapChange(event) {
        if (this.props.onMapChange) {
            this.props.onMapChange(event.center, event.zoom)
        }
    }

    onMarkerEnter(locationId) {
        this.setState({
            hovered: locationId,
        });
    }

    onMarkerLeave() {
        this.setState({
            hovered: null,
        });
    }

    onMarkerPress(x) {
        browserHistory.push(LOCATIONS_ID + x);
    }

    calculateDistance(lat1, lon1, lat2, lon2, unit) {
        let radlat1 = Math.PI * lat1 / 180;
        let radlat2 = Math.PI * lat2 / 180;
        let theta = lon1 - lon2;
        let radtheta = Math.PI * theta / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
            dist *= 1.609344
        }
        return dist
    }

    removeLocationTag(tagId) {
        let index = this.props.locationFilter.tags.indexOf(tagId);
        let newTags = this.props.locationFilter.tags;
        newTags.splice(index, 1);
        let newLocationFilter = {
            ...this.props.locationFilter,
            tags: newTags,
        };

        this.props.setLocationFilter(newLocationFilter)
    }

    render() {

        let sortedLocations = [];
        for (let i = 0; i < this.props.locations.length; i++) {
            let location = this.props.locations[i];
            let distance = this.calculateDistance(this.props.centre.lat, this.props.centre.lng, location.latitude, location.longitude, 'K');
            sortedLocations.push({...location, distance: distance});
        }

        sortedLocations.sort((a, b) => {
            return a.distance - b.distance;
        });

        let markers = [];

        let tiles = sortedLocations.map((location, i) => {
            if (this.props.locationFilter && this.props.locationFilter.tags.length > 0) {
                let bool = false;
                for (let i = 0; i < this.props.locationFilter.tags.length; i++) {
                    let filterTag = this.props.locationFilter.tags[i];
                    if (location.tags.indexOf(filterTag) > -1) {
                        bool = true;
                    } else {
                        bool = false;
                        break;
                    }
                }
                if (!bool) {
                    return null;
                }
            }

            markers.push(<Marker key={i}
                                 location={location}
                                 lat={location.latitude}
                                 lng={location.longitude}
                                 onMarkerPress={this.onMarkerPress}
                                 onMarkerEnter={this.onMarkerEnter}
                                 onMarkerLeave={this.onMarkerLeave}/>)

            return (

                <ListingsItem key={location.id}
                              location={location}
                              active={this.state.hovered === location.id}
                              setRef={(ref) => {
                                  this.tiles[location.id] = ref
                              }}/>

            )
        });

        let tegs = [];
        if (this.props.locationFilter && this.props.locationFilter.tags) {
            tegs = this.props.locationFilter.tags.map((tagId, i) => {

                return (
                    <div className="filter-chip" key={i}>
                        <a onClick={() => {
                            this.removeLocationTag(tagId)
                        }}>{this.props.tagsObj[tagId].label}</a>
                    </div>
                );
            });
        }


        return (
            <Grid fluid id="locations-map">
                <Row>
                    {/*Mobile view*/}
                    <Col className="visible-xs">
                        <Tabs defaultActiveKey={2} id="uncontrolled-tab-example">
                            <Tab eventKey={1} title="List View">
                                <Col className="location-tiles">{tiles}</Col>
                            </Tab>
                            <Tab eventKey={2} title="Map View">
                                <Col className="google-map" style={{height: '70vh'}}>
                                    <GoogleMap
                                        bootstrapURLKeys={{key: 'AIzaSyAiNWZ4MV_uIdNzrfmiwqfRmPlloBeHo24'}}
                                        center={this.props.centre}
                                        zoom={this.props.zoom}
                                        draggable
                                        debounced
                                        onChange={this.onMapChange}>
                                        {markers}
                                    </GoogleMap>
                                </Col>
                            </Tab>
                        </Tabs>
                    </Col>
                    {/*End mobile view*/}

                    <Col className="hidden-xs location-container-left" xs={12} sm={6} md={7} lg={8}>


                        <Row className="tags-container">
                            <Col xs={12} className="location-filter-container">
                                <Button className="filter-button" onClick={this.props.showFilterDialogPress}>Filter</Button>
                                {tegs}
                            </Col>
                        </Row>

                        <Row className="location-tiles">
                            {tiles}
                        </Row>
                    </Col>

                    <Col className="hidden-xs google-map" xs={12} sm={6} md={5} lg={4}
                         style={{height: '91vh', padding: 0}}>
                        <GoogleMap
                            bootstrapURLKeys={{key: 'AIzaSyAiNWZ4MV_uIdNzrfmiwqfRmPlloBeHo24'}}
                            center={this.props.centre}
                            zoom={this.props.zoom}
                            draggable
                            debounced
                            onChange={this.onMapChange}>
                            {markers}
                        </GoogleMap>
                    </Col>
                </Row>
            </Grid>
        )
    }
}
