import React from 'react';
import Dropzone from 'react-dropzone'

export default class SortableDndItem extends React.Component {

    render() {
        return (
            <Dropzone className="dropzone" onDrop={this.props.onDrop}>
                <h4>Add Images</h4>
                <p>Drop images here, or click to select images to upload.</p>
            </Dropzone>
        );
    }
};