import React, {Component} from 'react';
import '../../App.css';
import {Pager, Table} from "react-bootstrap"
import moment from 'moment';
import PriceFormatterStripe from '../../components/common/PriceFormatterStripe';
import Load from '../../components/Load';

export default class HostTransactionsController extends Component {


    constructor(props) {
        super(props);

        this.onNextPageClick = this.onNextPageClick.bind(this);
        this.onPreviousPageClick = this.onPreviousPageClick.bind(this);

        this.state = {
            pagerPosition: 0,
        }
    }

    onNextPageClick() {
        if (!!this.props.transactions && !!this.props.transactions.items && this?.props?.transactions?.items?.length <= ((this.state.pagerPosition + 1) * 50)) {
            this.props.onNextPageClick(this.props.transactions.cursor);
        }

        this.setState({
            pagerPosition: this.state.pagerPosition + 1,
        });
    }

    onPreviousPageClick() {
        this.setState({
            pagerPosition: this.state.pagerPosition - 1,
        });
    }

    render() {




        let transactionItemViews = [];

        if (!!this.props.transactions) {
            let transactionItems = this.props.transactions.items;

            console.log("transaction Items",transactionItems)
            if (transactionItems?.length === 0) {
                transactionItemViews = (
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>You have no transactions.</td>
                        <td>&nbsp;</td>
                    </tr>
                );
            } else {
                for (let i = 0; i < 50; i++) {
                    const transaction = transactionItems[(this.state.pagerPosition * 50) + i];
                    if (transaction) {
                        transactionItemViews.push(
                            <tr key={i}>
                                <td width="15">&nbsp;</td>
                                <td className="transaction-date">{moment(transaction.date).format('Do MMM YYYY')}</td>
                                <td className="transaction-type">{transaction.description}</td>
                                <td className="transaction-total">{PriceFormatterStripe(transaction.amount)}</td>
                                <td width="15">&nbsp;</td>
                            </tr>
                        );
                    }
                }
            }
        }


        let payoutDate = moment("15-01-2019", "DD-MM-YYYY").set("month", moment().month());

        if (payoutDate.isBefore(moment())) {
            payoutDate = payoutDate.clone().add(1, "month")
        }

        return (
            <div className="host-transactions-controller card">

                <div className={"host_next_payout"}>
                    <span>Next Payout Amount: {PriceFormatterStripe((this.props.accountBalance || {available: [{amount: 0}]}).available[0].amount)}</span>
                </div>
                <div className={"host_next_payout"}>
                    <span>Next Payout Date: {payoutDate.format("ddd DD MMM YYYY")}</span>
                </div>
                <Table responsive className="host-booking-row">
                    <thead>
                    <tr>
                        <th width="15">&nbsp;</th>
                        <th width="120">Date</th>
                        <th>Type</th>
                        <th width="80">Total $</th>
                        <td width="15">&nbsp;</td>
                    </tr>
                    </thead>
                    <tbody>
                    {transactionItemViews}
                    </tbody>
                </Table>

                {(transactionItemViews?.length === 0 && this.state.pagerPosition > 0) || (transactionItemViews?.length === 0 && !this.props.transactions) ? <Load /> : null}

                <Pager className="pager">
                    <Pager.Item previous
                                onClick={this.onPreviousPageClick}
                                disabled={this.state.pagerPosition === 0}>&larr; Previous Page</Pager.Item>
                    <Pager.Item next
                                onClick={this.onNextPageClick}
                                disabled={transactionItemViews?.length !== 50}>Next Page &rarr;</Pager.Item>
                </Pager>
            </div>
        );
    }
}
