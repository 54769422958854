import {Component} from 'react';

export default class HostHomepageCallToActionBanner extends Component {

    render() {

        return null;
    }

}
