import React, {Component} from 'react';
import Load from '../Load';
import ValidatedCheckBoxGroup from '../../components/common/ValidatedCheckBoxGroup';
import Card from '../../components/Card';

export default class LocationAdminPanel extends Component {

    render() {


        if (this.props.location && this.props.tags) {
            let tags = this.props.tags.filter((tag)=>{
               return (tag.group !== 'site_feature');
            });

            return (

                <div className="location-detail-view">
                    <Card title="Admin Panel">

                    <ValidatedCheckBoxGroup label="Admin Tags" name="tags"
                                            checkOptions={tags}/>
                    </Card>

                </div>

            );
        }

        return (
            <Load />
        );
    }
}


