/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by chris on 3/05/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import * as Type from "../actions/overlay";

const initialState = {
    loginOverlayShown: false,
    isSignUp: false,
};

export default function overlay(state = initialState, action) {
    switch (action.type) {
        case Type.SHOW_LOGIN_OVERLAY:
            return {
                loginOverlayShown: true,
                isSignUp: action.isSignUp,
            };
        case Type.HIDE_LOGIN_OVERLAY: {
            return initialState;
        }
        default:
            return state;
    }
}