import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as formReducer} from 'redux-form';

import auth from './auth';
import data from './data';
import content from './content';
import overlay from './overlay';
import admindata from './admindata';
import widgetData from './widget';
import languages from './languages';

export default combineReducers({
    auth,
    routing: routerReducer,
    data,
    content,
    form: formReducer,
    overlay,
    admindata,
    widgetData,
    languages
})
