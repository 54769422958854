import React, {Component} from 'react';
import {Field} from 'redux-form';
import TextInput from './inputs/TextInput';

export default class ValidatedTextInput extends Component {

	render() {

        let className = "";
        if (this.props.className) {
            className = this.props.className;
        }

		return (
			<div className={className}>
        		<Field onChange={this.props.onChange} component={TextInput} {...this.props}/>
      		</div>	
		);
	}  
}
