import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import ContentRow from '../ContentRow';
import {Grid,} from 'react-bootstrap';
import Banner from './Banner';
import {LOCATIONS} from "../../../constants/Routes";
import {clearLocations} from "../../../actions";
import {showLoginOverlay} from "../../../actions/overlay";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.searchLocation = this.searchLocation.bind(this);
        this.showLoginOverlay = this.showLoginOverlay.bind(this);
    }

    searchLocation(location) {
        //console.log("Search")
        this.props.dispatch(clearLocations());
      browserHistory.push({
        pathname: LOCATIONS,
        query: {
          c: location.latitude + ',' + location.longitude,
          address: location.address
        }
      })
    }

    showLoginOverlay(isSignUp = false) {
        this.props.dispatch(showLoginOverlay(isSignUp))
    }

    render() {

        let bannerDetails = this.props.page.homeBanner;
        let banner = <Banner searchLocation={this.searchLocation} bannerDetails={bannerDetails} />;


        let rows = [];
        this.props.page.rows.map((row, i) => {
            let rowComponent = <ContentRow key={i} row={row} showLoginOverlay={this.showLoginOverlay} isLoggedIn={!!this.props.user}/>
            rows.push(rowComponent);
            return null;
        });


        return (
            <div>
                {banner}
                <Grid className="">
                    {rows}
                </Grid>
            </div>
        );
    }


}

export default HomePage = connect((state) => {
    return {
        user: state.data.campableUser,
    }
})(HomePage);
