/**
 * Created by Chris on 29/03/17.
 */


export const LINK_ANDROID_APP_STORE = 'https://play.google.com/store/apps/details?id=com.campable';
export const LINK_IOS_APP_STORE ='https://itunes.apple.com/us/app/campable/id1209349485';




export function toProperCase(str) {
    return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}

