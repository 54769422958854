import React, {Component} from 'react';
import Card from '../../Card';
import ValidatedCheckBoxGroup from '../../common/ValidatedCheckBoxGroup';

export default class AddAmenities extends Component {

    render() {
        return (
            <Card title="Add Features" onboardingCard={this.props.notOnBoarding ? null : true} helpText="Give Guests an idea of what features your site has.">
                <ValidatedCheckBoxGroup name={"Features"} checkOptions={this.props.tags}/>
            </Card>
        );
    }
}