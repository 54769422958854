import React, {Component} from 'react';
import Card from '../../components/Card';
import ValidatedTextInput from '../../components/common/ValidatedTextInput';
import ValidatedCreditCardInput from "../common/ValidatedCreditCardInput";
import Loading from "react-loading";

export default class AccountPayment extends Component {

    render() {


        let buttonText = 'Add a Credit Card';

        if (this.props.showEditCreditCard) {
            buttonText = 'Cancel';
        } else if (this.props.user.paymentInformation.valid) {
            buttonText = 'Change Credit Card';
        }


        let validCreditCard = null;
        if (this.props.user.paymentInformation.valid) {
            validCreditCard = (
                <div>
                    <label>Card Number</label>
                    <p className="form-disabled-field">{'XXXX-XXXX-XXXX-' + this.props.user.paymentInformation.card.last4}</p>

                    <label>Card Expiry</label>
                    <p className="form-disabled-field">{this.props.user.paymentInformation.card.expiryMonth + '/' + this.props.user.paymentInformation.card.expiryYear}</p>
                </div>
            );
        }


        let loading = null;
        if (this.props.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'/>
                </div>
            );
        }

        let editCreditCard = null;
        if (this.props.showEditCreditCard) {
            editCreditCard = (
                <div className="animated fadeIn edit-credit-card-container">
                    <ValidatedCreditCardInput name="cardNumber" label="Card Number" placeholder="e.g. 1234-5678-9101-1121" maxLength={19}/>
                    <ValidatedTextInput name="cardExpMonth" label="Card Expiry Month" placeholder="e.g. 12" maxLength={2}/>
                    <ValidatedTextInput name="cardExpYear" label="Card Expiry Year" placeholder="e.g. 24" maxLength={2}/>
                    <ValidatedTextInput name="cardCVV" label="Card CVV" placeholder="e.g. 123" maxLength={3}/>
                </div>
            );
        }

        return (
            <div className="account-payment">
                <Card title="Payment Details">
                    {validCreditCard}
                    <a onClick={this.props.onEditCreditCardClick}>{buttonText}</a>
                    {editCreditCard}
                    {loading}
                </Card>
            </div>
        );
    }
}


