import React, {Component} from 'react';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import {Col, Grid, Row} from 'react-bootstrap';
import AccountProfileController from '../../components/account/AccountProfileController'
import AccountPaymentController from '../../components/account/AccountPaymentController'
import AccountHostDetailsController from '../../components/account/AccountHostDetailsController'
import LocationPanel from '../../components/locations/LocationPanel'
import AdminUserMenu from '../../components/admin/AdminUserMenu'
import BookingsController from '../../components/bookings/BookingsController'
import AdminTransactionsContainer from './AdminTransactionsContainer'
import Load from '../../components/Load';
import TextEntryDialog from '../../components/admin/TextEntryDialog';
import '../../App.css';
import {
    adminAddAffiliateToUser,
    adminFetchAffiliates,
    adminFetchUser,
    adminFetchUserBookings,
    adminFetchUserProperties, adminRemoveAffiliatefromUser,
    cancelCampableUserBooking,
    getLocation,
    refundBooking,
    showAlert,
    updateCampableUser,
    updatePropertyStatus
} from "../../actions/index";
import {ADMIN_LOCATIONS_ID} from "../../constants/Routes";
import {changeUserPasswordAdmin} from "../../api/api";

class AdminUserContainer extends Component {


    constructor(props) {
        super(props);
        this.saveCampableUser = this.saveCampableUser.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.locationPressed = this.locationPressed.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
        this.refundBooking = this.refundBooking.bind(this);
        this.doRefundBooking = this.doRefundBooking.bind(this);
        this.onEditReferrerClick = this.onEditReferrerClick.bind(this);
        this.getLocation = this.getLocation.bind(this);


        this.state = {
            loadingUserProperties: false,
            pagerPosition: 0,
            loadingBookings: false,
            loadingAffiliates: false,
            showRefundReasonDialog: false,
            showEditReferrer: false,
            showEditPassword: false,
        }


    }

    componentWillMount() {
        let userId = this.props.params.userId;

        let user = this.props.users[userId];

        if (!!this.props.campableUser) {
            if (!user) {
                console.log("FETCHING");
                this.props.dispatch(adminFetchUser(userId));
            }
        }
    }


    componentWillReceiveProps(nextProps) {

        if (!!nextProps.campableUser) {
            let userId = this.props.params.userId;

            let user = this.props.user;

            if (!user) {
                this.fetchData(nextProps);
            }

            if (user && !nextProps.userProperties && !this.state.loadingUserProperties) {
                this.fetchData(nextProps, false);
            }

            if (user && !nextProps.userBookings && !this.state.loadingBookings) {
                this.fetchData(nextProps, true);
            }

            if (user && !nextProps.affiliates && !this.state.loadingAffiliates) {
                this.fetchData(nextProps, true);
            }

            if (nextProps.userProperties) {
                this.setState({loadingUserProperties: false})
            }

            if (nextProps.userBookings) {
                this.setState({loadingBookings: false})
            }

            if (nextProps.affiliates) {
                this.setState({loadingAffiliates: false})
            }
        }
    }

    fetchData(props, getBookings) {

        let userId = props.params.userId;
        let user = this.props.user;

        //load the property from the url parameter
        if (!user) {
            this.props.dispatch(adminFetchUser(userId));
        } else {
            if (!props.userProperties && !getBookings) {
                this.setState({loadingUserProperties: true});
                this.props.dispatch(adminFetchUserProperties(userId));
            }

            if (!props.userBookings && getBookings) {
                this.setState({loadingBookings: true});
                this.props.dispatch(adminFetchUserBookings(userId));
            }

            if (!props.affiliates && props.userBookings && props.userProperties) {
                this.setState({loadingAffiliates: true});
                this.props.dispatch(adminFetchAffiliates());
            }
        }
    }

    locationPressed(id) {
        //this.props.dispatch(fetchProperty(id));
        browserHistory.push(ADMIN_LOCATIONS_ID + id + '/details');
    }

    handleSelectChange(propertyId, e) {
        if (e === "Listed") {
            this.props.dispatch(updatePropertyStatus(propertyId, "active"));
        } else if (e === "Unlisted") {
            this.props.dispatch(updatePropertyStatus(propertyId, "inactive"));
        }
    }

    onNextPageClick() {
        if (this.props.propertyBookings && this.props.propertyBookings.length <= ((this.state.pagerPosition + 1) * 50)) {
            this.fetchData(this.props, this.props.bookingsCursor);
        }

        this.setState({
            pagerPosition: this.state.pagerPosition + 1,
        });
    }

    onPreviousPageClick() {
        this.setState({
            pagerPosition: this.state.pagerPosition - 1,
        });
    }

    saveCampableUser(values) {
        let user = {};
        if (values.firstName && values.lastName) {
            user.names = {
                firstName: values.firstName,
                lastName: values.lastName
            };
        }

        if (values.phone) {
            user.contact = {
                phone: values.phone,
            };
        }

        if (values.image) {
            user.image = {
                url: values.image
            }
        }

        this.props.dispatch(updateCampableUser(user));
    }

    cancelBooking(bookingUIDS) {
        if (bookingUIDS) {
            this.props.dispatch(showAlert('info', 'Cancelling booking(s)...'));
            bookingUIDS.map((bookingUID) => {
                this.props.dispatch(cancelCampableUserBooking(bookingUID));
                return null;
            });
        }
    }

    refundBooking(bookingUID) {
        this.setState({showRefundReasonDialog: true, bookingToRefund: bookingUID});
    }

    doRefundBooking(bookingUID, reason) {
        if (bookingUID) {
            this.props.dispatch(showAlert('info', 'Refunding booking...'));
            // eslint-disable-next-line
            this.props.dispatch(refundBooking(this.state.bookingToRefund, reason));
        }

        this.setState({showRefundReasonDialog: false, bookingToRefund: undefined});
    }

    onEditReferrerClick() {
        this.setState({
            showEditReferrer: !this.state.showEditReferrer,
        });
    }

    onRemoveReferrerClick = (user) => {
        this.props.dispatch(adminRemoveAffiliatefromUser(user.id));
    };

    getLocation(id) {
        if (!!this.props.campableUser) {
            this.props.dispatch(getLocation(id));
        }
    }

    onAddAffialate = (affialteEmail) => {
        this.props.dispatch(adminAddAffiliateToUser(this.props.user.id, affialteEmail, this.onAddAffialateSuccess));
    };

    onAddAffialateSuccess = () => {

        this.setState({
            showEditReferrer: false,
        });

    };

    onEditPasswordClick = () => {
        this.setState({
            showEditPassword: !this.state.showEditPassword,
        });
    };

    changeUserPassword = (newPassword) => {
        this.props.dispatch(showAlert("info", "Updating...", "Updating user's password."));
        changeUserPasswordAdmin(this.props.params.userId, newPassword).then((resp) => {
            this.props.dispatch(showAlert("success", "Successfully changed the user's password."));
        }).catch((err) => {
            this.props.dispatch(showAlert("danger", "Failed to change user password. Error: ", err.message));
        })
    };

    render() {


        let user = this.props.user;

        if (!user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let userId = user.userId;

        const refundReasonDialog = this.state.showRefundReasonDialog ?
            <TextEntryDialog title="This refund requires a reason" hint="...enter a reason"
                             onCancel={() => {
                                 this.setState({showRefundReasonDialog: false})
                             }}
                             onOk={(reason) => this.doRefundBooking(this.state.bookingToRefund, reason)}/>
            : null;

        const addAffiliateDialog = this.state.showEditReferrer ?
            <TextEntryDialog title="Add affiliate" hint="Affiliate email"
                             textBoxType={'input'}
                             onCancel={() => {
                                 this.setState({showEditReferrer: false})
                             }}
                             onOk={this.onAddAffialate}/>
            : null;

        const changePasswordDialog = this.state.showEditPassword ?
            <TextEntryDialog title="Set user password:" hint="Password"
                             textBoxType={'input'}
                             minLength={6}
                             onCancel={() => {
                                 this.setState({showEditPassword: false})
                             }}
                             onOk={this.changeUserPassword}/>
            : null;

        let showUserBookingsItems = [];

        if (this.props.userBookings && this.props.userBookings) {
            let bookingItems = this.props.userBookings;
            for (let i = 0; i < 50; i++) {
                let booking = bookingItems[(this.state.pagerPosition * 50) + i];
                if (booking) {

                    showUserBookingsItems.push(booking);
                }
            }
        }

        let component = null;
        if (this.props.params.splat.indexOf('details') > -1) {
            component = (<div className="account-container">
                <AccountProfileController
                    onSubmit={this.saveCampableUser}
                    user={user}
                    initialValues={{
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: user.phone,
                        image: user.image,
                    }}
                    affiliates={this.props.affiliates}
                    onEditPasswordClick={this.onEditPasswordClick}
                    onEditReferrerClick={this.onEditReferrerClick}
                    onRemoveReferrerClick={this.onRemoveReferrerClick}
                />
                <AccountPaymentController initialValues={undefined} user={user}/>
                <AccountHostDetailsController initialValues={undefined} user={user}/>
            </div>)
        } else if (this.props.params.splat.indexOf('properties') > -1) {
            if (this.props.userProperties === undefined || this.props.userProperties === '') {
                component = <h1>No Properites</h1>
            } else {
                component = (this.props.userProperties || []).map((location) => {
                    return <LocationPanel key={location.id} location={location}
                                          campableUser={user}
                                          locationPressed={this.locationPressed}
                                          handleSelectChange={this.handleSelectChange}/>
                });
            }
        } else if (this.props.params.splat.indexOf('bookings') > -1) {

            if (this.props.userBookings === undefined || this.props.userBookings === '' || this.props.userBookings.length === 0) {
                component = <h1>No Bookings</h1>
            } else {
                component = (<BookingsController administratorView={userId !== this.props.campableUser} campableUserBookings={this.props.userBookings}
                                                 campableUserReviews={[]}
                                                 cancelBooking={this.cancelBooking}
                                                 refundBooking={this.refundBooking}
                                                 locations={this.props.locations === undefined ? null : this.props.locations}
                                                 campableUser={this.props.campableUser}
                                                 getLocation={this.getLocation}/>)
            }
        }

        else if (this.props.params.splat.indexOf('transactions') > -1) {
            component = (<AdminTransactionsContainer route={this.props.route.path} user={user}/>)
        }

        return (
            <Grid className="main-container">
                {addAffiliateDialog}
                {refundReasonDialog}
                {changePasswordDialog}
                <Row>
                    <h1>{user ? user.firstName + " " + user.lastName : "User: No Name"}</h1>
                </Row>
                <Row>
                    <Col md={2} className="menu">
                        <AdminUserMenu userId={user.id}
                                       currentRoute={this.props.params.splat}/>
                    </Col>
                    <Col md={10}>
                        {component}
                    </Col>
                </Row>
            </Grid>
        );
    }
}

export default AdminUserContainer = connect((state, props) => {

    const userId = props.params.userId;

    const userProperties = state.admindata.userProperties && state.admindata.userProperties[userId] ? state.admindata.userProperties[userId] : '';
    const userBookings = state.admindata.userBookings && state.admindata.userBookings[userId] ? state.admindata.userBookings[userId] : '';

    return {
        user: (state.admindata.users || {})[userId],
        users: (state.admindata.users || {}),
        userProperties: userProperties,
        userBookings: state.admindata.userBookings && state.admindata.userBookings[userId] ? state.admindata.userBookings[userId] : '',
        campableUser: state.data.campableUser,
        shallowComparisonFix: state.admindata.shallowComparisonFix,
        locations: state.data.locationsIndex,
        affiliates: state.admindata.affiliates
    }
})(AdminUserContainer);