import React, {Component} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import {Pager} from "react-bootstrap";
import {campableUserReceived, showAlert} from "../../actions/index";
import Card from "../Card";
import AccountImageCard from "../account/AccountImageCard";
import Loading from "react-loading";
import {updateCampableUser} from "../../api/api";

class AccountImageController extends Component {


    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            loading: false
        };
    }

    onSubmit(values) {
        if (!this.props.pristine || this.props.dirty) {
            let user = {
                image: {
                    url: values.image,
                }
            };
            updateCampableUser(user).then((userResponse) => {
                this.props.dispatch(campableUserReceived(userResponse));
                this.props.dispatch(showAlert('success', 'Account details successfully updated', ''));
                this.setState({
                    loading: false,
                });
                this.props.onNextStepPress();
            }).catch((err) => {
                this.props.dispatch(showAlert('danger', 'There was an error while updating your account details.', 'If this problem persists please contact Campable support. Error: ' + err));
                this.setState({
                    loading: false,
                });
            });
        } else {
            this.setState({
                loading: false,
            });
            this.props.onNextStepPress();
        }
    }

    render() {


        let loading = null;
        if (this.state.loading) {
            loading = (
                <div className="onboarding-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }


        return (
            <Card title="Account details">
                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <AccountImageCard />
                    <Pager>
                        <Pager.Item previous onClick={this.props.onBackPress}>Back</Pager.Item>
                        <Pager.Item next type="submit" onClick={this.props.handleSubmit(this.onSubmit)}>Next</Pager.Item>
                    </Pager>
                </form>
                {loading}
            </Card>
        );
    }
}

AccountImageController = reduxForm({
    form: 'accountImageForm'
})(AccountImageController);

export default AccountImageController = connect((state) => {
    return {
        initialValues: {
            image: state.data.campableUser.image,
        }
    }
})(AccountImageController);

