import React, {Component} from 'react';
import '../../App.css';
import BookingRow from './HostBookingRow';
import values from 'lodash/values';

export default class HostBookingsPanel extends Component {

    render() {
        const bookings = values(this.props.hostBookings);
        const numOfBookings = bookings.length;
        const bookingRows = bookings.map((booking, i) => {
            let isLastItem = (numOfBookings-1 === i);
            return <BookingRow booking={booking} cancelPressed={this.props.cancelPressed} key={i} isLastItem={isLastItem}/>;
        });


        return (
            <div>
                {bookingRows}
            </div>
        );
    }
}
