/**
 * Created by Kyle on 12/04/17.
 */

import React from 'react';

export default function formatterStripe(priceCents) {

    let price = (priceCents / 100).toFixed(2);
    let isMinus = null;
    if (price < 0) {
        isMinus = <span>-</span>;
        price = (price * -1).toFixed(2);
    }
    return <span>{isMinus}<span className="price-dollar-symbol">$</span>{price}</span>;
}