import React, {Component} from 'react';
import {createImage} from '../../../api/api';
import {Image} from 'react-bootstrap';
import defaultImage from '../../../resources/images/camera-man@2x.png';
import {connect} from 'react-redux';
import {showAlert} from '../../../actions/index';

class ImageInput extends Component {

    constructor(props) {
        super(props);
        this.imageChanged = this.imageChanged.bind(this);
    }

    imageChanged() {
        //Add image to state
        let imageInput = this.imageInput;

        if (imageInput.files && imageInput.files.length > 0) {
            this.props.dispatch(showAlert("info", "Uploading image...", "Your image is being uploaded..."));
            createImage(imageInput.files).then(images => {
                this.props.dispatch(showAlert("success", "Upload complete", "Your image was uploaded successfully"));
                this.props.input.onChange(images[0].url);
            });
        }
    }

    render() {
        let label = "Upload Image";
        let image = defaultImage;
        if (this.props.input.value) {
            image = this.props.input.value;
            label = "Edit Image";
        }

        return (
            <div className="image-input">
                <Image src={image} circle className="image-input-image-circle"/>
                <div className="file-input-box">
                    <input type="file" name="file" id="file" ref={(ref) => {this.imageInput = ref}} className="inputfile"
                           accept="image/jpg, image/png, image/jpeg,image/gif"
                           onChange={this.imageChanged} data-multiple-caption="{count} files selected" multiple/>
                    <label htmlFor="file">{label}</label>
                </div>
            </div>
        )
    }

}

export default ImageInput = connect(state => {
    return {}
})(ImageInput)