import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import {getPage} from '../actions';
import Load from '../components/Load';
import HomePage from '../components/content/Home/HomePage';

class HomeContainer extends Component {

    constructor(props) {
        super(props);
        this.props.dispatch(getPage("home"));

    }

    componentWillMount() {
        let code = this.props.location.query.affiliate;
        if(code !== undefined) {
            localStorage.setItem('affiliateReferralCode', code);
        }
    }

    render() {

        if (this.props.pages && this.props.pages['home']) {

            return (
                <HomePage page={this.props.pages['home']} />
            );
        }

        return (
           <div className="loaderContainer">
               <Load />
           </div>
        );
    }
}


export default HomeContainer = connect(state => {
    return {
        campableUser: state.data.campableUser,
        pages: state.content.pages
    }
})(HomeContainer)
