import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col, Grid, Row} from 'react-bootstrap';
import Load from '../components/Load';
import {browserHistory} from 'react-router';
import {isAdmin} from '../auth';
import '../App.css';
import CampableSearchBar from '../components/common/CampableSearchBar';
import LocationPanel from '../components/locations/LocationPanel';
import {updatePropertyStatus} from '../actions/';
import {ADMIN_LOCATIONS_ID} from "../constants/Routes";
import Toggle from 'react-toggle';
import './inline-styles/toggle.css';
import DropdownSelect from "../components/common/DropdownSelect";
import {adminGetLocations, clearLocations} from "../actions";

class AdminLocationsContainer extends Component {

    constructor(props) {
        super(props);
        this.searchLocation = this.searchLocation.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.locationPressed = this.locationPressed.bind(this);
        this.handleEnvironmentChange = this.handleEnvironmentChange.bind(this);
        this.onPropertyTypeChange = this.onPropertyTypeChange.bind(this);
        this.onStatusChange = this.onStatusChange.bind(this);

        this.state = {
            propertyTypeValue: 'property',
            propertyStatusValue: 'ACTIVE'
        };
    }

    handleEnvironmentChange(event) {
        // do something with event.target.checked
        // let useProduction = localStorage.setItem("useProduction",event.target.checked);
    }

    handleSelectChange(propertyId, e) {
        if (e === "Listed") {
            this.props.dispatch(updatePropertyStatus(propertyId, "active"));
        } else if (e === "Unlisted") {
            this.props.dispatch(updatePropertyStatus(propertyId, "inactive"));
        }
    }

    searchLocation(query) {

        let params = {
            freeFormQuery: query,
            type: this.state.propertyTypeValue,
            status: this.state.propertyStatusValue
        };

        this.props.dispatch(clearLocations());
        this.props.dispatch(adminGetLocations(params));
    }

    locationPressed(id) {
        //this.props.dispatch(fetchProperty(id));
        browserHistory.push(ADMIN_LOCATIONS_ID + id + '/details');
    }

    onPropertyTypeChange(e) {
        this.setState({
            propertyTypeValue: e
        })
    }

    onStatusChange(e) {
        this.setState({
            propertyStatusValue: e
        })
    }

    render() {

        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        const useProduction = (localStorage.getItem("useProduction") === 'true');

        let user = this.props.user;
        let toggle;
        if (process.env.REACT_APP_ENV !== 'production') {

            toggle = (
                <Row>


                </Row>
            );
        }

        let locationTypeSelector = (
            <Row>
                <Col md={2}>
                    <h4>Select Location Type</h4>
                </Col>
                <Col md={3}>
                    <div style={{marginTop: 10}}>
                        <DropdownSelect value={this.state.propertyTypeValue} selectOptions={["property", "commercial_campsite", "freedom_campsite"]}
                                        onChange={this.onPropertyTypeChange}/>
                    </div>
                </Col>
                <Col md={2}>
                    <h4>Use production locations</h4>
                </Col>
                {process.env.REACT_APP_ENV !== 'production' ? <Col md={1}>
                    <div style={{marginTop: 10}}>
                        <Toggle
                            defaultChecked={useProduction}
                            icons={false}
                            onChange={this.handleEnvironmentChange}/>
                    </div>
                </Col> : null}
                <Col md={3}>
                    <div style={{marginTop: 10}}>
                        <DropdownSelect value={this.state.propertyStatusValue} selectOptions={["ACTIVE", "INACTIVE"]} onChange={this.onStatusChange}/>
                    </div>
                </Col>
            </Row>

        );

        let panel = (this.props.locations || []).map((location) => {
            return <LocationPanel key={location.id} location={location}
                                  campableUser={this.props.user}
                                  locationPressed={this.locationPressed}
                                  handleSelectChange={this.handleSelectChange}/>
        });

        if (isAdmin(user)) {
            return (
                <Grid className="main-container">
                    <Row>
                        {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                        <Col md={12}>
                            <div>
                                <p>Locations Admin</p>
                                <CampableSearchBar onSearch={this.searchLocation}/>
                            </div>
                            {toggle}
                            {locationTypeSelector}
                            {panel}
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <div className="loaderContainer">
                <h1> Sorry you are not an admin. </h1>
            </div>
        )


    }
}

export default AdminLocationsContainer = connect((state) => {
    return {
        user: state.data.campableUser,
        locations: state.data.locations
    }
})(AdminLocationsContainer);