import React, {Component} from 'react';
import Slider from 'react-slick';
import Load from '../Load';

export default class LocationGallery extends Component {


    render() {

        let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToScroll: 1,
            variableWidth: true
        };

        let images = this.props.images.map(image=> {
            return <div key={image.original}><img src={image.original} alt="location" /></div>
        });


        if (this.props.images) {
            return (
                <Slider {...settings}>
                    {images}
                </Slider>
            );
        }

        return (
            <Load/>
        );


    }
}

