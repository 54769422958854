import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from "react-router";

class AccountVerificationSuccessContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div style={{textAlign: 'center'}}>
                    <h4>{"You're ready to go!"}</h4>
                    <br/>
                    <Link to={"/hosting/locations"}>
                        <Button>My Properties</Button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default AccountVerificationSuccessContainer;
