/**
 * Created by Chris on 3/04/17.
 */



export const LOGIN = '/login';
export const SIGN_UP = '/signup';
export const LOG_OUT = '/logout';

export const ACCOUNT = '/account';
export const REQUIREMENTS = '/requirements';
export const BOOKINGS = '/bookings';
export const CONTACT_US = '/contact-us';

export const ACCOUNT_VERIFICATION_SUCCESS = '/account-verification-success';
export const ACCOUNT_VERIFICATION_FAILURE = '/account-verification-failure';
export const ACCOUNT_UPDATE_FAILURE = '/account-update-failure';

export const BECOME_A_HOST = '/become-a-host';
export const ONBOARDING = '/onboarding';

export const LOCATIONS = '/locations';
export const LOCATIONS_ID = '/locations/';

export const COLLECTIONS = '/collections';
export const COLLECTION_PAGE = '/collections/';

export const HOSTING_LOCATIONS = '/hosting/locations';
export const HOSTING_LOCATIONS_ID = '/hosting/locations/';
export const HOSTING_BOOKINGS = '/hosting/bookings';
export const HOSTING_CREATE_A_PROPERTY = '/create-a-property';
export const HOSTING_TRANSACTIONS = '/hosting/transactions';

export const ADMIN = '/admin';
export const ADMIN_TAGS = '/admin/tags';
export const ADMIN_LOCATIONS = '/admin/locations';
export const ADMIN_LOCATIONS_ID = '/admin/locations/';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USERS_ID = '/admin/users/';
export const ADMIN_CAMPAIGNS = '/admin/campaigns';
export const ADMIN_AFFILIATES = '/admin/affiliates';
export const ADMIN_AFFILIATES_ID = '/admin/affiliates/';
export const ADMIN_DATA_IMPORT =  '/admin/dataimport';
export const ADMIN_CREATE_COMMERCIAL = '/admin/createcommercial';
export const WIDGET = '/widget/';
export const FAQ_WIFI = '/faq-wifi';

export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/privacy-policy#cookie-policy';