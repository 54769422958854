export const validate = (values, props) => {

    const errors = {};

    if (!values.bankAccount) {
        errors.bankAccount = 'Required'
    } else {
        let bankAccount = values.bankAccount.replace(new RegExp('-', 'g'), '');
        if (isNaN(Number(bankAccount))) {
            errors.bankAccount = 'Must be a number'
        } else if (bankAccount.length < 15) {
            errors.bankAccount = 'Bank account number should be in this format e.g 00-1234-5678912-00';
        } else if (bankAccount.length > 16) {
            errors.bankAccount = 'Bank account number should be in this format e.g 00-1234-5678912-00';
        }
    }

    return errors
}



