import React, {Component} from 'react';
import {ControlLabel, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';

export default class TextInput extends Component {


    render() {
        let validationState = null;
        let helpBlock;

        if (this.props.meta && this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;


        return (
            <FormGroup readOnly validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl
                    {...this.props.input}
                    {...inputProps}/>
                {helpBlock}
            </FormGroup>)
    }
}

