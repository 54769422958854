/**
 * Created by Chris on 14/03/17.
 */

export default function formatter(priceCents) {
    if(!priceCents) return priceCents;
    if (priceCents.toString().indexOf('-') > -1) {
        return priceCents / 1000;
    }
    return priceCents / 100;
}
