import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'react-bootstrap';
import Loading from 'react-loading';

export default class Dialog extends Component {


    static propTypes = {
        shown: PropTypes.bool,
        cancelable: PropTypes.bool,
        dontDismissOnPress: PropTypes.bool,
        dontDismissOnConfirmPress: PropTypes.bool,
        dontDismissOnCancelPress: PropTypes.bool,
        closeButton: PropTypes.bool,

        title: PropTypes.string,
        body: PropTypes.any,

        positiveText: PropTypes.string,
        negativeText: PropTypes.string,

        onPositivePress: PropTypes.func,
        onNegativePress: PropTypes.func,

        loading: PropTypes.bool,
        hideFooter: PropTypes.bool,

    };

    static defaultProps = {
        shown: false,
        cancelable: true,
        dontDismissOnPress: false,
        dontDismissOnConfirmPress: false,
        dontDismissOnCancelPress: false,

        closeButton: true,
        title: undefined,
        body: undefined,

        positiveText: undefined,
        negativeText: undefined,

        onPositivePress: () => {},
        onNegativePress: () => {},

        loading: false,
        hideFooter: false,
    };


    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.dismiss = this.dismiss.bind(this);

        this.onPositivePress = this.onPositivePress.bind(this);
        this.onNegativePress = this.onNegativePress.bind(this);

        this.state = {
            shown: props.shown,
        }
    }

    toggle() {
        this.setState({shown: !this.state.shown});
    }

    show() {
        this.setState({shown: true});
    }

    hide() {
        this.setState({shown: false});
    }

    dismiss() {
        if (!this.props.dontDismissOnPress) {
            this.setState({shown: false});
        }
    }

    onPositivePress() {
        if (!this.props.dontDismissOnConfirmPress) {
            this.dismiss();
        }
        this.props.onPositivePress();
    }

    onNegativePress() {
        if (!this.props.dontDismissOnCancelPress) {
            this.dismiss();
        }
        this.props.onNegativePress();
    }


    render() {

        let loading = null;
        if (this.props.loading) {
            loading = (
                <div className="dialog-loading" style={{display: 'flex', alignItems: 'center'}}>
                    <Loading
                        className="loading-bubbles"
                        type='bubbles'
                        color='grey'
                    />
                </div>
            );
        }

        return (
            <Modal
                className="modal"
                show={this.state.shown}
                onHide={this.dismiss}>
                {this.props.title || this.props.header ?
                    <Modal.Header
                        className="modal-header"
                        onHide={this.dismiss}
                        closeButton={this.props.closeButton}>
                        <Modal.Title className="modal-title">{this.props.title}</Modal.Title>
                        {this.props.header ? this.props.header : null}
                    </Modal.Header> : null }

                {this.props.body || this.props.children ? <Modal.Body className="modal-body">{this.props.body}{this.props.children}</Modal.Body> : null }

                {this.props.hideFooter ? null : this.props.footer || this.props.positiveText || this.props.negativeText ?
                    <Modal.Footer className="modal-footer">
                        {this.props.negativeText ? <a className="negativeButton" onClick={this.onNegativePress}>{this.props.negativeText}</a> : null}
                        {this.props.positiveText ? <Button className="positiveButton" type="submit" onClick={this.onPositivePress}>{this.props.positiveText}</Button> : null}
                        {this.props.footer ? this.props.footer : null}
                    </Modal.Footer> : null}
                {loading}
            </Modal>
        );
    }
}
