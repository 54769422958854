import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Col, Grid, Row} from 'react-bootstrap';
import Load from '../components/Load';
import {browserHistory} from 'react-router';
import {isAdmin} from '../auth';
import '../App.css';
import TextInput from '../components/common/inputs/TextInput';
import UserSummaryPanel from '../components/UserSummaryPanel';
import {adminFetchUsers} from '../actions/index';
import {ADMIN_USERS_ID} from "../constants/Routes";

class AdminUsersContainer extends Component {

    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);

        this.state = {}
    }

    userPressed(id) {
        browserHistory.push(ADMIN_USERS_ID + id + "/details");
    }

    search() {
        if (this.state.searchValue) {
            this.props.dispatch(adminFetchUsers(this.state.searchValue, this.props.users.cursor));
        }
    }

    onTextChange(e) {
        this.setState({
            searchValue: e.target.value
        })
    }

    onKeyPress(e) {
        if(e.key == 'Enter'){
            this.search();
        }
    }

    render() {

        if (!this.props.user) {
            return (
                <div className="loaderContainer">
                    <Load/>
                </div>
            )
        }

        let users = [];

        for (let property in this.props.users) {
            if (this.props.users.hasOwnProperty(property)) {
                users.push(this.props.users[property])
            }
        }

        let panel = (users || []).map((user) => {
            return <UserSummaryPanel key={user.id} user={user}
                                     userPressed={this.userPressed}/>
        });

        if (isAdmin(this.props.user)) {
            return (
                <Grid className="main-container">
                    <Row>
                        {/*<Col md={3} className="menu"><AdminMenuContainer/></Col>*/}
                        <Col md={12}>
                            Search User
                            <Row className="row-eq-height">
                                <Col xs={12} sm={6}>
                                    <div className="search-bar">
                                        <div className="search-field">
                                            <TextInput onChange={this.onTextChange} onKeyPress={this.onKeyPress}/>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={2} sm={2} className="row-centre-vert">
                                    <Button type="submit" onClick={this.search}>Search</Button>
                                </Col>
                            </Row>
                            {panel}
                        </Col>
                    </Row>
                </Grid>
            );
        }

        return (
            <div className="loaderContainer">
                <h1> Sorry you are not an admin. </h1>
            </div>
        )


    }
}

export default AdminUsersContainer = connect((state) => {
    return {
        isLoading: state.auth.isLoading,
        user: state.data.campableUser,
        users: (state.admindata.users || {})
    }
})(AdminUsersContainer);