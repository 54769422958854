import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {Button} from 'react-bootstrap';

export default class ImageBlockWithButton extends Component {

    render() {

        if (!this.props.block.imageField) {
            return (
                null
            );
        }

        let imageName = this.props.block.imageField.title || "";
        let url = this.props.block.imageField.url || null;
        let imageClass = this.props.block.class || null;
        let imageButtonText = this.props.block.imageButtonText || null;
        let buttonLink = this.props.block.buttonLink || null;
        return (
            <div>
                <div className="image-block-with-button">
                    <img src={url} alt={imageName} className={imageClass}/>
                </div>
                <div className="image-with-button-button">
                    <a onClick={() => browserHistory.push({buttonLink})}><Button>{imageButtonText}</Button></a>
                </div>
            </div>
        );
    }

}