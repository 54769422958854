/**
 * Created by brodynelson on 16/03/17.
 */
import React, {Component} from "react";
import {ControlLabel, FormControl, FormGroup, HelpBlock} from "react-bootstrap";

export default class PriceInput extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(event) {
        let newEvent = event;
        let newTarget = newEvent.target;
        newTarget.value *= 100;
        this.props.input.onChange(newEvent);
    }

    onBlur(event) {
        let newEvent = event;
        let newTarget = newEvent.target;
        newTarget.value *= 100;
        this.props.input.onBlur(newEvent);

    }

    render() {
        let validationState = null;
        let helpBlock;

        if (this.props.meta.touched && this.props.meta.error !== undefined) {
            validationState = 'error';
            helpBlock = <HelpBlock>{this.props.meta.error}</HelpBlock>
        }

        let inputProps = {...this.props};
        delete inputProps.input;
        delete inputProps.meta;

        return (
            <FormGroup readOnly validationState={validationState}>
                <ControlLabel>{this.props.label}{this.props.required ? <span className="required-star">*</span> : null}</ControlLabel>
                <FormControl
                    {...this.props.input}
                    {...inputProps}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    value={this.props.input.value / 100}
                />

                {/*<Cleave className="cleave"*/}
                {/*options={{numericOnly: true, prefix: '$',}}*/}
                {/*{...this.props.input}*/}
                {/*{...inputProps}*/}
                {/*value={this.props.input.value}*/}
                {/*onChange={this.onChange}/>*/}
                {helpBlock}
            </FormGroup>)
    }

}

