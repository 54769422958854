import React, {Component} from 'react';
import {connect} from 'react-redux';
import '../App.css';
import HostBookingsController from '../components/hostBookings/HostBookingsController';
import {cancelHostBooking, fetchHostBookings} from '../actions';
import Load from '../components/Load';
import {Col, Grid, Row} from 'react-bootstrap';
import MenuContainer from './MenuContainer';
import {resetHostBookings} from "../actions/index";

class HostBookingsContainer extends Component {

    constructor(props) {
        super(props);
        this.cancelPressed = this.cancelPressed.bind(this);
    }

    cancelPressed(booking) {
        this.props.dispatch(cancelHostBooking(booking.uid));
    }

    componentDidMount() {
        if (this.props.campableUser) {
            this.props.dispatch(fetchHostBookings(undefined, this.props.campableUser.id));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.campableUser) {
            if (!nextProps.hostBookings) {
                this.props.dispatch(fetchHostBookings(undefined, nextProps.campableUser.id));
            }
        }
    }

    componentWillUnmount() {
        this.props.dispatch(resetHostBookings())
    }

    render() {


        let renderScene = (
            <div className="loaderContainer">
                <Load/>
            </div>);
        if (this.props.hostBookings) {
            renderScene = (
                <HostBookingsController hostBookings={this.props.hostBookings}
                                        cancelPressed={this.cancelPressed}/>
            );
        }

        return (
            <Grid className="main-container">
                <Row>
                    <h1>Guest Bookings</h1>
                    <Col md={3} className="menu"><MenuContainer currentRoute={this.props.route.path}/></Col>
                    <Col md={9}>
                        {renderScene}
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default  HostBookingsContainer = connect((state) => {
    return {
        hostBookings: state.data.hostBookings,
        campableUser: state.data.campableUser,
    }
})(HostBookingsContainer);