/**
 * Created by Chris on 13/03/17.
 */


export const validate = (values) => {
    const errors = {};
    let cardNumber = values.cardNumber;
    let cardExpMonth = values.cardExpMonth;
    let cardExpYear = values.cardExpYear;
    let cardCVV = values.cardCVV;
    if (cardNumber || cardExpMonth || cardExpYear || cardCVV) {
        if (cardNumber) {
            cardNumber = cardNumber.replace(new RegExp('-', 'g'), '');
            if (isNaN(Number(cardNumber))) {
                errors.cardNumber = 'Must be a number';
            } else if (cardNumber.length !== 16) {
                errors.cardNumber = `Card number must be 16 digits. ${cardNumber.length}/16`;
            } else if ((cardCVV || cardExpMonth || cardExpYear) && !cardNumber) {
                errors.cardNumber = `Required`;
            }
        } else {
            errors.cardNumber = `Required`;
        }

        if (cardExpMonth) {
            if (isNaN(Number(cardExpMonth))) {
                errors.cardExpMonth = 'Must be a number';
            } else if (cardExpMonth.length !== 2) {
                errors.cardExpMonth = `Card number must be 2 digits. ${cardExpMonth.length}/2`;
            } else if ((cardNumber || cardCVV || cardExpYear) && !cardExpMonth) {
                errors.cardExpMonth = `Required`;
            }
        } else {
            errors.cardExpMonth = `Required`;
        }

        if (cardExpYear) {
            if (isNaN(Number(cardExpYear))) {
                errors.cardExpYear = 'Must be a number';
            } else if (cardExpYear.length !== 2) {
                errors.cardExpYear = `Card number must be 2 digits. ${cardExpYear.length}/2`;
            } else if ((cardNumber || cardExpMonth || cardCVV) && !cardExpYear) {
                errors.cardExpYear = `Required`;
            }
        } else {
            errors.cardExpYear = `Required`;
        }

        if (cardCVV) {
            if (isNaN(Number(cardCVV))) {
                errors.cvv = 'Must be a number';
            } else if (cardCVV.length !== 3) {
                errors.cardCVV = `Security code must be 3 digits. ${cardCVV.length}/3`;
            } else if ((cardNumber || cardExpMonth || cardExpYear) && !cardCVV) {
                errors.cardCVV = `Required`;
            }
        } else {
            errors.cardCVV = `Required`;
        }
    } else {
        return 1;
    }

    return errors
};


