import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Col} from 'react-bootstrap';
import ListingsItem from './ListingsItem';
import {getLocations} from "../../actions/index";
import {fireECommerceEvent} from "../../tagmanager";
import Load from "../Load";


class CollectionListingsBlock extends Component {

    componentWillMount() {
        if (this.props.block) {
            let tag = this.props.block.collection.tag || null;
            let params = {
                "tags": tag
            }
            this.props.dispatch(getLocations(params));
        }
    }

    componentWillReceiveProps(nextProps){
        if(this.props.locations === null && nextProps.locations !== null && this.props.collection !== undefined){
            let collectionTag = this.props.block.collection.tag || null;
            let locations = [];
            nextProps.locations.map(location => {
                location.tags.map(tag => {
                    if (tag === collectionTag) {
                        locations.push(location)
                    }
                    return null;
                });
                return null;
            });
            fireECommerceEvent(this.props.campableUser, "impressions", "/", locations);
        }
    }

    render() {

        let title = this.props.block.title || null;
        //let buttonText = this.props.content.button || null;
        //let buttonLink = this.props.content.buttonLink || null;

        let collectionTag = this.props.block.collection.tag || null;

        const listItems = [];
        this.props.locations.map(location => {
            location.tags.map(tag => {
                if (tag.trim() === collectionTag.trim()) {
                    listItems.push(<ListingsItem key={location.id} location={location}/>);
                }
                return null;
            });
            return null;
        });

        return (
            <div className="collection-listings-block row">
                <Col xs={12}>
                    { title ? <h4>{title}</h4> : null }
                    {listItems.length > 0 ? listItems : <Load/>}
                </Col>
            </div>
        );
    }

}

export default CollectionListingsBlock = connect(state => {
    return {
        locations: state.data.locations || [],
        campableUser: state.data.campableUser,
        deepCopyFix: state.data.deepCopyFix
    }
})(CollectionListingsBlock)